import theme from "./Theme";
import { alpha } from '@material-ui/core/styles/colorManipulator';
export const drawerWidth = 240;

export const ORDER_COLUMNS = [
    { name: 'orderNumber', title: 'Order', width: '10%', type: 'string' },
    { name: 'orderType', title: 'Type', width: '10%', type: 'string' }
]

export const tabStyle = {
    tabProfile: {
        "&.MuiButtonBase-root.MuiTab-root": {
            color: "#00394D !important",
            backgroundColor: "#E0E0E0",
            borderRadius: 30,
            padding: "0px 17px!important",
            minHeight: "20px!important",
            height: "30px!important",
            textTransform: 'none',
            marginRight: 5,
            minWidth: "auto !important",
            fontSize: 14,
            fontFamily: 'Montserrat', //h2
            fontWeight: 800,
        },
        "&.MuiButtonBase-root.MuiTab-root.Mui-selected": {
            color: "#fff !important",
            backgroundColor: "#72B6CD",
            borderRadius: 30,
            padding: "0px 17px!important",
            minHeight: "20px!important",
            height: "30px!important",
            textTransform: 'none',
            marginRight: 5,
            minWidth: "auto !important",
            fontSize: 14,
            fontFamily: 'Montserrat', //h2
            fontWeight: 800,
        },
    },
    masterTab: {
        "&.MuiButtonBase-root.MuiTab-root": {
            color: theme.palette.color2.main,
            borderRadius: 5,
            padding: "0px 20px!important",
            minHeight: "20px!important",
            height: "30px!important",
            textTransform: 'none',
            marginRight: 5,
            minWidth: "auto !important",
            backgroundColor: alpha(theme.palette.color2.main, 0.3),
            fontSize: 14,
            fontFamily: 'Montserrat', //h2
            fontWeight: 800,
        },
        "&.MuiButtonBase-root.MuiTab-root.Mui-selected": {
            color: "#fff !important",
            backgroundColor: theme.palette.color2.main,
            borderRadius: 5,
            padding: "0px 20px!important",
            minHeight: "20px!important",
            height: "30px!important",
            textTransform: 'none',
            marginRight: 5,
            minWidth: "auto !important",
            fontSize: 14,
            fontFamily: 'Montserrat', //h2
            fontWeight: 800,
        },
    },
}

