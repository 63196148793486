import { Grid, ThemeProvider } from "@mui/material";
import RequestCard from "../../components/Cards/RequestCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import useGetActivitiesByFilter from "../../apiServices/Queries/Activities/GetActivitiesByFilter";
import { useEffect } from "react";
import ActivityList from "./Activities/ActivityList";
import Text from "../../visualComponents/Text";
import NewsCard from "../../components/Cards/NewsCard";
import EventNewsCard from "../../components/Cards/EventNewsCard";

export default function NewsDetails(props) {
    const globals  = useGlobalContext();
    const userId   = globals.state.loggedInUser.userId;
    const history  = useHistory();
    const activities = useGetActivitiesByFilter("all", history.location.state?.data?.id, null, 1, 50);

    useEffect(() => {
        activities.fetchActivities();
    }, [history.location.state]);
    return(
        <ThemeProvider theme={Theme_WIP}>
        <Grid container item xs={12} justifyContent="center">
        <Text item xs={12} variant="body1" bold color="background" id="allNews" align="right" mt={4} sx={{cursor: "pointer"}}
            onClick={history.goBack}
        />
        <Grid container maxWidth="md" p={ThemeConstants.custom.spacings.sm}>
            <Text item xs={12} variant="h1" bold color="background" id="allMyResponses" mb={4}/>
            {history.location.state?.data?.eventId ? <EventNewsCard {...history.location.state}/> : <NewsCard {...history.location.state}/>}

            {!activities.loading && (activities.activities.length == 0 ?
            <Text id="NoResponses" color="background" py={8} align="center" item xs={12}/>
            : <ActivityList 
            onRefresh={activities.fetchActivities}
            activities={activities?.activities?.filter(activity => activity?.activity?.requesterUserProfileId != userId) ?? []}/>)}
        </Grid>
        </Grid>
        </ThemeProvider>
    );
}