
const CircleEventsStyle = (theme) => ({
    logo: {
        width: "100%",
    },
    tabcontent: {
        margin: "0px 0 15px 0",
    },
    chip: {
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5,
        backgroundColor: "#66b2ff",
    },
    top7bot7: {
        marginTop: 7,
        marginBottom: 7,
    },
    pl5: {
        paddingLeft: 5,
    },
});
export default CircleEventsStyle;
