import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider"
import GoTo from "../apiServices/Navigation/NavigateWithReload";

// Get extended info about our current subscription
export function useSubInfo(premiumCta) {
    const globals     = useGlobalContext();
    const history     = useHistory();
    const freemiumCta = () => GoTo(history, "/GetPremium");
    const cancelled   = globals.state.loggedInUser.role.scheduledForCancellation;

    const subsEnum = {
        freemium: {
            identifyer       : "freemium",
            name             : "Freemium"             ,
            LowerCaseRole    : "freemium"             ,
            LowerCaseSubType : "freemium_subscription",
            descriptionId    : "FreemiumDescription"  , //id correlates to a key in translations
            CtaTextId        : "FreemiumCTA"          , //id correlates to a key in translations
            CtaAction        : freemiumCta            ,
            current          : globals.state.loggedInUser.role.role?.toLowerCase() == "freemium" 
                            && globals.state.loggedInUser.role.subType?.toLowerCase() != "freemium_by_admin"
        },
        freemiumByAdmin: {
            identifyer       : "freemiumByAdmin",
            name             : "Freemium"             ,
            LowerCaseRole    : "freemium"             ,
            LowerCaseSubType : "freemium_by_admin"    ,
            descriptionId    : "FreemiumDescription"  , //id correlates to a key in translations
            CtaTextId        : "FreemiumCTA"          , //id correlates to a key in translations
            CtaAction        : freemiumCta            ,
            current          : globals.state.loggedInUser.role.role?.toLowerCase() == "freemium" 
                            && globals.state.loggedInUser.role.subType?.toLowerCase() == "freemium_by_admin"
        },
        premium: {
            identifyer       : "premium",
            name             : "Premium"              ,
            LowerCaseRole    : "premium"              ,
            LowerCaseSubType : "paid_subscription"    ,
            descriptionId    : cancelled? "CancelledDescription" : "PremiumDescription", //id correlates to a key in translations
            CtaTextId        : cancelled? "" : "PremiumCTA", //id correlates to a key in translations
            CtaAction        : () => premiumCta()     ,
            current          : globals.state.loggedInUser.role.role?.toLowerCase() == "premium" 
                            && globals.state.loggedInUser.role.subType?.toLowerCase() == "paid_subscription"
        },
        business: {
            identifyer       : "business",
            name             : "Business"             ,
            LowerCaseRole    : "premium"              ,
            LowerCaseSubType : "premium_subscription_by_admin",
            descriptionId    : "BusinessDescription_" , //id correlates to a key in translations
            CtaTextId        : ""                     , //id correlates to a key in translations
            CtaAction        : () => {}               ,
            current          : globals.state.loggedInUser.role.role?.toLowerCase() == "premium" 
                            && globals.state.loggedInUser.role.subType?.toLowerCase() == "premium_subscription_by_admin"
        },
        initialTrial: {
            identifyer       : "initialTrial",
            name             : "Premium trial"        ,
            LowerCaseRole    : "premium"              ,
            LowerCaseSubType : "premium_trial_subscription",
            descriptionId    : "InitTrialDescription"  , //id correlates to a key in translations
            CtaTextId        : "FreemiumCTA"          , //id correlates to a key in translations
            CtaAction        : freemiumCta            ,
            current          : globals.state.loggedInUser.role.role?.toLowerCase() == "premium" 
                            && globals.state.loggedInUser.role.subType?.toLowerCase() == "premium_trial_subscription"
                            && globals.state.loggedInUser.acceptedInvites < 1
                            && !globals.state.loggedInUser.extendedTrial
        },
        extendedTrial: {
            identifyer       : "extendedTrial",
            name             : "Premium trial"        ,
            LowerCaseRole    : "premium"              ,
            LowerCaseSubType : "premium_trial_subscription",
            descriptionId    : "ExtTrialDescription"  , //id correlates to a key in translations
            CtaTextId        : "FreemiumCTA"          , //id correlates to a key in translations
            CtaAction        : freemiumCta            ,
            current          : globals.state.loggedInUser.role.role?.toLowerCase() == "premium" 
                            && globals.state.loggedInUser.role.subType?.toLowerCase() == "premium_trial_subscription"
                            && globals.state.loggedInUser.acceptedInvites < 1
                            && globals.state.loggedInUser.extendedTrial
        },
        longTrial: {
            identifyer       : "longTrial",
            name             : "Premium trial"        ,
            LowerCaseRole    : "premium"              ,
            LowerCaseSubType : "premium_trial_subscription",
            descriptionId    : "LongTrialDescription" , //id correlates to a key in translations
            CtaTextId        : "FreemiumCTA"          , //id correlates to a key in translations
            CtaAction        : freemiumCta            ,
            current          : globals.state.loggedInUser.role.role?.toLowerCase() == "premium" 
                            && globals.state.loggedInUser.role.subType?.toLowerCase() == "premium_trial_subscription"
                            && globals.state.loggedInUser.acceptedInvites > 0
        },
    }
    const subs = [subsEnum.freemium, subsEnum.freemiumByAdmin, subsEnum.business, subsEnum.premium, subsEnum.initialTrial, subsEnum.extendedTrial, subsEnum.longTrial];

    return {current: globals.state.loggedInUser.role.loading? null : subs.find(s => s.current), all: subsEnum};
}