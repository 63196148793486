
const CircleInfoStyle = (theme) => ({
    root: {
        width: "100%",
    },
    tabcontent: {
        margin: "8px 0px 15px 0",
        padding: "20px !important",
    },

    mt7: {
        marginTop: 7,
    },
    p25: {
        marginTop: 25,
    },
    margin: {
        marginRight: 5,
    },
    btxt15: {
        color: "#72B6CD",
        marginTop: 15,
    },
    chip: {
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5,
        fontWeight: 700,
        backgroundColor: theme.palette.color4.main,
        borderColor: theme.palette.color4.main,
    },
    p15: {
        marginTop: 15,
        noWrap: true,

    },
    iconGlobe: {
        color: theme.palette.color2.main,
        fontSize: 14,
    },
    iconGlobelink: {
        position: "relative",
        bottom: 3,
        left: 5,
        color: theme.palette.color2.main,
    },
    W100: {
        width: "94%",
        margin: "0px 3% 2% 3%",
    },
    noAccordian: {
        padding: 16,
        width: "100%",
    },
    Select: {
        width: "100%"
    }
});
export default CircleInfoStyle;
