import theme from "../container/Theme";

const HamburgerStyle = (_theme) => ({

    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default,
        zIndex: _theme.zIndex.drawer + 1,
    },
    backdrop: {
        backgroundColor: theme.palette.background.default,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginTop: "50px",
        marginBottom: _theme.spacing(13)

    },


});
export default HamburgerStyle;