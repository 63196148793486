import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { gql } from "@apollo/client";

export function useMatchProfilesOfCurrentUser() {
    const {loading, error, data} = useQuery( gql`query get {
        matchProfiles{
          id
          userId
          llmResponse
          myProfileType
          createdDate
          fields {
            who
            what
            why
            forWhom
            extra
          }
          createdDate
          myProfileType
        }
      }` );
    const [result, setResult] = useState(undefined);
    useEffect(() => {
      if(!loading && data != undefined && data.matchProfiles != undefined)
        setResult(data.matchProfiles);
    }, [loading]);
    return [result, loading, (error != undefined)];
  }
  