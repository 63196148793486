import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import { CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import { useHistory, useLocation } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { JOIN_EVENT } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { ClearCache, GetUserId } from "../../apiServices/CommonMethods";
import FormContainer from "../../components/Forms/FormContainer";
import InputSection from "../../components/Forms/InputSection";

export default function JoinEvent() {
  const userId = GetUserId();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const [eventData, setEventData] = React.useState({});
  const [joinEvent, { data, loading, error }] = useMutation(JOIN_EVENT);

  React.useEffect(() => {
    if (location.state && typeof location.state.eventDetails !== "undefined") {
      setEventData(location.state.eventDetails);
    }
  }, [location]);
  let [input, setInput] = React.useState({
    messages: "",
  });
  let [inputError, setInputError] = React.useState({
    messages: "",
  });

  const updateTextField = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const joinEventAction = async () => {
    let isValid = await checkForErrors();
    const variable = {
      messages: input["messages"].trim(),
      loginProfileId: userId,
      id: eventData.id,
      participantScopeDesc: "Guest event",
    };
    // console.log("Variable :", variable);
    if (isValid) {
      joinEvent({
        variables: variable,
        onCompleted: () => ClearCache("allEvents")
      });
    }
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      messages: !input["messages"].trim() ? "messageError" : "",
    }));
    return input["messages"].trim();
  };
  const handleSucessAlert = async () => {
    // history.push("/dashboard/eventDetails", {
    //   selectedEventId: eventData.id,
    // });
    history.push("/dashboard/eventDetails/?id=" + eventData.id, {
      selectedEventId: eventData.id,
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer
        modalMessage = {data?.joinEvent?.errorCode ==  0 ? <FormattedMessage id={"RequestSentSuccessfully"} /> : undefined}
        onModalClose = {handleSucessAlert}
        error        = {data?.joinEvent?.errorCode == -1 ? data.joinEvent.message : undefined}
        title        = {<FormattedMessage id="JoinEventNameAsAGuest"/>}
        description  = {<FormattedMessage id="JoinEvent_Description"/>}
        loading      = {loading}
        onSave       = {joinEventAction}
        disabled     = {loading || !input.messages}
        onCancel     = {() => history.goBack()}
        saveId       = "Button_text_Send"
      >
        <InputSection
        type        = "textarea"
        disabled    = {loading}
        id          = "messages"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<FormattedMessage id="writeAMessage"/>}
        onChange    = {updateTextField}
        hint        = {inputError["messages"] ? <FormattedMessage id="pleaseEnterMessage" /> : undefined}
        max         = {1000}
        />
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
