import React, { useEffect } from "react";
import {
  MenuItem,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import {
  ACCEPT_EVENT_JOIN,
  DECLINE_EVENT_JOIN,
} from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";
import Text from "../../../visualComponents/Text";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";

export default function ActivityEventJoinAsGuest({activity, source, isLess, onRefresh, color}) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const history = useHistory();
  const [acceptInvitation, { data: dataAccept, loading: loadingAccept }] =
    useMutation(ACCEPT_EVENT_JOIN);
  const [declineInvitation, { data: dataDecline, loading: loadingDecline }] =
    useMutation(DECLINE_EVENT_JOIN);

  useEffect(() => {
    if (dataAccept || dataDecline) {
      onRefresh();
    }
  }, [dataAccept, dataDecline]);

  const acceptInvitationAction = (meeting) => {
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    acceptInvitation({
      variables: variables,
    });
  };
  const declineInvitationAction = () => {
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    declineInvitation({
      variables: variables,
    });
  };
  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };
  const navigateToEventsDetail = () => {
    history.push("/dashboard/eventDetails/?id=" + activity.activity.eventId, {
      selectedEventId: activity.activity.eventId,
    });
  };
  
  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  if(source == "menu")
    return <>
      {activity?.activity?.status !== ActivityStatus.activityJoinAsGuestAccepted &&
       activity?.activity?.status !== ActivityStatus.activityJoinAsGuestDeclined && (
        <MenuItem onClick={acceptInvitationAction}>
          <FormattedMessage id="Accept" />
        </MenuItem>
      )}
      {activity?.activity?.status !== ActivityStatus.activityJoinAsGuestAccepted &&
       activity?.activity?.status !== ActivityStatus.activityJoinAsGuestDeclined && (
        <MenuItem onClick={declineInvitationAction}>
          <FormattedMessage id="Decline" />
        </MenuItem>
      )}
      <MenuItem onClick={navigateToMessage}>
        <FormattedMessage id="Message" />
      </MenuItem>
    </>;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity.activity &&
        <Text item xs={12} color={color??"background"}>
          <FormattedMessage id={"JoinAsGuest_card_title"} />
          <Link
            color="inherit"
            component="button"
            underline="always"
            onClick={navigateToEventsDetail}
          >
            {activity.activity.eventName}
          </Link>
        </Text>
      }
      <Text item xs={12} color={color??"background"}>
        {isLess &&
          activity?.activity?.messages &&
          activity.activity.messages.length > 150 ? 
            activity.activity.messages.substring(0, 150) + " "
          : activity.activity.messages
        }
      </Text>
    </ThemeProvider>
  );
}
