import { GET_ALL_CIRCLES_WITH_FILTER } from "../../Queries";
import { useCachedQuery } from "../CachedQuery";
import { ClearCache } from "../../CommonMethods";

export function useUserCircles(userId) {
  return useListAllCircles(
    {
      loggedInUser: userId,
      memberId: userId,
      pageSize: 100,
      pageNumber: 1
    },
    5,
    true
  );
}
    
export function useListAllCircles(variables, expires = 15, useLocalstorage = false) {
  return useCachedQuery(
      "allCircles",
      GET_ALL_CIRCLES_WITH_FILTER,
      variables,
      (data) => data?.allCircles?.result?.circles,
      expires,
      useLocalstorage
  );
}
export function ClearListAllCirclesCache() {
  ClearCache("allCircles");
}
