import { gql } from "@apollo/client";
import { useMutationBase } from "../../mutations";

export function useUpdateMetaData(userId, connector) { 
    return useMutationBase(gql`
    mutation UpdateMetaData($userId: String!, $metaData: MetaDataInput) {
        updateMetaData(userId: $userId, metaData: $metaData)
    }`, 
    {
     noCallOnStart: true,
     variables: { 
        userId:   userId,
        metaData: {
            connector: connector
        }
    },
     errorPolicy: "ignore"});
}