import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Filters from "../components/Filters";
import RequestCard from "../components/Cards/RequestCard";
import { Stack, ThemeProvider } from "@mui/material";
import { MastersTabItems, userRoles } from "../apiServices/Constants";
import RequestsStyle from "../styles/container/RequestsStyle";
import { Waypoint } from "react-waypoint";
import { FormattedMessage, useIntl } from "react-intl";
import GoTo from "../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import NewRequestCard from "../components/Cards/NewRequestCard";
import Text from "../visualComponents/Text";
import Theme_WIP, { ThemeConstants } from "../styles/themes/main/Theme_WIP";
import { useListRequests } from "../apiServices/Queries/Collabs/GetCollabRequests";
import { ClearCache } from "../apiServices/CommonMethods";
import { MarkerEntitySources } from "../components/General";

const useStyles = makeStyles(RequestsStyle);

export default function Requests() {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => globals.functions.setMarker({
    lastPageVisitedUponCreation: MarkerEntitySources.REQUEST_FEED, lastEntityVisitedUponCreationId: undefined, flags: []
  }), []);

  const [tabType, setTabType] = React.useState({
    type: intl.formatMessage({ id: MastersTabItems[0].type }),
    key: MastersTabItems[0].key,
  });

  const location = useLocation();

  const [requests, setRequests] = React.useState([]);
  const [requestTabItemList, setRequestTabItemList] = React.useState([]);
  const [selectedCircle, setSelectedCircle] = React.useState({
    type: null,
    key: null,
  });
  const [selectedRequestId, setSelectedRequestId] = React.useState(null);

  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: null,
    pageNumber: 1,
    locationPlaceID: null,
    matchesOnly: false
  });
  const getVariable = () => ({
    userId: null,
    pageNumber: input["pageNumber"],
    location: input["location"],
    industries: input["industries"],
    skills: input["skills"],
    maxDistance: input["maxDistance"],
    collaborationType: input["collaborationType"],
    businessTypes: input["businessType"],
    searchInput: input["searchInput"] ?? "",
    pageSize: parseInt(10),
    circleId: selectedCircle.key,
    requestId: selectedRequestId,
    eventId: null,
    locationPlaceID: input["locationPlaceID"],
    matchesOnly: input["matchesOnly"],
    loginProfileId: userId,
    filterType: input.filterType,
    profileReqOnly: null
  });

  const {data: req, loading: loading, refetch: refetch, updateVariables} = useListRequests(getVariable());
  const reload = () => {
    globals.functions.loggedInUser.refetchRole(); 
    ClearCache("allCollabRequests");
    ClearCache("circle_feed", globals);
    setInput({...input, pageNumber: 1});
    setRequests([]);
    setTimeout(() => refetch({...getVariable(), pageNumber: 1}), 100);
  };

  useEffect(() => updateVariables(getVariable()), [input, selectedCircle, selectedRequestId]);
  useEffect(() => {
    if(!req || (!req.length && input.pageNumber != 1))
      return;
    if (input["pageNumber"] === 1)
      setRequests(req);
    else
      setRequests(prevState => [
        ...prevState,
        ...req,
      ]);
  }, [req]);

  function handleCircleData () {
    const circleTabItems = !globals.state.loggedInUser.circles ? [] : globals.state.loggedInUser.circles.map(
      circle => {
        return {
          type: circle.name,
          key: circle.id,
        };
      }
    );
    const masterTabList = MastersTabItems.map(item => {
      return {
        type: intl.formatMessage({
          id: item.type,
        }),
        key: item.key,
      };
    });
    setRequestTabItemList([...masterTabList, ...circleTabItems]);
  }
  useEffect(() => {
    handleCircleData();
  }, []);

  const handleChange = (event, newValue) => {
    // console.log("newvalue", newValue);
    setIsLoading(false)
    setSelectedRequestId(null)
    setInput((prevState) => ({
      ...prevState,
      pageNumber: 1,
    }));
    let filteredTab = requestTabItemList.filter(tabItem => {
      return tabItem.type === newValue;
    });

    setTabType(filteredTab[0]);
    if (filteredTab[0].type !== intl.formatMessage({ id: "filter_title_mymatches" }) &&
      filteredTab[0].type !== intl.formatMessage({ id: "filter_title_all" })) {
      setSelectedCircle(filteredTab[0]);
      setInput((prevState) => ({
        ...prevState,
        matchesOnly: false,
      }));
    } else {
      setSelectedCircle({
        type: null,
        key: null,
      });
      setInput((prevState) => ({
        ...prevState,
        matchesOnly: filteredTab[0].type === intl.formatMessage({ id: "filter_title_mymatches" }),
      }));
    }
    setRequests([]);
    globals.functions.loggedInUser.refetchRole();

  };

  useEffect(() => {
    globals.functions.loggedInUser.refetchRole();
    // console.log("locationKeyRequest", location.key);
    if (location.state && typeof location.state.selectedRequestId !== "undefined" &&
      location.key !== localStorage.getItem("locationKeyRequest")) {
      setSelectedRequestId(location.state.selectedRequestId);
      // console.log("selectedRequestId", location.state.selectedRequestId);
    }
    localStorage.setItem("locationKeyRequest", location.key);

  }, [location]);

  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };

  const filterAction = inputs => {
    setInput({
      ...inputs,
      pageNumber: 1,
    });
  };

  const navigateToProfile = request => {
    if (userRole.role == userRoles.freemium &&
      request.sharedCircles.length <= 0) {
      navigateToGetPremium();
    } else {
      history.push("/dashboard/profile/" + request.userId, {
        selectedUserId: request.userId,
      });
    }
  };

  return (
    <>
      <NewRequestCard mt={10} onCompleted={reload}/>

        <ThemeProvider theme={Theme_WIP}>
          <Grid container mt={ThemeConstants.custom.spacings.lg}>
            <Text item xs={12} bold color="background" pt={3} mb={ThemeConstants.custom.spacings.xs} id="SharedRequests" sx={{":not(p)":{borderTop: "solid 1px " + Theme_WIP.palette.lightPetrol.main}}}/>
          </Grid>
        </ThemeProvider>

        <Filters search includeSkills includeRequestFilters onSearch={filterAction} tab={tabType.type} tabs={requestTabItemList} onTab={handleChange} useRefresh/>

        <Grid container spacing={2} mt={ThemeConstants.custom.spacings.md}>
          <Grid container item xs={12} sm={12}>
            {(loading) &&
              !requests.length ? (
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <CircularProgress />
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.title}
                  ></Typography>
                </Stack>
              </Box>
            ) : requests.length > 0 ? (
              <Grid container gap={ThemeConstants.custom.spacings.md}>
                {requests.map(
                  (card, index) => (
                    <React.Fragment key={index}>
                      <Grid container item xs={12} sm={12} key={index}>
                        <RequestCard
                          userRole={userRole}
                          request={card}
                          fromProfile
                          onRefresh={reload}
                          onUserSelection={navigateToProfile}
                        />
                      </Grid>
                      {index ===
                        requests.length -
                        2 && (
                          <Waypoint
                            scrollableAncestor={window}
                            onEnter={() => {
                              if(loading)
                                return;
                              const newPage = input["pageNumber"] + 1;
                              setIsLoading(true);
                              setInput(prevState => ({
                                ...prevState,
                                pageNumber: newPage,
                              }));
                            }}
                          />
                        )}
                    </React.Fragment>
                  )
                )}
              </Grid>
            ) : (
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <Typography
                  component="span"
                  variant="h3"
                  color={"textPrimary"}
                >
                  {selectedRequestId !== null ?
                    <FormattedMessage id={"Removed_NoData_Message"} /> :
                    <FormattedMessage id={"noDataAvailable"} />}
                </Typography>
              </Box>
            )}
            {isLoading && !requests.length ? (
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <CircularProgress />
                </Stack>
              </Box>
            ) : null}
          </Grid>
        </Grid>
    </>
  );
}
