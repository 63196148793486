import { gql } from "@apollo/client";
import { useCachedQuery } from "../CachedQuery";

export function useGetFlag(flag) {
return useCachedQuery(
    "flag",
    gql`
        query flag($flag: String) {
            flags(flag: $flag)
        }
    `,
    { flag: flag },
    (data) => data?.flags,
    15,
    false
);
}
  