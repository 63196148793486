import React, { useEffect } from "react";
import { CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import { useHistory, useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { INVITE_TO_ALL_CIRCLE_MEMBERS } from "../../apiServices/Queries";
import { useLazyQuery } from "@apollo/client";
import { GetUserId } from "../../apiServices/CommonMethods";
import FormContainer from "../../components/Forms/FormContainer";

export default function InviteAllCirclesToEvent() {
  const history = useHistory();
  const userId = GetUserId();
  const location = useLocation();

  const [circleId , setCircleId] = React.useState("");
  const [eventId  , setEventId] = React.useState("");
  const [eventData, setEventData] = React.useState({});


  const getVariables = () => {
    const variable = {
      loginUserId    : userId,
      circleId       : circleId,
      eventId        : eventId,
      requestedUserId: userId
    };
    return variable;
  };
  const [sendInviteAll, { loading, data }] =
    useLazyQuery(INVITE_TO_ALL_CIRCLE_MEMBERS, {
      variables: getVariables(),
    });

  useEffect(() => {
    if (location.state && typeof location.state.eventDetails !== "undefined") {
      setEventData(location.state.eventDetails)
      setEventId(location.state.eventDetails.id)
      setCircleId(location.state.eventDetails.circleId)
    }
  }, [location, data]);

  const onSendInvite = () => {

    sendInviteAll()
  }
  const handleSucessAlert = () => {
    history.push("/dashboard/eventDetails/?id=" + eventData.id, {
      eventDetails: eventData,
      selectedTab: 4,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        modalMessage = {data?.inviteAllCircleMembersForEvent?.errorCode ==  0 ? <FormattedMessage id={"IntroductionSentSuccessfully"} /> : undefined}
        onModalClose = {handleSucessAlert}
        error        = {data?.inviteAllCircleMembersForEvent?.errorCode == -1 ? data.createIndroductionActivity.message : undefined}
        title        = {<FormattedMessage id="InviteAllCircleMembersToYourEvent"/>}
        description  = {<FormattedMessage id="InviteAllCirclesToEvent_Description" values={{br: <br/>}}/>}
        loading      = {loading}
        onSave       = {onSendInvite}
        disabled     = {loading}
        onCancel     = {() => history.goBack()}
        saveId       = "Button_text_Send"
      >
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
