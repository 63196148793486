
const RegisterStyle = (theme) => ({
    h1: {
        marginTop: theme.spacing(20),
        fontSize: "40px",
        fontWeight: 500,
        color: theme.palette.background.specialOverlay
    },
    text: {
        color: theme.palette.secondary.main,
    },
    login: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        textDecorationLine: "underline"
    },
    customBg: {
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        position: "fixed",
        backgroundColor: theme.palette.background.dark,
        zIndex: "-100"
    },
    inputField: {
        bottom: 5,
        backgroundColor: theme.palette.background.default,
        borderRadius: "0",
        '& .MuiOutlinedInput-root' : {
            borderRadius: "0"
        },
        '& .MuiOutlinedInput-notchedOutline' : {
            border: "none"
        }
    },
    container: {
        borderRadius: "20px 20px 20px 0px",
        backgroundColor: theme.palette.background.overlay,
        alignSelf: "center",
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        position: "relative",
    },
    root: {
    },
    input: {
        textAlign: 'center',
        width: "100%",
        justifyContent: "center",
        marginBottom: theme.spacing(5)
    },
    inputText: {
        color: theme.palette.secondary.main,
        fontStyle: "italic"
    },
    title: {
        alignSelf: "center",
        fontSize: "22px",
        fontWeight: 700,
        lineHeight: "30px"
    },
    description: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: "0px 10vw"
    },
    buttonSave: {
        marginTop: theme.spacing(2),
        alignSelf: "center",
        width: "141px",
        height: "40px",
        '& .MuiButton-root': {
            height: "100%"
        }
    },
    buttonLinkedIn: {
        padding: '6px',
        marginTop: theme.spacing(5),
        textAlign: 'left',
    },
    buttonGoogle: {
        padding: '6px',
        margin: '7px 0 10px 0',
    },
    textFiledBoxStyle: {
        [theme.breakpoints.up('sm')]: {
            width: "50%"
        },
    }
});
export default RegisterStyle;