import React, { useEffect, useRef } from 'react';

export default function AuthProvider({ userManager: manager, children }) {
  let userManager = useRef();

  useEffect(() => {
    userManager.current = manager

    const onUserLoaded = (user) => {
      // console.log("user loaded:", user)
      if (user && typeof user["profile"] !== "undefined") {
        localStorage.setItem("token", user["profile"].sub);
        localStorage.setItem("accessToken", user["access_token"]);
        localStorage.setItem("refreshToken", user["refresh_token"])
      }
    }

    const onUserUnloaded = () => {
      // console.log(`user unloaded`)
    }

    const onAccessTokenExpiring = () => {
      // console.log(`user token expiring`)
      localStorage.setItem("expiring", "doNotReload")
      userManager.current.signinSilent()
    }

    const onAccessTokenExpired = () => {
      // console.log(`user token expired`)
      userManager.current.signinSilent()
    }

    const onUserSignedOut = () => {
      // console.log(`user signed out`)
      // userManager.current.clearStaleState()
      // userManager.current.removeUser()
      // userManager.current.signoutRedirect()
    }

    // events for user
    userManager.current.events.addUserLoaded(onUserLoaded)
    userManager.current.events.addUserUnloaded(onUserUnloaded)
    userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring)
    userManager.current.events.addAccessTokenExpired(onAccessTokenExpired)
    userManager.current.events.addUserSignedOut(onUserSignedOut)

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.current.events.removeUserLoaded(onUserLoaded);
      userManager.current.events.removeUserUnloaded(onUserUnloaded);
      userManager.current.events.removeAccessTokenExpiring(onAccessTokenExpiring)
      userManager.current.events.removeAccessTokenExpired(onAccessTokenExpired)
      userManager.current.events.removeUserSignedOut(onUserSignedOut)
    };
  }, [manager]);

  return (
    React.Children.only(children)
  )
}