import * as React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Card, CardContent, Stack, Link } from "@mui/material";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import { Container, CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../Navigationbar";
import Header from "../Header";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import GetPremiumStyle from "../../styles/component/Accountsettings/GetPremiumStyle";
import { GET_FEATURES_LISTS } from "../../apiServices/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { MOLLIE_INITIAL_PAYMENT } from "../../apiServices/mutations";
import { CircularProgress } from "@mui/material";
import { PAYMENT_PAGE_URL } from "../../Config";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";

const useStyles = makeStyles(GetPremiumStyle);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default function GetPremium(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [selectedCard, setselectedCard] = React.useState(0);
  const [featureList, setFeatureList] = React.useState([]);
  const [selectedFeature, setSelectedFeature] = React.useState(null);

  const { data, loading: loadingFeaturesList } = useCachedQuery(
    "features",
    GET_FEATURES_LISTS,
    { getAllPlans: true },
    (data) => data?.featureListForUserType?.result,
    180,
    true
  );
  React.useEffect(() => {
    if (!data) return;
    const premimumList = data.filter((value) => {
      return value.planName === "Premium"
    })
    setFeatureList(premimumList)
    setSelectedFeature(premimumList[0])
  }, [data])

  const [initiatePayment, { loading: loadingInitialPayment, }] = useMutation(MOLLIE_INITIAL_PAYMENT, {
    onCompleted: (dataInitiatePayment) => {
      if (typeof dataInitiatePayment !== "undefined" &&
        dataInitiatePayment.mollieInitialPayment.errorCode === 0
      ) {
        // console.log("dataInitiatePayment", dataInitiatePayment)
        redirectToMollie(dataInitiatePayment.mollieInitialPayment.result._links.checkout.href)
      }
    },
  }
  );

  const handleCardChange = (newValue) => {
    setselectedCard(newValue);
    setSelectedFeature(featureList[newValue])
  };
  const startPayment = () => {
    // console.log("selectedFeature", selectedFeature)

    const variable = {
      userId: userId,
      planId: selectedFeature.planId
    }
    // console.log("Variable", variable)
    initiatePayment({
      variables: variable,
    });
  };
  const redirectToMollie = (url) => {
    window.location.replace(url)
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(4), mb: theme.spacing(4) }}>
          {loadingFeaturesList || loadingInitialPayment ? (
            <Box sx={{ width: "50%" }}>
              <CircularProgress color="primary" style={{ float: "right" }} />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item sm={10} xs={10}>
                <Typography component="h1" variant="h1" color={"textPrimary"}>
                  Haal het beste uit je netwerk!
                </Typography>
              </Grid>
              <Grid item sm={2} xs={2}>
                <CloseIcon
                  className={classes.closeicon}
                  onClick={() => history.goBack()}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Typography
                  component="h4"
                  variant="h4"
                  color={"textPrimary"}
                  className={classes.mt10}
                >
                  Kies Premium
                </Typography>
              </Grid>
              {/* <Grid item sm={6} xs={6}>
              <Card
                sx={{
                  border: selectedCard === 0 ? "1px solid #006080" : "",
                  backgroundColor: selectedCard === 0 ? "#FFF3E4" : "",
                }}
                onClick={() => {
                  handleCardChange(0);
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    7,50 EUR/ maand
                  </Typography>

                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    display="inline"
                  >
                    Betaal per jaar
                  </Typography>
                  <Typography
                    component="body"
                    variant="body"
                    color={"textPrimary"}
                    className={classes.blueColor}
                    display="inline"
                  >
                    (bespaar 17%)
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
              {featureList.map((option, index) => (
                <Grid item sm={6} xs={6}>
                  <Card
                    sx={{
                      border: selectedCard === index ? "1px solid #006080" : "",
                      backgroundColor: selectedCard === index ? "#FFF3E4" : "",
                    }}
                    onClick={() => {
                      handleCardChange(index);
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h1"
                        color={"textPrimary"}
                        display="inline"
                        gutterBottom
                      >
                        {option.planAmount.replace('.', ',') + ' EUR'}
                      </Typography>
                      <Typography variant="h5" color={"textSecondary"} display="inline" gutterBottom>
                        {" / maand"}
                      </Typography>
                      <div style={{ height: 10 }} />
                      <Typography variant="h5" color={"textSecondary"} display="inline">
                        {option.discount !== "0.00" ? "Betaal per jaar" : "Betaal per maand"}
                      </Typography>
                      {option.discount !== "0.00" &&
                        <Typography
                          component="body"
                          variant="body"
                          color={"textPrimary"}
                          className={classes.blueColor}
                          display="inline"
                        >
                          {" (bespaar " + option.discount.replace('.', ',') + "%)"}
                        </Typography>}
                    </CardContent>
                  </Card>
                </Grid>
              ))}

              {selectedFeature ? (
                <Grid item sm={12} xs={12}>
                  {selectedFeature.features.map((option, index) => (
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <CheckIcon />
                      <Typography
                        component="h5"
                        variant="h5"
                        color={"textSecondary"}
                        className={classes.checkContent}
                      >
                        {option.featureName}
                      </Typography>
                    </Stack>
                  ))
                  }
                </Grid>
              ) : null}

              {/* {selectedCard === 1 ? (
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={1} className={classes.mt7}>
                    <Grid item sm={1} xs={1}>
                      <CheckIcon />
                    </Grid>
                    <Grid item sm={11} xs={11}>
                      <Typography
                        component="h5"
                        variant="h5"
                        color={"textSecondary"}
                        className={classes.checkContent}
                      >
                        Zoeken naar Masters
                      </Typography>
                    </Grid>

                    <Grid item sm={1} xs={1}>
                      <CheckIcon />
                    </Grid>
                    <Grid item sm={11} xs={11}>
                      <Typography
                        component="h5"
                        variant="h5"
                        color={"textSecondary"}
                        className={classes.checkContent}
                      >
                        Onbeperkt matchen
                      </Typography>
                    </Grid>
                    <Grid item sm={1} xs={1}>
                      <CheckIcon />
                    </Grid>
                    <Grid item sm={11} xs={11}>
                      <Typography
                        component="h5"
                        variant="h5"
                        color={"textSecondary"}
                      //className={classes.checkContent}
                      >
                        Oproepen posten en beantwoorden
                      </Typography>
                    </Grid>
                    <Grid item sm={1} xs={1}>
                      <CheckIcon />
                    </Grid>
                    <Grid item sm={11} xs={11}>
                      <Typography
                        component="h5"
                        variant="h5"
                        color={"textSecondary"}
                        className={classes.checkContent}
                      >
                        Onbeperkte 1:1's (ook tijdens events)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null} */}

              <Grid item sm={12} xs={12}>
                <Typography
                  component="h5"
                  variant="h5"
                  color={"textSecondary"}
                  className={classes.mt3}
                >
                  <Link href="#"
                    underline="hover"
                    color="gray"
                    onClick={() => {
                      window.open(PAYMENT_PAGE_URL, "_blank");
                    }}>
                    Bekijk alle functionaliteiten
                  </Link>
                </Typography>
              </Grid>

              {/* <Grid item xs={12} sm={12}>
              <Typography
                component="h4"
                variant="h4"
                color={"textPrimary"}
                className={classes.mt3}
              >
                Kies een betaalmethode
              </Typography>
              <Tabs
                value={tabvalue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  icon={
                    <Box
                      sx={{
                        padding: "25px !important",
                        border: "1px solid #E0E0E0",
                        boxShadow: "0 4px 8px 0 #E0E0E0, 0 6px 3px 0 #E0E0E0",
                        borderRadius: "4px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={"/assets/img/IDEAL_Logo.png"}
                        alt="mastermatch_logo"
                        width={70}
                        height={30}
                        className={classes.logo}
                      />
                    </Box>
                  }
                />
                <Tab
                  icon={
                    <Box
                      sx={{
                        paddingLeft: "5px!important",
                        paddingTop: "32px !important",
                        paddingBottom: "32px !important",
                        paddingRight: "5px !important",
                        border: "1px solid #E0E0E0",
                        boxShadow: "0 4px 8px 0 #E0E0E0, 0 6px 20px 0 #E0E0E0",
                        borderRadius: "4px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={"/assets/img/visa.png"}
                        alt="mastermatch_logo"
                        width={20}
                        height={20}
                        className={classes.logo}
                      />
                    </Box>
                  }
                />
              </Tabs>
            </Grid> */}
              {/* <Grid item xs={12} sm={12}>
              <TabPanel value={tabvalue} index={0}>
                <Grid container spacing={4}>
                  <Grid item sm={12} xs={12}>
                    <Box sx={{ minWidth: 120 }} className={classes.mt3}>
                      <FormControl className={classes.Select}>
                        <Typography
                          component="h4"
                          variant="h4"
                          color={"textPrimary"}
                        >
                          IDeal
                        </Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          name="isOrganizer"
                          size="small"
                          className={classes.mt7}
                        >
                          {["No", "Yes"].map((option, index) => (
                            <MenuItem value={option} key={index}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TabPanel value={tabvalue} index={1}>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <Typography
                      component="h4"
                      variant="h4"
                      color={"textPrimary"}
                    //className={classes.mt20}
                    >
                      Creditcard
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    // className={classes.mt20}
                    >
                      Name on the card:*
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.mt7}
                      gutterBottom
                    >
                      <FormControl variant="filled" className={classes.Select}>
                        <TextField
                          label="Type here"
                          variant="filled"
                          size="small"
                          id={"name"}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    //className={classes.mt20}
                    >
                      Creditcard number:*
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.mt7}
                      gutterBottom
                    >
                      <FormControl variant="filled" className={classes.Select}>
                        <TextField
                          label="Type here"
                          variant="filled"
                          size="small"
                          id={"cardno"}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    //className={classes.mt20}
                    >
                      Expiry date:*
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.mt7}
                      gutterBottom
                    >
                      <FormControl variant="filled" className={classes.Select}>
                        <DatePicker />
                      </FormControl>
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    // className={classes.mt20}
                    >
                      CVV:*
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.mt7}
                      gutterBottom
                    >
                      <FormControl variant="filled" className={classes.Select}>
                        <TextField
                          label="Type here"
                          variant="filled"
                          size="small"
                          id={"name"}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid> */}
              <Grid item sm={3} xs={12}>
                <Box>
                  <ButtonDarkBlue onClick={startPayment} >
                    <FormattedMessage id={"Pay"} />
                  </ButtonDarkBlue>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textSecondary"}
                  className={classes.mt7}
                >
                  <FormattedMessage id={"Price_description"} />
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}
