import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { CardMedia, Chip } from "@material-ui/core";
import Styles from "../../styles/component/Cards/MatchCardStyle";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(Styles);

export default function MatchCard(props) {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  useEffect(() => {
    // console.log("profile details", props.data);
  }, [props]);
  const navigateToProfile = (data) => {
    // console.log(data);
    if (data.profiles.givenName !== null) {
      history.push("/dashboard/profile", {
        selectedUserId: data.profiles.userId,
      });
    }
  };
  return (
    <Paper elevation={3} className={classes.tabcontent}>
      <Grid container spacing={4}>
        <Grid item xs={2} sm={2}>
          <CardMedia
            component="img"
            image={
              props.data.profiles.profilePictureUrl ??
              "/assets/img/userProfile.png"
            }
            alt="green iguana"
            className={classes.logosmall}
            onClick={() => navigateToProfile(props.data)}
          />
        </Grid>
        <Grid item xs={10} sm={10}>
          <Typography component="h3" variant="h3" color={"textPrimary"}>
            {props.data.profiles.givenName +
              " " +
              props.data.profiles.familyName +
              (props.data.profiles.country
                ? " - " + props.data.profiles.country
                : "")}
          </Typography>
          <Typography
            component="span"
            variant="h5"
            color={"textPrimary"}
            className={classes.p3}
          >
            {props.data.profiles.role && props.data.profiles.role.length
              ? props.data.profiles.role +
              ", " +
              props.data.profiles.companyName
              : ""}{" "}
            {props.data.sharedCircles !== null &&
              props.data.sharedCircles.length > 0
              ? props.data.sharedCircles.map((sharedcircle, index) => (
                <span key={index} className={classes.violet}>
                  {(index ? ", " : "") + sharedcircle}
                </span>
              ))
              : ""}
            <span className={classes.grey}>
              {"." +
                props.data.sharedMatchCount +
                " " +
                intl.formatMessage({ id: "shared_matches" })}
            </span>
          </Typography>
          <Typography component="h3" variant="h3" className={classes.chip}>
            {props.data.profiles.skills.map((skill, index) => (
              <Chip
                label={
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    display="inline"
                  >
                    {skill}
                  </Typography>
                }
                variant="outlined"
                className={classes.chipItem}
                size="small"
                key={index}
              />
            ))}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
