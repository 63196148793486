import { Grid, IconButton, useTheme } from "@mui/material";
import Background from "../../visualComponents/Background";
import Image from "../Media/Image";
import Text from "../../visualComponents/Text";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Avatar, Grow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSaveRandomSuggestion, useSetSuggestionStatus } from "../../apiServices/Mutations/IAm/SetSuggestionStatus";
import { useCreateYesActivity } from "../../apiServices/Mutations/Activities/YesActivity";
import { GetUserId } from "../../apiServices/CommonMethods";

export default function MatchProfileCard(props) {
    const theme                   = useTheme();
    const userId                  = props.userId        ?? "";
    const imgSrc                  = props.imgSrc        ?? "/logo512.png";
    const introductionProfile     = props.introduction  ?? "";
    const requestProfile          = props.request       ?? "";
    const aiGeneratedIntroduction = props.aiGeneratedIntroduction;
    const aiGeneratedRequest      = props.aiGeneratedRequest;


    return(
    <Background item xs={12} p={5} color="rgba(255, 255, 255, 0.80)" border="bottomLeft" height="fit-content">
    <Background container item xs={12} p={5} color="white" border="bottomLeft" justifyContent="center" gap={6} height="fit-content"
    onClick = {() => window.location.href = "/dashboard/profile/?id=" + userId}
    sx      = {{ cursor: "pointer"}}
    >
        <Grid item>
            <Image
                src     = {imgSrc}
                alt     = "pfp"
                sx      = {{borderRadius: theme.custom.borderRadius.bottomLeft, aspectRatio: "1 / 1" }}
                width   = "90px"/>
        </Grid>
        <Grid container item xs>
            {aiGeneratedIntroduction && introductionProfile &&
            <Text item xs={12} variant="body3" color="background" align="left" id="AiGeneratedText"/>}
            <Text item xs={12} variant="body2" color="white"      align="left"     >{introductionProfile}</Text>
            {aiGeneratedRequest      && requestProfile      && 
            <Text item xs={12} variant="body3" color="background" align="left" id="AiGeneratedText"/>}
            <Text item xs={12} variant="body2" color="white"      align="left" bold>{requestProfile     }</Text>
        </Grid>

    </Background>
    </Background>
    );
}