import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MatchCard from "../Cards/MatchCard";
import Styles from "../../styles/component/ProfileTabs/SharedTabStyle";
import { GET_ALL_SHARED_MATCHES } from "../../apiServices/Queries";
import { GetUserId } from "../../apiServices/CommonMethods";
import { Grid, Paper, Stack } from "@mui/material";
import { CircularProgress, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";

const useStyles = makeStyles(Styles);

export default function SharedTab(props) {
  const classes = useStyles();
  const userId = GetUserId();
  const [viewUserProfileId, setViewUserProfileId] = React.useState(null);

  const getVariable = () => {
    const variable = {
      loginUserProfileId: userId,
      viewUserProfileId: viewUserProfileId,
    };
    // console.log("variable", variable);
    return variable;
  };
  const { data: sharedMatches, loading, updateVariables: refetch } = useCachedQuery(
    "sharedMatches",
    GET_ALL_SHARED_MATCHES,
    getVariable(),
    (data) => data?.sharedMatches?.result,
    30,
    true
  );

  React.useEffect(() => {
    if (props.viewUserProfileId !== "undefined") {
      setViewUserProfileId(props.viewUserProfileId);
      refetch(getVariable());
    }
  }, [props.viewUserProfileId]);

  return (
    <div className={classes.root}>
      {loading && (!sharedMatches || !sharedMatches.length) ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : sharedMatches && sharedMatches.length > 0 ? (
        sharedMatches.map((item, index) => (
          <MatchCard data={item} key={index} />
        ))
      ) : (
        <Paper elevation={3} className={classes.tabcontent}>
          <Grid container spacing={2}>
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 200,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Typography component="span" variant="h3" color={"textPrimary"}>
                  <FormattedMessage id={"noDataAvailable"} />
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
