import * as React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { ButtonBlue } from "../../components/Buttons/ButtonBlue";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";
import { FormattedMessage } from "react-intl";

export default function EmailInputField(props) {
  const [savingMail, setSavingMail] = React.useState(false);
  const [input, setInput] = React.useState("");

  const sendInvite = () => {
    setSavingMail(true);
    props.onSubmit(input);
  };

  const updateTextFileds = (event) => {
    setInput(event.target.value);
  };

  return (
    <Grid container spacing={5} alignItems="flex-end">

      <Grid item xs>
        <FormControl variant="filled" style={{ width: "100%", minWidth: "150px"}}>
        
            <TextField
                label={<FormattedMessage id={"Register_page.Email"} />}
                onChange={updateTextFileds}
                variant="filled"
                value={input}
                InputLabelProps={{shrink: false}}
                sx={{ 
                    div: {height: 37},
                    input: {paddingTop: 1.5},
                    label: {
                        top: -7,
                        color: "#5f8ea3",
                        display: (input != "" ? ("none") : "")
                    }
                }}
                style={{backgroundColor: "white"}}
            />
        </FormControl>
      </Grid>
      <Grid item xs sx={{maxWidth: "350px"}}>
        {savingMail ? (
            <Skeleton variant="rectangular" height={40} />
        ) : (
            <ButtonBlue onClick={sendInvite}>
                <FormattedMessage id={"FreeRegister"} />
            </ButtonBlue>
        )}
      </Grid>

    </Grid>
  );
}
