
const RequestCardStyle = (theme) => ({
    card: {
        borderRadius: "30px 30px 30px 0px",
        color: theme.palette.secondary.main
    },
    logosmall: {
        width: "90%",
        maxWidth: "60px",
        minWidth: "45px",
        borderRadius: "10px 10px 10px 0px",
        marginBottom: 10,
        aspectRatio: 1
    },
    doticon: {
        float: 'right',
        fontSize: 32,
        marginTop: 10,
        border: "2px solid #bdbdbd",
        borderRadius: 4,
        color: "#bdbdbd",
    },
    p3: {
        marginTop: 3,
    },
    p15: {
        marginTop: 10,
    },
    violet: {
        color: theme.palette.color1.main,
    },
    grey: {
        color: theme.palette.background.dark,
    },
    doticonbottom: {
        float: 'right',
        color: "#7f7c7c",
    },
    msgicon: {
        fontSize: 20,
        color: "#006080",
        textAlign: 'right',
        marginLeft: 5,
    },
    tabcontent: {
        margin: "8px 0 15px 0",
        padding: "10px !important",
    },
    right: {
        float: 'right',
    },
    chip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        alignSelf: "end",
        padding: "5px 10px",
        borderRadius: 30,
    },
    chipItem2: {
        alignSelf: "end",
        padding: "5px 10px",
        borderRadius: 30,
        fontSize: 14,
        fontFamily: 'Montserrat', //h2
        fontWeight: 600,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main
    },
    typoVarient: {
        color: theme.palette.color2.main,
    },
    bottomArrow: {
        transform: 'rotate(270deg)',
        color: theme.palette.color5.main,
        float: 'right',
    },
    boldFont: {
        fontWeight: "bold"
    },
    rightAlign: {
        float: "right",
    },
    blurView: {
        backgroundColor: "#F2F2F2",
        filter: 'blur(4px)'
    },
});
export default RequestCardStyle;