import { gql } from "@apollo/client";
import { useMutationBase } from "../../mutations";

export function useSetSuggestionStatus(suggestionId, status, onCompleted) { 
    return useMutationBase(gql`
    mutation set($id: String!, $status: Status!){
        setSuggestionStatus(suggestionId: $id, status: $status)
    }`, 
    {noCallOnStart: true,
    onCompleted: onCompleted,
    variables: { 
        id:     suggestionId,
        status: status 
    },
     errorPolicy: "ignore"});
}

export function useSaveRandomSuggestion(id, requestProfileId, matchProfileId, status, onCompleted) { 
    return useMutationBase(gql`
    mutation set($requestProfileId: String!, $matchProfileId: String!, $status: Status!, $proposedId: String){
        saveRandomSuggestion(proposedId: $proposedId, requestProfileId: $requestProfileId, matchProfileId: $matchProfileId, swipeStatus: $status) {
            id
        }
    }`, 
    {noCallOnStart: true,
    onCompleted: onCompleted,
    variables: { 
        proposedId       : id,
        requestProfileId : requestProfileId, 
        matchProfileId   : matchProfileId, 
        status           : status
    },
     errorPolicy: "ignore"});
}