import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { CircularProgress, Paper } from "@material-ui/core";
import { CssBaseline, Stack } from "@mui/material";
import EventCard from "../../components/Cards/EventCard";
import { GetUserId } from "../../apiServices/CommonMethods";
import { EventsTabItems } from "../../apiServices/Constants";
import { useDispatch } from "react-redux";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import styles from "../../styles/container/EventsPageStyle";
import { Waypoint } from "react-waypoint";
import { FormattedMessage } from "react-intl";
import { useListEvents } from "../../apiServices/Queries/Events/GetAllEvents";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Filters from "../../components/Filters";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { MarkerEntitySources } from "../../components/General";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(styles);
export default function EventPage() {
  const globals  = useGlobalContext();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const userId = GetUserId();
  const [allEvents, setAllEvents] = React.useState([]);
  const location    = useLocation();
  const savedFilter = JSON.parse(localStorage.getItem("filters_"+location.key));
  const [eventTabType, setEventTabType] = React.useState(EventsTabItems.find(i => i.type == savedFilter?.tab) ?? EventsTabItems[0]);

  useEffect(() => userId && globals.functions.setMarker({
    lastPageVisitedUponCreation: MarkerEntitySources.EVENTS_PAGE, lastEntityVisitedUponCreationId: undefined, flags: []
  }), []);

  let [input, setInput] = React.useState({
    pageNumber: 1,
    location: savedFilter?.value?.location,
    maxDistance: savedFilter?.value?.maxDistance,
    eventTags: savedFilter?.value?.eventTags,
    operatesIn: savedFilter?.value?.operatesIn,
    searchInput: savedFilter?.value?.searchInput,
    date: savedFilter?.value?.startDate,
  });

  const getCurrentEventData = () => {
    return allEvents;
  };

  const getVariable = {
      hint: eventTabType.type,
      loginProfileId: userId,
      pageSize: 10,
      pageNumber: input["pageNumber"],
      location: input["location"],
      maxDistance: input["maxDistance"],
      eventTags: input["eventTags"],
      title: input["searchInput"] ?? "",
      operatesIn: input["operatesIn"],
      startDate: input["date"],
    };

  const {data: data, loading: loading, updateVariables: refetch } = useListEvents(getVariable);

  React.useEffect(() => {
    if (!data || (!data.length && input["pageNumber"] != 1))
      return;
    if (input["pageNumber"] === 1) {
      setAllEvents(data);
    } else
        setAllEvents((prevState) => [
          ...prevState,
          ...data,
        ]);
  }, [data]);

  React.useEffect(() => {
    refetch(getVariable);
  }, [input, eventTabType]);


  const filter = (input) => {
    setInput({
      ...input,
      pageNumber: 1,
    });
  };
  const tabChange = (event, newValue) => {
    let filteredTabItems = EventsTabItems.filter((tabItem) => {
      return tabItem.type === newValue;
    });
    setEventTabType(filteredTabItems[0]);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container item xs={12} gap={ThemeConstants.custom.spacings.lg} mt={ThemeConstants.custom.spacings.lg}>
      <Filters search includeEventFilters includeOperatesIn includeDate includeLocation onSearch={filter} useRefresh
        addDisplayText="AddEvent" onAdd={() => {history.push("/eventNew");}}
        tab={eventTabType.type} tabs={EventsTabItems} onTab={tabChange}/>

      <Grid container gap={ThemeConstants.custom.spacings.md}>
        <Grid item xs={12} sm={12}>
          {loading && !getCurrentEventData().length ? (
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 300,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <CircularProgress />
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.title}
                ></Typography>
              </Stack>
            </Box>
          ) : getCurrentEventData().length > 0 ? (
            getCurrentEventData().map((item, index) => (
              <Paper
                elevation={0}
                className={classes.tabcontent}
                key={index}
                onClick={() => {
                  // console.log("event selcted", item);
                  // history.push("/dashboard/eventDetails", {
                  //   eventDetails: item,
                  // });
                  history.push("/dashboard/eventDetails/?id=" + item.id, {
                    eventDetails: item,
                  });
                  dispatch(
                    updateBrandColor(
                      item.brandColor ?? theme.palette.primary.main
                    )
                  );
                }}
              >
                <EventCard eventData={item}></EventCard>
                {index === getCurrentEventData().length - 2 && (
                  <Waypoint
                    scrollableAncestor={window}
                    onEnter={() => {
                      // console.log(
                      //   "Waypointlength",
                      //   getCurrentEventData().length
                      // );
                      // console.log("Waypointindex", index);
                      const newPage = input["pageNumber"] + 1;
                      // console.log("newPage", newPage);
                      setInput((prevState) => ({
                        ...prevState,
                        pageNumber: newPage,
                      }));
                    }}
                  />
                )}
              </Paper>
            ))
          ) : (
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 300,
              }}
            >
              <Typography
                component="span"
                variant="h3"
                color={"textPrimary"}
              >
                <FormattedMessage id="noDataAvailable" />
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      </Grid>
    </React.Fragment>
  );
}
