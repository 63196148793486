import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Backdrop, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import styles from '../styles/component/HamburgerStyle'
import { useHistory } from 'react-router-dom';
import { loginType, userRoles } from '../apiServices/Constants';
import { signoutRedirect } from './IdentityServer/userService';
import { SUPPORT_PAGE_URL } from '../Config';
import { ClearItems, useUserRole } from '../apiServices/CommonMethods';
import { useHasCompanyAccess } from '../apiServices/Queries/Profiles/MetaData';
import Text from '../visualComponents/Text';
import { useGlobalContext } from '../apiServices/Providers/AppGlobalStateProvider';

const useStyles = makeStyles(styles);

export default function HamburgerView(props) {
    const globals  = useGlobalContext();
    const userRole = globals.state.loggedInUser.role.role;
    const classes = useStyles();
    const history = useHistory();
    const companyAccess = useHasCompanyAccess();

    const [open, setOpen] = React.useState(props.openStatus);
    React.useEffect(() => {
        setOpen(props.openStatus)
    }, [props.openStatus]);

    const navigateToProfile = () => {
        props.hambergurAction()
        history.push("/dashboard/profile")
    };
    const logoutAction = () => {
        if (localStorage.getItem("loginType") === loginType.idserver) {
            ClearItems();
            signoutRedirect()
        } else {
            ClearItems();
            history.push("/Registration")
        }
    };
    const navigateToSettings = () => {
        props.hambergurAction()
        history.push("/dashboard/settings")
    };
    const navigateToIntroduction = () => {
        props.hambergurAction()
        history.push("/introduction")
    }
    const navigateToHelp = () => {
        props.hambergurAction()
        window.open(SUPPORT_PAGE_URL, "_blank");
    }
    const navigateToAdmin = () => {
        props.hambergurAction()
        history.push("/dashboard/admin");
    }
    return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open={open} onClick={props.hamburgerAction}>
                <List component="nav" aria-label="secondary mailbox folders" style={{ width: "100%" }}>
                    {/* <ListItem button style={{ height: 80 }} >
                        <ListItemText primary={<Typography variant="h3" component="h3" color={"textSecondary"}>
                            <FormattedMessage id={"Header_menu.Invite"} />
                        </Typography>} />
                    </ListItem> 
                    <Divider /> */}
                    {companyAccess.accessToCompanies &&
                    <React.Fragment>
                        <ListItem button onClick={() => {
                            history.push("/dashboard/companies");
                        }}>
                            <ListItemText primary={<Text id="Side_menu.Companies"/>} className={classes.icons}/>
                        </ListItem>
                        <Divider />
                    </React.Fragment>}
                    <ListItem button onClick={navigateToIntroduction} style={{ height: 80 }} >
                        <ListItemText primary={<Text id="Header_menu.Introduce" />} />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={navigateToHelp} style={{ height: 80 }} >
                        <ListItemText primary={<Text id={"Header_menu.Help_desk"} />} />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={navigateToSettings} style={{ height: 80 }} >
                        <ListItemText primary={<Text id={"Header_menu.Account_settings"} />} />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={navigateToProfile} style={{ height: 80 }} >
                        <ListItemText primary={<Text id={"Header_menu.My_profile"} />} />
                    </ListItem>
                    <Divider />
                    {userRole === userRoles.admin &&
                        <ListItem button onClick={navigateToAdmin} style={{ height: 80 }} >
                            <ListItemText primary={<Text id={"Side_menu.Admin"} />} />
                        </ListItem>}
                    <Divider />
                    <ListItem button onClick={logoutAction} style={{ height: 80 }} >
                        <ListItemText primary={<Text id={"Header_menu.Log_out"} />} />
                    </ListItem>
                    <Divider />
                </List>
            </Backdrop>
        </div>
    );
}
HamburgerView.prototype = {
    openStatus: PropTypes.bool,
    hambergurAction: PropTypes.func
};