import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Styles from "../../../../styles/component/ProfileTabs/InfoTabs/CollaborationOfInterestTabs/ProjectPartnerStyle";
import {
  FormHelperText,
  TextareaAutosize,
} from "@material-ui/core";
import { ButtonDarkBlue } from "../../../Buttons/ButtonDarkBlue";
import { Alert, Skeleton } from "@mui/material";
import { useMutation } from "@apollo/client";
import { SAVE_BUSINESS_PARTNER_COLLAB } from "../../../../apiServices/mutations";
import { GetUserId } from "../../../../apiServices/CommonMethods";
import { FormattedMessage } from "react-intl";
import { clearSelfProfileCache } from "../../../../apiServices/Queries/Profiles/GetProfile";

const useStyles = makeStyles(Styles);

export default function BusinessPartners(props) {
  const userId = GetUserId();
  const classes = useStyles();

  const [SaveBusinessPartner, { data, loading, error }] = useMutation(
    SAVE_BUSINESS_PARTNER_COLLAB, {onCompleted: (data) => {
      clearSelfProfileCache();
    }}
  );
  let [input, setInput] = React.useState({
    interestedIn: "",
  });
  let [inputError, setInputError] = React.useState({
    interestedIn: "",
  });
  React.useEffect(() => {
    // console.log("Business partner ", data);
    if (
      typeof data !== "undefined" &&
      data.saveBusinessPartnerCollaborationInterest.errorCode === 0
    ) {
      props.saveAction(
        data.saveBusinessPartnerCollaborationInterest.result,
        false
      );
    }
    if (typeof props.collabData !== "undefined") {
      setInputData(props.collabData);
    }
  }, [data, props, props.collabData]);

  const setInputData = (inputData) => {
    setInput((prevState) => ({
      ...prevState,
      interestedIn: inputData.interestedIn ?? "",
    }));
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setInput((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  //   setInputError((prevState) => ({
  //     ...prevState,
  //     [name]: "",
  //   }));
  // };
  const updateTextFileds = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  // const handleChangeAutoCompleteIndustries = (event, newvalue) => {
  //   setInput((prevState) => ({
  //     ...prevState,
  //     industries: newvalue,
  //   }));
  //   setInputError((prevState) => ({
  //     ...prevState,
  //     industries: "",
  //   }));
  // };
  // const handleChangeAutoCompleteSkills = (event, newvalue) => {
  //   setInput((prevState) => ({
  //     ...prevState,
  //     skills: newvalue,
  //   }));
  //   setInputError((prevState) => ({
  //     ...prevState,
  //     skills: "",
  //   }));
  // };
  // const onLocationChange = (location) => {
  //   if (location) {
  //     setInput((prevState) => ({
  //       ...prevState,
  //       location:
  //         typeof location === "string" || location instanceof String
  //           ? location
  //           : location.description,
  //     }));
  //     setInputError((prevState) => ({
  //       ...prevState,
  //       location: "",
  //     }));
  //   }
  // };
  const handleSave = async () => {
    let isValid = await checkForErrors();
    // console.log("input", input);
    if (isValid) {
      SaveBusinessPartner({
        variables: {
          userId: userId,
          interestedIn: input["interestedIn"],
        },
      });
    }
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState
    }));
    return (
      true
    );
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        {error ? (
          <Alert severity="error">
            {error.length ? (
              error
            ) : (
              <FormattedMessage id={"somethingWentWrong"} />
            )}
          </Alert>
        ) : null}
        {typeof data !== "undefined" &&
          data.saveBusinessPartnerCollaborationInterest.errorCode === -1 ? (
          <Alert severity="error">
            {data.saveBusinessPartnerCollaborationInterest.message}
          </Alert>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"BusinessesOfInterest"} />
          :
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description3"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextareaAutosize
              id="interestedIn"
              onChange={updateTextFileds}
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              // placeholder="Type..."
              className={classes.txtarea}
              value={input["interestedIn"]}
            />
          )}
          {inputError["interestedIn"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnterTheBusinessesOfInterest"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <ButtonDarkBlue className={classes.submit} onClick={handleSave}>
          <FormattedMessage id={"Button_Text_Save"} />
        </ButtonDarkBlue>
      </Grid>
    </Grid>
  );
}
BusinessPartners.prototype = {
  saveAction: PropTypes.func,
  collabData: PropTypes.string,
};
