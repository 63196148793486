import { tabStyle } from "../../container/CommonStyle";

const EventCardStyle = (theme) => ({
  ...tabStyle,
  logo: {
    borderRadius: "5px 5px 0 0",
    width: "100%",
    aspectRatio: "16/7"
  },
  mt5: {
    marginTop: "5px",
  },
  p7: {
    marginTop: 7,
  },
  p15: {
    marginTop: 15,
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  Select: {
    width: "100%",
    marginTop: 7,
  },
  iconSearch: {
    position: "relative",
    right: 50,
    top: 15,
    color: theme.palette.color5.main,
  },
  iconFilter: {
    position: "relative",
    top: 7,
    color: theme.palette.color5.main,
  },
  EventBg: {
    backgroundColor: "#590a8f",
    marginBottom: 20,
    color: "#fff",
    paddingTop: 20,
  },
  tabcontent: {
    margin: "0px 0 15px 0",
    padding: "10px !important",
  },
  mLeft3: {
    marginLeft: 3,
  },
  closeicon: {
    float: "right",
    fontSize: 32,
    color: theme.palette.color5.main,
  },
  chipItem: {
    borderRadius: 5,
    marginRight: 5
  },
  chip: {
    marginTop: 10,
    padding: "18px 5px",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  card: {
    cursor: "pointer",
  },
  title: {
      fontSize: "1.3rem"
  },
});
export default EventCardStyle;
