import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Styles from "../../../../styles/component/ProfileTabs/InfoTabs/CollaborationOfInterestTabs/ProjectPartnerStyle";
import {
  Chip,
  FormHelperText,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import LocationAutoComplete from "../../../Autocomplete/LocationAutoComplete";
import { ButtonDarkBlue } from "../../../Buttons/ButtonDarkBlue";
import { useMutation } from "@apollo/client";
import { SAVE_STRATEGIC_PARTNER_COLLAB } from "../../../../apiServices/mutations";
import { Alert, Autocomplete, Skeleton } from "@mui/material";
import { GetUserId } from "../../../../apiServices/CommonMethods";
import {
  masterDataIndustries,
  masterDataSkills,
} from "../../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { clearSelfProfileCache } from "../../../../apiServices/Queries/Profiles/GetProfile";

const useStyles = makeStyles(Styles);

export default function StrategicPartner(props) {
  const userId = GetUserId();
  const classes = useStyles();
  const [SaveStrategicPartner, { data, loading, error }] = useMutation(
    SAVE_STRATEGIC_PARTNER_COLLAB, {onCompleted: (data) => {
      clearSelfProfileCache();
    }}
  );
  let [input, setInput] = React.useState({
    industries: [],
    interestedIn: "",
    location: "",
    myPartOfThePartnership: "",
    preferredBenefits: "",
    skills: [],
  });
  let [inputError, setInputError] = React.useState({
    industries: "",
    interestedIn: "",
    location: "",
    myPartOfThePartnership: "",
  });
  React.useEffect(() => {
    // console.log("saveStrategicPartnerCollaborationInterest data", data);
    if (
      typeof data !== "undefined" &&
      data.saveStrategicPartnerCollaborationInterest.errorCode === 0
    ) {
      props.saveAction(
        data.saveStrategicPartnerCollaborationInterest.result,
        false
      );
    }
    if (typeof props.collabData !== "undefined") {
      setInputData(props.collabData);
    }
  }, [data, props, props.collabData]);

  const setInputData = (inputData) => {
    setInput((prevState) => ({
      ...prevState,
      industries: inputData.industries ?? [],
      skills: inputData.skills ?? [],
      interestedIn: inputData.interestedIn ?? "",
      location: inputData.location ?? "",
      myPartOfThePartnership: inputData.myPartOfThePartnership ?? "",
      preferredBenefits: inputData.preferredBenefits ?? "",
    }));
  };

  const updateTextFileds = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const handleChangeAutoCompleteIndustries = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      industries: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      industries: "",
    }));
  };
  const handleChangeAutoCompleteSkills = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      skills: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      skills: "",
    }));
  };
  const onLocationChange = (location) => {
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        location:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInputError((prevState) => ({
        ...prevState,
        location: "",
      }));
    }
  };
  const handleSave = async () => {
    let isValid = await checkForErrors();
    // console.log("input", input);
    if (isValid) {
      SaveStrategicPartner({
        variables: {
          userId: userId,
          industries: input["industries"],
          interestedIn: input["interestedIn"],
          location: input["location"],
          myPartOfThePartnership: input["myPartOfThePartnership"],
          preferredBenefits: input["preferredBenefits"],
          skills: input["skills"],
        },
      });
    }
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      industries: !input["industries"].length ? "industriesError" : "",
      location: !input["location"] ? "locationError" : ""
    }));
    return (
      input["industries"].length &&
      input["location"].length > 0
    );
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        {error ? (
          <Alert severity="error">
            {error.length ? (
              error
            ) : (
              <FormattedMessage id={"somethingWentWrong"} />
            )}
          </Alert>
        ) : null}
        {typeof data !== "undefined" &&
          data.saveStrategicPartnerCollaborationInterest.errorCode === -1 ? (
          <Alert severity="error">
            {data.saveStrategicPartnerCollaborationInterest.message}
          </Alert>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"Industries"} />
          *:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="industries"
              options={masterDataIndustries()}
              value={input["industries"]}
              onChange={handleChangeAutoCompleteIndustries}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
          {inputError["industries"].length ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterindustry"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"Location"} />
          *:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <LocationAutoComplete
              onChange={onLocationChange}
              value={input["location"]}
            />
          )}
          {inputError["location"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterlocation"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"PartnershipsOfInterest"} />
          :
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description13"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextareaAutosize
              id="interestedIn"
              onChange={updateTextFileds}
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              // placeholder="Type..."
              className={classes.txtarea}
              value={input["interestedIn"]}
            />
          )}
          {inputError["interestedIn"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnteTheProjects"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"WhatIBringToThePartnership"} />
          :
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description14"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            // <TextField
            //   id="myPartOfThePartnership"
            //   // label="Write here"
            //   variant="filled"
            //   size="small"
            //   onChange={updateTextFileds}
            //   value={input["myPartOfThePartnership"]}
            //   helperText={
            //     inputError["myPartOfThePartnership"] ? (
            //       <Typography variant="h5" component="span" color="error">
            //         <FormattedMessage id={"PleaseEnterTheContribution"} />
            //       </Typography>
            //     ) : null
            //   }
            // />
            <TextareaAutosize
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              id="myPartOfThePartnership"
              className={classes.txtarea}
              value={input["myPartOfThePartnership"]}
              onChange={updateTextFileds}
            />
          )}
          {inputError["myPartOfThePartnership"] ? (
            <Typography variant="h5" component="span" color="error">
              <FormattedMessage id={"PleaseEnterTheContribution"} />
            </Typography>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h2"
          variant="h2"
          color={"textPrimary"}
          className={classes.btxt}
        >
          <FormattedMessage id={"Optional"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"PartnerSkillsOfInterest"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description2"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              freeSolo
              id="skills"
              autoSelect={true}
              options={masterDataSkills()}
              value={input["skills"]}
              onChange={handleChangeAutoCompleteSkills}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, maxLength: 25 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                    e.target.value = e.target.value.split(" ").length > 2 ? e.target.value.trim() : e.target.value
                    e.target.value = e.target.value.substring(0, 25)
                  }}
                />
              )}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"StrategicPartner_Edit_PreferedBenefits"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description15"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              id="preferredBenefits"
              // label="Write here"
              variant="filled"
              size="small"
              onChange={updateTextFileds}
              value={input["preferredBenefits"]}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonDarkBlue className={classes.submit} onClick={handleSave}>
          <FormattedMessage id={"Button_Text_Save"} />
        </ButtonDarkBlue>
      </Grid>
    </Grid>
  );
}
StrategicPartner.prototype = {
  saveAction: PropTypes.func,
  data: PropTypes.string,
};
