const EventVisitorsStyle = (theme) => ({
  root: {
    width: "100%",
  },
  logosmall: {
    width: "100%",
    borderRadius: 6,
    aspectRatio: 1,
  },
  blurView: {
    backgroundColor: "#F2F2F2",
    filter: 'blur(4px)'
  },
  tabcontent: {
    margin: "8px 0 15px 0",
    padding: "10px !important",
  },
  p3: {
    marginTop: 3,
  },
  p18: {
    paddingTop: 18,
  },
  p15: {
    marginTop: 15,
  },
  violet: {
    color: "#8247ab",
  },
  grey: {
    margin: 0,
    float: "right",

    //color: "#bdbdbd",
  },
  greydark: {
    color: "#828282",
  },
  margin: {
    marginRight: 4,
    color: "#828282",
  },
  P15L35: {
    marginTop: 15,
    paddingLeft: 35,
  },
  p60: {
    marginTop: 60,
  },
  submit: {
    color: "#fff",
    width: "70%",
  },
  rightimg: {
    width: "250px",
    marginTop: "100px",
  },
  chip: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  chipItem: {
    borderRadius: 5,
  },
  iconSearch: {
    position: "relative",
    right: 50,
    top: 15,
    color: theme.palette.color5.main,
  },
  iconFilter: {
    position: "relative",
    top: 7,
    left: 7,
    color: theme.palette.color5.main,
  },
  mb10: {
    marginBottom: 10,
    color: "#72B6CD",
  },
  uline: {
    color: "#72B6CD",
    marginTop: 3,
  },
  doticonbottom: {
    float: "right",
    color: "#7f7c7c",
  },
  btnModerator: {
    backgroundColor: "#006080",
    padding: "2px 10px",
    borderRadius: 5,
    color: "#fff",
    fontSize: 10,
    marginLeft: 10,
  },
  iconReset: {
    marginTop: "12px",
    padding: "0 !important",
    color: theme.palette.color5.main,
  },
  right: {
    float: "right",
    height: "15px",
    minWidth: "45px !important",
  },
  rightAlign: {
    float: "right",
  },
  boldFont: {
    fontWeight: "bold"
  }
});

export default EventVisitorsStyle;
