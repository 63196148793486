import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import { useGetActivityUsers } from "../apiServices/Queries/Activities/ActivityUsers";
import { useIntl } from "react-intl";

export default function IntroductionAutoSearchPage(props) {
    const intl = useIntl();
    const globals = useGlobalContext();
    const userId  = globals.state.loggedInUser.userId;
    const [searchText, setSearchText] = React.useState("");
    const [userToRemove, setUserToRemove] = React.useState("");

    const { loading, data, updateVariables } = useGetActivityUsers({
        userId: userId,
        searchString: searchText,
      });

    useEffect(() => {
        // console.log("masters", data);

        if (typeof props.userToRemove !== "undefined") {
            // console.log("userToRemove", props.userToRemove);

            setUserToRemove(props.userToRemove);
        }
    }, [props, data]);

    const updateTextFileds = (event) => {
        // console.log("updateTextFileds", event.target.value);
        setSearchText(event.target.value);
        updateVariables({
                userId: userId,
                searchString: event.target.value,
        });
        if (typeof props.onSearchTextChanges === "function") {
            props.onSearchTextChanges(event.target.value);
        }
    };
    const onChange = (event, newvalue) => {
        // console.log("onChange", newvalue);
        if (typeof data !== "undefined") {
            const arryayMaster = data.filter((master) => {
                return master.givenName + " " + master.familyName === newvalue;
            });
            props.onSearch(arryayMaster[0]);
        } else 
            props.onSearch(newvalue)
    };
    const getupdatedList = () => {
        const arrayFiltered = data.filter((item) => {
            return item.userId !== userToRemove;
        });
        return arrayFiltered;
    };
    return (
        <Autocomplete
            noOptionsText={searchText && intl.formatMessage({ id: "notFoundContact" })}
            freeSolo
            size={"small"}
            id="free-solo-2-demo"
            onChange={onChange}
            // filterOptions={(x) => x}
            disableClearable
            options={data &&
                    searchText.length
                    ? getupdatedList().map(
                        (option, index) => option.givenName + " " + option.familyName
                    )
                    : []
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={updateTextFileds}
                    placeholder={
                        typeof props.placeHolder !== "undefined"
                            ? props.placeHolder
                            : "Type name"
                    }
                    value={searchText}
                    InputProps={{
                        ...params.InputProps,
                        // type: 'search',
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
IntroductionAutoSearchPage.prototype = {
    onSearch: PropTypes.func,
};
