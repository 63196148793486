import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Hidden } from "@mui/material";
import Navigationbar from "../components/Navigationbar";
import { CREATE_INTRODUCTION } from "../apiServices/mutations";
import { useMutation } from "@apollo/client";
import Footer from "../components/Footer";
import { CssBaseline } from "@material-ui/core";
import { isEmail } from "../components/General";
import { GetUserId } from "../apiServices/CommonMethods";
import { ActivitySource } from "../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import FormContainer from "../components/Forms/FormContainer";
import InputSection from "../components/Forms/InputSection";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";


export default function InroductionPage() {
  const userId = GetUserId();
  const globals = useGlobalContext();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const [selectedUser, setSelectedUser] = React.useState(location.state?.selectedUser ?? location.state?.request ?? null);

  const [createIntroductiom, { data, loading }] =
    useMutation(CREATE_INTRODUCTION);
  let [input, setInput] = React.useState({
    recipientUserProfileIds: [],
    activitySource: null,
    activitySourceId: null,
    messages: "",
    requesterUserProfileId: "",
    selectedUser: "",
    recipientEmails: [],
  });
  let [inputError, setInputError] = React.useState({
    selectedUser: "",
    toUser: "",
    messages: "",
    validEmail: "",
  });
  const [request, setRequest] = React.useState({});

  React.useEffect(() => {
    if (location.state && location.state.request) {
      setRequest(location.state.request);
      setInput((prevState) => ({
        ...prevState,
        selectedUser: location.state.request.userId,
      }));
    }

    if (location.state && location.state.selectedUser) {
      setSelectedUser(location.state.selectedUser);
      setInput((prevState) => ({
        ...prevState,
        selectedUser: location.state.selectedUser.userId
      }));
    }
    if (typeof data !== "undefined") {
      setInput((prevState) => ({
        ...prevState,
        messages: "",
      }));
    }
  }, [location, data]);

  const updateTextFileds = (event) => {
    const { value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      messages: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      messages: "",
    }));
  };
  const searchAction = (searchUser) => {
    setInput((prevState) => ({
      ...prevState,
      selectedUser: searchUser.userId,
    }));
    setInputError((prevState) => ({
      ...prevState,
      selectedUser: "",
    }));
  };
  const searchActionToUser = (searchUser) => {
    if (searchUser?.userId) {
      setInput((prevState) => ({
        ...prevState,
        recipientUserProfileIds: [searchUser.userId],
        recipientEmails: [],
      }));
    } else if (typeof searchUser == "string") {
        setInput((prevState) => ({
          ...prevState,
          recipientUserProfileIds: [],
          recipientEmails: [searchUser],
        }));
    }
    setInputError((prevState) => ({
      ...prevState,
      toUser: "",
    }));
  };

  const onSearchTextChangesOnIntroduceUser = (searchText) => {
    if (!searchText.length) {
      setInput((prevState) => ({
        ...prevState,
        selectedUser: "",
      }));
    }
  };

  const handleSave = async () => {
    let isValid = await checkForErrors();
    // console.log("Introduction-Request", typeof request.givenName !== "undefined")
    const variable = typeof request.givenName !== "undefined"
      ? {
        activitySource: typeof request.circlePosts !== "undefined" ? ActivitySource.circlePostRespond : ActivitySource.collabRequest,
        activitySourceId: typeof request.circlePosts !== "undefined" ? request.circlePosts.id : request.id,
        recipientUserProfileIds: [
          ...input["recipientUserProfileIds"],
          input["selectedUser"],
        ],
        messages: input["messages"],
        requesterUserProfileId: userId,
        recipientEmails: input["recipientEmails"].length > 0 ? input["recipientEmails"] : null,
      }
      : {
        messages: input["messages"],
        recipientUserProfileIds: [
          ...input["recipientUserProfileIds"],
          input["selectedUser"],
        ],
        requesterUserProfileId: userId,
        activitySource: null,
        activitySourceId: null,
        recipientEmails: input["recipientEmails"].length > 0 ? input["recipientEmails"] : null,
      };
    // console.log("variables introduction", variable);
    if (isValid) {
      createIntroductiom({
        variables: {...variable, marker: globals.state.marker},
      });
    }
  };

  const toUserCheck = () => {
    return (
      input["recipientUserProfileIds"].length > 0 ||
      (input["recipientEmails"].length > 0 &&
        isEmail(input["recipientEmails"][0]))
    );
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      messages: !input["messages"].trim() ? "messageError" : "",
      toUser: !toUserCheck() ? "toUserError" : "",
      selectedUser: !input["selectedUser"] ? "fromEmailError" : "",
    }));
    // console.log("toUserCheck()", toUserCheck());
    return input["messages"].trim() && input["selectedUser"] && toUserCheck();
  };
  const getUserName = () => {
    if (typeof request.givenName !== "undefined") {
      return request.givenName + " " + request.familyName
    } else if (selectedUser !== null) {
      return selectedUser.givenName + " " + selectedUser.familyName
    } else {
      return ""
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        modalMessage = {data?.createIndroductionActivity?.errorCode ==  0 ? <FormattedMessage id={"IntroductionSentSuccessfully"} /> : undefined}
        onModalClose = {() => history.goBack()}
        error        = {data?.createIndroductionActivity?.errorCode == -1 ? data.createIndroductionActivity.message : undefined}
        title        = {<FormattedMessage id="introduction_Page_title"/>}
        description  = {<FormattedMessage id="introduction_Page_description"/>}
        loading      = {loading}
        onSave       = {handleSave}
        disabled     = {loading || !input.messages?.length || (!input.recipientUserProfileIds[0]?.length && !input.recipientEmails[0]?.length) || !input.selectedUser?.length}
        onCancel     = {() => history.goBack()}
        saveId       = "Button_text_Send"
      >
        <InputSection
        type        = {selectedUser ? "text" : "contactsearch"}
        disabled    = {selectedUser || loading}
        id          = "recipient"
        title       = {<FormattedMessage id="introduction_Field_label_introduce"/>}
        value       = {selectedUser ? getUserName() : undefined}
        placeholder = {intl.formatMessage({ id: "Input_Label_Text_Type_UserName" })}
        onChange    = {searchAction}
        inputProps={{
          onSearchTextChanges: onSearchTextChangesOnIntroduceUser,
          userToRemove: input["recipientUserProfileIds"].length ? input["recipientUserProfileIds"][0] : null
        }}
        hint        = {inputError["selectedUser"] ? <FormattedMessage id="pleaseEnterTheUserName" /> : undefined}
        />

        <InputSection
        type        = {"contactsearch"}
        disabled    = {loading}
        id          = "recipient2"
        title       = {<FormattedMessage id="introduction_Field_label_to"/>}
        onChange    = {searchActionToUser}
        placeholder = {intl.formatMessage({ id: "TypeUsernameOrEmailAddress" })}
        inputProps  = {{ 
          freeSolo: true,
          onSearchTextChanges: searchActionToUser,
          userToRemove: input["selectedUser"] ?? null,
          noOptionsText: "notFoundContact4"
        }}
        hint        = {inputError["toUser"] ? input["recipientUserProfileIds"].length > 0 ? (
          <FormattedMessage id={"pleaseEnterTheUserName"} />
        ) : (
          <FormattedMessage id={"emailWrong"} />
        ) : undefined}
        />

        <InputSection
        type        = "textarea"
        disabled    = {loading}
        id          = "message"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<FormattedMessage id="introduction_Field_label_message"/>}
        description = {<FormattedMessage id="introduction_Field_description_message"/>}  
        value       = {input["messages"]}
        onChange    = {updateTextFileds}
        hint        = {inputError["messages"] ? <FormattedMessage id="pleaseEnterMessage" /> : undefined}
        max         = {250}
        />
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
