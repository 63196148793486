import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { CssBaseline, Hidden } from "@material-ui/core";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router";
import { RESPOND_TO_EVENT_NEWS } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { Alert, Skeleton } from "@mui/material";
import { ActivitySource } from "../../apiServices/Constants";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";
import { GetUserId } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "../../styles/container/Circle/NewsRespondStyle";

const useStyles = makeStyles(styles);

export default function EventNewsRespond() {
  const userId = GetUserId();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  let [message, setMessage] = React.useState("");
  let [messageError, setMessageError] = React.useState("");
  const [news, setNews] = React.useState(null);
  const location = useLocation();
  const [eventData, setEventData] = React.useState("");
  const [repondToNews, { data, loading, error }] = useMutation(
    RESPOND_TO_EVENT_NEWS
  );

  const updateTextFileds = (event) => {
    const { value } = event.target;
    setMessage(value);
    setMessageError("");
  };

  const handleSave = async () => {
    let isValid = await checkForErrors();
    let variable = {};
    if (news !== null) {
      variable = {
        activitySource: ActivitySource.eventNewsUpdate,
        activitySourceId: news.id,
        messages: message,
        recipientUserProfileIds: [news.createdProfileId],
        requesterUserProfileId: userId,
      };
    }
    // console.log("EVENT NEWS RESPOND VARIABLES", variable);
    if (isValid) {
      repondToNews({
        variables: variable,
      });
    }
  };

  React.useEffect(() => {
    if (typeof location.state.eventNews !== "undefined") {
      setNews(location.state.eventNews);
    }
    if (location.state && typeof location.state.eventDetails !== "undefined") {
      setEventData(location.state.eventDetails);
    }
    // console.log("eventNewsDetails", location.state.eventNews);
    // console.log("NewsData", news);
    // console.log("EVENT DETAILS", location.state.eventDetails);
  }, [location, data]);

  const checkForErrors = async () => {
    setMessageError(!message.trim() ? "messageError" : "");
    return message.trim();
  };

  const handleSucessAlert = () => {
    // history.push("/dashboard/eventDetails", {
    //   eventDetails: eventData,
    //   selectedTab:
    //     typeof data !== "undefined" &&
    //       data.sendResponseToEventNewsActivity.errorCode === 0
    //       ? 1
    //       : 3,
    // });
    history.push("/dashboard/eventDetails/?id=" + eventData.id, {
      selectedTab:
        typeof data !== "undefined" &&
          data.sendResponseToEventNewsActivity.errorCode === 0
          ? 1
          : 3,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(2), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              {typeof data !== "undefined" &&
                data.sendResponseToEventNewsActivity.errorCode === 0 ? (
                <SuccessAlertWithAction
                  message={<FormattedMessage id={"responseSentSuccessfully"} />}
                  handleClose={handleSucessAlert}
                  open={true}
                />
              ) : null}

              {error ? (
                <Alert severity={"error"}>
                  {<FormattedMessage id={"somethingWentWrong"} />}
                </Alert>
              ) : null}
            </Grid>
            <Grid item sm={11} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"respond"} />
              </Typography>
            </Grid>
            <Grid item sm={1} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={() => {
                  history.goBack();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"writeAMessage"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={80} />
                  ) : (
                    <TextareaAutosize
                      variant="filled"
                      aria-label="minimum height"
                      minRows={5}
                      placeholder={intl.formatMessage({
                        id: "Type",
                      })}
                      className={classes.txtarea}
                      onChange={updateTextFileds}
                    />
                  )}
                  {messageError.length ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"pleaseEnterMessage"} />
                    </Typography>
                  ) : null}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item sm={3} xs={9}>
              {loading ? (
                <Skeleton variant="rectangular" height={80} />
              ) : (
                <ButtonDarkBlue onClick={handleSave}>
                  <FormattedMessage id={"Button_text_Send"} />
                </ButtonDarkBlue>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
