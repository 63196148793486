import { Button, Grid, TextField, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Background from "../../../visualComponents/Background";
import useMatchTimeHook from "../../../apiServices/Hooks/MatchTimeHooks";
import { useEffect, useState } from "react";
import Text from "../../../visualComponents/Text";
import { useMatchTimeMetaDataAdmin } from "../../../apiServices/Queries/IAm/MatchTimeMetaData";

export default function MatchTimeTab(props) {
    const [meta, loadingMeta, error, refetch]   = useMatchTimeMetaDataAdmin();
    const [resource, setResource] = useState(null);
    const [apiKey  , setKey     ] = useState(null);
    const [response, loading, callHook] = useMatchTimeHook(apiKey, resource);

    useEffect(() => {
        if(resource) {
            callHook();
            setResource(null);
        }
    }, resource);
    return(
        <ThemeProvider theme={Theme_WIP}>
            <Grid container p={10} justifyContent="center">
                {!loadingMeta && 
                    <Grid container item xs={12} mb={5} rowGap={5}>
                        <Text>Count = total amount in db for today, Generated = amount answered by LLM with generated content, Filled = amount filled in by users, Empty = amount neither filled by user nor responded to by LLM</Text>
                        {meta?.map((data) =>
                        <Grid item xs={3}>
                            <Text>{data.type                }</Text>
                            <Text>Count: {data.count        }</Text>
                            <Text>Generated: {data.generated}</Text>
                            <Text>Filled: {data.filled      }</Text>
                            <Text>Empty: {data.empty        }</Text>
                        </Grid>
                        )}
                    </Grid>
                }
                {loading ? <Text>Loading...</Text> :
                <Background container item gap={10} justifyContent="center" p={10} py={20} border="bottomLeft">
                    <TextField fullWidth label="API key" onChange={(x) => setKey(x.target.value)}/>
                    <Button variant="contained" onClick={() => setResource("suggestions")}>Create Match Suggestions</Button>
                    <Button variant="contained" onClick={() => setResource("profiles"   )}>Create Match Profiles</Button>
                    <Text item xs={12} align="center">{response}</Text>
                </Background>}
            </Grid>
        </ThemeProvider>
    )
}