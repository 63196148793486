import { Button, Grid, Icon, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import Theme_WIP from "../styles/themes/main/Theme_WIP";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useMatchTime from "../apiServices/Hooks/MatchTimeHooks";
import {ReactComponent as MatchOn} from "../svg/MatchOn.svg";
import {ReactComponent as MatchOff} from "../svg/MatchOff.svg";
import Text from "../visualComponents/Text";
import { useMatchTimeMetaData } from "../apiServices/Queries/IAm/MatchTimeMetaData";
import { isItMatchTime } from "../apiServices/Methods/MatchProfileMethods";
import QueryString from "query-string";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import { useSubInfo } from "../objects/Subscriptions";

export default function MatchTimeButton(props) {
    const globals  = useGlobalContext();
    const role = globals.state.loggedInUser.role.role;
    const sub = useSubInfo();
    const history = useHistory();
    const params  = QueryString.parse(window.location.search);
    const [data, loading, error, refetch]   = useMatchTimeMetaData();
    const alive = () => ((params.debug == "matchtimeActive" && role == "Admin") || isItMatchTime) || ((data?.pendingSwipes ?? 0) > 0);

    const GoToMatchTime = () => {
        history.push(sub.current.LowerCaseRole == sub.all.freemium.LowerCaseRole || sub.current.LowerCaseSubType == sub.all.initialTrial.LowerCaseSubType ? 
        "/getpremium" : "/matchtime", {
            path:  "/matchtime",
            alive : alive(),
            meta  : data
          });
    };

    return null; //hide the button for now
    return(!loading &&
        <React.Fragment>
            <ThemeProvider theme={Theme_WIP}>
                <Button 
                startIcon={<Icon>{alive() ? <MatchOn/> : <MatchOff/>}</Icon>}
                sx={{
                    marginLeft: "30px",
                    padding: 0,
                    ".MuiIcon-root" : {
                        scale: "0.75",
                        width: "fit-content",
                        height: "fit-content"
                }}}
                size="large"
                    onClick={GoToMatchTime}
                    variant="text"
                >
                    {/* notification */}
                    {(data?.pendingSwipes ?? 0) > 0 && 
                        <Text color="primary" variant="body2" bold 
                            sx={{
                                zIndex: 10,
                                right: (props.mobile? 14 : -5), top: -1,
                                position: "absolute"
                            }}
                        ><span style={{
                            background: "rgb(217, 22, 64)",
                            padding: "5px 9px",
                            borderRadius: "100%",
                            aspectRatio: "1 / 1"
                        }}>{data?.pendingSwipes}</span></Text>}
                    {!props.mobile && <Text color={alive() ? "#FF7C03" : "background"} bold={alive}>Match time</Text>}
                </Button>
            </ThemeProvider>
        </React.Fragment>
    );
}