import { tabStyle } from "./CommonStyle";

const ActivityPageStyle = (theme) => ({
  ...tabStyle,
  p3: {
    marginTop: 3,
    color: theme.palette.color5.main,
  },
  p10: {
    paddingTop: 10,
    color: theme.palette.color5.main,
  },
  p15: {
    marginTop: 15,
  },
  mt5: {
    marginTop: "5px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  Select: {
    width: "100%",
    marginTop: 7,
  },
  iconSearch: {
    position: "relative",
    right: 50,
    top: 15,
    color: theme.palette.color5.main,
  },
  iconFilter: {
    position: "relative",
    right: 15,
    top: 15,
    color: theme.palette.color5.main,
  },
  violet: {
    color: "#8247ab",
    /*color: theme.palette.color5.main,*/
  },
  logosmall: {
    width: "100%",
    borderRadius: 6,
    marginRight: 5,
    aspectRatio: 1,
  },
  dateTime: {
    paddingLeft: 5,
    color: theme.palette.color3.main,
  },
  iconbtn: {
    float: "right",
    minWidth: 0,
    padding: 0,
  },
  doticon: {
    float: "right",
    fontSize: "20px !important",
    border: "1px solid",
    borderColor: theme.palette.color4.main,
    borderRadius: 4,
    color: theme.palette.color5.main,
  },
  checkicon: {
    float: "right",
    fontSize: "20px !important",
    border: "1px solid",
    borderColor: theme.palette.color4.main,
    borderRadius: 4,
  },
  tabcontentInner: {
    margin: "0",
    padding: "8px 10px 5px 10px !important",
    border: "2px solid",
    borderColor: "white",
    borderRadius: "15px 15px 15px 0px",
    position: "relative",
    bottom: 15,
    top: 1,
    width: "100%",
    backgroundColor: "transparent"
  },
  cardSent: {
    margin: "0",
    padding: "8px 10px 5px 10px !important",
    border: "2px solid",
    borderColor: "white",
    borderRadius: "15px 15px 0px 15px",
    position: "relative",
    bottom: 15,
    top: 1,
    width: "100%",
    backgroundColor: "white"
  },
  pRelative: {
    position: "relative",
    bottom: 12,
  },
  grey: {
    color: theme.palette.color5.main,
  },
  hardCodedDescription: {
    color: theme.palette.color1.main,
  },
  bottomArrow: {
    transform: "rotate(270deg)",
    color: theme.palette.color5.main,
    float: "right",
  },
  iconGlobelink: {
    position: "relative",
    bottom: 3,
    left: 5,
    color: theme.palette.color2.main,
  },
  circleName: {
    color: "#1976d2",
  },
  iconReset: {
    marginTop: "13px",
    padding: "0 !important",
    color: theme.palette.color5.main,
    //marginLeft: "-7px",
  },
  rightAlign: {
    float: "right",
  },
  leftAlign: {
    float: "left",
  }
});
export default ActivityPageStyle;
