import { Button, Grid, TextField, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Background from "../../../visualComponents/Background";
import { useEffect, useState } from "react";
import Text from "../../../visualComponents/Text";
import { useSaveCollabCategories } from "../../../apiServices/Mutations/Collabs/Categories/SaveCollabCategories";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";

const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

export default function RequestsTab(props) {
    const global                        = useGlobalContext();
    const categoriesFromQuery           = global.state.meta.collaborationCategories;
    const refetch                       = global.functions.meta.refetchCollaborationCategories;
    const [categories , setCategories ] = useState(categoriesFromQuery);
    const [selectedTop, setSelectedTop] = useState();
    const [selected   , setSelected   ] = useState();
    const [editing    , setEditing    ] = useState(false);
    const [input      , setInput      ] = useState({title: "", description: ""});
    const {data, loading, error, mutate} = useSaveCollabCategories(categories, refetch);

    useEffect(() => {
        setCategories(categoriesFromQuery);
    }, [categoriesFromQuery]);

    function Category(props) {
        var bg     = props.title ? (props.selected? "darkPetrol" : "primary") : "background";
        var button = props.selected || !props.title? "primary" : "darkPetrol";
        return (<Background {...props} container item gap={5} color={bg} p={4} border="bottomLeft" sx={{height: "auto"}}>
            { props.title       && !(props.editing && props.selected)  && <Text variant="h2" bold color="primary" item xs={12}>{props.title}</Text>}
            { props.description && !(props.editing && props.selected)  && <Text variant="body1"   color="primary" item xs={12}>{props.description}</Text>}

            { props.selected && !props.editing  && <Button onClick={() =>{
                if(!props.sub)
                    setSelectedTop(null);
                setSelected(null);
                setCategories(categories.filter(c => c.id != props.id && c.parentId != props.id));
                }
            }>delete</Button>}
            { props.selected && !props.editing  && <Button variant="contained" color={button} onClick={() => setEditing(true)}>edit</Button>}
            {!props.selected && <Button variant="contained" color={button} onClick={
                () => {
                    setSelected(props.id); 
                    setInput({title: props.title, description: props.description});
                    if(!props.title) setEditing(true); 
                    if(!props.sub && props.title) setSelectedTop(props.id);}
            }>{props.title ? "select" : "add"}</Button>}

            {             props.editing && props.selected && <TextField sx={{background: "white"}} fullWidth onChange={(x) => setInput((prev) => ({...prev, title: x.target.value}))} value={input.title}/>}
            {props.sub && props.editing && props.selected && <TextField sx={{background: "white"}} fullWidth onChange={(x) => setInput((prev) => ({...prev, description: x.target.value}))} value={input.description}/>}
            {props.editing && props.selected && <Button 
            color={button}
            variant="contained" 
            onClick={() => {
                setSelected(null);
                setEditing(false);
                if(props.title)
                    setCategories(categories.map(c => c.id == props.id ? {...c, title: input.title, description: props.sub? input.description : null} : c));
                else
                    setCategories(categories.concat([{id: genRanHex(24), title: input.title, description: props.sub? input.description : null, parentId: props.sub? selectedTop : null}]));
            }}
            >Save Category</Button>}
        </Background>);
    }

    return(
        <ThemeProvider theme={Theme_WIP}>
            <Grid container p={10} justifyContent="center">
                {!loading && <Background container item gap={5} justifyContent="center" p={10} border="bottomLeft">
                    <Text variant="h1" bold item xs>Request categories</Text>
                    <Button disabled={categories == categoriesFromQuery} onClick={mutate} variant="contained">Save All Categories</Button>
                    <Text item xs={12} mb={4}>For some reason the text fields in the blockd don't work that well so you can just use this one for typing and copy paste it into the ones in the blocks</Text>
                    <TextField fullWidth/>

                    <Text variant="h2" bold item xs={12}>Top categories</Text>
                    {/* <Text item xs={12}>Fill in only the id of the video; not the full url. the id is a string of numbers at the end of a video url</Text> */}
                    {global.functions.meta.getTopCategories().map(c => <Category id={c.id} title={c.title} xs={3} editing={editing} selected={selected == c.id}/>)}
                    <Category id="top" xs={2} editing={editing} selected={selected == "top"}/>                    

                    {selectedTop && <Text variant="h2" bold item xs={12}>{categories.find(c => c.id == selectedTop).title} - Sub categories</Text>}
                    {global.functions.meta.getSubCategories(selectedTop).filter(c => c.parentId == selectedTop).map(c => <Category sub id={c.id} title={c.title} description={c.description} xs={3} editing={editing} selected={selected == c.id}/>)}
                    {selectedTop && <Category id="sub" sub xs={3} editing={editing} selected={selected == "sub"}/>}   

                </Background>}
            </Grid>
        </ThemeProvider>
    )
}