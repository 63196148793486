import { gql } from "@apollo/client";
import { useMutationBase } from "../../mutations";

export function useSetFlag(flag) { 
    return useMutationBase(gql`
    mutation SetFlag($flag: String) {
        setFlag(flag: $flag)
    }`, 
    {
     noCallOnStart: true,
     variables: {flag: flag},
     errorPolicy: "ignore"});
}