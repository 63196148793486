import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { Chip, ThemeProvider } from "@mui/material";
import styles from "../../styles/component/Forms/ChipListStyle";
import { makeStyles } from "@material-ui/core";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";

const useStyles = makeStyles(styles);

export default function ChipList(props) {
    const entries = props.entries;
    const selected = props.selected;
    const value = props.value;
    const onClick = (value) => () => props.onClick(value);
    const classes = useStyles();

    const chips = () => {return entries.map((entry) =>
        <Grid item>
            {
            selected == entry.id && value != entry.id ?
            <Chip 
            className={classes.selected}
            label={entry.title} 
            component="a"
            onClick={onClick(entry.id)}
            clickable
            />
            :
            value == entry.id ? 
            <Chip className={classes.highlighted} label={entry.title}/>
            :
            <Chip 
            className={classes.default}
            label={entry.title} 
            component="a"
            onClick={onClick(entry.id)}
            clickable
            variant="outlined"
            />}
        </Grid>
    );};

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container item xs={12} spacing={1} className={classes.list}>
        {chips()}
    </Grid>
    </ThemeProvider>
  );
}
