import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ButtonDarkBlue } from "../components/Buttons/ButtonDarkBlue";
import styles from "../styles/container/ForgotPasswordStyle";
import {
  Alert,
  Box,
  Skeleton,
} from "@mui/material";
import { isValidPassword } from "../components/General";
import { FormattedMessage } from "react-intl";
import QueryString from "query-string";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../apiServices/mutations";
import SuccessAlertWithAction from "../components/Alert/SuccessAlertWithAction";
import { signinRedirect } from "../components/IdentityServer/userService";
import Header from "../components/Header";
const useStyles = makeStyles(styles);
export default function ResetPassword() {
  const theme = useTheme();
  const classes = useStyles();
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordWrong, setPasswordWrong] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [passwordMismatch, setpasswordMismatch] = React.useState("");
  const [token, setToken] = React.useState("");
  const [userid, setUserid] = React.useState("");
  const [input, setInput] = React.useState({
    password: "",
  });

  React.useEffect(() => {
    const params = QueryString.parse(window.location.search);
    // console.log("pa", params);
    if (params.token) {
      setToken(params.token);
    }
    if (params.id) {
      setUserid(params.id);
    }
  }, []);

  const [resetPassword, { data, loading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted: (data) => {
        // console.log("resetPasswordresponse", data);
      },
    }
  );
  const checkForErrors = async () => {
    setPasswordError(!input["password"] ? "passwordError" : "");
    setPasswordWrong(
      typeof input["password"] !== "undefined" &&
        !isValidPassword(input["password"])
        ? "passwordWrong"
        : ""
    );
    setConfirmPasswordError(
      !input["confirmPassword"] ? "confirmPasswordError" : ""
    );
    if (
      typeof input["password"] !== "undefined" &&
      typeof input["confirmPassword"] !== "undefined"
    ) {
      setpasswordMismatch(
        input["password"] !== input["confirmPassword"]
          ? "mismatchPasswordError"
          : ""
      );
    }
    return (
      input["password"] &&
      isValidPassword(input["password"]) &&
      input["confirmPassword"] &&
      input["confirmPassword"] &&
      input["password"] === input["confirmPassword"]
    );
  };
  const handleOnChange = (event) => {
    let inputs = input;
    inputs[event.target.id] = event.target.value;
    setInput(inputs);

    if (event.target.id === "password") {
      setPasswordError("");
      setPasswordWrong("");
    }

    if (event.target.id === "confirmPassword") {
      setConfirmPasswordError("");
      setpasswordMismatch("");
    }
  };

  const handleSave = async () => {
    let isError = await checkForErrors();
    if (isError) {
      // console.log("inputs", input);
      const name = token.replace(/\s/g, "+");
      const variable = {
        userId: userid,
        newPassword: input["password"],
        token: name,
      };
      // console.log("resetPassword variable", variable);
      resetPassword({
        variables: variable,
      });
    }
  };

  const handleSucessAlert = () => {
    signinRedirect();
  };
  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(2), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              {typeof data !== "undefined" &&
                data.resetPassword.errorCode === 0 ? (
                <SuccessAlertWithAction
                  message={
                    <FormattedMessage id={"PasswordChangedSucessfully"} />
                  }
                  open={true}
                  handleClose={handleSucessAlert}
                />
              ) : null}
              {typeof data !== "undefined" &&
                data.resetPassword.errorCode === -1 ? (
                <Alert severity="error">{data.resetPassword.message}</Alert>
              ) : null}
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"Reset_Password"} />
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt20}
              >
                <FormattedMessage id={"newPassword"} />
                *:
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      type={"password"}
                      id="password"
                      label={
                        <Typography variant="h5" component="h5">
                          <FormattedMessage id={"Reset_page.Password"} />
                        </Typography>
                      }
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onChange={handleOnChange}
                      className={classes.inputField}
                      inputProps={{
                        style: theme.palette.textFieldInputLabelProps,
                      }}
                      InputLabelProps={{
                        style: theme.palette.textFieldInputProps,
                      }}
                      helperText={
                        passwordError ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={passwordError} />
                          </Typography>
                        ) : passwordWrong ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={passwordWrong} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt20}
              >
                <FormattedMessage id={"confirmPassword"} />
                *:
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      type={"password"}
                      id="confirmPassword"
                      label={
                        <Typography variant="h5" component="h5">
                          <FormattedMessage
                            id={"Register_page.Confirm_password"}
                          />
                        </Typography>
                      }
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onChange={handleOnChange}
                      className={classes.inputField}
                      inputProps={{
                        style: theme.palette.textFieldInputLabelProps,
                      }}
                      InputLabelProps={{
                        style: theme.palette.textFieldInputProps,
                      }}
                      helperText={
                        confirmPasswordError ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={confirmPasswordError} />
                          </Typography>
                        ) : passwordMismatch ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={passwordMismatch} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12} className={classes.mt10}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <ButtonDarkBlue onClick={handleSave}>
                  <FormattedMessage id={"Button_Text_Reset_Password"} />
                </ButtonDarkBlue>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
