import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Skeleton } from "@mui/material";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { FormattedMessage } from "react-intl";
import styles from "../../styles/container/AccountSettingStyle";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILEINFO } from "../../apiServices/mutations";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { clearSelfProfileCache } from "../../apiServices/Queries/Profiles/GetProfile";

const useStyles = makeStyles(styles);

export default function PersonalInfoUpdate(props) {
  const globals = useGlobalContext();
  const profile = globals.state.loggedInUser.profile;
  const [
    ProfileUpdate,
    {
      data: profileData,
      loading: profileUpdateLoading,
    },
  ] = useMutation(UPDATE_PROFILEINFO);
  const classes = useStyles();
  const theme = useTheme();
  const [firstName, setFirstName] = React.useState("");
  const [familyName, setFamilyName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");

  React.useEffect(() => {
    if (profile) {
      setFirstName(profile.givenName);
      setFamilyName(profile.familyName);
      setEmail(profile.email);
    }
    if (
      typeof profileData !== "undefined" &&
      typeof profileData.updateAccountSettings !== "undefined"
    ) {
      // console.log(profileData, "pd");
      setFirstName(profileData.updateAccountSettings.result.givenName);
      setFamilyName(profileData.updateAccountSettings.result.familyName);
    }
  }, [profile, profileData]);

  const saveProfileInfo = async () => {
    clearSelfProfileCache();
    let isError = await checkForErrors();
    // console.log("user id", props.userId);
    // console.log("first name", firstName);
    // console.log("last name", familyName);

    if (isError) {
      ProfileUpdate({
        variables: {
          userId: props.userId,
          givenName: firstName,
          familyName: familyName,
        },
      });
    }
  };
  const checkForErrors = async () => {
    setFirstNameError(!firstName ? "firstNameError" : "");
    setLastNameError(!familyName ? "lastNameError" : "");
    return firstName && familyName;
  };
  const onChangeFirstName = (event) => {
    setFirstNameError("");
    setFirstName(event.target.value);
  };
  const onChangeFamilyName = (event) => {
    setLastNameError("");
    setFamilyName(event.target.value);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt7}
        >
          {profileUpdateLoading ? (
            <Skeleton variant="text" />
          ) : (
            <FormattedMessage id={"firstName"} />
          )}
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.mt7}
        >
          <FormControl variant="filled" className={classes.Select}>
            {profileUpdateLoading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <TextField
                id="filled-basic"
                size="small"
                autoComplete="current-password"
                variant="filled"
                value={firstName}
                onChange={onChangeFirstName}
                inputProps={{ style: theme.palette.textFieldInputLabelProps }}
                InputLabelProps={{ style: theme.palette.textFieldInputProps }}
                helperText={
                  firstNameError ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={firstNameError} />
                    </Typography>
                  ) : null
                }
              />
            )}
          </FormControl>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          {profileUpdateLoading ? (
            <Skeleton variant="text" />
          ) : (
            <FormattedMessage id={"familyName"} />
          )}
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.mt7}
        >
          <FormControl variant="filled" className={classes.Select}>
            {profileUpdateLoading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <TextField
                id="filled-basic"
                size="small"
                autoComplete="current-password"
                variant="filled"
                value={familyName}
                onChange={onChangeFamilyName}
                inputProps={{ style: theme.palette.textFieldInputLabelProps }}
                InputLabelProps={{ style: theme.palette.textFieldInputProps }}
                helperText={
                  lastNameError ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={lastNameError} />
                    </Typography>
                  ) : null
                }
              />
            )}
          </FormControl>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          {profileUpdateLoading ? (
            <Skeleton variant="text" />
          ) : (
            <FormattedMessage id={"email"} />
          )}
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.mt7}
        >
          <FormControl variant="filled" className={classes.Select}>
            {profileUpdateLoading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <TextField
                id="filled-basic"
                size="small"
                autoComplete="current-password"
                variant="filled"
                disabled
                value={email}
                inputProps={{ style: theme.palette.textFieldInputLabelProps }}
                InputLabelProps={{ style: theme.palette.textFieldInputProps }}
              />
            )}
          </FormControl>
        </Typography>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Grid item sm={2} xs={12}>
          <Typography component="h3" variant="h3">
            <ButtonDarkBlue onClick={saveProfileInfo}>
              <FormattedMessage id={"save"} />
            </ButtonDarkBlue>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
PersonalInfoUpdate.prototype = {
  userId: PropTypes.string,
};
