import React, { useEffect } from "react";
import {
  CircularProgress,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import {
  ACCEPT_EVENT_INVITE,
  DECLINE_EVENT_INVITE,
} from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";
import { ClearCache } from "../../../apiServices/CommonMethods";
import { Button, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";


export default function ActivityEventInvite({activity, source, onRefresh, color, sent}) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const history = useHistory();
  const [acceptInvitation, { data: dataAccept, loading: loadingAccept }] =
    useMutation(ACCEPT_EVENT_INVITE);
  const [declineInvitation, { data: dataDecline, loading: loadingDecline }] =
    useMutation(DECLINE_EVENT_INVITE);

  useEffect(() => {
    if (dataAccept || dataDecline) {
      onRefresh();
    }
  }, [dataAccept, dataDecline]);

  const acceptInvitationAction = (meeting) => {
    ClearCache("allEvents");
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    // console.log("Accept invite ", variables);
    acceptInvitation({
      variables: variables,
    });
  };
  const declineInvitationAction = () => {
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    // console.log("Accept decline ", variables);
    declineInvitation({
      variables: variables,
    });
  };

  const navigateToEventsDetail = () => {
    // console.log("Event Iddd :,", activity.activity.eventId);
    // history.push("/dashboard/eventDetails", {
    //   selectedEventId: activity.activity.eventId,
    // });
    history.push("/dashboard/eventDetails/?id=" + activity.activity.eventId, {
      selectedEventId: activity.activity.eventId,
    });
  };

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity.activity &&
        <Text item xs={12} color={color??"background"} id="event_invite_card_title" values={{
          event: <Link
            color="inherit"
            component="button"
            underline="always"
            onClick={navigateToEventsDetail}
          >
            {activity.activity.eventName}
          </Link>}}
        />
      }

      { activity.activity.status !== ActivityStatus.Closed &&
        activity.activity.status !== ActivityStatus.activityEventInviteAccepted &&
        activity.activity.status !== ActivityStatus.activityEventInviteDeclined && 
        !sent && (
        loadingAccept || loadingDecline ? (
          <CircularProgress />
        ) : (
          <>
            <Button variant="contained" onClick={acceptInvitationAction}>
              <FormattedMessage id="EventVisitors_MenuItem_Accept" />
            </Button>
            <Button variant="contained" onClick={declineInvitationAction}>
              <FormattedMessage id="EventVisitors_MenuItem_Decline" />
            </Button>
          </>
        )
      )}
    </ThemeProvider>
  );
}
