import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Chip, CssBaseline, FormHelperText, Hidden } from "@material-ui/core";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router";
import { Alert, Autocomplete, FormControl, Skeleton, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { UPDATE_EVENT } from "../../apiServices/mutations";
import { GetUserId } from "../../apiServices/CommonMethods";
import { useMutation } from "@apollo/client";
import { isEmail } from "../../components/General";
import EventPublishStyle from "../../styles/container/Event/EventPublishStyle";

const useStyles = makeStyles(EventPublishStyle);

export default function EventPublish() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const userId = GetUserId();
  const location = useLocation();

  const [eventData, setEventData] = React.useState({});
  const [input, setInput] = React.useState({});
  const [fileSelected, setFileSelected] = React.useState("");
  const [email, setEmail] = React.useState([]);
  const [emailError, setEmailError] = React.useState("");
  const [isEmailEmpty, setIsEmailEmpty] = React.useState(false);
  const [isEmailExceed, setIsEmailExceed] = React.useState(false);

  const [updateEvent, { loading, data: dataUpdateEvent }] = useMutation(UPDATE_EVENT, {
    onCompleted: (data) => {
      // console.log("data publish", data);
      if (typeof data !== "undefined" && data.updateEvents.errorCode === 0) {
        // history.push("/dashboard/eventDetails", {
        //   eventDetails: data.updateEvents.result,
        // });
        history.push("/dashboard/eventDetails/?id=" + data.updateEvents.result.id, {
          eventDetails: data.updateEvents.result,
        });
      }
    },
  });
  React.useEffect(() => {
    if (location.state && typeof location.state.input !== "undefined") {
      setInput(location.state.input);
      setFileSelected(location.state.fileSelected);
      setEventData(location.state.eventData);
    }
  }, [location]);

  const onChange = (event, newvalue) => {
    setIsEmailEmpty(false);
    setIsEmailExceed(false);
    setEmailError(false);
    setEmail(newvalue);
  };

  const updateTextField = (event) => {
    // const { id, value } = event.target;
    // setEmail(value)
    // setEmailError("");
  };

  // const handlePublish = () => {
  //   if (input["isOrganizer"] === "No") {
  //     const isEmailEmpty = email.length === 0 ? true : false;
  //     const isEmailExceed = email.length > 5 ? true : false;
  //     setIsEmailEmpty(isEmailEmpty);
  //     setIsEmailExceed(isEmailExceed);
  //     if (!isEmailEmpty && !isEmailExceed) {
  //       const emailFilter = email.map((option) =>
  //         !isEmail(option) ? "notvalidemail" : ""
  //       );
  //       let result = emailFilter.includes("notvalidemail");
  //       const isValidemail = result ? true : false;
  //       setEmailError(isValidemail);
  //       if (isValidemail === false) {
  //         updateEvent({
  //           variables: getVariable(true),
  //         });
  //       }
  //     } else {
  //       setEmailError(false);
  //     }
  //   } else {
  //     updateEvent({
  //       variables: getVariable(true),
  //     });
  //   }
  // };
  const handlePublish = () => {
    if (input["isOrganizer"] === "No") {
      const emailFilter = email.map((option) =>
        !isEmail(option) ? "notvalidemail" : ""
      );
      let result = emailFilter.includes("notvalidemail");
      const isValidemail = result ? true : false;
      setEmailError(isValidemail);
      if (isValidemail === false) {
        updateEvent({
          variables: getVariable(true),
        });
      }
    } else {
      updateEvent({
        variables: getVariable(true),
      });
    }
  };

  const getVariable = (isPublished) => {
    let variable = {
      title: input["title"],
      id: eventData.id,
      quote: input["quote"],
      startDate: input["startDate"],
      endDate: input["endDate"],
      isOrganizer: input["isOrganizer"] === "No" ? false : true,
      logo: fileSelected ?? null,
      operatesInDesc: input["operatesInDesc"],
      location: input["location"],
      videoLink: input["videoLink"],
      tagsDesc: input["tagsDesc"],
      description: input["description"],
      website: input["website"],
      ticketingUrl: input["ticketingUrl"],
      isInvitationEnabled: input["isInvitationEnabled"],
      hasNewsUpdate: input["hasNewsUpdate"],
      hideVisitorsToPublic: input["hideVisitorsToPublic"],
      participantScopeDesc: input["participantScopeDesc"],
      loginProfileId: userId,
      profileId: userId,
      shortDescription: input["shortDescription"],
      isPublished: isPublished,
      organizersEmail: email.length ? email : null,
      isOrganizerMailSent: false,
      locationPlaceID: input["locationPlaceID"],
    };
    // console.log("variable", variable);
    return variable;
  };

  // const handleClose = () => {
  //   history.push("/dashboard/eventDetails", {
  //     selectedTab: 5,
  //   });
  // };
  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(3), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              {typeof dataUpdateEvent !== "undefined" &&
                dataUpdateEvent.updateEvents.errorCode === -1 && dataUpdateEvent.updateEvents.result == null ? (
                <Alert severity={"error"}>
                  {dataUpdateEvent.updateEvents.message}
                </Alert>
              ) : null}
            </Grid>
            <Grid item sm={11} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"EventPublish_SpreadTheWorld"} />!
              </Typography>
            </Grid>
            <Grid item sm={1} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={() => {
                  history.goBack();
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"EventPublish_Description1"} />
              </Typography>
              {input["isOrganizer"] === "No" && (
                <Typography
                  component="h3"
                  variant="h2"
                  color={"textPrimary"}
                  className={classes.mt20Orange}
                >
                  <FormattedMessage id={"EventPublish_Description2"} />
                </Typography>
              )}
              {input["isOrganizer"] === "No" && (
                <Typography
                  component="h3"
                  variant="h2"
                  color={"textPrimary"}
                  className={classes.mt10Orange}
                >
                  <FormattedMessage id={"EventPublish_Description3"} />
                </Typography>
              )}
              {input["isOrganizer"] === "No" && (
                <Typography
                  component="h3"
                  variant="h2"
                  color={"textPrimary"}
                  className={classes.mt10Orange}
                >
                  <FormattedMessage id={"EventPublish_Description4"} />
                </Typography>
              )}
            </Grid>
            {input["isOrganizer"] === "No" && (
              <Grid container item xs={12} sm={12} spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    component="h3"
                    variant="h2"
                    color={"textPrimary"}
                    className={classes.mt10}
                  >
                    <FormattedMessage id={"EventPublish_Description5"} />
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h2"
                    color={"textSecondary"}
                    className={classes.mt7}
                  >
                    <FormattedMessage id={"EventPublish_EmailAddress"} />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <FormControl variant="filled" style={{ width: "100%" }}>
                      <Autocomplete
                        style={{ width: "100%" }}
                        multiple
                        freeSolo
                        autoSelect={true}
                        size={"small"}
                        id="emailIds"
                        onChange={onChange}
                        filterOptions={(x) => x}
                        disableClearable
                        options={[]}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                              size="small"
                              className={classes.chip}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            label={<FormattedMessage id={"EmailAddress"} />}
                            {...params}
                            onChange={updateTextField}
                            variant="outlined"
                            value={input}
                            InputProps={{
                              ...params.InputProps,
                              type: "input",
                            }}
                          />
                        )}
                      />
                      {isEmailEmpty ? (
                        <FormHelperText>
                          {" "}
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={"invite_email_error"} />
                          </Typography>{" "}
                        </FormHelperText>
                      ) : null}
                      {isEmailExceed ? (
                        <FormHelperText>
                          {" "}
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage
                              id={"Onboarding_Emailexceed_Validation"}
                            />
                          </Typography>{" "}
                        </FormHelperText>
                      ) : null}
                      {emailError ? (
                        <FormHelperText>
                          {" "}
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={"emailWrong"} />
                          </Typography>{" "}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item sm={3} xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <ButtonDarkBlue onClick={handlePublish}>
                  <FormattedMessage id={"continue"} />
                </ButtonDarkBlue>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
