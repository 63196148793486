//TODO: why is this its own page? We could easily turn this into a modal
import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Background from "../../visualComponents/Background";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import Footer from "../../components/Footer";
import { useHistory, useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import Styles from "../../styles/container/Event/AttendEventStyle";
import { JOIN_EVENT } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { Alert, Button, CssBaseline, Grid, Hidden, Skeleton, ThemeProvider } from "@mui/material";
import { ClearCache, GetUserId } from "../../apiServices/CommonMethods";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import Navigationbar from "../../components/Navigationbar";
import FormContainer from "../../components/Forms/FormContainer";

const useStyles = makeStyles(Styles);

export default function AttendEvent() {
  const userId = GetUserId();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const [eventData, setEventData] = React.useState({});
  const [joinEvent, { data, loading, error }] = useMutation(JOIN_EVENT);

  React.useEffect(() => {
    if (location.state && typeof location.state.eventDetails !== "undefined") {
      setEventData(location.state.eventDetails);
    }
    if(!loading && !data && eventData)
      joinEventAction();
  }, [location]);

  const joinEventAction = async () => {
    const variable = {
      messages: null,
      loginProfileId: userId,
      id: location.state.eventDetails.id,
      participantScopeDesc: "Open event",
    };
    // console.log("Variable :", variable);
    joinEvent({
      variables: variable,
      onCompleted: () => ClearCache("allEvents")
    });
  };
  const handleSucessAlert = async () => {
    // history.push("/dashboard/eventDetails", {
    //   selectedEventId: eventData.id,
    // });
    history.push("/dashboard/eventDetails/?id=" + eventData.id, {
      selectedEventId: eventData.id,
      selectedTab: 3
    });
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
      <CssBaseline />
      <Navigationbar/>
      <FormContainer
        error        = {data?.joinEvent?.errorCode == -1 ? data.joinEvent.message : undefined}
        loading      = {loading}
        onSave       = {handleSucessAlert}
        disabled     = {loading}
        saveId       = "Button_Text_Attend"
      >
        <Text item xs={12} variant="h1" color="background" id={"Attend_EVENT_NAME"} bold/>
        <Text item xs={12} variant="h2" color="background" id={"AttendEvent_Description1" + (eventData?.isOrganizer ? "" : "_not_organisor")} />

        {eventData?.website         && <Text item xs={12} color="background" variant="body1"><a style={{color: "inherit"}} href={eventData?.website        }><Text color="background" id="AttendEvent_link1"/></a></Text>}
        {eventData?.ticketingUrl    && <Text item xs={12} color="background" variant="body1"><a style={{color: "inherit"}} href={eventData?.ticketingUrl   }><Text color="background" id="AttendEvent_link2"/></a></Text>}
        {!eventData?.website && !eventData?.ticketingUrl && <Text item xs={12} color="background" variant="body1">
          <a style={{color: "inherit"}} href={"https://www.google.com/search?q="+encodeURI(eventData?.title)} target="blank">
            <Text color="background" id="AttendEvent_link3"/>
          </a>
        </Text>}
        <Text item xs={12} variant="body1" bold id={"AttendEvent_Description2"} />
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </ThemeProvider>
  );
}
