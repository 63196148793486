import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormattedMessage } from "react-intl";
import { Box } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import { isEmail, isValidPassword } from "../components/General";
import styles from "../styles/container/RegisterStyle";
import LinkedIn from "../components/LinkedIn/LinkedIn";
import GoogleLogin from "react-google-login";
import { useMutation } from "@apollo/client";
import {
  REGISTER_EXTERNALUSER_LINKEDIN,
  REGISTER_WITH_EMAIL,
  REGISTER_WITH_GOOGLE,
} from "../apiServices/mutations";
import { useHistory } from "react-router";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { signinRedirect } from "../components/IdentityServer/userService";
import { Skeleton, ThemeProvider } from "@mui/material";
import {
  GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID,
  LINKEDIN_CLIENT_SECRET,
  LINKEDIN_RIDERECT,
  LINKEDIN_SCOPE,
  LINKEDIN_STATE
} from "../Config";
import { ButtonDarkBlue } from "../components/Buttons/ButtonDarkBlue";
import { loginType } from "../apiServices/Constants";
import QueryString from "query-string";
import { ClearItems } from "../apiServices/CommonMethods";
import Theme_WIP from "../styles/themes/main/Theme_WIP";
import Text from "../visualComponents/Text";

const useStyles = makeStyles(styles);

export default function Register() {
  const [
    RegisterWithEmail,
    { data: registerData, loading: registerLoading, error: registerError },
  ] = useMutation(REGISTER_WITH_EMAIL);
  const [
    RegisterWithGoogle,
    {
      data: registerExternalData,
      loading: registerExternalLoading,
      error: registerExternalError,
    },
  ] = useMutation(REGISTER_WITH_GOOGLE);
  const [
    RegisterWithLinkedIn,
    {
      data: registerExternalLinkedInData,
      loading: registerExternalLinkedInLoading,
      error: registerExternalLinkedInError,
    },
  ] = useMutation(REGISTER_EXTERNALUSER_LINKEDIN);

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailWrong, setEmailWrong] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordWrong, setPasswordWrong] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordMismatch, setpasswordMismatch] = useState("");
  const [error1, setError1] = useState("");
  const [inviteId, setInviteId] = React.useState(null);

  useEffect(() => {
    ClearItems();

    const params = QueryString.parse(window.location.search);
    if (params.inviteId) {
      setInviteId(params.inviteId);
    }
    if (registerData) {
      // console.log("registerData", registerData);
      if (
        typeof registerData.registerUser !== "undefined" &&
        typeof registerData.registerUser.errorCode !== "undefined"
      ) {
        if (registerData.registerUser.errorCode === 0) {
          localStorage.setItem(
            "token",
            registerData.registerUser.result.userId
          );
          localStorage.setItem("loginType", loginType.email);
          localStorage.setItem("accessToken", registerData.registerUser.result.accessToken);
        }
      }
    }
    if (registerExternalData) {
      if (
        typeof registerExternalData.registerGoogleUser !== "undefined" &&
        typeof registerExternalData.registerGoogleUser.errorCode !== "undefined"
      ) {
        // console.log("registerGoogleUser", registerExternalData.registerGoogleUser)
        if (registerExternalData.registerGoogleUser.errorCode === 0) {
          localStorage.setItem(
            "token",
            registerExternalData.registerGoogleUser.result.userId
          );
          localStorage.setItem("loginType", loginType.google);
          localStorage.setItem("accessToken", registerExternalData.registerGoogleUser.result.accessToken);
        }
      }
    }
    if (registerExternalLinkedInData) {
      if (
        typeof registerExternalLinkedInData.registerLinkedInUser !==
        "undefined" &&
        typeof registerExternalLinkedInData.registerLinkedInUser.errorCode !==
        "undefined"
      ) {
        // console.log("registerLinkedInUser", registerExternalLinkedInData.registerLinkedInUser)

        if (registerExternalLinkedInData.registerLinkedInUser.errorCode === 0) {
          localStorage.setItem(
            "token",
            registerExternalLinkedInData.registerLinkedInUser.result.userId
          );
          localStorage.setItem("loginType", loginType.linkedin);
          localStorage.setItem("accessToken", registerExternalLinkedInData.registerLinkedInUser.result.accessToken);
        }
      }
    }
  }, [
    registerData,
    registerExternalData,
    registerError,
    registerExternalLinkedInData,
  ]);

  const actionSignIn = async () => {
    let noErrors = await checkForErrors();
    if (noErrors) {
      RegisterWithEmail({
        variables: {
          givenName: input["firstName"],
          familyName: input["lastName"],
          email: input["email"],
          password: input["password"],
          phone: "123455",
          inviteId: inviteId,
        },
      }).then( completed => {
        if(!completed.errors && completed.data && completed.data.registerUser && completed.data.registerUser.errorCode != -1)
          login();
      });
    }
  };

  const checkForErrors = async () => {
    // console.log("firstNAme", input["firstName"]);
    setFirstNameError(!input["firstName"].trim() ? "firstNameError" : "");
    setLastNameError(!input["lastName"].trim() ? "lastNameError" : "");
    setEmailError(!input["email"] ? "emailError" : "");
    setEmailWrong(
      typeof input["email"] !== "undefined" && !isEmail(input["email"])
        ? "emailWrong"
        : ""
    );
    setPasswordError(!input["password"] ? "passwordError" : "");
    setPasswordWrong(
      typeof input["password"] !== "undefined" &&
        !isValidPassword(input["password"])
        ? "passwordWrong"
        : ""
    );
    setConfirmPasswordError(
      !input["confirmPassword"] ? "confirmPasswordError" : ""
    );
    if (
      typeof input["password"] !== "undefined" &&
      typeof input["confirmPassword"] !== "undefined"
    ) {
      setpasswordMismatch(
        input["password"] !== input["confirmPassword"]
          ? "mismatchPasswordError"
          : ""
      );
    }
    return (
      input["firstName"].trim() &&
      input["lastName"].trim() &&
      input["email"] &&
      input["password"] &&
      isValidPassword(input["password"]) &&
      input["confirmPassword"] &&
      input["confirmPassword"] &&
      input["password"] === input["confirmPassword"]
    );
  };

  const handleOnChange = (event) => {
    let inputs = input;
    inputs[event.target.id] = event.target.value;
    setInput(inputs);
    if (event.target.id === "firstName") setFirstNameError("");

    if (event.target.id === "lastName") setLastNameError("");

    if (event.target.id === "email") {
      setEmailError("");
      setEmailWrong("");
    }

    if (event.target.id === "password") {
      setPasswordError("");
      setPasswordWrong("");
    }

    if (event.target.id === "confirmPassword") {
      setConfirmPasswordError("");
      setpasswordMismatch("");
    }
  };

  const responseGoogle = (response) => {
    if (response) {
      // console.log("email", response.profileObj);
      RegisterWithGoogle({
        variables: {
          providerUserId: response.profileObj.googleId,
          email: response.profileObj.email,
          givenName: response.profileObj.givenName,
          familyName: response.profileObj.familyName,
          inviteId: inviteId,
        },
      });
      const variable = {
        providerUserId: response.profileObj.googleId,
        email: response.profileObj.email,
        givenName: response.profileObj.givenName,
        familyName: response.profileObj.familyName,
        inviteId: inviteId,
      };
      // console.log("RegisterWithGoogle variable", variable);
    } else {
      setError1("error");
    }
  };
  const responseGoogleError = (response) => {
    // console.log("responseGoogleError", response);
  };
  const handleSuccess = (data) => {
    // console.log("handleSuccess", data.code);
    if (data) {
      RegisterWithLinkedIn({
        variables: {
          linkedInUserCode: data.code,
          inviteId: inviteId,
        },
      });
    }
  };

  const handleFailure = (error) => {
    // console.log("handleSuccess", error);
    setError1("error");
  };
  const login = () => {
    signinRedirect();
  };

  return (
    <div>
      <Box className={classes.customBg}></Box>
      <Typography variant="h1" align="center" className={classes.h1}>
        <b>master</b>match
      </Typography>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <div>
          {registerData && registerData.registerUser.errorCode === -1 ? (
            <Alert severity="error">
              <AlertTitle>
                <FormattedMessage id={"error"} />
              </AlertTitle>
              {registerData.registerUser.message}
            </Alert>
          ) : null}
          {/* handle authentication failures for google and linkedin */}
          {registerExternalData && registerExternalData.registerGoogleUser.errorCode === -1 ? (
            <Alert severity="error">
              <AlertTitle>
                <FormattedMessage id={"error"} />
              </AlertTitle>
              {registerExternalData.registerGoogleUser.message}
            </Alert>
          ) : null}
          {registerExternalLinkedInData && registerExternalLinkedInData.registerLinkedInUser.errorCode ===
            -1 ? (
            <Alert severity="error">
              <AlertTitle>
                <FormattedMessage id={"error"} />
              </AlertTitle>
              {registerExternalLinkedInData.registerLinkedInUser.message}
            </Alert>
          ) : null}
          {/* handle any registration failures */}
          {registerError ||
            registerExternalError ||
            error1 ||
            registerExternalLinkedInError ? (
            <Alert severity="error">
              <AlertTitle>
                <FormattedMessage id={"error"} />
              </AlertTitle>
              {<FormattedMessage id={"FailedToFetch"} />}
            </Alert>
          ) : null}

          <form className={classes.root} noValidate autoComplete="off">
            <Box className={classes.textFiledBoxStyle} width={"100% !important"}>
              <Grid container spacing={3} alignItems="center" alignContent="center" style={{padding: "30px"}}>

                <Grid container item xs={12} alignItems="center" alignContent="center">
                  <Typography
                  style={{width: "100%"}}
                  align="center"
                  component="h1" 
                  variant="h1" 
                  className={[classes.text, classes.title]}>
                    <FormattedMessage id={"Register_page.Page_title"} />
                  </Typography>
                  <Typography
                    style={{width: "100%"}}
                    align="center"
                    component="h3"
                    variant="h3"
                    className={[classes.text, classes.description]}
                  >
                    <FormattedMessage id={"Register_page.Page_description"} />
                  </Typography>
                </Grid>

                <Grid item sm={12} xs={12}>
                  {registerLoading ||
                    registerExternalLoading ||
                    registerExternalLinkedInLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      id="firstName"
                      label={
                        <Typography variant="h5" component="h5" className={classes.inputText}>
                          <FormattedMessage id={"Register_page.First_name"} />
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      onChange={handleOnChange}
                      className={classes.inputField}
                      helperText={
                        firstNameError ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={firstNameError} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  {registerLoading ||
                    registerExternalLoading ||
                    registerExternalLinkedInLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      id="lastName"
                      label={
                        <Typography variant="h5" component="span" className={classes.inputText}>
                          <FormattedMessage id={"Register_page.Last_name"} />
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      onChange={handleOnChange}
                      className={classes.inputField}
                      helperText={
                        lastNameError ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={lastNameError} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  {registerLoading ||
                    registerExternalLoading ||
                    registerExternalLinkedInLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      id="email"
                      label={
                        <Typography variant="h5" component="span" className={classes.inputText}>
                          <FormattedMessage id={"Register_page.Email"} />
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      onChange={handleOnChange}
                      className={classes.inputField}
                      helperText={
                        emailError ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={emailError} />
                          </Typography>
                        ) : emailWrong ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={emailWrong} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  {registerLoading ||
                    registerExternalLoading ||
                    registerExternalLinkedInLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      type={"password"}
                      id="password"
                      label={
                        <Typography variant="h5" component="h5" className={classes.inputText}>
                          <FormattedMessage id={"Register_page.Password"} />
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      onChange={handleOnChange}
                      className={classes.inputField}
                      helperText={
                        passwordError ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={passwordError} />
                          </Typography>
                        ) : passwordWrong ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={passwordWrong} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  {registerLoading ||
                    registerExternalLoading ||
                    registerExternalLinkedInLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      fullWidth
                      type={"password"}
                      id="confirmPassword"
                      label={
                        <Typography variant="h5" component="h5" className={classes.inputText}>
                          <FormattedMessage
                            id={"Register_page.Confirm_password"}
                          />
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      onChange={handleOnChange}
                      className={classes.inputField}
                      helperText={
                        confirmPasswordError ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={confirmPasswordError} />
                          </Typography>
                        ) : passwordMismatch ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={passwordMismatch} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{ margin: "10px 0 5px 5px" }}
                    className={classes.text}
                  >
                    <FormattedMessage id={"Register_page.CustomText1"} />
                    <Link
                      href="http://mastermatch.net/gebruikersvoorwaarden/"
                      variant="h5"
                      color=""
                    >
                      {" "}
                      <FormattedMessage id={"Register_page.CustomText2"} />
                    </Link>{" "}
                    <FormattedMessage id={"Register_page.CustomText3"} />
                    <Link
                      href="http://mastermatch.net/privacybeleid/"
                      variant="h5"
                      color=""
                    >
                      {" "}
                      <FormattedMessage id={"Register_page.CustomText4"} />
                    </Link>{" "}
                    <FormattedMessage id={"Register_page.CustomText5"} />
                  </Typography>
                </Grid>
                <Grid container item sm={12} xs={12} justifyContent="center" alignContent="center">
                  <Grid item className={classes.buttonSave}>
                  {registerLoading ||
                    registerExternalLoading ||
                    registerExternalLinkedInLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <ButtonDarkBlue
                      fullWidth
                      onClick={actionSignIn}
                    >
                      <Typography variant="h5" component="h5">
                        <FormattedMessage id={"Register_page.Save"} />
                      </Typography>
                    </ButtonDarkBlue>
                  )}
                  </Grid>
                </Grid>
                {/* <Grid item sm={12} xs={12}>               //uncomment this when linkedin is fixed
                  {registerLoading ||
                    registerExternalLoading ||
                    registerExternalLinkedInLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <LinkedIn
                      clientId={LINKEDIN_CLIENT_ID}
                      clientSecret={LINKEDIN_CLIENT_SECRET}
                      redirectUri={LINKEDIN_RIDERECT}
                      scope={LINKEDIN_SCOPE}
                      state={LINKEDIN_STATE}
                      onFailure={handleFailure}
                      onSuccess={handleSuccess}
                      supportIE
                      redirectPath="/signin-linkedin"
                      renderElement={({ onClick, disabled }) => (
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.buttonLinkedIn}
                          color={"default"}
                          onClick={onClick}
                        >
                          <LinkedInIcon color="primary" />{" "}
                          <span
                            style={{
                              padding: "3px 0 0 4px",
                              textTransform: "initial",
                            }}
                          >
                            <Typography variant="h5" component="h5">
                              <FormattedMessage
                                id={"Register_page.Button_LinkedIn"}
                              />
                            </Typography>
                          </span>
                        </Button>
                      )}
                    ></LinkedIn>
                  )}
                </Grid>*/}
                {/* <Grid item sm={12} xs={12}> //uncomment once it is decided we should allow google login again
                  {registerLoading ||
                    registerExternalLoading ||
                    registerExternalLinkedInLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <GoogleLogin
                      clientId={GOOGLE_CLIENT_ID}
                      render={(renderProps) => (
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.buttonGoogle}
                          color={"default"}
                          onClick={renderProps.onClick}
                        >
                          <GTranslateIcon color="primary" />
                          <span
                            style={{
                              padding: "3px 0 0 4px",
                              textTransform: "initial",
                            }}
                          >
                            <Typography variant="h5" component="h5">
                              <FormattedMessage
                                id={"Register_page.Button_Google"}
                              />
                            </Typography>
                          </span>
                        </Button>
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogleError}
                      cookiePolicy={"single_host_origin"}
                    />
                  )}
                </Grid>*/}
                <Grid item sm={12}>
                  <Button onClick={login} style={{width: "100%"}}>
                    <Link variant="h4" className={classes.text}>
                      <Typography
                        variant="h4"
                        component="span"
                        align="center"
                        className={classes.login}
                      >
                        <FormattedMessage id={"Register_page.Login_here"} />
                      </Typography>
                    </Link>
                  </Button>
                </Grid>
                <ThemeProvider theme={Theme_WIP}>
                  <Text item xs={12} variant="body3" color="background" align="center" id={"Register_page.CustomText6"} mt={ 4} bold/>
                  <Text item xs={12} variant="body3" color="background" align="center" id={"Register_page.CustomText7"} mt={-3}/>
                </ThemeProvider>
              </Grid>
            </Box>
          </form>
        </div>
      </Container>
    </div>
  );
}
