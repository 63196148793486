import { GET_ALL_EVENTS } from "../../Queries";
import { useCachedQuery } from "../CachedQuery";

export function useListEvents(variables) {
    return useCachedQuery(
        "allEvents",
        GET_ALL_EVENTS,
        variables,
        (data) => data?.allEvents?.result,
        60,
        true
    );
}