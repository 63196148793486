
const profileStyle = (theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(18),

    },
    avatar: {
        margin: 1,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 1,
        margin: theme.spacing(1),

    },
    submit: {
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 5,
        color: "#fff",
        fontSize: 13,
        padding: "6px 0 6px 0",
    },
    inputField: {
        height: 50,
    },
    container: {
        alignSelf: "center",
        marginTop: theme.spacing(18),

    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
        marginTop: theme.spacing(8),

    },
    logo: {
        width: "100%",
        aspectRatio: 1,
        borderRadius: "inherit",
        // [theme.breakpoints.up('sm')]: {
        //     borderRadius: "50%",
        // },
    },
    mt10: {
        marginTop: "10px",
        marginRight: "10px"
    },
    mt10c: {
        marginTop: "10px",
        color: "#828282",
    },
    doticon: {
        float: 'right',
        fontSize: 32,
        marginTop: 10,
        border: "2px solid #bdbdbd",
        borderRadius: 4,
        color: "#bdbdbd",
    },
    doticonbottom: {
        float: 'right',
        fontSize: 32,
    },
    closeicon: {
        float: 'right',
        fontSize: 32,
        color: "#bdbdbd",
        '@media (max-width: 599px)': {
            position: 'absolute',
            top: "9%",
        },
    },
    batchleft: {
        float: 'left',
    },
    batchright: {
        float: 'right',
        backgroundColor: "#a3ccfb",
        borderRadius: 4,
        padding: "2px 10px 0 10px",
    },
    matches: {
        overflow: 'Hidden',
        width: "100%",
        paddingTop: "10px",
    },
    tabcontent: {
        border: "1px solid",
        borderRadius: 4,
        padding: "10px 0",
        width: "100%",
        fontWeight: '400',
    },
    plr10: {
        padding: "0 10px 0 10px",
    },
    plr10m7: {
        padding: "0 10px 0 10px",
        marginTop: 7,
    },
    plrm10: {
        padding: "0 10px 0 10px",
        marginTop: 10,
    },
    right: {
        float: 'right',
    },
    profilimg: {
        width: "100% !important",
        [theme.breakpoints.up('sm')]: {
            width: "50% !important",
        },
    },
    tabheading: {
        minWidth: 'unset !important',
        maxWidth: 'unset !important',
        Width: 'unset !important',
        color: "#00394D"
    },
    tabmain: {
        width: "100%",
        margin: "0 !important",
        padding: "0 !important",
        height: 20,
        "&& .MuiTabs-scrollable .MuiTabs-flexContainer": {gap: "15px"}
    },
});
export default profileStyle;