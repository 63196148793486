import { gql } from "@apollo/client";
import { useCachedQuery } from "../CachedQuery";
import { MarkerEntitySources } from "../../../components/General";


export function useAdminDashboard(filter) {
return useCachedQuery(
    "acceptedInvites",
    gql`
    query getAll($filter: BaseFilterInput, $eventsFilter: BaseFilterInput, $circlesFilter: BaseFilterInput) {
        onboardings(filter: $filter) {
            total
            results { createdDate }
        }
        # openedDashboard(filter: $filter) {
        #     total
        #     results { openedDashboard }
        # }
        # subscriptions(filter: $filter) {
        #     total
        #     results { createdDate }
        # }
        premiums: subscriptions(filter: $filter, subscription: PAID_SUBSCRIPTION) {
            total
            results { createdDate }
        }
        requests(filter: $filter) {
            total
            results { createdDate }
        }
        eventRequests: requests(filter: $eventsFilter) {
            total
            results { createdDate }
        }
        circleRequests: requests(filter: $circlesFilter) {
            total
            results { createdDate }
        }

        responsesToRequest(filter: $filter) {
            total
            results { createdDate }
        }
        eventResponsesToRequest: responsesToRequest(filter: $eventsFilter) {
            total
            results { createdDate }
        }
        circleResponsesToRequest: responsesToRequest(filter: $circlesFilter) {
            total
            results { createdDate }
        }

        meetings(filter: $filter) {
            total
            results { createdDate }
        }
        eventMeetings: meetings(filter: $eventsFilter) {
            total
            results { createdDate }
        }
        circleMeetings: meetings(filter: $circlesFilter) {
            total
            results { createdDate }
        }

        introductions(filter: $filter) {
            total
            results { createdDate }
        }
        eventIntroductions: introductions(filter: $eventsFilter) {
            total
            results { createdDate }
        }
        circleIntroductions: introductions(filter: $circlesFilter) {
            total
            results { createdDate }
        }

        messages(filter: $filter) {
            total
            results { createdDate }
        }
        eventMessages: messages(filter: $eventsFilter) {
            total
            results { createdDate }
        }
        circleMessages: messages(filter: $circlesFilter) {
            total
            results { createdDate }
        }

        eventInvites(filter: $filter) {
            total
            results { createdDate }
        }
        eventInvitedMembers(filter: $filter) {
            total
        }
        acceptedEventMembers: eventInvitedMembers(filter: $filter, accepted: true) {
            total
        }
        eventRegistrations(filter: $filter) {
            total
        }
        eventOnboardings(filter: $filter) {
            total
            results { createdDate }
        }

        circleInvites(filter: $filter) {
            total
            results { createdDate }
        }
        circleInvitedMembers(filter: $filter) {
            total
        }
        acceptedCircleMembers: circleInvitedMembers(filter: $filter, accepted: true) {
            total
        }
        circleRegistrations(filter: $filter) {
            total
        }
        circleOnboardings(filter: $filter) {
            total
            results { createdDate }
        }

        profileInvites(filter: $filter) {
            total
            results { createdDate }
        }
        profileInvitedMembers(filter: $filter) {
            total
        }
        profileRegistrations(filter: $filter) {
            total
        }
    }
    `,
    { 
        filter: filter,
        eventsFilter : {...filter, source: MarkerEntitySources.EVENT},
        circlesFilter: {...filter, source: MarkerEntitySources.CIRCLE}
    },
    (data) => data,
    0.3,
    true
);
}