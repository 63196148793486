import { Button, Grid, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";
import { useAdminDashboard } from "../../../apiServices/Queries/Dashboard/AdminQueries";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function AdminDashboard() {
    const history = useHistory();
    const query   = useAdminDashboard();
    const [datas, setDatas] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [csv  , setCsv  ] = useState("");

    useEffect(() => {
        var dataList = [];
        var dates    = [];
        for(var key in query.data) {
            if (!query.data.hasOwnProperty(key)) continue;
            dataList.push({
                key  : key,
                total: query.data[key]?.total, 
                dates: query.data[key]?.results ? entriesFromDates(query.data[key].results).sort((a, b) => b.year - a.year || b.week - a.week) : []
            });
            if(query.data[key]?.results?.length)
                dates = dates.concat(query.data[key].results);
        }
        const get = (key) => dataList.find(d => d.key == key);
        var dataSortOrder = !dataList.length ? [] : [
            {title: "Invites"                      , key: "totalInvites_" , bold: true, 
                note : "Note this is the calculated total number of invites. For every event/circle invite to people without an account, a profile invite is also created. If there already is an account, a event/circle invite without profile invite is created. We can't really calculate total per week because of this relation.",
                total: get("profileInvites").total,
                dates: []
            },
            {title: "Event invites"                , key: "eventInvites"   , bold: false},
            {title: "Circle invites"               , key: "circleInvites"  , bold: false},
            {title: "Profile invites"              , key: "profileInvites", bold: false, 
                note : "These invites are created upon both event/circle invitates, and user invitations for people who are not on mastermatch yet. Some of the circle/event invites in the 'per week' section may have overlap with the profile invites",
                total: get("profileInvites").total - get("eventInvites").total - get("circleInvites").total,
                //dates: []
            },

            {title: "Registrations"                , key: "profileRegistrations" , bold: true, 
                note : "TODO: some extra work is required in backend to retrieve 'per week' here" },
            {title: "Registrations via event"      , key: "eventRegistrations"   , bold: false},
            {title: "Registrations via circle"     , key: "circleRegistrations"  , bold: false},
            {title: "Registrations via user"       , key: "profileRegistrations_", bold: false,
                total: get("profileRegistrations").total - get("eventRegistrations").total - get("circleRegistrations").total,
                dates: []
            },

            {title: "Onboardings"                  , key: "onboardings"      , bold: true , 
                note : "The dates in 'per week' are the registration dates. We don't actually save when we finished onboarding yet."},
            {title: "Onboardings via event"        , key: "eventOnboardings" , bold: false},
            {title: "Onboardings via circle"       , key: "circleOnboardings", bold: false},
            {title: "Onboardings via user"         , key: "userOnboardings_" , bold: false, 
                note : "To get onboardings in a week, subtract even and circle onboardings from total.",
                total: get("onboardings").total - get("eventOnboardings").total - get("circleOnboardings").total,
                dates: []
            },

            {title: "Invite acceptations"          , key: "acceptedTotalMembers_", bold: true, 
                note : "We don't save in the backend when an invite got accepted.", 
                total: get("acceptedEventMembers").total + get("acceptedCircleMembers").total,
                dates: []
            },
            {title: "Acceptations via event"       , key: "acceptedEventMembers" , bold: false},
            {title: "Acceptations via circle"      , key: "acceptedCircleMembers", bold: false},

            {title: "Premium subscriptions (paid)" , key: "premiums"          , bold: true, 
                note : "These do not include trials or business subscriptions."},

            {title: "Requests"                     , key: "requests"          , bold: true},
            {title: "Requests from Event"          , key: "eventRequests"     , bold: false},
            {title: "Requests from Circle"         , key: "circleRequests"    , bold: false},

            {title: "Request Responses"            , key: "responsesToRequest", bold: true},
            {title: "Request Responses from Event" , key: "eventResponsesToRequest"  , bold: false},
            {title: "Request Responses from Circle", key: "circleResponsesToRequest" , bold: false},

            {title: "Meetings"                     , key: "meetings"          , bold: true},
            {title: "Meetings from Event"          , key: "eventMeetings"     , bold: false},
            {title: "Meetings from Circle"         , key: "circleMeetings"    , bold: false},

            {title: "Messages"                     , key: "messages"          , bold: true },
            {title: "Messages from Event"          , key: "eventMessages"     , bold: false},
            {title: "Messages from Circle"         , key: "circleMessages"    , bold: false},

            {title: "Introductions"                , key: "introductions"      , bold: true },
            {title: "Introductions from Event"     , key: "eventIntroductions" , bold: false},
            {title: "Introductions from Circle"    , key: "circleIntroductions", bold: false},
        ];
        //setDatas(dataList);

        setDatas(dataSortOrder.map(d => ({...get(d.key), ...d })));
        setWeeks(entriesFromDates(dates).sort((a, b) => b.year - a.year || b.week - a.week));
    }, [query.data]);

    //set CSV
    useEffect(() => {
        setCsv(
            "key,total,perWeekEntries," + weeks.map(w => getDateOfWeek(w.week, w.year).toLocaleDateString()).join(",") + "\n" +
            datas.map(d => d.title+","+d.total+","+(d.dates?.length ?? "0")+"," +
                weeks.map(y => d.dates.filter(m => m.week == y.week && m.year == y.year)[0]?.count ?? "0").join(",") ).join("\n")
        );
    }, [datas, weeks]);

    function getDateOfWeek(w, y, d_ = 0) {
        var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
    
        return new Date(y, 0, d + d_);
    }

    function getWeekNumber(d) { //d = string
        d = new Date(d[Object.keys(d)[0]]);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return {year: d.getUTCFullYear(), week: weekNo};
    }
    
    function entriesFromDates(d) {
        var helper = {};
        return d.map(getWeekNumber).reduce(function(r, o) {
            var key = o.week + '-' + o.year;
            
            if(!helper[key]) {
              o.count = 1;
              helper[key] = Object.assign({}, o); // create a copy of o
              r.push(helper[key]);
            } else {
              helper[key].count += 1;
            }
          
            return r;
          }, []);
    }
    function download(filename, text) {
        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        pom.setAttribute('download', filename);
    
        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        }
        else {
            pom.click();
        }
    }

    return <ThemeProvider theme={Theme_WIP}>
        <Grid container p={{xs: 2, sm: 10}} gap="30px">
            <Text variant="h1" bold align="center" color="background" xs={12} item>Dashboard</Text>
            <Grid item xs={12} container gap="30px">
                <Grid item xs={12} container gap="5px" position="absolute" zIndex={-10}>
                    <Text bold variant="body1" align="left" color="background" item xs={12}>Info</Text>
                    {datas.map((d, index) =>
                        <Text  variant="body1" align="left" color="transparent" sx={index % 2 ? {} : {
                            background: "white",
                            opacity: "0.5"
                        }} item xs={12}> - </Text>
                    )}
                </Grid>
                <Grid item xs={2} container gap="5px">
                    <Text bold variant="body1" align="left" color="background" item xs={12}>Info</Text>
                    {datas.map(d =>
                        <Text bold={d.bold} title={d.note} sx={{overflowX: "scroll", textWrapMode: "nowrap", scrollbarWidth: "none"}} variant="body1" align="left" color="background" item xs={12}>{d.title ?? d.key}</Text>
                    )}
                </Grid>
                <Grid item xs={0.25} container gap="5px">
                    <Text bold variant="body1" align="right" color="background" item xs={12}>Total</Text>
                    {datas.map(d =>
                        <Text  variant="body1" align="right" color="background" item xs={12}>{d.total}</Text>
                    )}
                </Grid>

                <Grid item xs={7.5} sm={8.25} md={8.6} lg={9} container>
                    <div style={{
                        width: "fit-content",
                        overflowX: "scroll",
                        scrollbarWidth: "none",
                        display: "flex",
                        gap: "15px"
                    }}>
                    {((weeks?.length ?? 0) > 0) && weeks.map((y, index) => 
                        <>
                        {(index == 0 || weeks[index].year != weeks[index-1].year) && <Grid container sx={{minWidth: "75px"}}>
                            <Text variant="body1" align="right" color="background" item xs={12} bold >{y.year} - Week: </Text>
                        </Grid>}
                        <Grid container sx={{minWidth: "30px"}} gap="5px">
                        <Text variant="body1" align="right" color="background" item xs={12} bold 
                        title={"Starts on: " + getDateOfWeek(y.week, y.year).toLocaleDateString()+",\nEnds on: " + getDateOfWeek(y.week, y.year, 7).toLocaleDateString()}
                        >{y.week}</Text>
                        {/* <Text variant="body1" align="right" color="background" width="150px" bold>week {y.week} - {getDateOfWeek(y.week, y.year).toLocaleDateString()}</Text> */}
                        {datas.map(d => {
                            var x = ((d.dates?.length ?? 0) < 1) ? "." :
                            (d.dates.filter(m => m.week == y.week && m.year == y.year)[0]?.count ?? "0");
                            var important = x != "." && x != "0";
                            return <Text variant="body1" align="right" color="background" item xs={12} sx={{opacity: important ? 1 : 0.5}}>{x}</Text>
                        }
                        )}
                        </Grid>
                        </>
                    )}
                    </div>
                </Grid>
            </Grid>
            <Button variant="contained" onClick={() => history.goBack()} color="lightPetrol">Terug naar Admin Panel</Button>
            <Button variant="contained" onClick={() => download("dashboard.csv", csv)} disabled={!csv}>Download CSV</Button>
        </Grid>
        <p style={{display: "none"}}>{csv}</p>
    </ThemeProvider>;
}