import React from "react";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

// TODO matchtime styling restore
export default function MatchSuggestionFoundCard({source}) {

  if(source == "canCollapse")
    return undefined;
  if(source == "menu")
    return undefined;
  return (
      <ThemeProvider theme={Theme_WIP}>
        <Text variant="body1" color="primary" id="Activity.MatchSuggestion.Description" />
      </ThemeProvider>
    );
}
