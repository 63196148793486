import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router";
import { GET_ALL_FEEDS } from "../../apiServices/Queries";
import { Waypoint } from "react-waypoint";
import FeedsCard from "../../components/Cards/FeedsCard";
import { Alert, CircularProgress, Grid, Stack } from "@mui/material";
import Box from "@material-ui/core/Box";
import { FormattedMessage, useIntl } from "react-intl";
import { FeedsTabItems, userRoles } from "../../apiServices/Constants";
import RequestCard from "../../components/Cards/RequestCard";
import Styles from "../../styles/component/Circles/CircleFeedsStyle";
import Filters from "../Filters";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import NewRequestCard from "../Cards/NewRequestCard";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import { ClearCache } from "../../apiServices/CommonMethods";

const useStyles = makeStyles(Styles);

export default function CircleFeed(props) {
  const globals  = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();

  const circledata = props?.circleDetails;
  const [feeds, setFeeds] = React.useState([]);
  const [selectedPostID, setSelectedPostID] = React.useState("");

  const [input, setInput] = React.useState({
    circleId: circledata.id,
    searchInput: "",
    pageNumber: 1,
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessTypes: null,
    locationPlaceID: null
  });

  const getVariables = () => ({
    circleId: input.circleId,
    loginProfileId: userId,
    pageNumber: input["pageNumber"],
    pageSize: parseInt(10),
    searchInput: input["searchInput"],
    hint: "all",
    sourceID: selectedPostID ? selectedPostID : null,
    location: input["location"],
    industries: input["industries"],
    skills: input["skills"],
    maxDistance: input["maxDistance"],
    collaborationType: input["collaborationType"],
    businessTypes: input["businessTypes"],
    locationPlaceID: input["locationPlaceID"],
  });

  const { data, loading, error, updateVariables} = useCachedQuery(
    "circle_feed",
    GET_ALL_FEEDS,
    getVariables(),
    (data) => data?.allCircleFeeds?.result,
    5,
    true
  );
  useEffect(() => {
    setInput({...input, circleId: circledata.id, pageNumber: 1});
    setFeeds([]);
  }, [circledata]);

  useEffect(() => {
    updateVariables(getVariables());
  }, [input]);

  const reload = () => {
    globals.functions.loggedInUser.refetchRole(); 
    ClearCache("allCollabRequests");
    ClearCache("circle_feed", globals);
    setInput({...input, pageNumber: 1});
    setFeeds([]);
    setSelectedPostID(null);
    setTimeout(() => updateVariables({...getVariables(), pageNumber: 1}), 100);
  };

  useEffect(() => {
    if(!data || !input.circleId) return;
    if (input.pageNumber === 1)
      setFeeds(data);
    else if (data.length > 0)
      setFeeds((prevState) => [
        ...prevState,
        ...data,
      ]);
  }, [data]);

  const navigateToProfile = (feeds) => {
    history.push("/dashboard/profile/?id=" + feeds.userId, {
      selectedUserId: feeds.userId,
    });
  };

  const filterAction = inputs => {
    setFeeds([]);
    setInput(inputs);
    setInput(prevState => ({
      ...prevState,
      searchInput: null,
    }));
  };
  
  const getRequestCardData = (card) => ({ ...card.requestFeeds, ...card });

  return (
    <Grid container>
      {(circledata.loggedInUserRole === "Moderator" ||
        circledata.loggedInUserRole === "Member" ||
        circledata.loggedInUserRole === "Superuser")
      && <Grid container mb={4}><NewRequestCard circleId={circledata.id} onCompleted={reload} noPremiumNeeded/></Grid>}     
      <Grid container className={classes.mb15}>
        {error ? (
          <Grid item xs={12}>
            <Alert severity="error">
              {error.length ? (
                error
              ) : (
                <FormattedMessage id={"somethingWentWrong"} />
              )}
            </Alert>
          </Grid>
        ) : null}
      </Grid>

      {/* <Filters onSearch={filterAction} isBrandColor={true} filterType={"collabRequest"} /> */}
      {loading && !feeds.length ? (
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              flex: "row",
              justifyContent: "center",
              width: "100%",
              height: 300,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <CircularProgress />{" "}
            </Stack>
          </Box>
        ) : feeds.length > 0 ? (
          <Grid container gap={ThemeConstants.custom.spacings.md} mt={ThemeConstants.custom.spacings.md}>
            {feeds.map((card, index) => (
              <React.Fragment key={index}>
                {typeof card.circlePosts !== "undefined" &&
                  card.circlePosts !== null ? (
                  <Grid item sm={12} xs={12}>
                    <FeedsCard
                      data={card}
                      onRefresh={reload}
                      circleData={circledata}
                      key={index}
                    />
                  </Grid>
                ) : (
                  <Grid container item sm={12} xs={12}>
                    <RequestCard
                      request={getRequestCardData(card)}
                      onRefresh={reload}
                      circleData={circledata}
                      fromProfile={true}
                      key={index}
                      onUserSelection={navigateToProfile}
                      isFromCircle={true}
                    />
                  </Grid>
                )}
                {index === feeds.length - 2 && (
                  <Waypoint
                    scrollableAncestor={window}
                    onEnter={() => {
                      const newPage = input["pageNumber"] + 1;
                      setInput((prevState) => ({
                        ...prevState,
                        pageNumber: newPage,
                      }));
                    }}
                  />
                )}
              </React.Fragment>
            ))}{" "}
          </Grid>
        ) : (
          <Paper elevation={3} className={classes.tabcontent}>
            <Grid container spacing={4}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography component="span" variant="h3" color={"textPrimary"}>
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Paper>
        )
      }
    </Grid >
  );
}
