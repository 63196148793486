import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { Chip } from "@mui/material";
import { useMutation } from "@apollo/client";
import Styles from "../../styles/component/Cards/CircleMemberCardStyle";
import {
  REMOVE_CIRCLE_MEMBERS,
  MakeAs_Circle_Moderator,
} from "../../apiServices/mutations";
import { FormattedMessage, useIntl } from "react-intl";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import UserPostedCard from "./Defaults/UserPostedCard";

const useStyles = makeStyles(Styles);

export default function MemberCard(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const intl = useIntl();
  const [circledata, setCircleData] = React.useState({});

  useEffect(() => {
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails);
      // console.log("listyyy", props.data.userId, userId);
    }
  }, [props]);
  const [removeMember, { loading }] = useMutation(REMOVE_CIRCLE_MEMBERS, {
    onCompleted: (data) => {
      if (props.onRefresh) {
        props.onRefresh();
      }
    },
  });
  const [
    makeModerator,
    { loading: loadingDoneRequest },
  ] = useMutation(MakeAs_Circle_Moderator, {
    onCompleted: (data) => {
      if (props.onRefresh) {
        props.onRefresh();
      }
    },
  });
  const removeCircle = (data) => {
    const variables = {
      circleId: circledata.id,
      loginProfileId: userId,
      profileId: data.userId,
    };
    removeMember({
      variables: variables,
      onCompleted: (data) => {
        if (props.onRefresh) {
          props.onRefresh();
        }
      }
    });
  };

  const makeAsCircleModerator = (data) => {
    const variables = {
      circleId: circledata.id,
      loginProfileId: userId,
      profileId: data.userId,
    };
    makeModerator({
      variables: variables,
      onCompleted: (data) => {
        if (props.onRefresh) {
          props.onRefresh();
        }
      }
    });
  };
  return (<UserPostedCard
    id            = {props.data.userId}
    username      = {props.data.givenName ? props.data.givenName + " " + props.data.familyName : props.data.userId}
    pfp           = {props.data.profilePictureUrl ?? "/assets/img/userProfile.png"}
    role          = {props?.data?.companyRole}
    company       = {props?.data?.companyName}
    circles       = {props?.data?.sharedCircles}
    label         = {(circledata?.loggedInUserRole === "Moderator" || userId == globals.state.admin.userId) && (
      <FormattedMessage id={props.data.circleRole === "Moderator" ? "Manager" : props.data.circleRole === "Member" ? "CircleMember_Accepeted" : "CircleMember_Pending"}/>
    )}
    location      = {props?.data?.location}
    info          =
      {props.data.userId !== userId ? (
        <span className={classes.violet}>
          {props.data.isMatch !== null && props.data.isMatch !== false
            ? intl.formatMessage({ id: "VisitorCard_Match" }) + " "
            : ""}
        </span>
      ) : (
        ""
      )}
    menuItems={
      (circledata.loggedInUserRole === "Moderator" || circledata.loggedInUserRole === "SuperUser") &&
      props.data.circleRole !== "Moderator" &&
      props.data.circleRole !== "SuperUser" &&
      props.data.isMember && <>
        <MenuItem onClick={() => removeCircle(props.data)}>
          <FormattedMessage id={"CircleMember_RemoveMember"} />
        </MenuItem>
        <MenuItem onClick={() => makeAsCircleModerator(props.data)}>
          <FormattedMessage id={"CircleMember_Makemanager"} />
        </MenuItem>
      </>}
      tags={props?.data?.skills}
    />
  );
}
