import React from "react";
import {
  MenuItem,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";


export default function ActivityNewsUpdate({activity, isLess, source, color}) {
  const history = useHistory();

  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };
  const navigateToCircleDetail = () => {
    history.push("/dashboard/circleDetails/?id=" + activity.activity.circleId, {
      selectedCircleId: activity.activity.circleId,
      selectedTab: 1,
      selectedNewsID: activity.activity.activitySourceId,
    });
  };

  if(source == "menu")
    return
      <MenuItem onClick={navigateToMessage}>
        <FormattedMessage id={"Message"} />
      </MenuItem>;
  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  return (
    <ThemeProvider theme={Theme_WIP}>
            <Text item xs={12} color={color??"background"}>
              {isLess && (activity?.activity?.messages?.length ?? 0) > 150 ? 
                  activity.activity.messages.substring(0, 150) + "..."
                : activity?.activity?.messages}
            </Text>
            {(!isLess || (activity?.activity?.messages?.length ?? 151) <= 150) &&
              <Text>
                  <Link
                    color="inherit"
                    component="button"
                    underline="always"
                    onClick={navigateToCircleDetail}
                  >
                    <FormattedMessage id="SeeAllCircleNewsUpdates" />
                  </Link>
              </Text>
            }
    </ThemeProvider>
  );
}
