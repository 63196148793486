import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Tabs, Tab, CardMedia, } from "@mui/material";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { FormattedMessage } from "react-intl";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import { Container, CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../Navigationbar";
import Header from "../Header";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import DatePicker from "../DatePicker/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ChangePremiumStyle from "../../styles/component/Accountsettings/ChangePremiumStyle";
const useStyles = makeStyles(ChangePremiumStyle);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default function ChangePremium(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [tabvalue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    // console.log("ne", newValue);
    setTabValue(newValue);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(4), mb: theme.spacing(4) }}>
          <Grid container spacing={2}>
            <Grid item sm={10} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"ChangeYourPaymentMethod"} />
              </Typography>
            </Grid>
            <Grid item sm={2} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={() => history.goBack()}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Typography
                component="h4"
                variant="h4"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"CurrentPaymentMethod"} />:
              </Typography>
            </Grid>
            <Grid item sm={1} xs={1}>
              <CardMedia
                component="img"
                image={"/assets/img/IDEAL_Logo.png"}
                alt="mastermatch_logo"
                //width={5}
                //height={10}
                className={classes.logo}
              />
              {/* </Box> */}
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                component="h4"
                variant="h4"
                color={"textPrimary"}
                className={classes.mt3}
              >
                <FormattedMessage id={"ChooseYourNewPaymentMethod"} />:
              </Typography>
              <Tabs
                value={tabvalue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  icon={
                    <Box
                      sx={{
                        padding: "25px !important",
                        border: "1px solid #E0E0E0",
                        boxShadow: "0 4px 8px 0 #E0E0E0, 0 6px 3px 0 #E0E0E0",
                        borderRadius: "4px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={"/assets/img/IDEAL_Logo.png"}
                        alt="mastermatch_logo"
                        width={70}
                        height={30}
                      // className={classes.logo}
                      />
                    </Box>
                  }
                />
                <Tab
                  icon={
                    <Box
                      sx={{
                        paddingLeft: "5px!important",
                        paddingTop: "32px !important",
                        paddingBottom: "32px !important",
                        paddingRight: "5px !important",
                        border: "1px solid #E0E0E0",
                        boxShadow: "0 4px 8px 0 #E0E0E0, 0 6px 20px 0 #E0E0E0",
                        borderRadius: "4px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={"/assets/img/visa.png"}
                        alt="mastermatch_logo"
                        width={20}
                        height={20}
                      //className={classes.logo}
                      />
                    </Box>
                  }
                />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TabPanel value={tabvalue} index={0}>
                <Grid container spacing={4}>
                  <Grid item sm={12} xs={12}>
                    <Box sx={{ minWidth: 120 }} className={classes.mt3}>
                      <FormControl className={classes.Select}>
                        <Typography
                          component="h4"
                          variant="h4"
                          color={"textPrimary"}
                        >
                          <FormattedMessage id={"IDeal"} />
                        </Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          name="isOrganizer"
                          size="small"
                          className={classes.mt7}
                        >
                          {["No", "Yes"].map((option, index) => (
                            <MenuItem value={option} key={index}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TabPanel value={tabvalue} index={1}>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <Typography
                      component="h4"
                      variant="h4"
                      color={"textPrimary"}
                    //className={classes.mt20}
                    >
                      <FormattedMessage id={"CreditCard"} />
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    // className={classes.mt20}
                    >
                      <FormattedMessage id={"NameOnTheCard"} />
                      *:
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.mt7}
                      gutterBottom
                    >
                      <FormControl variant="filled" className={classes.Select}>
                        <TextField
                          // label="Type here"
                          variant="filled"
                          size="small"
                          id={"name"}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    //className={classes.mt20}
                    >
                      <FormattedMessage id={"CreditCardNumber"} />
                      *:
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.mt7}
                      gutterBottom
                    >
                      <FormControl variant="filled" className={classes.Select}>
                        <TextField
                          // label="Type here"
                          variant="filled"
                          size="small"
                          id={"cardno"}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    //className={classes.mt20}
                    >
                      <FormattedMessage id={"ExpiryDate"} />
                      *:
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.mt7}
                      gutterBottom
                    >
                      <FormControl variant="filled" className={classes.Select}>
                        <DatePicker />
                      </FormControl>
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                    // className={classes.mt20}
                    >
                      <FormattedMessage id={"Cvv"} />
                      *:
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.mt7}
                      gutterBottom
                    >
                      <FormControl variant="filled" className={classes.Select}>
                        <TextField
                          // label="Type here"
                          variant="filled"
                          size="small"
                          id={"name"}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Box>
                <ButtonDarkBlue>
                  <FormattedMessage id={"Button_Text_Pay"} />
                </ButtonDarkBlue>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
