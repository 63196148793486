import { CssBaseline, Grid, ThemeProvider } from "@mui/material";
import React from "react";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import { Redirect } from "react-router";
import MTCountdown from "./Pages/MTCountdown";
import MTRequest from "./Pages/MTRequest";
import MTResults from "./Pages/MTResults";
import MTRandomSwipes from "./Pages/MTRandomSwipes";
import switchRoutes from "../../apiServices/Methods/RoutingMethods";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { areSwipesPending, canEditMatchProfiles, doneSwiping } from "../../apiServices/Methods/MatchProfileMethods";
import { isItMatchTime } from "../../apiServices/Methods/MatchProfileMethods";
import Navigationbar from "../../components/Navigationbar";

const routes = (meta) => {
  return [
      { path: "/*",
        component: (() => <Redirect to="/matchtime"/>) },
      { path: "",
        component: (() => null)},
  ];
}

const component = (meta) => switchRoutes("/matchtime", routes(meta));

export default function MTLayout(props) {
  const history = useHistory();

    return(
        <ThemeProvider theme={Theme_WIP}>
            <CssBaseline />
            <Navigationbar/>
            <Background page color="linear-gradient(180deg, #FFA303 53.5%, #F17844 100%)"/>
            <Grid container py={30} px={{xs: 5, sm: 25, md: 30 }} justifyContent="center" gap={4} sx={{width: "100vw"}}>
              <Grid container item justifyContent="center" gap={4} sx={{
                minWidth: "300px",
                maxWidth: "700px"
              }}>
                {component(history.location.state?.meta)}
                {areSwipesPending(history.location.state?.meta) ? 
                  <MTResults/> 
                : (isItMatchTime ? //is it monday?
                    (canEditMatchProfiles ? //is it before 15:00?
                      (history.location.state?.meta?.reviewedProfiles ?
                        <MTResults/>
                      : <MTRequest/>)
                    : (history.location.state?.meta?.finishedRandomSwipes ?
                        <MTRandomSwipes/>
                      : <MTResults/>))
                  : <MTCountdown/>)
                }
              </Grid>
            </Grid>
        </ThemeProvider>
    );
}