// If you see any common queries which get used by the session owner, feel free to add them to this
// we currently have a lot of duplicate logic for obtaining the resources of the authenticated user
// We should store them in Hooks to avoid bloating our components with query logic
import { useQuery } from "@apollo/client";
import { GET_ADMIN_USER, GET_AUTH_PROFILE, GET_NOTIFICATION_COUNT, GET_ONBOARDING_STATUS } from "./Queries";
import { GetUserId } from "./CommonMethods";
import { useEffect, useState } from "react";
import { useGetProfile } from "./Queries/Profiles/GetProfile";
import { useCachedQuery } from "./Queries/CachedQuery";

//TODO: find all places we need the profile of the authenticated user and replace the query boilerplate with this hook, and then delete this comment
//use like const { loading, profile } = CurrentUserProfile(); then call profile.profile
export function useCurrentUserProfile() {
    const userId = GetUserId();
    const {data: profileData} = useGetProfile(userId, userId);
    return profileData;
}

export function useAdminUserIdQuery() {
  return useCachedQuery(
    "admin",
    GET_ADMIN_USER,
    {},
    (data) => data?.adminUser?.result?.userID,
    360,
    true
  );
}

export function useIsOnboardingComplete() {
  const userId = GetUserId();
  const {loading, error, data} = useQuery(GET_ONBOARDING_STATUS, {
      variables: {
        userId: userId
      },
    });
  const [isOnboardingProcessCompleted, setCompleted] = useState(undefined);
  useEffect(() => {
    if(!loading && data != undefined && data.onboardingStatus != undefined && data.onboardingStatus.result != undefined && data.onboardingStatus.result.isOnboardingProcessCompleted != undefined)
      setCompleted(data.onboardingStatus.result.isOnboardingProcessCompleted);
  }, [loading]);
  return [isOnboardingProcessCompleted, (error != undefined)];
}

export function useNotificationCount() {
  return useCachedQuery(
    "notificationCount",
    GET_NOTIFICATION_COUNT,
    {},
    (data) => data?.feedNotificationCount,
    0.25,
    false
  );
}

export function useAuthenticationProfile() {
  const [result, setResult] = useState(undefined);
  const {loading, error, data} = useQuery(GET_AUTH_PROFILE, {
    onCompleted: (data) => {
      if(data.validateSession == null)
        setResult({redirect: "/login"});
      else
        setResult(data.validateSession);
    }
  });
  return [result, loading, (error != undefined)];
}
