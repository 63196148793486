import React from "react";
import {
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

export default function ActivityNewsRespond({activity, source, AllActivity, isLess, color}) {
  const history = useHistory();

  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };

  if(source == "menu")
    return <>
      <MenuItem onClick={navigateToMessage}>
        <FormattedMessage id={"Message"} />
      </MenuItem>
      <MenuItem onClick={AllActivity}>
        <FormattedMessage id={"ToAllActivities"} />
      </MenuItem>
    </>;
  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text color={color??"background"}>
        {isLess && (activity?.activity?.messages?.length ?? 0) > 150 ? 
          activity.activity.messages.substring(0, 150) + "..." : activity.activity.messages}
      </Text>
    </ThemeProvider>
  );
}
