import { tabStyle } from "./CommonStyle";

const RequestsStyle = (theme) => ({
  ...tabStyle,
  mt5: {
    marginTop: "5px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  Select: {
    width: "100%",
    marginTop: 7,
  },
  iconSearch: {
    position: "relative",
    right: 50,
    top: 15,
    color: theme.palette.color5.main,
  },
  iconFilter: {
    position: "relative",
    top: 7,
    left: 7,
    color: theme.palette.color5.main,
  },
  iconReset: {
    marginTop: "11px",
    padding: "0 !important",
    color: theme.palette.color5.main,
    marginLeft: "11px",
  },
});
export default RequestsStyle;
