import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  DELETE_CIRCLE_NEWS,
} from "../../apiServices/mutations";
import { ClearCache, getDateFormatNews } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import UserPostedCard from "./Defaults/UserPostedCard";
import Text from "../../visualComponents/Text";

export default function NewsCard(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const intl = useIntl();
  const history = useHistory();
  const [isLess, setIsLess] = React.useState(true);

  const [removeNewsData, { loading }] = useMutation(DELETE_CIRCLE_NEWS, {
    onCompleted: (data) => {
      ClearCache("circle_feed");
      // console.log("remove news : ", data);
      if (
        typeof data !== "undefined" &&
        typeof props.onRefresh === "function" &&
        data.deleteCircleNews.errorCode === 0
      ) {
        props.onRefresh();
      }
    },
  });

  const removeNews = () => {
    const variables = {
      circleId: props.data.circleId,
      loginProfileId: userId,
      newsId: props.data.id,
      newsInfo: props.data.newsInfo,
    };
    // console.log("remove news request :", variables);
    removeNewsData({
      variables: variables,
    });
  };
  const navigateToEditNews = () => {
    history.push("/addNews/", {
      newsData: props.data,
      circleDetails: props.circleData,
      isEdit: true,
    });
  };
  const navigateToRequest = () => {
    // console.log("Newscard propspass:", props.data);

    history.push("/respondToNews", {
      news: props.data,
      circleDetails: props.circleData,
    });
  };

  const navigateToActivity = () => {
    history.push("/dashboard/news/responses", {data: props.data, circleData: props.circleData, key: 0});
  };
  return (
    <UserPostedCard
    id            = {props.data.userId}
    username      = {props.data.givenName ? props.data.givenName + " " + props.data.familyName : props.data.userId}
    pfp           = {props.data.profilePictureUrl ?? "/assets/img/userProfile.png"}
    label         = {< FormattedMessage id={"newsUpdate"} />}
    location      = {props?.data?.location}
    date          = {getDateFormatNews(props.data.postedDate)}
    role          = {props?.data?.companyRole}
    company       = {props?.data?.companyName}
    circles       = {props?.data?.sharedCircles}
    info          = 
      {props.data.createdProfileId !== userId ? (
        <span>
          {". " +
            props.data.sharedMatchCount +
            " " +
            intl.formatMessage({
              id: "shared_matches",
            })}
        </span>
      ) : null}
    menuItems     = 
      {props.data.createdProfileId === userId && <>
        <MenuItem onClick={navigateToActivity}>
          <FormattedMessage id={"allResponses"} />
        </MenuItem>
        <MenuItem onClick={navigateToEditNews}>
          <FormattedMessage id={"edit"} />
        </MenuItem>
        <MenuItem onClick={removeNews}>
          <FormattedMessage id={"delete"} />
        </MenuItem>
      </>}
    respond =
      {props.data.createdProfileId !== userId && navigateToRequest}
    onExpand={props.data.newsInfo.length > 150 && setIsLess}
    >
      {/* <Grid item xs={2} sm={1}>
          {[1].map((number) => (
            <Chip
              label={<FormattedMessage id={"newsUpdate"} />}
              className={classes.chipItem}
              size="small"
            />
          ))}
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography>
            <Typography
              display="inline"
              component="h3"
              variant="h5"
              color={"textSecondary"}
              className={classes.updateChip}
            >
              {props.data.postedDate !== null
                ? intl.formatMessage({
                  id: "posted",
                }) +
                getDateFormatNews(props.data.postedDate) +
                " "
                : ""}
              {props.data.createdProfileId === userId ? (
                <Link
                  component="button"
                  variant="h5"
                  underline={"none"}
                  onClick={navigateToActivity}
                >
                  {<FormattedMessage id={"allResponses"} />}
                </Link>
              ) : null}
            </Typography>
          </Typography>
        </Grid> */}
        {/* <FormattedMessage id={"message"} />{" "} */}
        {isLess && props.data.newsInfo.length > 150 ? (
          <Text variant="body1" color="background">
            {props.data.newsInfo.substring(0, 150) + "..."}
          </Text>
        ) : (
          <Text variant="body1" color="background">
            {props.data.newsInfo}
          </Text>
        )}
    </UserPostedCard>
  );
}
