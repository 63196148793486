import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Styles from "../../../../styles/component/ProfileTabs/InfoTabs/CollaborationOfInterestTabs/ProjectPartnerStyle";
import {
  Chip,
  FormHelperText,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { ButtonDarkBlue } from "../../../Buttons/ButtonDarkBlue";
import { SAVE_MENTORING_COLLAB } from "../../../../apiServices/mutations";
import { Alert, Autocomplete, Skeleton } from "@mui/material";
import { useMutation } from "@apollo/client";
import { GetUserId } from "../../../../apiServices/CommonMethods";
import {
  masterDataIndustries,
  masterDataSkills,
} from "../../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { clearSelfProfileCache } from "../../../../apiServices/Queries/Profiles/GetProfile";

const useStyles = makeStyles(Styles);

export default function Mentoring(props) {
  const userId = GetUserId();
  const classes = useStyles();
  const [SaveMentoring, { data, loading, error }] = useMutation(
    SAVE_MENTORING_COLLAB, {onCompleted: (data) => {
      clearSelfProfileCache();
    }}
  );
  let [input, setInput] = React.useState({
    industries: [],
    skills: [],
    interestedIn: "",
    isAvailable: null,
    myPartOfThePartnership: "",
  });
  let [inputError, setInputError] = React.useState({
    industries: [],
    skills: [],
    isAvailable: null,
    myPartOfThePartnership: "",
  });
  React.useEffect(() => {
    // console.log("saveMentoringCollaborationInterest data", data);
    if (
      typeof data !== "undefined" &&
      data.saveMentoringCollaborationInterest.errorCode === 0
    ) {
      props.saveAction(data.saveMentoringCollaborationInterest.result, false);
    }
    if (typeof props.collabData !== "undefined") {
      setInputData(props.collabData);
    }
  }, [data, props, props.collabData]);

  const setInputData = (inputData) => {
    setInput((prevState) => ({
      ...prevState,
      industries: inputData.industries ?? [],
      skills: inputData.skills ?? [],
      interestedIn: inputData.interestedIn ?? "",
      isAvailable: inputData.isAvailable ?? null,
      myPartOfThePartnership: inputData.myPartOfThePartnership ?? "",
    }));
  };

  const handleChangeAutoCompleteIndustries = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      industries: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      industries: "",
    }));
  };
  const updateTextFileds = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const handleChangeAutoCompleteSkills = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      skills: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      skills: "",
    }));
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      industries: !input["industries"].length ? "industriesError" : "",
      skills: !input["skills"].length ? "skillsError" : ""
    }));
    return (
      input["industries"].length > 0 &&
      input["skills"].length > 0
    );
  };
  const handleSave = async () => {
    let isValid = await checkForErrors();
    // console.log("input", input);
    // console.log("userID", userId);

    if (isValid) {
      SaveMentoring({
        variables: {
          userId: userId,
          industries: input["industries"],
          interestedIn: input["interestedIn"],
          skills: input["skills"],
          isAvailable: input["isAvailable"],
          myPartOfThePartnership: input["myPartOfThePartnership"],
        },
      });
    }
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        {error ? (
          <Alert severity="error">
            {error.length ? (
              error
            ) : (
              <FormattedMessage id={"somethingWentWrong"} />
            )}
          </Alert>
        ) : null}
        {typeof data !== "undefined" &&
          data.saveMentoringCollaborationInterest.errorCode === -1 ? (
          <Alert severity="error">
            {data.saveMentoringCollaborationInterest.message}
          </Alert>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"IndustriesOfKnowledge"} />
          *:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          Field description Field description Field description:
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="industries"
              options={masterDataIndustries()}
              value={input["industries"]}
              onChange={handleChangeAutoCompleteIndustries}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
          {inputError["industries"].length > 0 ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterindustry"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"SkillsOfknowledge"} />
          *:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description2"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              freeSolo
              id="skills"
              autoSelect={true}
              options={masterDataSkills()}
              value={input["skills"]}
              onChange={handleChangeAutoCompleteSkills}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, maxLength: 25 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                    e.target.value = e.target.value.split(" ").length > 2 ? e.target.value.trim() : e.target.value
                    e.target.value = e.target.value.substring(0, 25)
                  }}
                />
              )}
            />
          )}
          {inputError["skills"].length > 0 ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnterSkillsOfknowledge"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"WhatIBringAsAMentor"} />
          :
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description7"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextareaAutosize
              id={"myPartOfThePartnership"}
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              // placeholder="Type..."
              className={classes.txtarea}
              onChange={updateTextFileds}
              value={input["myPartOfThePartnership"]}
            />
          )}
          {
            inputError["myPartOfThePartnership"] ? (
              <FormHelperText>
                {" "}
                <Typography variant="h5" component="span" color="error">
                  <FormattedMessage id={"PleaseEnterTheContribution"} />
                </Typography>{" "}
              </FormHelperText>
            ) : null
          }
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h2"
          variant="h2"
          color={"textPrimary"}
          className={classes.btxt}
        >
          <FormattedMessage id={"Optional"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"Available"} />:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description8"} />
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Select
              labelId="isAvailable"
              id="isAvailable"
              name="isAvailable"
              onChange={handleChange}
              value={input["isAvailable"]}
            >
              {["Ja", "Nee"].map((option, index) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          )}
          {inputError["isAvailable"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnterYourAvailability"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p7}
        >
          <FormattedMessage id={"InMyPupilsIdeallyILikeToSee"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description9"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextareaAutosize
              id={"interestedIn"}
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              // placeholder="Type..."
              className={classes.txtarea}
              onChange={updateTextFileds}
              value={input["interestedIn"]}
            />
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <ButtonDarkBlue className={classes.submit} onClick={handleSave}>
          <FormattedMessage id={"Button_Text_Save"} />
        </ButtonDarkBlue>
      </Grid>
    </Grid>
  );
}
Mentoring.prototype = {
  saveAction: PropTypes.func,
  data: PropTypes.string,
};
