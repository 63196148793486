import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  CssBaseline,
  FormControl,
  Hidden,
  TextareaAutosize,
  FormHelperText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Box } from "@mui/system";
import { useHistory } from "react-router";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";
import { useSelector } from "react-redux";
import { selectedBrandColor } from "../../apiServices/Redux/Reducer";
import { useLocation } from "react-router";
import { useMutation } from "@apollo/client";
import { SAVE_CIRCLE_POST } from "../../apiServices/mutations";
import { EDIT_CIRCLE_POST } from "../../apiServices/mutations";
import { Alert, Skeleton } from "@mui/material";
import { GetUserId } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "../../styles/container/Circle/AddCirclePostStyle";

const useStyles = makeStyles(styles);

export default function AddCirclePost() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const brandColor = useSelector(selectedBrandColor);
  const userId = GetUserId();
  const intl = useIntl();

  const [
    addPost,
    { data: dataAddPost, loading: loadingAddPost },
  ] = useMutation(SAVE_CIRCLE_POST);

  const [editPost, { data: dataEditPost, loading: loadingEditPost }] =
    useMutation(EDIT_CIRCLE_POST);

  const [circleData, setCircleData] = React.useState("");
  const [circleEditPostData, setcircleEditPostData] = React.useState("");
  const [input, setInput] = React.useState({
    circleMessageText: "",
  });
  const [inputError, setInputError] = React.useState({
    circleMessageText: "",
  });

  React.useEffect(() => {
    if (location.state && typeof location.state.circleDetails !== "undefined") {
      setCircleData(location.state.circleDetails);
    }
    if (location.state && location.state.isEdit === true) {
      setInput({ circleMessageText: location.state.feedsData.message });
      setcircleEditPostData(location.state.feedsData);
    }
    // console.log(circleData, "CircleData in AddCirclePost");
    // console.log(input, "CircleMessage");
  }, [location, dataAddPost]);

  const handleOnChange = (event) => {
    const { id, value } = event.target;
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const checkForErrors = () => {
    setInputError((prevState) => ({
      ...prevState,
      circleMessageText: !input["circleMessageText"].trim()
        ? "input error"
        : "",
    }));

    return input["circleMessageText"].trim().length !== 0;
  };

  const handleOnAddPost = () => {
    const isError = checkForErrors();
    if (isError) {
      // const isEdit = true;
      // // console.log(isEdit, "isEdit");
      if (location.state.isEdit === true) {
        const editVariable = {
          postInfo: input["circleMessageText"],
          circleId: circleEditPostData.circleId,
          loginProfileId: userId,
          postId: circleEditPostData.id,
        };
        editPost({
          variables: editVariable,
        });
        // console.log(editVariable, "EditPostData");
      } else {
        const addVariable = {
          message: input["circleMessageText"],
          circleId: circleData.id,
          loginProfileId: userId,
        };
        addPost({
          variables: addVariable,
        });
        // console.log(addVariable, "AddPostData");
      }
    }
  };

  const handleSucessAlert = () => {
    // history.push("/dashboard/circleDetails", {
    //   circleDetails: circleData,
    //   selectedTab: 3,
    // });
    history.push("/dashboard/circleDetails/?id=" + circleData.id, {
      circleDetails: circleData,
      selectedTab: 3,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(3), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            {typeof dataAddPost !== "undefined" &&
              dataAddPost.saveCirclePost.errorCode === 0 ? (
              <SuccessAlertWithAction
                message={<FormattedMessage id={"postAddedSuccessfully"} />}
                handleClose={handleSucessAlert}
                open={true}
              />
            ) : null}
            {typeof dataAddPost !== "undefined" &&
              dataAddPost.saveCirclePost.errorCode === -1 ? (
              <Alert severity={"error"}>
                {dataAddPost.saveCirclePost.message}
              </Alert>
            ) : null}

            {typeof dataEditPost !== "undefined" &&
              dataEditPost.editCirclePost.errorCode === 0 ? (
              <SuccessAlertWithAction
                message={<FormattedMessage id={"postUpdatedSuccessfully"} />}
                handleClose={handleSucessAlert}
                open={true}
              />
            ) : null}
            {typeof dataEditPost !== "undefined" &&
              dataEditPost.editCirclePost.errorCode === -1 ? (
              <Alert severity={"error"}>
                {dataEditPost.editCirclePost.message}
              </Alert>
            ) : null}

            <Grid item sm={11} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"addACirclePost"} />
              </Typography>
            </Grid>
            <Grid item sm={1} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={() => {
                  history.goBack();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component="h3" variant="h2" color={"textPrimary"}>
                <FormattedMessage id={"writeAPostToAllMembersOfThisCircle"} />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"writeYourMessage"} />
              </Typography>

              {/* <Typography
                component="h3"
                variant="h2"
                color={"textSecondary"}
                className={classes.mt7}
              >
                <FormattedMessage id={"AddCirclePost_Page_Description"} />
              </Typography> */}

              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loadingAddPost || loadingEditPost ? (
                    <Skeleton variant="rectangular" height={80} />
                  ) : (
                    <TextareaAutosize
                      id="circleMessageText"
                      value={input["circleMessageText"]}
                      onChange={handleOnChange}
                      variant="filled"
                      aria-label="minimum height"
                      minRows={5}
                      placeholder={intl.formatMessage({ id: "Type" })}
                      className={classes.txtarea}
                    />
                  )}
                  {inputError["circleMessageText"] ? (
                    <FormHelperText>
                      {" "}
                      <Typography variant="h5" component="span" color="error">
                        <FormattedMessage id={"pleaseEnterMessage"} />
                      </Typography>{" "}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              {loadingAddPost || loadingEditPost ? (
                <Skeleton variant="rectangular" height={40} />
              ) : circleData.brandSecondColor ? (
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: brandColor, color: "white" }}
                  onClick={handleOnAddPost}
                  disableElevation
                >
                  <FormattedMessage id={"send"} />
                </Button>
              ) : (
                <ButtonDarkBlue onClick={handleOnAddPost}>
                  <FormattedMessage id={"send"} />
                </ButtonDarkBlue>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
