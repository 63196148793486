import React, { useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Filters from "../components/Filters";
import FormGroup from "@mui/material/FormGroup";
import UserPostedCard from "../components/Cards/Defaults/UserPostedCard";
import { useLazyQuery } from "@apollo/client";
import {
  GET_MASTERS_MATCHES,
} from "../apiServices/Queries";
import { CircularProgress, ThemeProvider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { MastersTabItems, userRoles } from "../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import { useListMasters } from "../apiServices/Queries/Masters/ListMasters";
import Theme_WIP, { ThemeConstants } from "../styles/themes/main/Theme_WIP";
import Text from "../visualComponents/Text";

export default function Masters() {
  const globals    = useGlobalContext();
  const circleData = globals.state.loggedInUser.circles;
  const userRole   = globals.state.loggedInUser.role.role;
  const userId     = globals.state.loggedInUser.userId;
  const intl       = useIntl();
  const [masterTabType, setMasterTabType] = React.useState({
    type: intl.formatMessage({ id: MastersTabItems[0].type }),
    key: MastersTabItems[0].key,
  });
  const [mastersMatch, setMastersMatch] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [isSecondDegreeMatches, setIsSecondDegreeMatches] =
    React.useState(false);
  const [paginationCount, setPaginationCount] = React.useState(1);
  const masterTabList = MastersTabItems.map(item => {
    return {
      type: intl.formatMessage({
        id: item.type,
      }),
      key: item.key,
    };
  });

  const circleTabItems = circleData ? circleData.map(
    circle => {
      return {
        type: circle.name,
        key: circle.id,
      };
    }
  ) : [];
  const masterTabItemList = [...masterTabList, ...circleTabItems];
  const [selectedCircle, setSelectedCircle] = React.useState({
    type: null,
    key: null,
  });

  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: "",
    showLoggedInUser: false,
  });
  const getVariableMymatches = {
    userId: userId,
    pageNumber: page,
    location: input["location"],
    industries: input["industries"],
    skills: input["skills"],
    maxDistance: input["maxDistance"],
    collaborationType: input["collaborationType"],
    businessType: input["businessType"],
    pageSize: 10,
    isMyMatches: !isSecondDegreeMatches,
    searchInput: input["searchInput"],
  };

  const variables = {
    userId: userId,
    pageNumber: page,
    location: input["location"],
    industries: input["industries"],
    skills: input["skills"],
    maxDistance: input["maxDistance"],
    collaborationType: input["collaborationType"],
    businessType: input["businessType"],
    pageSize: 10,
    isMyMatches: false,
    searchInput: input["searchInput"],
    circleId: selectedCircle.key,
    showLoggedInUser: input["showLoggedInUser"],
  };
  const {data: masters, loading: loading, updateVariables: updateVariables, hash: hash} = useListMasters(variables);
  useEffect(() => {
    if (masterTabType.type !== intl.formatMessage({ id: "filter_title_mymatches" }))
      setPagination(masters);
  }, [masters]);

  const [fetchMatches,
    { loading: loadingMatches }] = useLazyQuery(
      GET_MASTERS_MATCHES,
      {
        variables: getVariableMymatches,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
          // console.log("GET_MASTERS_MATCHES", data)
          if (data.allProfilesByUserIds.errorCode === 0) {
            setMastersMatch(data.allProfilesByUserIds.result.profiles);
            if (
              masterTabType.type ===
              intl.formatMessage({ id: "filter_title_mymatches" })
            ) {
              setPagination(data.allProfilesByUserIds.result.profiles);
            }
          } else if (data.allProfilesByUserIds.errorCode !== 0) {
            setMastersMatch([]);
            if (
              masterTabType.type ===
              intl.formatMessage({ id: "filter_title_mymatches" })
            ) {
              setPagination([]);
            }
          }
        }
      }
    );

  useEffect(() => {
    if (masterTabType.type == intl.formatMessage({ id: "filter_title_all" }))
      setPagination(masters);

    if (
      masterTabType.type ===
      intl.formatMessage({ id: "filter_title_mymatches" })
    ) {
      fetchMatches();
    }
  }, [masterTabType, isSecondDegreeMatches, circleData, selectedCircle, masters]);

  useEffect(() => {
    updateVariables({...variables, pageNumber: page, circleId: selectedCircle.key});
  }, [page, selectedCircle]);

  const setPagination = data => {
    if (data && data?.length) {
      const master = data[0];
      const count = Math.floor(master.totalCount / 10);
      const remainder = master.totalCount % 10;
      setPaginationCount(count < 1 ? 1 : remainder > 0 ? count + 1 : count);
    } else {
      setPaginationCount(0);
    }
  };

  const handleChange = (event, newValue) => {
    setPage(1);
    setIsSecondDegreeMatches(false);
    let filteredMastersTab = masterTabItemList.filter(tabItem => {
      return tabItem.type === newValue;
    });
    setMasterTabType(filteredMastersTab[0] ?? masterTabItemList[0]);
    setPagination(getCurrentMastersData(newValue));
    if (
      filteredMastersTab[0].type !==
      intl.formatMessage({ id: "filter_title_mymatches" }) &&
      filteredMastersTab[0].type !==
      intl.formatMessage({ id: "filter_title_all" })
    ) {
      setSelectedCircle(filteredMastersTab[0]);
    } else {
      setSelectedCircle({
        type: null,
        key: null,
      });
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    if (
      masterTabType.type ===
      intl.formatMessage({ id: "filter_title_mymatches" })
    ) {
      // fetchMoreMatches({
      //   variables: {
      //     pageNumber: parseInt(value),
      //   },
      // });
      fetchMatches()
    }
  };

  const filterAction = inputs => {
    const newInput = {
      showLoggedInUser: false,
      pageNumber: 1,
      circleId: selectedCircle.key,
      ...inputs
    };
    setPage(1)
    setInput(newInput);
  };
  useEffect(() => {
    updateVariables(variables);
  }, [input]);

  const navigateToGetPremium = () => {
    //GoTo(history, "/GetPremium");
  };

  const updateSecondDegreeMatches = () => {
    if (userRole === userRoles.freemium) {
      navigateToGetPremium();
    } else {
      setIsSecondDegreeMatches(!isSecondDegreeMatches);
      // fetchMoreMatches({
      //   variables: {
      //     pageNumber: page,
      //     isMyMatches: !isSecondDegreeMatches,
      //   },
      // });

      fetchMatches()

    }
  };

  const getCurrentMastersData = type => {
    if (type === intl.formatMessage({ id: "filter_title_mymatches" })) {
      return mastersMatch;
    } else {
      return masters;
    }
  };
  const currentMasters = getCurrentMastersData(masterTabType.type);

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container item mt={6} gap={ThemeConstants.custom.spacings.md} pb={14}>
      <Filters search includeBusiness includeIndustry includeSkills onSearch={!loading && filterAction} tab={masterTabType.type} tabs={masterTabItemList} onTab={handleChange} useRefresh/>

      <Grid item xs={12}>
        {masterTabType.type ===
          intl.formatMessage({ id: "filter_title_mymatches" }) &&
          <FormGroup>
            <Typography variant="body2" color={"textSecondary"}>
              <FormControlLabel
                color={"textSecondary"}
                control={
                  <Checkbox
                    checked={isSecondDegreeMatches}
                    onClick={updateSecondDegreeMatches}
                  />
                }
                label={
                  <FormattedMessage
                    id={
                      "Masters_SearchSecondDegreeConnectionsOfYourMatches"
                    }
                  />
                }
              />
            </Typography>
          </FormGroup>
        }
      </Grid>
      <Grid item xs={12}>
        {!loading && paginationCount > 0 && (
          <Pagination
            count    = {paginationCount}
            page     = {page}
            onChange = {handleChangePage}
          />
        )}
      </Grid>
      <Grid item container xs={12} gap={{xs: ThemeConstants.custom.spacings.md}}>
        {loading || loadingMatches ? (
          <CircularProgress />
        ) : currentMasters?.map(
              (card, index) => {
                return <UserPostedCard 
                props    = {{
                  //md: currentMasters.length > 6 ? 5.5 : undefined, 
                  style:{height: "auto"}}}
                id       = {card.userId} 
                username = {card.givenName + " " + card.familyName} 
                company  = {card.companyName}
                location = {card.location}
                role     = {card.role}
                circles  = {card.sharedCircles}
                pfp      = {card.profilePictureUrl}
                label    = {card.matchStatus ? "Match" : undefined}
                tags     = {card.skills}
                />
              }
            )}

          {!currentMasters?.length &&
            <Text item xs={12} mt={4} align="center" variant="h2" color="background" id={"noDataAvailable"} />
          }
      </Grid>
      
    </Grid>
    </ThemeProvider>
  );
}
