import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Styles from "../../../../styles/component/ProfileTabs/InfoTabs/CollaborationOfInterestTabs/ProjectPartnerStyle";
import {
  Chip,
  FormHelperText,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { ButtonDarkBlue } from "../../../Buttons/ButtonDarkBlue";
import { SAVE_COFOUNDING_COLLAB } from "../../../../apiServices/mutations";
import { Alert, Autocomplete, Skeleton } from "@mui/material";
import LocationAutoComplete from "../../../Autocomplete/LocationAutoComplete";
import { useMutation } from "@apollo/client";
import { GetUserId } from "../../../../apiServices/CommonMethods";
import {
  masterDataBusinessStage,
  masterDataCommitment,
  masterDataIndustries,
  masterDataSkills,
} from "../../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { clearSelfProfileCache } from "../../../../apiServices/Queries/Profiles/GetProfile";

const useStyles = makeStyles(Styles);

export default function CoFounding(props) {
  const userId = GetUserId();
  const classes = useStyles();
  const [SaveCofounding, { data, loading, error }] = useMutation(
    SAVE_COFOUNDING_COLLAB, {onCompleted: (data) => {
      clearSelfProfileCache();
    }}
  );
  let [input, setInput] = React.useState({
    businessOpportunityDescription: "",
    businessStage: [],
    commitment: [],
    industries: [],
    isAvailable: null,
    location: "",
    preferredCompanyRole: "",
    skills: [],
    sweatCommitmentInHours: null,
  });
  let [inputError, setInputError] = React.useState({
    commitment: "",
    industries: "",
    location: "",
    businessStage: "",
  });
  React.useEffect(() => {
    // console.log("data.saveCoFoundingCollaborationInterest", data);
    if (
      typeof data !== "undefined" &&
      data.saveCoFoundingCollaborationInterest.errorCode === 0
    ) {
      props.saveAction(data.saveCoFoundingCollaborationInterest.result, false);
    }
    if (typeof props.collabData !== "undefined") {
      setInputData(props.collabData);
    }
  }, [data, props]);

  const setInputData = (inputData) => {
    setInput((prevState) => ({
      ...prevState,
      businessOpportunityDescription:
        inputData.businessOpportunityDescription ?? "",
      businessStage: inputData.businessStage ?? [],
      commitment: inputData.commitment ?? [],
      industries: inputData.industries ?? [],
      isAvailable:
        typeof inputData.isAvailable !== "undefined"
          ? inputData.isAvailable
          : null,
      location: inputData.location ?? "",
      preferredCompanyRole: inputData.preferredCompanyRole ?? "",
      skills: inputData.skills ?? [],
      sweatCommitmentInHours: inputData.sweatCommitmentInHours ?? "",
    }));
  };
  const handleChangeAutoCompleteIndustries = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      industries: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      industries: "",
    }));
  };
  const handleChangeAutoCompleteSkills = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      skills: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      skills: "",
    }));
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };
  const updateTextFileds = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const onLocationChange = (location) => {
    // console.log("onLocationChange", location);
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        location:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInputError((prevState) => ({
        ...prevState,
        location: "",
      }));
    }
  };
  const handleChangeBusinessStage = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      businessStage: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      businessStage: "",
    }));
  };
  const handleChangeCommitment = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      commitment: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      commitment: "",
    }));
  };
  const handleSave = async () => {
    let isValid = await checkForErrors();
    const variable = {
      userId: userId,
      businessOpportunityDescription: input["businessOpportunityDescription"],
      businessStage: input["businessStage"],
      commitment: input["commitment"],
      industries: input["industries"],
      isAvailable: input["isAvailable"],
      location: input["location"],
      preferredCompanyRole: input["preferredCompanyRole"],
      skills: input["skills"].length ? input["skills"] : null,
      sweatCommitmentInHours: input["sweatCommitmentInHours"].length
        ? parseInt(input["sweatCommitmentInHours"])
        : null,
    };
    // console.log("input", variable);
    if (isValid) {
      SaveCofounding({
        variables: variable,
      });
    }
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      industries: !input["industries"].length ? "industriesError" : "",
      location: !input["location"] ? "locationError" : "",
    }));
    return (
      input["industries"].length &&
      input["location"]
    );
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        {error ? (
          <Alert severity="error">
            {error.length ? (
              error
            ) : (
              <FormattedMessage id={"somethingWentWrong"} />
            )}
          </Alert>
        ) : null}
        {typeof data !== "undefined" &&
          data.saveCoFoundingCollaborationInterest.errorCode === -1 ? (
          <Alert severity="error">
            {data.saveCoFoundingCollaborationInterest.message}
          </Alert>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"IndustriesOfInterest"} />
          *:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          Field description Field description Field description:
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="industries"
              options={masterDataIndustries()}
              value={input["industries"]}
              onChange={handleChangeAutoCompleteIndustries}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
          {inputError["industries"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterindustry"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"Location"} />
          *:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <LocationAutoComplete
              onChange={onLocationChange}
              value={input["location"]}
            />
          )}
          {inputError["location"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterlocation"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"BusinessStageOfInterest"} />
          :
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          <InputLabel id="businessStage">{/* Select */}</InputLabel>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            // <Select
            //   labelId="businessStage"
            //   id="businessStage"
            //   name="businessStage"
            //   onChange={handleChange}
            //   value={input["businessStage"]}
            // >
            //   {masterDataBusinessStage().map((option, index) => (
            //     <MenuItem value={option.key}>{option.key}</MenuItem>
            //   ))}
            // </Select>
            <Autocomplete
              multiple
              id="businessStage"
              options={masterDataBusinessStage().map(({ key }) => key)}
              value={input["businessStage"]}
              onChange={handleChangeBusinessStage}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
          {inputError["businessStage"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnterBusinessStageOfInterest"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"MyCommitment"} />
          :
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"BusinessPartners_Description2"} />
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          <InputLabel id="commitment">{/* Select */}</InputLabel>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            // <Select
            //   labelId="commitment"
            //   id="commitment"
            //   name="commitment"
            //   onChange={handleChange}
            //   value={input["commitment"]}
            // >
            //   {masterDataCommitment().map((option, index) => (
            //     <MenuItem value={option.key}>{option.key}</MenuItem>
            //   ))}
            // </Select>
            <Autocomplete
              multiple
              id="commitment"
              options={masterDataCommitment().map(({ key }) => key)}
              value={input["commitment"]}
              onChange={handleChangeCommitment}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
          {inputError["commitment"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnterYourCommitment"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"BusinessOpportunityDescription_coFounding"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_BusinessOpportunityDescription_FieldLabel"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={80} />
          ) : (
            <TextareaAutosize
              id="businessOpportunityDescription"
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              // placeholder="Type..."
              onChange={updateTextFileds}
              value={input["businessOpportunityDescription"]}
              className={classes.txtarea}
            />
          )}
          {inputError["businessOpportunityDescription"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnterTheBusinessDescription"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h2"
          variant="h2"
          color={"textPrimary"}
          className={classes.btxt}
        >
          <FormattedMessage id={"Optional"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"Co-founderSkillsOfInterest"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description2"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              freeSolo
              id="skills"
              autoSelect={true}
              options={masterDataSkills()}
              value={input["skills"]}
              onChange={handleChangeAutoCompleteSkills}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, maxLength: 25 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                    e.target.value = e.target.value.split(" ").length > 2 ? e.target.value.trim() : e.target.value
                    e.target.value = e.target.value.substring(0, 25)
                  }}
                />
              )}
            />
          )}
        </FormControl>
      </Grid>



      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"SweatCommitmentInHours"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description4"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              id="sweatCommitmentInHours"
              // label="Write here"
              variant="filled"
              size="small"
              onChange={updateTextFileds}
              value={input["sweatCommitmentInHours"]}
              type={"number"}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"PreferredCompanyRole"} />:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              id="preferredCompanyRole"
              // label="Write here"
              variant="filled"
              size="small"
              onChange={updateTextFileds}
              value={input["preferredCompanyRole"]}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"Available"} />:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Select
              labelId="isAvailable"
              id="isAvailable"
              name="isAvailable"
              onChange={handleChange}
              value={input["isAvailable"]}
            >
              {["Ja", "Nee"].map((option, index) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonDarkBlue className={classes.submit} onClick={handleSave}>
          <FormattedMessage id={"Button_Text_Save"} />
        </ButtonDarkBlue>
      </Grid>
    </Grid>
  );
}
CoFounding.prototype = {
  saveAction: PropTypes.func,
  collabData: PropTypes.string,
};
