import React from "react";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

export default function ActivityEventJoinAsGuestRespond({activity, source, color}) {
  const history = useHistory();
  const intl = useIntl();

  const getMessage = () => {
    if (activity.activity.messages === "Join As Guest Accepted") {
      return (intl.formatMessage({ id: "YourAttendanceRequestIsAcceptedFor" }) + " ");
    } else {
      return (intl.formatMessage({ id: "YourAttendanceRequestIsDeclinedFor" }) + " ");
    }
  };

  const navigateToEventDetail = () => {
    history.push("/dashboard/eventDetails/?id=" + activity.activity.eventId, {
      selectedEventId: activity.activity.eventId,
    });
  };


  if(source == "canCollapse")
    return undefined;
  if(source == "menu")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity?.activity?.messages && (
        <Text color={color??"background"}>
          {getMessage()}
          <Link
            color="inherit"
            component="button"
            underline="always"
            onClick={navigateToEventDetail}
          >
            {activity.activity.eventName}
          </Link>

          {activity.activity.messages === "Join As Guest Accepted" && " " + intl.formatMessage({ id: "EventJoinAsGuestDescription2" })}
        </Text>
      )}
    </ThemeProvider>
  );
}
