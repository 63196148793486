import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import { FormattedMessage } from "react-intl";
import Styles from "../../styles/component/EventTabs/EventVisitorsStyle";
import VisitorCard from "../Cards/VisitorCard";
import { GET_EVENT_VISITORS } from "../../apiServices/Queries";
import { useQuery } from "@apollo/client";
import { CircularProgress, Stack } from "@mui/material";
import Filters from "../Filters";
import { Waypoint } from "react-waypoint";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";

const useStyles = makeStyles(Styles);

export default function EventVisitor(props) {
  const globals  = useGlobalContext();
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const [members, setMembers] = React.useState([]);
  const [eventdata, setEventData] = React.useState(props?.eventDetails);
  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: null,
    pageNumber: 1,
  });
  const getVariable = () => {
    const variable = {
      id: eventdata.id,
      loginProfileId: userId,
      pageNumber: input["pageNumber"],
      pageSize: parseInt(50),
      searchInput: input["searchInput"],
      skills: input["skills"],
      industries: input["industries"],
      collaborationType: input["collaborationType"],
      businessTypes: input["businessType"],
      location: input["location"],
      maxDistance: input["maxDistance"],
      participantScopeDesc: eventdata.participantScopeDesc,
    };
    // console.log("vistorvariable", variable);
    return variable;
  };
  useEffect(() => {
    if (typeof props.eventDetails !== "undefined") {
      setEventData(props.eventDetails);
    }
  }, [props]);
  // console.log("eventdata", eventdata);
  const { loading, fetchMore, error } = useQuery(
    GET_EVENT_VISITORS,
    {
      variables: getVariable(),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: data => {
        if (typeof data !== "undefined" && data.eventVisitors.errorCode === 0) {
          const response = data.eventVisitors.result;
          // console.log("VisitorsList", response);
          // console.log("pageNumber", input["pageNumber"]);
          if (eventdata.loginUserStatus !== "Manager") {
            // console.log("loginUserStatus", "Manager");
            if (input["pageNumber"] === 1) {
              let visitorList = response.filter(list => {
                return (
                  list.visitorStatus === "Attending" ||
                  list.visitorStatus === "Manager" ||
                  list.visitorStatus === "Deelnemer"
                );
              });
              setMembers(visitorList);
            } else {
              if (data.eventVisitors.result.length) {
                let visitorList = response.filter(list => {
                  return (
                    list.visitorStatus === "Attending" ||
                    list.visitorStatus === "Manager" ||
                    list.visitorStatus === "Deelnemer"
                  );
                });
                setMembers(prevState => [...prevState, ...visitorList]);
              }
            }
          } else {
            // console.log("pgno", input["pageNumber"]);
            if (input["pageNumber"] === 1) {
              setMembers(response);
            } else {
              if (response.length) {
                setMembers(prevState => [...prevState, ...response]);
              }
            }
          }
        } else {
          setMembers([]);
        }
      },
    }
  );
  useEffect(() => fetchMore({variables: getVariable()}), [input]);

  const filterAction = inputs => {
    // console.log("inputs", inputs);
    setMembers([]);
    setInput((prev) => ({...prev, ...inputs, pageNumber: 1}));
    fetchMore({
      variables: {
        ...inputs,
        pageNumber: 1,
      },
    });
  };

  const refresh = () => {
    setInput(prevState => ({
      ...prevState,
      pageNumber: parseInt(1),
    }));
  };

  return (
    <Grid container gap={ThemeConstants.custom.spacings.md}>
      <Filters search includeBusiness includeIndustry includeSkills onSearch={filterAction} useRefresh/>
      {loading && !members.length ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : members.length > 0 ? (
        members.map((memberdata, index) => (
          <VisitorCard
            data={memberdata}
            eventDetails={eventdata}
            onRefresh={refresh}
            key={index}
          >
            {index === members.length - 2 && (
              <Waypoint
                scrollableAncestor={window}
                onEnter={() => {
                  // console.log("index", index);
                  const newPage = input["pageNumber"] + 1;
                  // console.log("newPage", newPage);
                  setInput(prevState => ({
                    ...prevState,
                    pageNumber: newPage,
                  }));
                }}
              />
            )}
          </VisitorCard>
        ))
      ) : (
        <Grid item xs={12} sm={12}>
          <Paper elevation={3} className={classes.tabcontent}>
            <Grid container spacing={4}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
