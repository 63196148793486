import { gql } from "@apollo/client";
import { useMutationBase } from "../../../mutations";

export function useSaveCollabCategories(collabs, onCompleted) { 
    return useMutationBase(gql`
    mutation SaveCollabCategories($collabs: [CollaborationCategoryInput]) {
        saveCollabCategories(input: $collabs) {
            id
            title
            description
            parentId
        }
    }`, 
    {noCallOnStart: true,
     variables: { 
        collabs: collabs},
     onCompleted: onCompleted,
     errorPolicy: "ignore"});
}