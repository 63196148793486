import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Styles from "../../../../styles/component/ProfileTabs/InfoTabs/CollaborationOfInterestTabs/ProjectPartnerStyle";
import {
  Chip,
  FormHelperText,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { ButtonDarkBlue } from "../../../Buttons/ButtonDarkBlue";
import { SAVE_INVESTMENT_COLLAB } from "../../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { GetUserId } from "../../../../apiServices/CommonMethods";
import { Alert, Autocomplete, Skeleton } from "@mui/material";
import LocationAutoComplete from "../../../Autocomplete/LocationAutoComplete";
import {
  masterDataCommitment,
  masterDataCompanyStage,
  masterDataIndustries,
  masterDataInvestmentType,
  masterDataInvestorType,
} from "../../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { clearSelfProfileCache } from "../../../../apiServices/Queries/Profiles/GetProfile";

const useStyles = makeStyles(Styles);

export default function Investment(props) {
  const userId = GetUserId();
  const classes = useStyles();

  const [SaveInvestment, { data, loading, error }] = useMutation(
    SAVE_INVESTMENT_COLLAB, {onCompleted: (data) => {
      clearSelfProfileCache();
    }}
  );
  let [input, setInput] = React.useState({
    companyStage: [],
    commitment: [],
    industries: [],
    interestedIn: "",
    location: "",
    investmentCollective: "",
    investmentType: [],
    investorType: [],
    preferredCompanyRole: "",
  });
  let [inputError, setInputError] = React.useState({
    companyStage: "",
    commitment: "",
    industries: "",
    interestedIn: "",
    location: ""
  });
  React.useEffect(() => {
    // console.log("masterDataInvestmentType", masterDataInvestmentType());
    if (
      typeof data !== "undefined" &&
      data.saveInvestmentCollaborationInterest.errorCode === 0
    ) {
      props.saveAction(data.saveInvestmentCollaborationInterest.result, false);
    }
    if (typeof props.collabData !== "undefined") {
      setInputData(props.collabData);
    }
  }, [data, props, props.collabData]);

  const setInputData = (inputData) => {
    setInput((prevState) => ({
      ...prevState,
      commitment: inputData.commitment ?? [],
      industries: inputData.industries ?? [],
      interestedIn: inputData.interestedIn ?? "",
      location: inputData.location ?? "",
      companyStage: inputData.companyStage ?? [],
      investmentType: inputData.investmentType ?? [],
      investorType: inputData.investorType ?? [],
      preferredCompanyRole: inputData.preferredCompanyRole ?? "",
      investmentCollective: inputData.investmentCollective ?? "",
    }));
  };
  // const handleChangeSelect = (event) => {
  //   const { name, value } = event.target;
  //   setInput((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  //   setInputError((prevState) => ({
  //     ...prevState,
  //     [name]: "",
  //   }));
  // };
  const updateTextFileds = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const handleChangeAutoCompleteIndustries = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      industries: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      industries: "",
    }));
  };
  const handleChangeCommitment = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      commitment: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      commitment: "",
    }));
  };
  const handleChangeBusinessStage = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      companyStage: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      companyStage: "",
    }));
  };
  const handleChangeInvestor = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      investorType: newvalue,
    }));
  };
  const handleChangeInvestment = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      investmentType: newvalue,
    }));
  };
  const onLocationChange = (location) => {
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        location:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInputError((prevState) => ({
        ...prevState,
        location: "",
      }));
    }
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      industries: !input["industries"].length ? "industriesError" : "",
      location: !input["location"] ? "locationError" : ""
    }));
    return (
      input["industries"].length > 0 &&
      input["location"].length > 0
    );
  };
  const handleSave = async () => {
    let isValid = await checkForErrors();
    let variable = {
      userId: userId,
      commitment: input["commitment"],
      industries: input["industries"],
      interestedIn: input["interestedIn"],
      investmentCollective: input["investmentCollective"],
      investmentType: input["investmentType"],
      investorType: input["investorType"],
      location: input["location"],
      preferredCompanyRole: input["preferredCompanyRole"],
      companyStage: input["companyStage"],
    }
    // console.log("Investment variable", variable)
    if (isValid) {
      SaveInvestment({
        variables: variable,
      });
    }
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        {error ? (
          <Alert severity="error">
            {error.length ? (
              error
            ) : (
              <FormattedMessage id={"somethingWentWrong"} />
            )}
          </Alert>
        ) : null}
        {typeof data !== "undefined" &&
          data.saveInvestmentCollaborationInterest.errorCode === -1 ? (
          <Alert severity="error">
            {data.saveInvestmentCollaborationInterest.message}
          </Alert>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"IndustriesOfInterest"} />
          *:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          Field description Field description Field description:
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="industries"
              options={masterDataIndustries()}
              value={input["industries"]}
              onChange={handleChangeAutoCompleteIndustries}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
          {inputError["industries"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterindustry"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"Location"} />
          *:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <LocationAutoComplete
              onChange={onLocationChange}
              value={input["location"]}
            />
          )}
          {inputError["location"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterlocation"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"BusinessOpportunityDescription_investment"} />
          :
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textPrimary"}
          className={classes.p7c}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description5"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextareaAutosize
              id="interestedIn"
              onChange={updateTextFileds}
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              // placeholder="Type..."
              className={classes.txtarea}
              value={input["interestedIn"]}
            />
          )}
          {inputError["interestedIn"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage
                  id={"PleaseEnterTheBusinessesOpportunityDescription"}
                />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"CompanyStageOfInterest"} />
          :
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          <InputLabel id="demo-simple-select-filled-label">
            {/* Select */}
          </InputLabel>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            // <Select
            //   labelId="demo-simple-select-filled-label"
            //   id="demo-simple-select-filled"
            //   name="companyStage"
            //   onChange={handleChangeSelect}
            //   value={input["companyStage"]}
            // >
            //   {masterDataCompanyStage().map((option, index) => (
            //     <MenuItem value={option.key}>{option.key}</MenuItem>
            //   ))}
            // </Select>
            <Autocomplete
              multiple
              id="companyStage"
              options={masterDataCompanyStage().map(({ key }) => key)}
              value={input["companyStage"]}
              onChange={handleChangeBusinessStage}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
          {inputError["companyStage"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage
                  id={"PleaseEnterYourCompanyStageOfInterest"}
                />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"MyCommitment"} />
          :
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          <InputLabel id="demo-simple-select-filled-label">
            {/* Select */}
          </InputLabel>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="commitment"
              options={masterDataCommitment().map(({ key }) => key)}
              value={input["commitment"]}
              onChange={handleChangeCommitment}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
          {inputError["commitment"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnterYourCommitment"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h2"
          variant="h2"
          color={"textPrimary"}
          className={classes.btxt}
        >
          <FormattedMessage id={"Optional"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"TypeOfInvestor"} />:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          <InputLabel id="demo-simple-select-filled-label">
            {/* Select */}
          </InputLabel>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="investorType"
              options={masterDataInvestorType().map(({ key }) => key)}
              value={input["investorType"]}
              onChange={handleChangeInvestor}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p15}
        >
          <FormattedMessage id={"TypeOfInvestment"} />:
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          <InputLabel id="demo-simple-select-filled-label">
            {/* Select */}
          </InputLabel>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="investmentType"
              options={masterDataInvestmentType().map(({ key }) => key)}
              value={input["investmentType"]}
              onChange={handleChangeInvestment}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"PreferredCompanyRole"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditCollaborationOfInterest_Description6"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              id="preferredCompanyRole"
              // label="Write here"
              variant="filled"
              size="small"
              value={input["preferredCompanyRole"]}
              onChange={updateTextFileds}
              helperText={
                inputError["preferredCompanyRole"] ? (
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage
                      id={"PleaseEnterThePreferredCompanyRole"}
                    />
                  </Typography>
                ) : null
              }
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.p7}
        >
          <FormattedMessage id={"InvestmentCollective"} />:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              id="investmentCollective"
              // label="Write here"
              variant="filled"
              size="small"
              value={input["investmentCollective"]}
              onChange={updateTextFileds}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonDarkBlue className={classes.submit} onClick={handleSave}>
          <FormattedMessage id={"Button_Text_Save"} />
        </ButtonDarkBlue>
      </Grid>
    </Grid>
  );
}
Investment.prototype = {
  saveAction: PropTypes.func,
  data: PropTypes.string,
};
