const EditProfileHeaderStyle = (theme) => ({
    root: {
        width: "100%",
    },
    logo: {
        width: "100%",
    },
    margin: {
        marginRight: 5,
    },
    p15: {
        marginTop: 15,
    },

    PL20: {
        paddingLeft: 20,
    },
    submit: {
        color: "#fff",
    },
    Select: {
        width: "100%",
        marginTop: 7,
    },
    txtfield: {
        margin: "0px !important",
        width: "100% !important",
    },
    closeicon: {
        float: 'right',
        fontSize: 32,
        color: theme.palette.color5.main
    },
    profileImage: {
        width: 150,
        height: 150,
    },
    buttonImage: {
        justifyContent: "flex-start"
    }
});
export default EditProfileHeaderStyle;