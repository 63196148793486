import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useButton } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import theme from '../../styles/container/Theme';

const CustomButtonRoot = styled(Button)(({ _theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: theme.palette.color2.main,
    borderRadius: 5,
    color: theme.palette.color2.overlay,
    width: "100%",
    flex: 1,
    fontSize: 14,
    fontWeight: 800,
    fontFamily: [
        'Montserrat',
    ].join(','),
    '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.color2.dark,
    },
}));
export const ButtonBlue = React.forwardRef(function ButtonBlue(props, ref) {
    const { children } = props;
    const { active, disabled, focusVisible, getRootProps } = useButton({
        ...props,
        ref,
        component: CustomButtonRoot,
    });

    const classes = {
        active,
        disabled,
        focusVisible,
    };

    return (
        <CustomButtonRoot {...getRootProps()} className={clsx(classes)}>
            {children}
        </CustomButtonRoot>
    );
});

ButtonBlue.propTypes = {
    children: PropTypes.node,
};


