import { UserManager } from 'oidc-client';
import { config } from '../../Config';


const userManager = new UserManager(config)

export function getUser() {
  return userManager.getUser()
}

export function signinRedirect() {
  return userManager.signinRedirect()
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function renewToken() {
  return userManager.signinSilent()
}

export function signoutRedirect() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export function signout() {
  userManager.clearStaleState()
  userManager.removeUser()
}

export default userManager