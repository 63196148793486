import React from "react";
import { Button, IconButton, CssBaseline, Grid, ThemeProvider, useTheme, Chip } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Background from "../../Background";
import Image from "../../../components/Media/Image";
import Text from "../../Text";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function DefaultBanner(props) {
    const theme      = useTheme();
    const image_     = props.image ?? (props.circle ? "/assets/img/mastermatch_circle_default.png" : "/assets/img/mastermatch_favicon_petrol_blue.svg");
    const color      = props.color ?? "transparent";
    const image      = (
        <Image
        bgcolor = {theme.palette.primary.main}
        src     = {image_}
        alt     = "Banner Picture"
        sx      = {{borderRadius: theme.custom.borderRadius.bottomLeft}}
        width   = "100%"/>
    );

    const otherInfo = !props.otherInfo ? null :
        props.otherInfo.map((info) => 
            <Text item variant="h2" color={color}>{info}</Text>
        );
    const otherInfo2 = !props.otherInfo2 ? null :
        props.otherInfo2.map((info) => 
            <Text item variant="body2" bold color={color}>{info}</Text>
        );
    const metaData = !props.metaData ? null :
        props.metaData.map((meta) => 
            <Text item variant="h2" color={color}><b>{meta.key}:</b> {meta.value}</Text>
        );

    const tags = !props.tags ? null :
        props.tags.map((tag) => 
            <Chip variant="filled" color="background" label={tag} sx={{margin: theme.spacing(1)}}/>
        );

    const action = !props.action ? null :
        <Button
        fullWidth 
        variant = "contained" 
        color   = "primary" 
        onClick = {props.action.onClick}
        ><Text bold py={1} color="primary">{props.action.name}</Text></Button>;

    const more = !props.onMore ? null :
    <IconButton 
            color="lightPetrol" size="large" onClick={props.onMore} sx={{
                zIndex: "100"
            }}><MoreHorizIcon fontSize="30px"/></IconButton>;

    const tabs = !props.tabs ? null :
        props.tabs.map((tab) => 
            <Button color={"primary"} onClick={tab.onClick}><Text bold px={2} color={color}>{tab.name}</Text></Button>
        );

  return (
    <CssBaseline>
    <ThemeProvider theme={Theme_WIP}>
        <Background {...props} color={color} border="none" item container overflow="hidden" gap={8}>
            {/* information */}
            <Grid item container xs={12}>
                {/* banner image */}
                <Grid xs={12} sm={4}>{image}</Grid>

                {/* other info */}
                <Grid xs={12} sm={8} p={5} gap={2}>
                    {!props.name       ?null: <Text item variant="h1" bold color={color}>{props.name}</Text>}
                    {!props.subtitle   ?null: <Text item variant="body"    color={color}>{props.subtitle}<br/><br/></Text>}
                    {!props.description?null: <Text item variant="h2"      color={color}>{props.description}<br/><br/></Text>}
                    {otherInfo}
                    {otherInfo2}
                    {metaData}
                    {tags}
                </Grid>
            </Grid>

            {/* actions */}
            <Grid container item xs={12} gap={0}>
                <Grid item xs={8} sm={4}>{action}</Grid>
                <Grid item xs={4} sm={8} px={2}>{props.customButtons}{more}</Grid>
            </Grid>

            {/* tab bar */}
            <Grid item xs={12}>
                {tabs}
            </Grid>
        </Background>
    </ThemeProvider>
    </CssBaseline>
  );
}