const CircleMemberCardStyle = (theme) => ({
  root: {
    width: "100%",
  },
  logosmall: {
    width: "100%",
    borderRadius: 6,
    aspectRatio: 1,
  },
  tabcontent: {
    margin: "8px 0 15px 0",
    padding: "10px !important",
  },
  // blurView: {
  //   backgroundColor: "#ABB0B8",
  //   opacity: 0.1,
  //   backdropFilter: 'blur(20px)'
  // },
  blurView: {
    backgroundColor: "#F2F2F2",
    filter: 'blur(4px)'
  },
  p3: {
    marginTop: 3,
  },
  p18: {
    paddingTop: 18,
  },
  p15: {
    marginTop: 15,
  },
  margin: {
    marginRight: 4,
    color: "#828282",
  },
  chip: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  chipItem: {
    borderRadius: 5,
  },
  iconSearch: {
    position: "relative",
    right: 50,
    top: 15,
    color: theme.palette.color5.main,
  },
  iconFilter: {
    position: "relative",
    top: 7,
    left: 7,
    color: theme.palette.color5.main,
  },
  uline: {
    color: theme.palette.color2.main,
    marginTop: 3,
  },
  doticonbottom: {
    float: "right",
    color: "#7f7c7c",
  },
  btnModerator: {
    backgroundColor: theme.palette.secondary.main,
    padding: "2px 10px",
    borderRadius: 5,
    color: "#fff",
    fontSize: 10,
    marginLeft: 10,
  },
  mb15: {
    marginBottom: 15,
  },
  violet: {
    color: "#8247ab",
  },
  right: {
    float: "right",
    height: "15px",
    minWidth: "45px !important",
  },
  rightAlign: {
    float: "right",
  },
  grey: {
    margin: 0,
    float: "right",
  },
  iconReset: {
    marginTop: "13px",
    padding: "0 !important",
    color: theme.palette.color5.main,
  },
  boldFont: {
    fontWeight: "bold"
  }
});

export default CircleMemberCardStyle;
