const AddNewCircleStyle = (theme) => ({
  mt7: {
    marginTop: "7px",
  },
  mt10: {
    marginTop: "10px",
  },
  closeicon: {
    float: "right",
    fontSize: 32,
    color: "#bdbdbd",
  },
  Select: {
    width: "100%",
  },
  txtarea: {
    width: "96%",
    border: "none",
    backgroundColor: "#e8e8e8",
    borderRadius: 5,
    padding: "2%",
  },
});

export default AddNewCircleStyle;
