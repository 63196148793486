import { Collapse, Grid, Hidden, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import Background from "../../visualComponents/Background";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import { useState } from "react";
import Text from "../../visualComponents/Text";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { getDateWith24Hrs } from "../../apiServices/CommonMethods";
import Image from "../Media/Image";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SearchIcon from '@mui/icons-material/Search';
import {ReactComponent as MessageIcon} from "../../svg/MessageIcon.svg";
import { useSubInfo } from "../../objects/Subscriptions";

export default function MessageBar(props) {
    const globals                 = useGlobalContext();
    const [expanded, setExpanded] = useState(false);
    const contacts                = expanded ? globals.state.loggedInUser.lazyContactList() : [];
    const [query   , setQuery   ] = useState("");
    const history                 = useHistory();
    const sub = useSubInfo();

    const filteredContacts = contacts
    .filter(contact => contact.userId != globals.state.admin.userId && (!query 
        || contact.fullName      .toLowerCase().includes(query.toLowerCase())
        || contact.messageSummary.toLowerCase().includes(query.toLowerCase())));

    return (
        //!window.location.href.toLowerCase().endsWith("dashboard/activity") &&
        <ThemeProvider theme={Theme_WIP}>
        {!expanded && <Hidden mdUp>
            <MessageIcon onClick={() => setExpanded(true)} style={{
                background: "white",
                top: (sub.current?.LowerCaseSubType == sub.all.initialTrial.LowerCaseSubType || sub.current?.LowerCaseSubType == sub.all.freemium.LowerCaseSubType) && !localStorage.getItem("acceptedInvites") ? "30px" : 0,
                zIndex   : "5000",
                position : "fixed", 
                right    : "120px",
                padding  : "13px",
                width    : "50px", 
                height   : "50px",
                cursor   : "pointer" 
            }}/>
        </Hidden>}
        <Background color="white" border="bottom" sx={{
            top: {xs: (sub.current?.LowerCaseSubType == sub.all.initialTrial.LowerCaseSubType || sub.current?.LowerCaseSubType == sub.all.freemium.LowerCaseSubType) && !localStorage.getItem("acceptedInvites") ? "30px" : 0, md: "unset"},
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05) !important",
            zIndex   : "5001 !important",
            position : "fixed", 
            bottom   : {xs: "unset", md:0},
            right    : {xs: 0, md: "2vw"}, 
            width    : {xs:expanded? "100%" : "0", md:"300px"}, 
            height   : "fit-content"
            }}>
            <Grid container item xs={12} px={8} py={{xs:4, md:6}} onClick={() => setExpanded(!expanded)} sx={{borderBottom: "solid 1px " + Theme_WIP.palette.lightPetrol.main}}>
                <Text item xs color="white" bold>Berichten</Text>
                <Hidden mdUp><KeyboardArrowUpIcon/></Hidden>
                <Hidden mdDown>{expanded? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}</Hidden>
            </Grid>
            <Collapse in={expanded}>
            <Grid item container xs={12} sx={{maxHeight: {xs: "88vh", md: "80vh"}, overflowY: "scroll", pointerEvents: "all !important"}}>
                <Grid item container xs={12} px={8} py={4} sx={{height: "fit-content"}}>
                    <TextField placeholder="Personen zoeken" fullWidth size="small" 
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{fill: Theme_WIP.palette.primary.main}}/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={(event) => setQuery(event.target.value)} 
                    sx={{
                        ".MuiInputBase-root": {
                            background: Theme_WIP.palette.background.main
                        },
                        "input": {
                            color: Theme_WIP.palette.background.contrastText
                        },
                        "fieldset": {
                            border: "none"
                        }
                    }}/>
                    {query.length && !filteredContacts.length ? <Text item xs={12} id="foundNothing" pt={2} pb={20} sx={{height: 20}}/> : null}
                </Grid>
                {filteredContacts
                    .map(contact => 
                    <Grid item container pt={4} xs={12} pl={8} pr={2} pb={2} 
                    onClick={() =>{ 
                        setQuery("");
                        setExpanded(false);
                        const reload = history.location?.state?.activityUserID;
                        history.push("/dashboard/activity", {activityUserID: contact.userId, pfp: contact.pfp, fullname: contact.fullName, query: "all"});
                        if(reload) window.location.reload(); //temporary patch
                    }}
                    sx={{
                            height: "fit-content",
                            borderBottom: "solid 1px " + Theme_WIP.palette.lightPetrol.main
                    }}>
                        <Image src={contact.pfp} sx={{
                            borderRadius: Theme_WIP.custom.borderRadius.bottomLeft,
                            width: "40px", 
                            height: "40px", 
                            marginTop: 1}}/>
                        <Grid item xs ml={3} pr={2}>
                            <Text item xs={12} variant="body2" color="white" bold>{contact.fullName}</Text>
                            <Text item xs={12} variant="body2" color={contact.received ? "white" : "background"}>{contact.messageSummary?.substring(0, 50)}</Text>
                        </Grid>
                        <Text item xs={12} variant="body3" color="background" align="right" mt={2}>{getDateWith24Hrs(contact.messageTime)}</Text>
                        {contact.pending && contact.received && <Grid sx={{
                            position: "relative",
                            marginTop: -18,
                            width: "15px", 
                            height: "15px", 
                            borderRadius: "10px", 
                            background: Theme_WIP.palette.primary.main}}/>}
                    </Grid>)}
            </Grid>
            </Collapse>
        </Background>
        </ThemeProvider>
    );
}