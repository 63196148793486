const ForgotPasswordStyle = (theme) => ({
  submit: {
    marginBottom: 20,
    color: "#fff",
    fontSize: 13,
    padding: "6px 0 6px 0",
  },
  mt7: {
    marginTop: "7px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt20: {
    marginTop: "20px",
  },
  Select: {
    width: "100%",
  },
  mtb10: {
    marginBottom: "10px",
  },
});
export default ForgotPasswordStyle;
