// TODO: create generic implementation for followup. 
// The followup and check logic is duplicated between iam messages and normal messages
import React from "react";
import { CssBaseline, Grid, IconButton, Menu, MenuItem, ThemeProvider, useTheme } from "@mui/material";
import Background from "../../Background";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Image from "../../../components/Media/Image";
import Check from './../Checkbox';
import Text from "../../Text";
import { getDateString } from "../../../apiServices/CommonMethods";
import ReplyIcon from '@mui/icons-material/Reply';
import { useIntl } from "react-intl";

export default function _IamMessage(props) {
    const menuItems   = props.menuItems;
    const onReply     = (event) => (props.onReply? props.onReply() : (menuItems ? handleClick(event) : null));
    const {children}  = props;
    const theme       = useTheme();
    const name        = props.name;
    const date        = props.date;
    const matchtime   = props.matchtime;
    const intl        = useIntl();
    const buttonColor = matchtime? "darkPetrol" : "lightPetrol";

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
  return (
    <ThemeProvider theme={Theme_WIP}>
        {/* xs={12} looks better but is not in line with old design */}
        <Grid item container {...props} p={0} zIndex={-50} xs={9} md={9}>
            <Background container color={matchtime? "linear-gradient(180deg, #FFA303 53.5%, #F17844 100%)" : "darkPetrol"} border="bottomLeft" p={5} gap={4} alignItems="center">
                {/* buttons */}
                {onReply != null && !props.checked && menuItems && 
                <IconButton 
                    title={intl.formatMessage({id: 'Opvolgen'})}
                    color={buttonColor} size="large" onClick={onReply} sx={{
                        padding: "0px",
                        position: "absolute",
                        top: "60px",
                        right: "20px",
                        zIndex: "100"
                }}><ReplyIcon fontSize="20px"/></IconButton>}
                {menuItems && <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {menuItems}
                </Menu>}

                <Check checked={props.checked} onClick={props.onCheck} color={buttonColor} size="large" sx={{
                    position: "absolute",
                    top: "5%",
                    right: "1%",
                    zIndex: "100"
                }}
                />

                {/* META info */}
                <Grid item xs={2} sm={1}>
                    <Image
                        bgcolor = "transparent"
                        src     = "/assets/img/iamFull.png"
                        alt     = "Iam Profile Picture"
                        sx      = {{minWidth: "45px", borderRadius: theme.custom.borderRadius.bottomLeft}}
                        width   = "100%"/>
                </Grid>
                <Grid item xs={8} sm={10} pl={4} overflow="hidden">
                    {date && <Text variant="body1" color="primary"     >{getDateString(date)}</Text>}
                    {name && <Text variant="body1" color="primary" bold>{name}</Text>}
                </Grid>

                {/* content */}
                <Grid container item xs={12} sx={{overflowX: "hidden"}}>
                    {children}
                </Grid>
            </Background>
        </Grid>
    </ThemeProvider>
  );
}