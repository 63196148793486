import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RequestCard from "../Cards/RequestCard";
import Styles from "../../styles/component/ProfileTabs/RequestsTabStyle";
import { Grid, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { CircularProgress, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Waypoint } from "react-waypoint";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useListRequests } from "../../apiServices/Queries/Collabs/GetCollabRequests";
import { ClearCache } from "../../apiServices/CommonMethods";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";

const useStyles = makeStyles(Styles);

export default function RequestTab(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [requests, setRequests] = React.useState([]);
  const [viewUserProfileId, setViewUserProfileId] = React.useState(props.viewUserProfileId);
  const pageSize = 10;

  // const getVariable = () => {
  //   const variable = {
  //     userId: viewUserProfileId,
  //     pageNumber: pageNumber,
  //     pageSize: parseInt(50),
  //     loginProfileId: userId !== viewUserProfileId ? userId : null,
  //   };
  //   console.log("variable", variable);
  //   return variable;
  // };
  React.useEffect(() => {
    // window.addEventListener("scroll", handleOnScroll);

    if (props.viewUserProfileId !== "undefined") {
      setViewUserProfileId(props.viewUserProfileId);
    }
    // refetch();
    // console.log("useEffect");
    // return () => {
    //   // unsubscribe event
    //   window.removeEventListener("scroll", handleOnScroll);
    // };
  }, [props.viewUserProfileId]);

  const getVariable = () => ({
      userId: viewUserProfileId ?? userId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      loginProfileId: userId,
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessTypes: null,
      searchInput: null,
      circleId: null,
      requestId: null,
      eventId: null,
      locationPlaceID: null,
      matchesOnly: null,
      profileReqOnly: true
    });

  const {data: req, loading: loading, updateVariables, refetch: refetch} = useListRequests(getVariable());
  const reload = () => {
    globals.functions.loggedInUser.refetchRole(); 
    ClearCache("allCollabRequests");
    ClearCache("circle_feed", globals);
    setPageNumber(1);
    setRequests([]);
    setTimeout(() => refetch({...getVariable(), pageNumber: 1}), 100);
  };
  useEffect(() => updateVariables(getVariable()), [pageNumber, pageSize, userId, viewUserProfileId]);
  useEffect(() => {
    if(!req || !req.length)
      return;
    if (pageNumber === 1)
      setRequests(req);
    else
      setRequests(prevState => [
        ...prevState,
        ...req,
      ]);
  }, [req]);
  // const refresh = () => {
  //   refetch();
  // };
  // const handleOnScroll = () => {
  //   if (window.innerHeight + window.scrollY === document.body.offsetHeight) {
  //     // const newPage = pageNumber + 1
  //     // setPageNumber(newPage)
  //     // fetchMore({
  //     //     variables: {
  //     //         pageNumber: newPage,
  //     //     },
  //     // })
  //   }
  // };

  return (
    <Grid container gap={ThemeConstants.custom.spacings.md + " !important"} height="fit-content">
      {(loading) &&
        !requests.length ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : requests.length > 0 ? (
        // requests.map((item, index) => (
        //   <RequestCard
        //     request={item}
        //     key={index}
        //     fromProfile={true}
        //     onRefresh={refresh}
        //   />
        // ))
        requests.map(
          (card, index) => (
            <React.Fragment key={index}>
              <RequestCard
                userRole={userRole}
                onRefresh={reload}
                request={card}
                key={index}
                fromProfile={true}
              // onRefresh={refresh}
              />
              {index === requests.length - 2 && (
                <Waypoint
                  scrollableAncestor={window}
                  onEnter={() => {
                    // console.log("index", index);
                    if (pageNumber * pageSize === requests.length) {
                      const newPage = pageNumber + 1;
                      // console.log("newPage", newPage);
                      setPageNumber(newPage)
                    }
                  }}
                />
              )}
            </React.Fragment>
          )
        )

      ) : (
        <Paper elevation={3} className={classes.tabcontent} sx={{width: "100%"}}>
          <Grid container spacing={2}>
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 200,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Typography component="span" variant="h3" color={"textPrimary"}>
                  <FormattedMessage id={"noDataAvailable"} />
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
}
