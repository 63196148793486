import * as React from "react";
import { CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { CONTACT_CIRCLE_MANAGER } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { GetUserId } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import FormContainer from "../../components/Forms/FormContainer";
import InputSection from "../../components/Forms/InputSection";


export default function ContactCircleManager() {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const userId = GetUserId();
  const [circleData, setCircleData] = React.useState({});
  const [message, setMessage] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const [contactCircleManager, { data, loading, error }] = useMutation(
    CONTACT_CIRCLE_MANAGER
  );

  React.useEffect(() => {
    // console.log("data", location.state);
    // console.log("contactCircleManager data", data);

    if (location.state && location.state.circleData) {
      setCircleData(location.state.circleData);
    }
  }, [location, data]);

  const contactCircleManagerAction = async () => {
    const isValid = await checkForErrors();
    // console.log("isValid", isValid);
    if (isValid) {
      const variable = {
        circleId: circleData.id,
        message: message.trim(),
        requestedUserId: userId,
      };
      contactCircleManager({
        variables: variable,
      });
    }
  };
  const checkForErrors = async () => {
    setMessageError(!message.trim() ? "messageError" : "");
    return message.trim();
  };
  const updateTextFileds = (event) => {
    const { value } = event.target;
    // console.log("value", value);
    setMessage(value);
    setMessageError("");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        modalMessage = {data?.createContactCircle?.errorCode ==  0 ? <FormattedMessage id={"responseSentSuccessfully"} /> : undefined}
        onModalClose = {() => history.goBack()}
        error        = {data?.createContactCircle?.errorCode == -1 ? data.createContactCircle.message : undefined}
        title        = {<FormattedMessage id="ContactCircleManager"/>}
        description  = {<FormattedMessage id="ContactCircleManager_Description"/>}
        loading      = {loading}
        onSave       = {contactCircleManagerAction}
        disabled     = {loading || !message}
        onCancel     = {() => history.goBack()}
        saveId       = "Button_text_Send"
      >
        <InputSection
        type        = "textarea"
        disabled    = {loading}
        id          = "message"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<FormattedMessage id="writeAMessage"/>}
        onChange    = {updateTextFileds}
        hint        = {messageError?.length ? <FormattedMessage id="pleaseEnterMessage" /> : undefined}
        max         = {1000}
        />
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
