import React from "react";
import { CssBaseline, Fade, Grid, Grow, ThemeProvider, useTheme } from "@mui/material";
import Background from "../Background";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Image from "../../components/Media/Image";
import Text from "../Text";
import { FormattedMessage } from "react-intl";

export default function ChatBubble(props) {
    const matchtime  = props.matchtime;
    const {children} = props;
    const theme      = useTheme();
    const image_     = props.image ?? (props.iam ? "/assets/img/iamFull.png" : "/assets/img/mastermatch_favicon_petrol_blue.svg");
    const name       = props.iam ? "iAm" : (props.name ?? <FormattedMessage id={"you"}/>);

    const [hidden,   setHidden]   = React.useState(true);
    const [scrolled, setScrolled] = React.useState(false);

    React.useEffect(() => {
        fade(props.hidden ?? false, (props.delay ?? 1) * 100); //this magic number is the delay of the fade in animation
    }, [props]);
    
      function fade(active, seconds) {
        setTimeout(() => {
            setHidden(active);
            if(!scrolled) {
                window.scrollTo({top: 100000, behavior: 'smooth', block: 'center'}); //scroll to bottom of page
                setScrolled(true);
            }
        }, seconds); //actually milliseconds
      }

    const image      = (
        <Image
        bgcolor = {theme.palette.primary.main}
        src     = {image_}
        alt     = "Profile Picture"
        sx      = {{
            minWidth: "35px", 
            borderRadius: (props.iam ? theme.custom.borderRadius.bottomLeft : theme.custom.borderRadius.bottomRight)
        }}
        width   = "100%"/>
    );

    return (
    <CssBaseline>
    <ThemeProvider theme={Theme_WIP}>
    <Fade in={!hidden}>
    <Grow in={!hidden}>
        <Grid item container {...props} p={3} gap={8} direction={props.iam ? "row" : "row-reverse"} zIndex={-50}>
            <Grid item xs={0.65} display={{ xs: 'none', sm: 'block' }} zIndex={-50}>
                {image}
                {/* This looks cool but is not conform with the design */}
                {/* <Text color="darkPetrol" variant="h1" align="center">{props.iam ? "Iam" : "TODO"}</Text> */}
            </Grid>
            <Background
            container item xs={12} sm={8} md={7} p={5} alignItems="center"
            color  = {(matchtime? "rgba(255, 255, 255, 0.50)" : (props.iam ? "darkPetrol" : "lighterPetrol"))} 
            border = {props.iam ? "topLeft"    : "topRight"}
            sx     = {{
                border      : props.iam && !matchtime ? theme.palette.primary.main : "transparent",
                borderWidth : "2px",
                borderStyle : props.iam ? "solid"                    : "none"
            }}>
                <Grid container item xs={12} mb={2} display={{sm: 'none' }} direction={props.iam ? "row" : "row-reverse"}>
                    <Grid item xs={1}>
                        {image}
                    </Grid>
                    <Grid item xs={10}>
                        <Text px={2} color={matchtime ? "background" : "darkPetrol"} variant="body" bold 
                        align={props.iam? "left" : "right"}>
                            {name}
                        </Text>
                    </Grid>
                </Grid>
                <Grid item xs={12} overflow="hidden" container>
                    <Text item xs={12} mb={2} color={matchtime ? "background" : "darkPetrol"} variant="body" bold 
                    align={props.iam? "left" : "right"} display={{ xs: 'none', sm: 'block' }}>
                        {name}
                    </Text>
                    {children}
                </Grid>
            </Background>
        </Grid>
    </Grow>
    </Fade>
    </ThemeProvider>
    </CssBaseline>
  );
}