import { gql } from "@apollo/client";
import { useMutationBase } from "../mutations";

export function useUpdateBlueprints(updated, deleted) { 
    return useMutationBase(gql`
    mutation UpdateBlueprints($updated: [BlueprintInput], $deleted: [BlueprintInput]) {
        updateTaskBlueprints(updatedBlueprints: $updated, deletedBlueprints: $deleted) {
            id
            createdDate
            updatedDate
            triggerSource
            daysAfterTrigger
            payload
        }
    }`, 
    {variables: { 
        updated: updated.map(bp => ({id: bp.id, daysAfterTrigger: bp.daysAfterTrigger, triggerSource: bp.triggerSource, payload: bp.payload})), //sanitize variables
        deleted: deleted.map(bp => ({id: bp.id, daysAfterTrigger: bp.daysAfterTrigger, triggerSource: bp.triggerSource, payload: bp.payload})) },
     errorPolicy: "ignore"});
}