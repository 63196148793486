import { makeStyles } from "@material-ui/core";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";

function page_style(color) {
    return ({
        bg: {
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            position: "fixed",
            background: color,
            zIndex: "-100 !important"
        }
    });
}
function component_style(color, border, z) {
    return ({
        bg: {
            width: "100%",
            height: "100%",
            background: color,
            position: "relative",
            zIndex: z ?? "-50 !important",
            borderRadius: border
        }
    });
}

export default function Background(props) {
    const {children} = props;
    const theme    = useTheme();
    const color    = props.color ? 
        (theme.palette[props.color] ?
            theme.palette[props.color].main
            :
            props.color
        ) : (
        props.page ? 
            (props.dark ? theme.palette.darkPetrol.main : theme.palette.background.main)
            : 
            theme.palette.white.main);
    const border   = props.border ?? "none";
    
    const useStyle = makeStyles(
        props.page ? page_style(color) : component_style(color, theme.custom?.borderRadius[border], props.z)
    );
    const style    = useStyle();

    return(<Grid className={style.bg} {...props}>{children}</Grid>);
}