import React from "react";
import { CssBaseline, Grid, ThemeProvider } from "@mui/material";
import Background from "./Background";
import Theme_WIP, { ThemeConstants } from "../styles/themes/main/Theme_WIP";
import Text from "./Text";

export default function Label(props) {
  return (
    <ThemeProvider theme={Theme_WIP}>
        <Text gridProps={{ py:"1.5px", px:"7px", sx:{background: ThemeConstants.palette.lighterPetrol.main, borderRadius: "3px"}}} variant="body2" color="background" bold {...props}>
            {props.children}
        </Text>
    </ThemeProvider>
  );
}