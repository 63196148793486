import React, { useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Tabs from "@mui/material/Tabs";
import { ThemeProvider } from "@mui/material";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import Header from "../Header";
import { FormattedMessage } from "react-intl";

export default function PageWithBanner({mt, onChangeTab, tabs, tab, children, header, needHelp}) {
  const tabBar = useRef(null);
  const globals = useGlobalContext();

  return (
    <ThemeProvider theme={Theme_WIP}>
    {!globals.state.loggedInUser.userId && <Header/>}
    <Grid container item xs={12} justifyContent="center" mt={!globals.state.loggedInUser.userId ? 8 : mt}>
    <Grid item xs={12} pb={ThemeConstants.custom.spacings.md} zIndex={1} maxWidth="690px" px={ThemeConstants.custom.spacings.sm}>
      {header}
    </Grid>
    <Box ref={tabBar} sx={{
      bgcolor: "white",
      position: "sticky",
      height: tabs ? "30px" : "0px",
      top: 50,
      width: "100%",
      maxWidth: "100vw"}}>   
        {tabs && <Grid container item style={{maxWidth: "90vw", width: "100%", justifyContent: "center"}}>
          <Tabs
            sx={{
              maxWidth:"930px",
              width: "100%",
              margin: "0 !important",
              padding: "0 !important"
            }}
            value={tab}
            onChange={onChangeTab}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map(t => t && typeof t.value === "number" && 
            <Text value={t.value} onClick={() => onChangeTab(t.value)} textProps={{sx: {p: "6px 10px"}}} sx={{
              cursor: "pointer",
              opacity: t.value == tab ? 1 : 0.8 ,
              borderBottom: t.value == tab ? "solid 3px " + ThemeConstants.palette.primary.main : "none" ,
              color: ThemeConstants.palette.primary.main
            }}>{t.label}</Text>
            )}
          </Tabs>
        </Grid>}
      <Box
        sx={{
          bgcolor: "white",
          height: "700px",
          position: "relative",
          bottom: tabs ? "715px" : "700px",
          left: "-100%",
          width: "300%",
          zIndex: -1000,
          borderBottom: "solid 1px " + ThemeConstants.palette.lighterPetrol.main ,
        }}
      >
      </Box>
    </Box>
    <Grid maxWidth="690px" container px={ThemeConstants.custom.spacings.sm} mt={ThemeConstants.custom.spacings.ml} item gap={ThemeConstants.custom.spacings.md} pb="60px">
        {children}
        {needHelp && <NeedHelp/>}
    </Grid>
    </Grid>
    </ThemeProvider>
  );
}

const NeedHelp = () => <Grid container item xs={12} mt={ThemeConstants.custom.spacings.md}>
  <Text item xs={12} align="center" color="background" bold id="NeedHelp1"/>
  <Text item xs={12} align="center" color="background"      id="NeedHelp2" gridProps={{mt: ThemeConstants.custom.spacings.sm}} values={{
    ul1: (...chunks) => <a href="https://mastermatch.freshdesk.com/support/home" style={{color: ThemeConstants.palette.primary.main}}>{chunks}</a>,
    ul2: (...chunks) => <a href="mailto:hello@mastermatch.net"                   style={{color: ThemeConstants.palette.primary.main}}>{chunks}</a>
  }}/>
  <Text item xs={12} align="center" color="background" bold id="NeedHelp3" gridProps={{mt: ThemeConstants.custom.spacings.lg}}/>
  <Text item xs={12} align="center" color="background" ><b>master</b>match</Text>
  <Text item xs={12} align="center" gridProps={{mt: ThemeConstants.custom.spacings.lg}}><a href="https://mastermatch.net/hoofdpagina/" target="_blank" style={{color: ThemeConstants.palette.primary.main}}><FormattedMessage id="learnMore"/></a></Text>
</Grid>