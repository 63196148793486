import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";

export default function SuccessAlertDialog(props) {
  const history = useHistory();
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => {
    setOpen(false);
    succcessDialogOnClose();
  };
  const succcessDialogOnClose = () => {
    props.onClick? props.onClick() : history.goBack();
  };
  return (
    <Dialog
      open={open}
      onClose={props.onClose ?? handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ marginLeft: 5, marginRight: 5 }}>
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id={"Success"} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message ? props.message : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose ?? handleClose} autoFocus>
            <FormattedMessage id={"Button_Text_OK"} />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
SuccessAlertDialog.prototype = {
  message: PropTypes.string,
  open: PropTypes.bool,
};
