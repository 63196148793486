import * as React from "react";
import TextField from "@mui/material/TextField";
import TimePicker from "@mui/lab/TimePicker";
import PropTypes from "prop-types";
export default function TimePickerPage(props) {
  const [value, setValue] = React.useState(null);

  return (
      <TextField
        type="time"
        variant="outlined"
        {...props}
        inputProps={{
          min: props.minDate,
          max: props.maxDate
        }}
        value={props.value ?? value}
        onError={(error) => {
          // console.log("Error o time", error);
          if (typeof props.onError === "function") {
            props.onError(error);
          }
        }}
        onChange={(newValue) => {
          setValue(newValue.target.value);
          if(props.updateTime)
            props.updateTime(newValue.target.value);
          if(props.onChange)
            props.onChange(newValue.target.value);
        }}
        ampm={false}
      />
  );
}
TimePicker.prototype = {
  updateTime: PropTypes.func,
};
