//todo remove most of this file and use "process.env" in the code base
//this is a legacy file
//keep implementation of stateStore and userStore
import { WebStorageStateStore } from "oidc-client";

export const Environment = process.env.REACT_APP_ENVIRONMENT;

export const GoogleAPIKEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const ApolloGraphQlURI = process.env.REACT_APP_APOLLO_GRAPHQL_API;

export const START_CIRCLE = process.env.REACT_APP_START_CIRCLE;
export const UPGRADE_TO_MASTER_CIRCLE = process.env.REACT_APP_UPGRADE_TO_MASTER_CIRCLE;
export const EXTERNAL_EVENT_INVITE_TAB = process.env.REACT_APP_EXTERNAL_EVENT_INVITE_TAB;
export const CANCEL_CIRCLE_URL = process.env.REACT_APP_CANCEL_CIRCLE_URL;
export const SUPPORT_PAGE_URL = process.env.REACT_APP_SUPPORT_PAGE_URL;
export const ONBOARDING_REDIRECTION_URL = process.env.REACT_APP_ONBOARDING_REDIRECTION_URL;
export const PAYMENT_PAGE_URL = process.env.REACT_APP_PAYMENT_PAGE_URL;

export const SHARE_CIRCLE_URL = process.env.REACT_APP_SHARE_CIRCLE_URL;
export const SHARE_PROFILE_URL = process.env.REACT_APP_SHARE_PROFILE_URL;
export const SHARE_EVENT_URL = process.env.REACT_APP_SHARE_EVENT_URL;

//ID server cofig
export const config = {
  publicPage: process.env.REACT_APP_PUBLIC_PAGE, //page with login button
  authority: process.env.REACT_APP_AUTHORITY_URL,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),

};

//LinkedIn Config

export const LINKEDIN_STATE = process.env.REACT_APP_LINKEDIN_STATE;
export const LINKEDIN_SCOPE = process.env.REACT_APP_LINKEDIN_SCOPE;
export const LINKEDIN_RIDERECT = process.env.REACT_APP_LINKEDIN_RIDERECT;
export const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
export const LINKEDIN_CLIENT_SECRET = process.env.REACT_APP_LINKEDIN_CLIENT_SECRET;


// Google Config
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
