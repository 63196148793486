import { gql } from "@apollo/client";
import { useMutationBase } from "../../mutations";


export function useCreateYesActivity(senderId, recipientId, matchSuggestionId) {
    return useMutationBase(gql`
    mutation create($senderId: String!, $recipientId: String!, $matchSuggestionId: String!) {
        createYes(senderId: $senderId, recipientId: $recipientId, suggestionId: $matchSuggestionId) {
            id
       }
    }`, 
    {   noCallOnStart: true,
        variables: { 
            senderId: senderId, 
            recipientId: recipientId, 
            matchSuggestionId: matchSuggestionId
        },
        errorPolicy: "ignore"
    });
}
