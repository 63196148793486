import React from "react";
import { CssBaseline, Grid, IconButton, ThemeProvider, useTheme } from "@mui/material";
import Background from "../../Background";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Image from "../../../components/Media/Image";
import Check from './../Checkbox';
import Text from "../../Text";
import ReplyIcon from '@mui/icons-material/Reply';
import { useIntl } from "react-intl";

export default function _IncomingMessage(props) {
    const onReply    = () => (props.onReply? props.onReply() : null);
    const onCheck    = () => (props.onCheck? props.onCheck() : null);
    const {children} = props;
    const intl       = useIntl();
    const theme      = useTheme();
    const image_     = props.image   ?? "/assets/img/mastermatch_favicon_petrol_blue.svg";
    const message    = props.message;
    const name       = props.name;
    const date       = props.date;
    const outgoing   = props.outgoing;
    const align      = outgoing? "right"                  : "left";
    const direction  = outgoing? "row-reverse"            : "row";
    const color      = outgoing? theme.palette.white.main : "transparent";
    const borderStyle= outgoing? "none"                   : "solid"
    const border     = outgoing? "bottomRight"            : "bottomLeft";

  return (
    <ThemeProvider theme={Theme_WIP}>
        <Grid container item xs={12} zIndex={-50}>

        {/* White space on left */}
        {outgoing? <Grid item xs={0} md={3}><span> </span></Grid> : null}

        {/* Message bubble */}
        <Grid item container {...props} p={3} zIndex={-50} xs={12} md={9}>
            <Background container p={5} gap={6} alignItems="center"
            color  = {color}
            border = {border}
            sx     = {{
                border: theme.palette.white.main,
                borderStyle: borderStyle
            }}>

            {/* Icons on top right */}
            {outgoing?null:<IconButton 
            title={intl.formatMessage({id: 'Opvolgen'})}
            color="lightPetrol" size="large" onClick={onReply} sx={{
                padding: "0px",
                position: "absolute",
                top: "60px",
                right: "20px",
                zIndex: "100"
            }}><ReplyIcon fontSize="20px"/></IconButton>}
            {outgoing?null:<Check color="lightPetrol" onChange={onCheck} size="large"  sx={{
                position: "absolute",
                top: "5%",
                right: "1%",
                zIndex: "100"
            }}/>}

                {/* Meta data. Username, pfp, date, message type */}
                <Grid container item xs={12} gap={4} direction={direction}>
                    <Grid item xs={2} sm={1}>
                        <Image
                            bgcolor = "transparent"
                            src     = {image_}
                            alt     = "Profile Picture"
                            sx      = {{minWidth: "45px", borderRadius: theme.custom.borderRadius.bottomLeft}}
                            width   = "100%"/>
                    </Grid>
                    <Grid container item xs={8} sm={10}>
                        {name   ?<Text item xs={12} align={align} bold      color="background" variant="h1"><b>{name}</b></Text>:null}
                        {message?<Text item xs={12} align={align} mt={-0.6} color="background" variant="h2">{message}</Text>:null}
                        {date   ?<Text item xs={12} align={align}           color="darkPetrol" variant="h2">{date}</Text>:null}
                    </Grid>
                </Grid>

                {/* content */}
                <Grid item xs={12} overflow="hidden">
                    {children}
                </Grid>
            </Background>
        </Grid>
        </Grid>
    </ThemeProvider>
  );
}