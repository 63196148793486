import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Styles from "../../../styles/component/ProfileTabs/InfoTabs/EditBioInfoStyle";
import { Alert, Autocomplete, Skeleton } from "@mui/material";
import { Chip, FormHelperText, TextareaAutosize } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY } from "../../../apiServices/mutations";
import { ClearCache, GetUserId } from "../../../apiServices/CommonMethods";
import LocationAutoComplete from "../../Autocomplete/LocationAutoComplete";
import { ButtonDarkBlue } from "../../Buttons/ButtonDarkBlue";
import {
  masterDataCompanyLevel,
  masterDataIndustries,
} from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(Styles);

export default function EditCompanyInfo(props) {
  const classes = useStyles();
  const userId = GetUserId();
  const theme = useTheme();
  const [isCompanyOnly, setIsCompanyOnly] = React.useState(false);
  const [companyArray] = React.useState(props.companyArray);

  const [CompanyUpdate, { data, loading, error: companyUpdateError }] =
    useMutation(UPDATE_COMPANY, {onCompleted: (data) => {
      ClearCache("selfProfile");
      ClearCache("profile");
      ClearCache("my");
    }});

  let [input, setInput] = React.useState({
    name: "",
    companyRole: "",
    companyLevel: "",
    includedInCompanySince: null,
    industry: [],
    workField: "",
    keyValues: [],
    mission: "",
    website: "",
    numberOfEmployees: "",
    annualRevenue: "",
    certifications: [],
    incubatorOrAccelerator: "",
    companyId: null,
  });
  let [inputError, setInputError] = React.useState({
    name: "",
    companyRole: "",
    companyLevel: "",
    includedInCompanySince: "",
    industry: "",
    workField: "",
    keyValues: "",
    mission: "",
  });
  React.useEffect(() => {
    setIsCompanyOnly(props.companyOnly);
    if (typeof data !== "undefined" && data.updateCompany.errorCode === 0) {
      setCompanydata(data.updateCompany.result);
      props.saveAction(data.updateCompany.result, isCompanyOnly);
    }
    if (props.data) {
      setCompanydata(props.data);
    }
  }, [props.companyOnly, props.data, data]);

  const setCompanydata = (companyData) => {
    setInput((prevState) => ({
      ...prevState,
      name: companyData.name,
      companyRole: companyData.companyRole,
      companyLevel: companyData.companyLevel,
      includedInCompanySince: companyData.includedInCompanySince,
      industry: companyData.industry ?? [],
      workField: companyData.workfield,
      keyValues: companyData.keyValues ?? [],
      mission: companyData.mission,
      website: companyData.website,
      numberOfEmployees: companyData.numberOfEmployees,
      annualRevenue: companyData.annualRevenue,
      certifications: companyData.certifications ?? [],
      incubatorOrAccelerator: companyData.incubatorOrAccelerator,
      companyId: companyData.companyId,
    }));
    setInputError((prevState) => ({
      ...prevState,
      name: "",
      companyRole: "",
      companyLevel: "",
      includedInCompanySince: "",
      industry: "",
      workField: "",
      keyValues: "",
      mission: "",
    }));
  };

  const handleSave = async () => {
    let filteredCompany = companyArray.filter((company) => {
      return company.name === input["name"];
    });
    // console.log("filteredCompany", filteredCompany.length);
    if (isCompanyOnly) {
      props.saveAction(input, isCompanyOnly);
      setIsCompanyOnly(false);
    } else {
      let isError = await checkForErrors();
      const variable = {
        userId: userId,
        name: input["name"],
        industry: input["industry"],
        includedInCompanySince: input["includedInCompanySince"]
          ? parseInt(input["includedInCompanySince"])
          : null,
        companyLevel: input["companyLevel"] ?? "",
        companyRole: input["companyRole"] ?? "",
        incubatorOrAccelerator: input["incubatorOrAccelerator"] ?? null,
        workfield: input["workField"] ?? "",
        numberOfEmployees: input["numberOfEmployees"]
          ? parseInt(input["numberOfEmployees"])
          : null,
        annualRevenue: input["annualRevenue"]
          ? parseFloat(input["annualRevenue"])
          : null,
        certifications: input["certifications"] ?? null,
        keyValues: input["keyValues"] ?? [],
        mission: input["mission"] ?? "",
        website: input["website"] ?? "",
        companyId: input["companyId"] ?? null,
      };
      // console.log("input annualRevenue ", input["annualRevenue"]);
      // console.log("variables save company", variable);
      if (isError) {
        CompanyUpdate({
          variables: variable,
        });
      }
    }
  };
  const handleChangeTextField = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };

  const handleChangeTextFieldNumberOfEmployees = (event) => {
    const { id, value } = event.target;
    const arayWords = value.split(" ");
    if (arayWords.length < 2) {
      setInput((prevState) => ({
        ...prevState,
        [id]: value,
      }));
      setInputError((prevState) => ({
        ...prevState,
        [id]: "",
      }));
    }
  };
  const handleChangeTextFieldYear = (event) => {
    const { id, value } = event.target;
    const arayWords = value.split(" ");
    if (arayWords.length < 2 && value.length < 5) {
      setInput((prevState) => ({
        ...prevState,
        [id]: value,
      }));
      setInputError((prevState) => ({
        ...prevState,
        [id]: "",
      }));
    }
  };
  const handleChangeSelect = (event) => {
    setInput((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [event.target.name]: "",
    }));
  };
  const handleChangeAutoCompleteCertifications = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      certifications: newvalue,
    }));
  };
  const handleChangeAutoCompleteIndustries = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      industry: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      industry: "",
    }));
  };
  const handleChangeAutoCompleteKeyValues = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      keyValues: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      keyValues: "",
    }));
  };
  const onLocationChange = (location) => {
    // console.log("onLocationChange ", location);
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        workField:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInputError((prevState) => ({
        ...prevState,
        workField: "",
      }));
    }
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      name: !input["name"] ? "companyNameError" : "",
      companyRole: !input["companyRole"] ? "roleError" : "",
      companyLevel: !input["companyLevel"] ? "typeError" : "",
      industry: !input["industry"].length ? "industryError" : "",
    }));
    // console.log("checkForErrors", input["workField"]);
    return (
      input["name"] &&
      input["companyRole"] &&
      input["companyLevel"] &&
      input["industry"].length
    );
  };

  return (
    <Box sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}>
      {companyUpdateError ? (
        <Alert severity="error">
          {<FormattedMessage id={"somethingWentWrong"} />}
        </Alert>
      ) : null}
      {typeof data !== "undefined" && data.updateCompany.errorCode === -1 ? (
        <Alert severity="error">{data.updateCompany.message}</Alert>
      ) : null}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.mt10}
          >
            <FormattedMessage id={"CompanyName"} /> *:
          </Typography>
          {/* <Typography
            component="h3"
            variant="h3"
            color={"textSecondary"}
            className={classes.p7}
          >
            Field description Field description Field description
          </Typography> */}
          <FormControl variant="filled" className={classes.Select}>
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <TextField
                id="name"
                // label="Write"
                variant="filled"
                size="small"
                onChange={handleChangeTextField}
                value={input["name"]}
                helperText={
                  inputError["name"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"PleaseEnterTheCompanyName"} />
                    </Typography>
                  ) : null
                }
              />
            )}
          </FormControl>
        </Grid>
      </Grid>
      {!isCompanyOnly ? (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Companyrole"} />
              *:
            </Typography>
            {/* <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              <FormattedMessage id={"EditCompanyInfo_Description1"} />
            </Typography> */}
            <FormControl variant="filled" className={classes.Select}>
              {/* <InputLabel id="demo-simple-select-label">
                <FormattedMessage id={"InputLabel_Text_Select"} />
              </InputLabel> */}
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                // <Select
                //   labelId="demo-simple-select-label"
                //   name="companyRole"
                //   onChange={handleChangeSelect}
                //   value={input["companyRole"]}
                // >
                //   {masterDataCompanyRole().map((option, index) => (
                //     <MenuItem value={option.key}>{option.key}</MenuItem>
                //   ))}
                // </Select>
                <TextField
                  id="companyRole"
                  autoComplete="current-password"
                  variant="filled"
                  className={classes.txtfield}
                  size={"small"}
                  onChange={handleChangeTextField}
                  value={input["companyRole"]}
                  helperText={
                    inputError["companyRole"] ? (
                      <Typography variant="h5" component="span" color="error">
                        <FormattedMessage id={"PleaseEnterCompanyRole"} />
                      </Typography>
                    ) : null
                  }
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Typeofcompany"} />
              *:
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              <FormattedMessage id={"CompanyType_Edit_Description"} />
            </Typography>
            <FormControl variant="filled" className={classes.Select}>
              <InputLabel id="demo-simple-select-label">
                <FormattedMessage id={"InputLabel_Text_Select"} />
              </InputLabel>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Select
                  labelId="demo-simple-select-label"
                  name="companyLevel"
                  onChange={handleChangeSelect}
                  value={input["companyLevel"]}
                >
                  {masterDataCompanyLevel().map((option, index) => (
                    <MenuItem value={option.key}>{option.key}</MenuItem>
                  ))}
                </Select>
              )}
              {inputError["companyLevel"] ? (
                <FormHelperText>
                  {" "}
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Pleaseentercompanytype"} />
                  </Typography>{" "}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"WithTheCompanySince"} />
              :
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              <FormattedMessage id={"EditCompanyInfo_Description2"} />
            </Typography>
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <TextField
                  id="includedInCompanySince"
                  // label="Year"
                  variant="filled"
                  size="small"
                  onChange={handleChangeTextFieldYear}
                  value={input["includedInCompanySince"]}
                  helperText={
                    inputError["includedInCompanySince"] ? (
                      <Typography variant="h5" component="span" color="error">
                        <FormattedMessage id={"PleaseEnterTheYear"} />
                      </Typography>
                    ) : null
                  }
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Industry"} />
              *:
            </Typography>
            {/* <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              <FormattedMessage id={"EditCompanyInfo_Description3"} />
            </Typography> */}
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Autocomplete
                  multiple
                  id="industry"
                  options={masterDataIndustries()}
                  value={input["industry"]}
                  onChange={handleChangeAutoCompleteIndustries}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        size="small"
                        className={classes.chip}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Add"
                      variant="outlined"
                    />
                  )}
                />
              )}
              {inputError["industry"] ? (
                <FormHelperText>
                  {" "}
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Pleaseenterindustry"} />
                  </Typography>{" "}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Workfield"} />
              :
            </Typography>
            {/* <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              Field description Field description Field description
            </Typography> */}
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <LocationAutoComplete
                  onChange={onLocationChange}
                  value={input["workField"]}
                />
              )}
              {inputError["workField"] ? (
                <FormHelperText>
                  {" "}
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Pleaseenterlocation"} />
                  </Typography>{" "}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Companykeyvalues"} />
              :
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              <FormattedMessage id={"EditCompanyInfo_Description"} />
            </Typography>
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Autocomplete
                  multiple
                  id="keyValues"
                  freeSolo
                  options={[]}
                  autoSelect={true}
                  value={input["keyValues"]}
                  onChange={handleChangeAutoCompleteKeyValues}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        size="small"
                        className={classes.chip}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Add"
                      variant="outlined"
                    />
                  )}
                />
              )}
              {inputError["keyValues"] ? (
                <FormHelperText>
                  {" "}
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Pleaseenterkeyvalues"} />
                  </Typography>{" "}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Company'smission"} />
              :
            </Typography>
            {/* <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              Field description Field description Field description
            </Typography> */}
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <TextareaAutosize
                  variant="filled"
                  aria-label="minimum height"
                  minRows={5}
                  id="mission"
                  className={classes.txtarea}
                  value={input["mission"]}
                  onChange={handleChangeTextField}
                />
              )}
              {
                inputError["mission"] ? (
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Pleaseenterthemission"} />
                  </Typography>
                ) : null
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h2"
              variant="h2"
              color={"textPrimary"}
              className={classes.btxt}
            >
              <FormattedMessage id={"Optional"} />:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Company's website"} />:
            </Typography>
            {/* <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              Field description Field description Field description
            </Typography> */}
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <TextField
                  id="website"
                  variant="filled"
                  size="small"
                  onChange={handleChangeTextField}
                  value={input["website"]}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography component="h3" variant="h2" color={"textPrimary"}>
              <FormattedMessage id={"NumberOfEmployees"} />:
            </Typography>
            {/* <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              Field description Field description Field description
            </Typography> */}
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <TextField
                  id="numberOfEmployees"
                  label=""
                  variant="filled"
                  size="small"
                  onChange={handleChangeTextFieldNumberOfEmployees}
                  value={input["numberOfEmployees"]}
                  type={"number"}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 7);
                  }}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"AnnualRevenue"} />:
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              <FormattedMessage id={"EditCompanyInfo_Description4"} />
            </Typography>
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <TextField
                  id="annualRevenue"
                  label=""
                  variant="filled"
                  size="small"
                  onChange={handleChangeTextField}
                  value={input["annualRevenue"]}
                  type={"number"}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                // InputProps={{
                //   startAdornment: <InputAdornment position="start">€</InputAdornment>,
                // }}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Company'sCertifications"} />:
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              <FormattedMessage id={"EditCompanyInfo_Description5"} />
            </Typography>
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Autocomplete
                  multiple
                  id="certifications"
                  freeSolo
                  options={[]}
                  autoSelect={true}
                  value={input["certifications"]}
                  onChange={handleChangeAutoCompleteCertifications}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        size="small"
                        className={classes.chip}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Add"
                      variant="outlined"
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"Incubator/accelerator"} />:
            </Typography>
            {/* <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              Field description Field description Field description
            </Typography> */}
            <FormControl variant="filled" className={classes.Select}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <TextField
                  id="incubatorOrAccelerator"
                  label=""
                  variant="filled"
                  size="small"
                  onChange={handleChangeTextField}
                  value={input["incubatorOrAccelerator"]}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <ButtonDarkBlue
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={handleSave}
          >
            <FormattedMessage id={"Button_Text_Save"} />
          </ButtonDarkBlue>
        </Grid>
      </Grid>
    </Box>
  );
}
EditCompanyInfo.prototype = {
  saveAction: PropTypes.func,
  data: PropTypes.object,
};
