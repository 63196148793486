const preRegisterPageStyle = (theme) => ({
    container: {
        marginTop: 75,
    },
    h1: {
        fontSize: "40px",
        fontWeight: 500,
        color: theme.palette.background.specialOverlay
    },
    h2: {
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "30px",
        color: theme.palette.background.overlay
    },
    p: {
        marginTop: "5px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: theme.palette.background.overlay
    },
    special: {
        color: theme.palette.background.specialOverlay
    },
    line: {
        backgroundColor: theme.palette.secondary.main
    },
    button: {
        width: "172px", //for some reason 32px gets cut off so I'm overcompensating here
        height: "40px"
    },
    buttonText: {
        fontSize: "16px",
        fontWeight: 400
    }
});
export default preRegisterPageStyle;