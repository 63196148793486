import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useButton } from '@mui/core/ButtonUnstyled';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
const CustomButtonRoot = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: "#E0E0E0",
    borderRadius: 5,
    color: "#828282",
    width: "100%",
    fontSize: 14,
    fontWeight: 800,
    fontFamily: [
        'Montserrat',
    ].join(','),
    '&:hover': {
        boxShadow: 'none',
        backgroundColor: "#F2F2F2",
    },
}));
export const ButtonGrey = React.forwardRef(function ButtonGrey(props, ref) {
    const { children } = props;
    const { active, disabled, focusVisible, getRootProps } = useButton({
        ...props,
        ref,
        component: CustomButtonRoot,
    });

    const classes = {
        active,
        disabled,
        focusVisible,
    };

    return (
        <CustomButtonRoot {...getRootProps()} className={clsx(classes)}>
            {children}
        </CustomButtonRoot>
    );
});

ButtonGrey.propTypes = {
    children: PropTypes.node,
};


