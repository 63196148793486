import React from "react";
import {
  format24Time,
  format24UTCTime,
  getLongDateWithYear,
} from "../../../apiServices/CommonMethods";
import { ActivityStatus } from "../../../apiServices/Constants";
import { useIntl } from "react-intl";
import Link from "@mui/material/Link";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";


export default function ActivitySuggestionResponseCard({source, activity, color}) {
  const intl = useIntl();

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneDeclined &&
        <Text item xs={12} color={color??"background"} id="DeclinedAllOptions" bold/>
      }
      {activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneDeclinedAndInitiatedNew &&
        <Text item xs={12} color={color??"background"} id="DeclinedAllOptionsAndSuggestedNew" bold/>
      }
      {activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneAccepted &&
          <Text item xs={12} color={color??"background"} bold id="AcceptedOption" />
      }
      {( activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneAccepted 
      || activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneDeclined) &&
        activity.activity.meetings.map(
          (meeting, index) => (meeting.status === "ACCEPT" || meeting.status === "DECLINED") && (
            <Text item xs={12} color={color??"background"}>
              {intl.formatMessage({ id: "Option" }) +
                " " +
                (index + 1) +
                ": " +
                getLongDateWithYear(meeting.meetingDateTime) +
                ", " +
                format24UTCTime(new Date(meeting.meetingDateTime)) +
                ", "}
              <br/>
              {meeting.locationURL !== null && (
                  meeting.meetingType === "OFFLINE" ? (meeting.locationURL) : (
                    <Link
                      color="inherit"
                      href={
                        meeting.locationURL.startsWith("http")
                          ? meeting.locationURL
                          : "https://" + meeting.locationURL
                      }
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      {meeting.locationURL}
                    </Link>
                  )
              )}
            </Text>
          )
        )}
    </ThemeProvider>
  );
}
