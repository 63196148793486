const ChangePremiumStyle = (theme) => ({
    submit: {
        marginBottom: 20,
        color: "#fff",
        fontSize: 13,
        padding: "6px 0 6px 0",
    },
    mt7: {
        marginTop: "7px",
    },
    mt3: {
        marginTop: "3px",
    },
    mt10: {
        marginTop: "10px",
    },
    mt15: {
        marginTop: "15px",
    },
    mt20: {
        marginTop: "20px",
    },
    mt30: {
        marginTop: "30px",
    },
    closeicon: {
        float: "right",
        fontSize: 32,
        color: "#bdbdbd",
        // '@media (max-width: 599px)': {
        //     position: 'absolute',
        //     top: "10%",
        // },
    },
    Select: {
        width: "100%",
    },
    txtarea: {
        width: "96%",
        border: "none",
        backgroundColor: theme.palette.color3.main,
        borderRadius: 6,
        padding: "2%",
    },
    blueColor: {
        color: "#72B6CD",
    },
    checkContent: {
        paddingLeft: "0px",
        marginTop: "7px",
    },
    logo: {
        width: "50%",
        borderRadius: "6px",
        // [theme.breakpoints.up('sm')]: {
        //     borderRadius: "50%",
        // },
    },
});

export default ChangePremiumStyle;
