import { GET_CIRCLE_UUID, GET_EVENT_UUID } from "../../Queries";
import { useCachedQuery } from "../CachedQuery";

export function useUUID(isEvent, sourceId) {
  return useCachedQuery(
    "uuid",
    isEvent? GET_EVENT_UUID : GET_CIRCLE_UUID,
    isEvent? {
        eventId: sourceId,
    } : {
        circleId: sourceId,
    },
    (data) => isEvent? data?.eventUUID : data?.circleUUID,
    180,
    true
  );
}
  