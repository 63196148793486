import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';


export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        value === index && <Grid container item sx={{pointerEvents: "none !important"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {children}
        </Grid>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};