const EventPublishStyle = (theme) => ({
    submit: {
        marginBottom: 20,
        color: "#fff",
        fontSize: 13,
        padding: "6px 0 6px 0",
    },
    mt7: {
        marginTop: "7px",
    },
    mt10: {
        marginTop: "10px",
    },
    mt10Orange: {
        color: theme.palette.secondary.main,
        marginTop: "10px",
    },
    mt20Orange: {
        color: theme.palette.secondary.main,
        marginTop: "20px",
        fontWeight: 800,
    },
    mt15: {
        marginTop: "15px",
    },
    mt20: {
        marginTop: "20px",
    },
    mt30: {
        marginTop: "30px",
    },
    closeicon: {
        float: "right",
        fontSize: 32,
        color: "#bdbdbd",
    },
    Select: {
        width: "100%",
    },
    txtarea: {
        width: "96%",
        border: "none",
        backgroundColor: theme.palette.color3.main,
        borderRadius: 6,
        padding: "2%",
    },
});

export default EventPublishStyle;
