
const CollaborationOfInterestStyle = (theme) => ({
    p7: {
        marginTop: 7,
    },

    p15: {
        marginTop: 15,
    },
    chip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5
    }
});
export default CollaborationOfInterestStyle;