import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Box, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getDateWithShortMonth, getdayValue, getMonthValue, getYearValue } from "../../apiServices/CommonMethods";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    mt40: {
        marginTop: 40,
    },

    mt30: {
        marginTop: 30,
    },

    mt10: {
        marginTop: 10,
    },

    pt15: {
        paddingTop: 15,
    },

    logo: {
        width: 300,
        position: 'relative',
        right: 20,
    },

    btline: {
        // borderBottom: "1px solid rgb(212, 212, 212)",
        paddingTop: 15,
        color: theme.palette.color5.main,

    },

    foter: {
        marginTop: 30,
    },
    tableCellAmount: {
        borderBottom: "none",
        // fontSize: 2,

        [theme.breakpoints.down('sm')]: {
            fontSize: 2,
        },
    }

}));

function priceRow(qty, unit) {
    return qty * unit;
}

function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit);
    return { desc, qty, unit, price };
}

function totalAmount(invoiceDetails) {
    let total = parseFloat(invoiceDetails?.billedAmount) + parseFloat(invoiceDetails?.taxAmount)
    return total.toString() + ",00"
}
const rows = [
    createRow('Paperclips (Box)', 100, 1.15),
];

export default function InvoiceDetails() {
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation()
    const theme = useTheme();
    const intl = useIntl();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
        defaultMatches: true
    });
    const [invoiceDetails, setInvoiceDetails] = React.useState({});
    const [userDetails, setUserDetails] = React.useState({});

    useEffect(() => {
        // console.log("invoiceDetails", location.state)
        if (location.state && location.state.invoiceDetails) {
            setInvoiceDetails(location.state.invoiceDetails)
            setUserDetails(location.state.userDetails)
        }
    }, [location]);

    const getTranslatedDate = (date) => {
        // console.log("monthValue date", date)
        if (typeof date !== "undefined") {
            const day = getdayValue(date)
            const monthValue = getMonthValue(date)
            const year = getYearValue(date)
            // console.log("monthValue", monthValue)
            return day + " " + intl.formatMessage({ id: monthValue }) + " " + year
        } else {
            return " "
        }

    }

    return (
        <div className={classes.mainRoot}>
            <Container component="main" maxWidth="sm" className={classes.container}>
                <div>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Box sx={{ marginTop: 30 }}>
                            <Grid container spacing={1}>
                                <Grid item sm={11} xs={11}>
                                    <img src={"/assets/img/mastermatch_logo_petrol_blue_large.svg"} alt={"mastermatch_logo"} className={classes.logo} />
                                </Grid>
                                <Grid item sm={1} xs={1}>
                                    <CloseIcon
                                        className={classes.closeicon}
                                        onClick={() => history.goBack()}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <Typography component="h3" variant="h3" className={classes.mt40}>
                                        {userDetails?.firstName + " " + userDetails?.lastName}
                                    </Typography>
                                    <Typography component="h3" variant="h3">
                                        {userDetails?.email}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12} className={classes.mt30}>
                                    <Typography component="h3" variant="h3" display="inline">
                                        {"Factuurdatum:" + " "}
                                    </Typography>
                                    <Typography component="h3" variant="h3" display="inline" className={classes.btline}>
                                        {invoiceDetails.createdDate !== null ? getTranslatedDate(invoiceDetails.createdDate) : ""}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12} className={classes.mt10}>
                                    <Typography component="h3" variant="h3" display="inline">
                                        {"Factuurnummer:" + " "}
                                    </Typography>
                                    <Typography component="h3" variant="h3" display="inline" className={classes.btline}>
                                        {invoiceDetails?.id}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12} className={classes.mt40}>
                                    <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                                        <Table aria-label="spanning table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                                                    <TableCell style={{ borderBottom: "none" }}>Omschrijving</TableCell>
                                                    <TableCell style={{ borderBottom: "none" }}>Periode levering</TableCell>
                                                    <TableCell style={{ borderBottom: "none" }} align="right">Totaal ex. btw</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.desc}>
                                                        <TableCell>1x</TableCell>
                                                        <TableCell>Premium lidmaatschap</TableCell>
                                                        <TableCell style={{ borderBottom: "none" }}>{getDateWithShortMonth(invoiceDetails?.billingStartDate) + "-" + getDateWithShortMonth(invoiceDetails?.billingEndDate)}</TableCell>
                                                        <TableCell style={{ fontSize: isMobile ? 12 : 15 }} align="right">&euro; {invoiceDetails?.billedAmount?.replace('.', ',')}</TableCell>
                                                    </TableRow>
                                                ))}

                                                <TableRow>
                                                    <TableCell style={{ borderBottom: "none" }} rowSpan={3} />
                                                    <TableCell style={{ borderBottom: "none" }} rowSpan={3} />
                                                    <TableCell style={{ borderBottom: "none", fontWeight: 500 }} align="right">Totaal ex. btw</TableCell>
                                                    <TableCell style={{ borderBottom: "none", fontSize: isMobile ? 12 : 15 }} align="right">&euro; {invoiceDetails?.billedAmount?.replace('.', ',')}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ borderBottom: "none" }} align="right">21% btw</TableCell>
                                                    <TableCell style={{ borderBottom: "none", fontSize: isMobile ? 12 : 15 }} align="right">&euro; {invoiceDetails?.taxAmount?.replace('.', ',')}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ borderBottom: "none", fontWeight: 500 }} align="right">Totaal incl. btw</TableCell>
                                                    <TableCell style={{ borderBottom: "none", fontSize: isMobile ? 12 : 15 }} align="right">&euro; {invoiceDetails ? totalAmount(invoiceDetails) : ""}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item sm={4} xs={6} className={classes.mt30}>
                                    <Typography component="h4" variant="h4">
                                        Mastermatch BV
                                    </Typography>
                                    <Typography component="h5" variant="h5">
                                        Dr. de Visserstraat 18 B
                                    </Typography>
                                    <Typography component="h5" variant="h5">
                                        3038 TS Rotterdam
                                    </Typography>
                                </Grid>
                                <Grid item sm={4} xs={6} className={classes.mt30}>
                                    <Typography component="h5" variant="h5" className={classes.pt15}>
                                        KVK 76896927
                                    </Typography>
                                    <Typography component="h5" variant="h5">
                                        BTW NL860830743B01
                                    </Typography>
                                </Grid>
                                <Grid item sm={4} xs={12} align={isMobile ? "left" : "right"} className={classes.mt30}>
                                    <Typography component="h5" variant="h5" className={classes.pt15}>
                                        www.mastermatch.net
                                    </Typography>
                                    <Typography component="h5" variant="h5">
                                        hello@mastermatch.net
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </div>
            </Container>
        </div>
    );
}
