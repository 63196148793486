import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from "react-intl";
import messages_nl from "./localization/nl.json"
import messages_en from "./localization/en.json";
import { ThemeProvider, } from '@material-ui/core/styles';
import theme from './styles/container/Theme';
import './App.css'
import store from './apiServices/Redux/Store'
import { Provider } from 'react-redux'
const messages = {
  'nl': messages_nl,
  'en': messages_en
};
// const language = navigator.language.split(/[-_]/)[0] === 'en' ? 'en' : 'nl';
const language = 'nl';



ReactDOM.render(
  < IntlProvider locale={language} messages={messages[language]} >
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </IntlProvider >
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
