import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Styles from "../../../styles/component/ProfileTabs/InfoTabs/EditCollaborationOfInterestStyle";
import ProjectPartner from "./CollaborationOfInterestTabs/ProjectPartner";
import StrategicPartner from "./CollaborationOfInterestTabs/StrategicPartner";
import BusinessPartners from "./CollaborationOfInterestTabs/BusinessPartners";
import CoFounding from "./CollaborationOfInterestTabs/CoFounding";
import Investment from "./CollaborationOfInterestTabs/Investment";
import Mentoring from "./CollaborationOfInterestTabs/Mentoring";
import { CollaborationOfInterestArray, CollaborationTypes } from "../../../apiServices/Constants";
import { Alert } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(Styles);

export default function EditCollaborationOfInterest(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.data.itemType);
  const [collabArray, setCollabArray] = React.useState(props.collabArray);
  const [collabData, setCollabData] = React.useState(props.data);
  const [error, setError] = React.useState("");
  const intl = useIntl();

  const [isCollabrationOnly, setIsCollabrationOnly] = React.useState(
    props.collaborationOnly
  );

  React.useEffect(() => {
    setIsCollabrationOnly(props.collaborationOnly);
    setValue(props.data.itemType);
    setCollabArray(props.collabArray);
    if (typeof props.data !== "undefined") {
      setCollabData(props.data);
    }
  }, [props.collaborationOnly, props.data, props.collabArray]);

  const handleChange = (e) => {
    let filteredCollabs = collabArray.filter((collab) => {
      return collab.itemType === e.target.value;
    });
    if (!filteredCollabs.length && isCollabrationOnly) {
      setError("");
      setValue(e.target.value);
      props.saveAction({ itemType: e.target.value }, true);
      setIsCollabrationOnly(false);
    } else {
      setError(intl.formatMessage({ id: "Collaboration_Already_added" }));
    }
  };

  const currentCollabTab = () => {
    if (props.data.itemType === CollaborationTypes.projectPartners) {
      return (
        <ProjectPartner saveAction={props.saveAction} collabData={collabData} />
      );
    } else if (props.data.itemType === CollaborationTypes.strategicPartners) {
      return (
        <StrategicPartner
          saveAction={props.saveAction}
          collabData={collabData}
        />
      );
    } else if (props.data.itemType === CollaborationTypes.businessPartners) {
      return (
        <BusinessPartners
          saveAction={props.saveAction}
          collabData={collabData}
        />
      );
    } else if (props.data.itemType === CollaborationTypes.coFounding) {
      return (
        <CoFounding saveAction={props.saveAction} collabData={collabData} />
      );
    } else if (props.data.itemType === CollaborationTypes.investment) {
      return (
        <Investment saveAction={props.saveAction} collabData={collabData} />
      );
    } else {
      return (
        <Mentoring saveAction={props.saveAction} collabData={collabData} />
      );
    }
  };

  return (
    <Box sx={{}}>
      {error.length ? <Alert severity="error">{error}</Alert> : null}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h2"
            color={"textPrimary"}
            className={classes.p15}
          >
            <FormattedMessage
              id={"ChooseACollabrationPeopleCanContactYouFor"}
            />
            *:
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.p7c}
          >
            <FormattedMessage id={"EditCollaborationOfInterest_Description1"} />
          </Typography>
          <FormControl variant="filled" className={classes.Select}>
            <InputLabel id="demo-simple-select-filled-label">
              <FormattedMessage id={"InputLabel_Text_Select"} />
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              onChange={handleChange}
              value={isCollabrationOnly ? "" : value}
              disabled={!isCollabrationOnly}
            >
              {CollaborationOfInterestArray.map((collabDict, index) => (
                <MenuItem value={collabDict.itemType} key={index}>
                  {collabDict.itemType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {!isCollabrationOnly ? currentCollabTab() : null}
    </Box>
  );
}
EditCollaborationOfInterest.prototype = {
  saveAction: PropTypes.func,
  data: PropTypes.string,
  collabArray: PropTypes.array,
};
