// Filters are saved in local storage under "filters_HISTORYKEY" and they expire within a day. We save max 15 filters
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { ClickAwayListener, Hidden, Icon, Tab, Tabs, ThemeProvider } from "@mui/material";
import {
  masterDataCircleOperatesIn,
  masterDataCircleTags,
  masterDataCompanyLevel,
  masterDataEventTags,
  masterDataIndustries,
  masterDataSkills,
} from "../apiServices/Constants";
import { ClearCache, removeItemOnce } from "../apiServices/CommonMethods";
import Button from "@mui/material/Button";
import { FormattedMessage, useIntl } from "react-intl";
import Theme_WIP, { ThemeConstants } from "../styles/themes/main/Theme_WIP";
import {ReactComponent as ArrowDown} from "../svg/ui/arrowDown.svg";
import SearchIcon from "@mui/icons-material/Search";
import InputSection from "./Forms/InputSection";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CachedIcon from '@mui/icons-material/Cached';
import Text from "../visualComponents/Text";

export default function Filters({search, tab, tabs, addDisplayText, onTab, onSearch, onAdd, includeLocation, includeBusiness, includeIndustry, includeSkills, includeEventFilters, includeCircleFilters, includeDate, includeOperatesIn, includeRequestFilters, useRefresh}) {
  const filter = includeLocation || includeCircleFilters || includeEventFilters || includeIndustry || includeSkills || includeBusiness;
  const intl = useIntl();
  const location = useLocation();
  const savedFilter = JSON.parse(localStorage.getItem("filters_"+location.key));
  const [isShowFilter, setIsShowFilter] = React.useState(savedFilter?.value != undefined);
  const emptyInput = {
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: "",
    pageNumber: 1
  };
  const [input, setInput] = React.useState(savedFilter ? savedFilter.value : emptyInput);

  React.useEffect(() => {
    if(!savedFilter && JSON.stringify(input) == JSON.stringify(emptyInput)) //don't save filter if empty
      localStorage.removeItem("filters_"+location.key);
    else {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      localStorage.setItem("filters_"+location.key, JSON.stringify({value: input, date: date, tab: tab}))
    }
    onSearch && onSearch(input);
  }, [input, tab]);

  React.useEffect(() => {
    //apply saved filter
    if(savedFilter?.tab && onTab)
      onTab(null, savedFilter.tab);
    
    //clean expired filters
    let i = 0;
    const maxFilters = 15;
    for (var key in localStorage) {
      if(key.startsWith("filters_") && key != "filters_"+location.key) {
        i++;
        let date = JSON.parse(localStorage.getItem(key))?.date;
        if((date && (new Date(date) - new Date() <= 0)) || i > maxFilters) 
          localStorage.removeItem(key);
        }
    }
  }, []);

  const handleChangeAutoCompleteSkills = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      skills: newvalue.length ? newvalue : null,
    }));
  };

  const onBusinessSelection = (event) => {
    var newBusinessTypes = input["businessType"] ?? [];
    if (event.target.checked) {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        let businessStageAll = masterDataCompanyLevel().map(({ key }) => key);
        newBusinessTypes = businessStageAll;
      } else if (!newBusinessTypes.includes(event.target.name)) {
        newBusinessTypes.push(event.target.name);
      }
    } else {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        newBusinessTypes = [];
      } else {
        removeItemOnce(newBusinessTypes, event.target.name);
      }
    }
    // console.log("newBusinessTypes", newBusinessTypes);
    setInput((prevState) => ({
      ...prevState,
      businessType: newBusinessTypes.length ? newBusinessTypes : null,
    }));
  };
  
  const onIndustrySelection = (event) => {
    var newIndustries = input["industries"] ?? [];
    if (event.target.checked) {
      // console.log("event.target.name", masterDataIndustries());
      if (event.target.name === "Alle") {
        newIndustries = masterDataIndustries();
      } else if (!newIndustries.includes(event.target.name)) {
        newIndustries.push(event.target.name);
        if (
          newIndustries.length === masterDataIndustries().length - 1 &&
          !newIndustries.includes("Alle")
        ) {
          newIndustries.push("Alle");
        }
      }
    } else {
      if (event.target.name === "Alle") {
        newIndustries = [];
      } else {
        removeItemOnce(newIndustries, event.target.name);
        if (newIndustries.includes("Alle")) {
          removeItemOnce(newIndustries, "Alle");
        }
      }
    }
    // console.log("newIndustries", newIndustries);

    setInput((prevState) => ({
      ...prevState,
      industries: newIndustries.length ? newIndustries : null,
    }));
  };


  const onDateSelection = (dates) => {
    var date = new Date(dates)
    var finaldate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'Z'
    setInput((prevState) => ({
      ...prevState,
      date: finaldate.startsWith("1970") ? undefined : finaldate,
    }));
  };

  const isCheckedBusinessStage = (option) => {
    const arrayCollabTypes = input["businessType"] ?? [];
    if (arrayCollabTypes.includes(option)) {
      return true;
    }
    return false;
  };

  const isCheckedAllBusinessStage = () => {
    const arrayBusinessType = input["businessType"] ?? [];
    if (arrayBusinessType.length === 5) {
      return true;
    }
    return false;
  };

  const isCheckedIndustry = (option) => {
    const arrayIndustries = input["industries"] ?? [];
    if (arrayIndustries.includes(option)) {
      return true;
    }
    return false;
  };


  const onReset = () => {
    setIsShowFilter(false);
    setInput(emptyInput);
  }

  const onEventTagSelection = (event) => {
    var newTags = input["eventTags"] ?? [];
    if (event.target.checked) {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        let tagsAll = masterDataEventTags().map(({ key }) => key);
        newTags = tagsAll;
      } else if (!newTags.includes(event.target.name)) {
        newTags.push(event.target.name);
      }
    } else {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        newTags = [];
      } else {
        removeItemOnce(newTags, event.target.name);
      }
    }
    setInput((prevState) => ({
      ...prevState,
      eventTags: newTags.length ? newTags : null,
    }));
  };
  const onEventOperatesInSelection = (event) => {
    var newOperatesIn = input["operatesIn"] ?? [];
    if (event.target.checked) {
      if (!newOperatesIn.includes(event.target.name)) {
        newOperatesIn.push(event.target.name);
      }
    } else {
      removeItemOnce(newOperatesIn, event.target.name);
    }
    setInput((prevState) => ({
      ...prevState,
      operatesIn: newOperatesIn.length ? newOperatesIn : null,
    }));
  };
  const isCheckedEventTag = (option) => {
    const arrayEventTags = input["eventTags"] ?? [];
    if (arrayEventTags.includes(option)) {
      return true;
    }
    return false;
  };

  const isCheckedAllEventTag = () => {
    const arrayEventTags = input["eventTags"] ?? [];
    if (arrayEventTags.length === masterDataEventTags().length) {
      return true;
    }
    return false;
  };
  const isCheckedOperatesIn = (option) => {
    const arrayOperatesIn = input["operatesIn"] ?? [];
    if (arrayOperatesIn.includes(option)) {
      return true;
    }
    return false;
  };

  const onCircleTagSelection = (event) => {
    var newTags = input["selectedCircleTags"] ?? [];
    if (event.target.checked) {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        let tagsAll = masterDataCircleTags().map(({ key }) => key);
        newTags = tagsAll;
      } else if (!newTags.includes(event.target.name)) {
        newTags.push(event.target.name);
      }
    } else {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        newTags = [];
      } else {
        removeItemOnce(newTags, event.target.name);
      }
    }
    setInput((prevState) => ({
      ...prevState,
      selectedCircleTags: newTags.length ? newTags : null,
    }));
  };
  const isCheckedTag = (option) => {
    const arrayCircleTags = input["selectedCircleTags"] ?? [];
    if (arrayCircleTags.includes(option)) {
      return true;
    }
    return false;
  };

  const isCheckedAllTag = () => {
    const arrayCircleTags = input["selectedCircleTags"] ?? [];
    if (arrayCircleTags.length === masterDataCircleTags().length) {
      return true;
    }
    return false;
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container item xs={12} gap={ThemeConstants.custom.spacings.md}>
    <Grid container item xs={12} gap={ThemeConstants.custom.spacings.md}>
      <Grid container item xs={12} gap={ThemeConstants.custom.spacings.sm}>
        {search && <Grid item xs>
          <InputSection 
          value={input.searchInput}
          type="text" 
          color="white"
          textColor="background"
          placeholder={intl.formatMessage({id: "Search_Bar_typeText"})}
          other={{
            iconColor: ThemeConstants.palette.lightPetrol.main,
            InputProps:{
              endAdornment: <SearchIcon/>,
            },
          }}
          onChange={(e) => {
            setInput((prev) => ({...prev, searchInput: e.target.value.length ? e.target.value : null}));}}/>
        </Grid>}
        {filter && 
        <>
        {(input.businessType?.length || input.collaborationType?.length || input.skills?.length || input.industries?.length ||
          input.location != null || input.maxDistance != null || input.location || input.date || input.eventTags || input.operatesIn
          || input.filterType
        ) ?
             <Grid item xs="auto">
               <Button variant="outlined" onClick={onReset} sx={{height: "100%"}}>
                 <FormattedMessage id="Resetten" />
               </Button>
             </Grid>
        :
        <Grid item xs="auto" textAlign="center" alignContent="center">
          <FilterAltIcon onClick={() => setIsShowFilter(!isShowFilter)} color="lightPetrol" fontSize="medium"/>
        </Grid>}</>}

        {useRefresh &&
          <Grid item xs="auto" height="100%" alignContent="center">
            <CachedIcon color="lightPetrol" sx={{ fontSize: 30, cursor: "pointer"}}
              onClick={() => { ClearCache(); window.location.reload(); }}/>
          </Grid>
        }

        {onAdd &&
          <Grid item xs="auto" height="100%" alignContent="center">
            <Hidden mdUp>
              <Icon sx={{ borderRadius: 1, fontSize: 30, cursor: "pointer", color: ThemeConstants.palette.background.main, background: ThemeConstants.palette.lightPetrol.main}}onClick={onAdd}>add</Icon>
            </Hidden>
            <Hidden mdDown>
              <Button variant="contained" onClick={onAdd} sx={{height: "100%"}}>
                <FormattedMessage id={addDisplayText ?? "Add"} />
              </Button>
            </Hidden>
          </Grid>
        }
      </Grid>

      {isShowFilter && <Grid container item xs={12} gap={ThemeConstants.custom.spacings.sm} >
      {includeSkills && <FilterBubble filled={input?.skills?.length} title="Master_TypeSkills">
        <InputSection
          props       = {{sx: {minWidth: "200px"}}}
          type        = "multipleText"
          placeholder = {intl.formatMessage({ id: "Master_TypeSkills" })}
          id          = "skills"
          options     = {masterDataSkills()}
          onChange    = {handleChangeAutoCompleteSkills}
          value       = {input["skills"] ?? []}
        />
      </FilterBubble>
      }

      {includeRequestFilters &&  <FilterBubble filled={input?.filterType} title="filterType">
        <InputSection
          color       = "white"
          textColor       = "background"
          props       = {{mb: 4, sx: {minWidth: "150px", maxHeight: "36px"}}}
          type        = "labeled-select"
          placeholder = {intl.formatMessage({ id: "MoreFilter" })}
          id          = "filterType"
          options     = {[{key: "Beide", value: "BOTH"}, {key: "Aanbod", value: "OFFER"}, {key: "Zoekvraag", value: "REQUEST"}]}
          onChange    = {(e) => setInput({...input, filterType: e.target.value})}
          value       = {input["filterType"] ?? "BOTH"}
        />
      </FilterBubble>
      }

      {includeCircleFilters && <FilterBubble filled={input?.selectedCircleTags?.length} title="Tags">
        <FormGroup>
          <Typography variant="h6" color={"textSecondary"}>
            <FormControlLabel
              control={<Checkbox checked={isCheckedAllTag()} />}
              label={intl.formatMessage({ id: "All" })}
              onChange={onCircleTagSelection}
              name={intl.formatMessage({ id: "All" })}
            />
          </Typography>
          {masterDataCircleTags().map((option, index) => (
            <Typography variant="h6" color={"textSecondary"} key={index}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedTag(option.key)} />}
                label={option.key}
                onChange={onCircleTagSelection}
                name={option.key}
              />
            </Typography>
          ))}
        </FormGroup>
      </FilterBubble>}

      {includeEventFilters && <FilterBubble filled={input?.eventTags?.length} title="Tags">
        <FormGroup>
          <Typography variant="h6" color={"textSecondary"}>
            <FormControlLabel
              control={<Checkbox checked={isCheckedAllEventTag()} />}
              label={intl.formatMessage({ id: "All" })}
              onChange={onEventTagSelection}
              name={intl.formatMessage({ id: "All" })}
            />
          </Typography>
          {masterDataEventTags().map((option, index) => (
            <Typography variant="h6" color={"textSecondary"} key={index}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedEventTag(option.key)} />}
                label={option.key}
                onChange={onEventTagSelection}
                name={option.key}
              />
            </Typography>
          ))}
        </FormGroup>
      </FilterBubble>}

      {includeOperatesIn && <FilterBubble filled={input?.operatesIn?.length} title="Master_SelectBusinessTypes">
        <FormGroup>
          {masterDataCircleOperatesIn().map((option, index) => (
            <Typography variant="h6" color={"textSecondary"} key={index}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedOperatesIn(option.key)} />}
                label={option.key}
                onChange={onEventOperatesInSelection}
                name={option.key}
              />
            </Typography>
          ))}
        </FormGroup>
      </FilterBubble>}

      {includeBusiness && <FilterBubble filled={input?.businessType?.length} title="Master_SelectBusinessTypes">
        <FormGroup>
          <Typography variant="h6" color={"textSecondary"}>
            <FormControlLabel
              control={<Checkbox checked={isCheckedAllBusinessStage()} />}
              label={intl.formatMessage({ id: "All" })}
              onChange={onBusinessSelection}
              name={intl.formatMessage({ id: "All" })}
            />
          </Typography>
          {masterDataCompanyLevel().map((option, index) => (
            <Typography variant="h6" color={"textSecondary"} key={index}>
              <FormControlLabel
                control={
                  <Checkbox checked={isCheckedBusinessStage(option.key)} />
                }
                label={option.key}
                onChange={onBusinessSelection}
                name={option.key}
              />
            </Typography>
          ))}
        </FormGroup>
      </FilterBubble>}

      {includeIndustry && <FilterBubble filled={input?.industries?.length} title="Master_SelectIndustries">
        <FormGroup>
          {masterDataIndustries().map((option, index) => (
            <Typography variant="h6" color={"textSecondary"} key={index}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedIndustry(option)} />}
                label={option}
                onChange={onIndustrySelection}
                name={option}
              />
            </Typography>
          ))}
        </FormGroup>
      </FilterBubble>}

      {includeDate && 
      <FilterBubble filled={input?.date} title="Date">
        <InputSection
          props       = {{mb: 4}}
          type        = "date"
          placeholder = {intl.formatMessage({ id: "Date" })}
          id          = "date"
          onChange    = {onDateSelection}
          value       = {input["date"]}
        />
      </FilterBubble>
      }

      {includeLocation && 
      <FilterBubble filled={input?.location} title="Location">
        <InputSection
          props       = {{mb: 4, sx: {minWidth: "150px"}}}
          type        = "location"
          placeholder = {intl.formatMessage({ id: "Location" })}
          id          = "location"
          onChange    = {(value) => setInput((prevState) => ({...prevState, location: value.description}))}
          value       = {input["location"]}
        />
      </FilterBubble>
      }

      </Grid>}
    </Grid>

    {tabs && <Tabs value={tab} onChange={onTab} variant="scrollable" scrollButtons={false} sx={{
      minHeight: "unset",
    ".MuiTabs-flexContainer": {
      gap: ThemeConstants.custom.spacings.sm,
    },
    ".Mui-selected": {
      color: ThemeConstants.palette.primary.contrastText + " !important",
      fontWeight: 900,
      background: ThemeConstants.palette.lightPetrol.main,
    }}}>
      {tabs.map(tab => (
        <Tab key={tab.key} value={tab.type} label={intl.formatMessage({id: tab.type})} wrapped
        sx={{
          minHeight: "unset",
          padding: "6px 15px",
          borderRadius: "25px",
          color: ThemeConstants.palette.background.contrastText
        }}
        />
      ))}
    </Tabs>}
    </Grid>
    </ThemeProvider>
  );
}

function FilterBubble({children, shortTitle, title, description, filled}) {
  const [show, setShow] = React.useState(false);
  const filterProps = {container: true, item: true, xs: "auto", 
    gap : ThemeConstants.custom.spacings.md, 
    sx  : {
      //border: filled ? "solid 1px " + ThemeConstants.palette.lightPetrol.main : undefined,
      maxWidth: "350px",
      height: "fit-content",
      position: "relative",
      background: filled ? ThemeConstants.palette.lightPetrol.main : ThemeConstants.palette.white.main, 
      borderRadius: "25px",
      "rect": {
        fill: ThemeConstants.palette[filled ? "primary" : "background"].contrastText + " !important",
        stroke: ThemeConstants.palette[filled ? "primary" : "background"].contrastText + " !important"
      }
    }, 
    };

  return <Grid {...filterProps}>
    <Text id={shortTitle ?? title} bold={filled} color={filled ? "primary" : "background"} onClick={() => setShow(!show)} sx={{cursor: "pointer", userSelect: "none"}} gridProps={{
      px  : ThemeConstants.custom.spacings.ml, 
      py  : ThemeConstants.custom.spacings.sm
    }}><ArrowDown height={"12px"} stroke={ThemeConstants.palette.background.contrastText} style={{
      transition: "all 0.5s",
      rotate: show? "180deg" : "0deg",
      marginLeft: "10px", 
      marginBottom: "-2px"}}/></Text>
    {show && 
    <ClickAwayListener onClickAway={() => setShow(false)}>
    <Grid container p={ThemeConstants.custom.spacings.ml} gap={ThemeConstants.custom.spacings.md} sx={{
      width: "max-content",
      maxWidth: "min(500px, 90vw)",
      overflowX: "hidden",
      overflowY: "scroll",
      //scrollbarWidth: "none",
      maxHeight: "400px",
      top: "125%",
      left: "0",
      zIndex: 10,
      position: "absolute",
      background: ThemeConstants.palette.white.main, 
      borderRadius: "5px"
    }}>
      {title       && <Text id={title} color="background" bold item xs={12}/>}
      {description && <Text id={title} color="description"     item xs={12} variant="body2"/>}
      {children}
    </Grid></ClickAwayListener>}
  </Grid>;
}