import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { CardMedia } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getLongDateWithYear,
  get24Time,
} from "../../apiServices/CommonMethods";
import EventCardStyle from "../../styles/component/Cards/EventCardStyle";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";

const useStyles = makeStyles(EventCardStyle);

export default function EventCard(props) {
  const classes = useStyles();
  const intl = useIntl();

  // console.log(props, "EventProps");
  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container className={classes.card}>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <CardMedia
            component="img"
            image={props.eventData.logo ?? "/assets/img/mastermatch_event_default.png"}
            alt="logo"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={10} sm={10} px={5}>
          <Text my={3} variant="h1" color="white" bold>
            {props.eventData.title}
          </Text>
          <Text
            variant="body1" mb={5}
            color="lighterPetrol"
            gutterBottom
          >
            {/* date */}
            <b><FormattedMessage id="date_"/>: </b>
            {props.eventData.startDate
              ? getLongDateWithYear(props.eventData.startDate) +
              ", " +
              get24Time(props.eventData.startDate) +
              " - " +
              get24Time(props.eventData.endDate)
              : ""}

            {/* location */}
            &emsp;<b><FormattedMessage id="Location"/>: </b>
            {(props.eventData.location && props.eventData.operatesInDesc !== "Online" ? intl.formatMessage({ id: "circleSettings_Location" }) + " " + props.eventData.location
              : "") +
              (props.eventData.location &&
                props.eventData.operatesInDesc &&
                props.eventData.operatesInDesc.length && props.eventData.operatesInDesc !== "Online"
                ? " / "
                : "") +
              (props.eventData &&
                props.eventData.operatesInDesc &&
                props.eventData.operatesInDesc.length
                ? props.eventData.operatesInDesc
                : "")}
          </Text>
          {(props.eventData.tagsDesc ? props.eventData.tagsDesc : []).map(
            (tag) => (
              <Chip
                key={tag}
                size="small"
                label={tag}
                color="background"
                className={classes.chipItem}
              />
            )
          )}
        </Grid>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
      <Grid item sm={12} p={5}>
        <Text
          variant="body2"
          color="white"
        >
          {props.eventData.shortDescription
            ? props.eventData.shortDescription
            : ""}
        </Text>
      </Grid>
    </Grid>
    </ThemeProvider>
  );
}
