
const EditCollaborationOfInterestStyle = (theme) => ({
    p7: {
        marginTop: 7,
    },
    p7c: {
        marginTop: 7,
        color: "#828282",
    },
    p15: {
        marginTop: 15,
    },
    p25: {
        marginTop: 25,
    },
    Select: {
        width: "100%",
        marginTop: 7,
    },
    btxt: {
        marginTop: 15,
        color: "#72B6CD"
    },
    submit: {
        color: "#fff",
    },
});
export default EditCollaborationOfInterestStyle;