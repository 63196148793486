import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button, CssBaseline, Hidden, TextField } from "@mui/material";
import { Alert, Skeleton, ThemeProvider } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import Background from "../../visualComponents/Background";


export default function FormContainer({color, props, modalMessage, onModalClose, error, title, description, loading, onSave, onCancel, disabled, children, saveId, cancelId, saveColor, hint, noFullscreen}) {
  return (
    <ThemeProvider theme={Theme_WIP}>
    {!noFullscreen && <Hidden smUp><Background page color={color ?? "white"} /></Hidden>}
    <Grid mt={{xs: 7, sm: 16}} {...props} mb={25} container gap={ThemeConstants.custom.spacings.md} justifyContent="center">
        <Grid item sm={12} xs={12}>
            {/* MODALS & NOTIFICATIONS */}
            {modalMessage &&
                <SuccessAlertWithAction
                open        = {modalMessage != undefined && modalMessage != "" && modalMessage != null}
                message     = {modalMessage}
                handleClose = {onModalClose}
                />
            }
            {error && <Alert severity={"error"}>{error}</Alert>}
        </Grid>
        <Background color={color ?? "white"} container item border="bottomLeft" p={{xs: "20px", md: ThemeConstants.custom.spacings.lg}} maxWidth="sm" columnGap={ThemeConstants.custom.spacings.md} rowGap={ThemeConstants.custom.spacings.lg}>
        {title       && <Text variant="h1" color={color ?? "background"} bold item xs={12}>{title      }</Text>}
        {description && <Text variant="h2" color={color ?? "background"}      item xs={12} mt={title? "-10px" : undefined}>{description}</Text>}
        {children}

        {onSave && (loading ? (
            <Skeleton variant="rectangular" height={40} width={60} />
        ) : 
            <Button variant="contained" color={saveColor ?? "primary"} onClick={onSave} disabled={disabled}>
            <FormattedMessage id={saveId ?? "Button_Text_Start"} />
            </Button>
        )}
        {onCancel && <Button variant="contained" color="white" onClick={onCancel}>
            <FormattedMessage id={cancelId ?? "Button_Text_DeleteEvent"} />
        </Button>}

        {hint && <Text item
          variant="body2"
          color={color}>{hint}</Text>}
        </Background>
    </Grid>
    </ThemeProvider>
  );
}
