import { tabStyle } from "./CommonStyle"

const CirclesPageStyle = (theme) => ({
    ...tabStyle,
    logo: {
        width: "100%",
    },
    mt5: {
        marginTop: "5px",
    },
    p7: {
        marginTop: 7,
    },
    mt10: {
        marginTop: "10px",
    },
    iconSearch: {
        position: 'relative',
        right: 50,
        top: 15,
        color: theme.palette.color5.main,
    },
    circleBg: {
        backgroundColor: theme.palette.secondary.main,
        paddingBottom: 20,
        color: "#fff",
        paddingTop: 20,
        marginTop: -5
    },
    tabcontent: {
        margin: "0px 0 15px 0",
        padding: "10px !important",
    },
    iconAdd: {
        backgroundColor: "#006080",
        fontSize: 40,
        color: "#fff",
        borderRadius: "50%",
        position: 'relative',
        top: 7,
    },
    iconAddAdmin: {
        backgroundColor: "white",
        fontSize: 40,
        color: "#828282",
        borderRadius: "50%",
        position: 'relative',
        top: 7,
        border: "1px solid #828282",
    },
    chip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5
    },
    iconFilter: {
        position: 'relative',
        top: 7,
        color: theme.palette.color5.main,
    },
    buttonLink: {
        boxShadow: 'none',
        textTransform: 'none',
        backgroundColor: "#006080",
        borderRadius: 5,
        color: "#ffff",
        width: "100%",
        fontFamily: [
            'Helvetica',
        ].join(','),
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: "#faa32f",
        },
    },
    iconReset: {
        marginTop: "12px",
        padding: "0 !important",
        color: theme.palette.color5.main,

    },
});
export default CirclesPageStyle;