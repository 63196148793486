const EventSettingsStyle = (theme) => ({
  root: {
    width: "100%",
    marginBottom: 50
  },
  p7: {
    marginTop: 7,
  },
  p15: {
    marginTop: 15,
  },
  m20: {
    marginTop: 20,
  },
  // txtarea: {
  //   color: "#828282",
  //   margin: "0px !important",
  //   width: "50% !important",
  //   "@media (max-width: 500px)": {
  //     width: "100% !important",
  //   },
  // },
  ml4: {
    marginLeft: 4,
  },
  // submit: {
  //   color: "#fff",
  // },
  tabcontent: {
    margin: "8px 0 15px 0",
    padding: "10px !important",
  },
  button: {
    width: "100%",
  },
  submit: {
    marginBottom: 20,
    color: "#fff",
    fontSize: 13,
    padding: "6px 0 6px 0",
  },
  mt7: {
    marginTop: "7px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  mt30: {
    marginTop: "30px",
  },
  mb10: {
    marginBottom: "10px",
  },
  closeicon: {
    float: "right",
    fontSize: 32,
    color: "#bdbdbd",

  },
  Select: {
    width: "100%",
  },
  txtarea: {
    width: "100%",
    border: "none",
    backgroundColor: "#e8e8e8",
    borderRadius: 6,
    padding: "2%",
  },
  btxt: {
    color: "#72B6CD",
  },
  chip: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  chipItem: {
    borderRadius: 5,
  },
  mt10Orange: {
    marginTop: "10px",
    color: "#006080",
  },
  pl13: {
    paddingLeft: 13,
  },
});

export default EventSettingsStyle;
