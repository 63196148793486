
const onboardingPageStyle = (theme) => ({
    container: {
        marginTop: 150
    },
    p3: {
        marginTop: 3,
    },
    p15: {
        marginTop: 15,
    },
    margin: {
        marginRight: 4,
        color: "#828282",
    },
    P15L35: {
        marginTop: 15,
        paddingLeft: 35,
        '@media (max-width: 500px)': {
            paddingLeft: 0,
        },
    },
    P20L35: {
        marginTop: 20,
        paddingLeft: 35,
    },
    P25L35: {
        marginTop: 25,
        paddingLeft: 35,
    },
    typoTextAlign: {
        textAlign: 'center',
        marginTop: 50,
    },

    p60: {
        marginTop: 60,
    },
    submit: {
        color: "#fff",
        width: "70%",
    },
    P35L35: {
        marginTop: 35,
        paddingLeft: 35,
    },
    rightimg: {
        width: "250px",
        marginTop: "100px",
        '@media (max-width: 500px)': {
            display: 'none',
        },
    },
    onboardingimg: {
        width: "100%",
        paddingTop: 50,
    },
    stepNo: {
        backgroundColor: theme.palette.color2.main,
        color: '#fff',
        marginRight: 10,
        borderRadius: 4,
        padding: "5px 7px 3px 7px",
    },
});
export default onboardingPageStyle;