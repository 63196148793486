//TODO: This file is absolutely in-navigable... Please split this up in a manner which makes sense
import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

//See Mutations/Tasks.jsx for an example how we utilize this to take GraphQL bloat code out of our Page components
export function useMutationBase(mutation, props) { 
  const [started, setStarted]              = useState   (false);
  const [output,  setOutput]               = useState   ({data: null, loading: true, error: null, mutate: () => {}});
  const [Update, { data, loading, error }] = useMutation(mutation, { ...props });

  useEffect(() => {
      if(!started) {
        setStarted(true);
        if(props?.noCallOnStart == null || !props.noCallOnStart)
          Update();
      }
      setOutput({data: data, loading: loading, error: error, mutate: Update});
  }, [loading]);

  return output;
}

export const UPDATE_FEED_CHECK = gql`
  mutation update {
    updateFeedcheck
  }
`;

export const REGISTER_WITH_EMAIL = gql`
  mutation registerUser(
    $givenName: String!
    $familyName: String!
    $email: String!
    $password: String!
    $phone: String!
    $inviteId: String
  ) {
    registerUser(
      _userInput: {
        givenName: $givenName
        familyName: $familyName
        email: $email
        password: $password
        phone: $phone
        inviteId: $inviteId
      }
    ) {
      errorCode
      message
      result {
        email
        familyName
        givenName
        id
        phone
        userId
        accessToken
        userRole
      }
    }
  }
`;

export const REGISTER_WITH_GOOGLE = gql`
  mutation registerGoogleUser(
    $providerUserId: String!
    $email: String!
    $givenName: String!
    $familyName: String!
    $inviteId: String
  ) {
    registerGoogleUser(
      _googlelUserInput: {
        providerUserId: $providerUserId
        email: $email
        givenName: $givenName
        familyName: $familyName
        inviteId: $inviteId
      }
    ) {
      errorCode
      message
      result {
        email
        familyName
        givenName
        id
        phone
        providerUserID
        accessToken
        userId
        userRole
      }
    }
  }
`;

export const REGISTER_EXTERNALUSER_LINKEDIN = gql`
  mutation registerLinkedInUser($linkedInUserCode: String, $inviteId: String) {
    registerLinkedInUser(
      _linkedInUser: { linkedInUserCode: $linkedInUserCode, inviteId: $inviteId }
    ) {
      errorCode
      message
      result {
        id
        email
        familyName
        givenName
        phone
        accessToken
        userId
        userRole
      }
    }
  }
`;

export const UPDATE_PROFILEINFO = gql`
  mutation updateAccountSettings(
    $userId: String!
    $givenName: String!
    $familyName: String!
  ) {
    updateAccountSettings(
      accountSettings: {
        familyName: $familyName
        givenName: $givenName
        userId: $userId
      }
    ) {
      errorCode
      message
      result {
        familyName
        givenName
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $userId: String!
    $currentPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      _changePasswordInput: {
        userId: $userId
        currentPassword: $currentPassword
        newPassword: $newPassword
      }
    ) {
      errorCode
      message
    }
  }
`;
export const EDIT_PROFILEHEADER = gql`
  mutation updateProfileHeader(
    $userId: String!
    $givenName: String!
    $familyName: String!
    $description: String!
    $role: String!
    $company: String!
    $location: String!
    $locationPlaceID: String
  ) {
    updateProfileHeader(
      profileHeader: {
        userId: $userId
        givenName: $givenName
        familyName: $familyName
        description: $description
        role: $role
        company: $company
        locationPlaceID: $locationPlaceID
        location: $location
      }
    ) {
      errorCode
      message
      result {
        company
        description
        familyName
        givenName
        country
        locationPlaceID
        profilePictureUrl
        role
        userId
      }
    }
  }
`;

export const UPDATE_PROFILE_BIO = gql`
  mutation updateAboutMe(
    $userId: String
    $personalLife: [String]
    $serivcesForClients: String
    $servicesForNetwork: String
    $visionIn5Years: String
    $portfolio: String
    $professionalLife: [String]
    $expertiseLevel: String
    $skills: [String]
    $languages: [String]
    $pastClients: [String]
    $networks: [String]
  ) {
    updateAboutMe(
      aboutMe: {
        userId: $userId
        personalLife: $personalLife
        serivcesForClients: $serivcesForClients
        servicesForNetwork: $servicesForNetwork
        visionIn5Years: $visionIn5Years
        portfolio: $portfolio
        professionalLife: $professionalLife
        expertiseLevel: $expertiseLevel
        skills: $skills
        languages: $languages
        pastClients: $pastClients
        networks: $networks
      }
    ) {
      errorCode
      message
      result {
        userId
        expertiseLevel
        languages
        networks
        pastClients
        personalLife
        professionalLife
        serivcesForClients
        servicesForNetwork
        skills
        visionIn5Years
        portfolio
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $userId: String
    $name: String
    $companyLevel: String
    $companyRole: String
    $includedInCompanySince: Int
    $keyValues: [String]
    $mission: String
    $incubatorOrAccelerator: String
    $certifications: [String]
    $numberOfEmployees: Int
    $annualRevenue: Float
    $workfield: String
    $website: String
    $companyId: Int
    $industry: [String]
  ) {
    updateCompany(
      company: {
        userId: $userId
        name: $name
        companyLevel: $companyLevel
        companyRole: $companyRole
        includedInCompanySince: $includedInCompanySince
        keyValues: $keyValues
        mission: $mission
        incubatorOrAccelerator: $incubatorOrAccelerator
        certifications: $certifications
        numberOfEmployees: $numberOfEmployees
        annualRevenue: $annualRevenue
        workfield: $workfield
        website: $website
        companyId: $companyId
        industry: $industry
      }
    ) {
      errorCode
      message
      result {
        name
        companyLevel
        companyRole
        incubatorOrAccelerator
        includedInCompanySince
        workfield
        numberOfEmployees
        annualRevenue
        certifications
        keyValues
        mission
        website
        companyId
        industry
      }
    }
  }
`;

export const SAVE_BUSINESS_PARTNER_COLLAB = gql`
  mutation saveBusinessPartnerCollaborationInterest(
    $userId: String
    $interestedIn: String
  ) {
    saveBusinessPartnerCollaborationInterest(
      businessPartnerCollaborationInterestRequest: {
        userId: $userId
        interestedIn: $interestedIn
      }
    ) {
      errorCode
      message
      result {
        interestedIn
      }
    }
  }
`;
export const SAVE_COFOUNDING_COLLAB = gql`
  mutation saveCoFoundingCollaborationInterest(
    $userId: String!
    $businessOpportunityDescription: String
    $industries: [String]
    $businessStage: [String]
    $location: String!
    $commitment: [String]
    $skills: [String]
    $preferredCompanyRole: String
    $isAvailable: String
    $sweatCommitmentInHours: Int
  ) {
    saveCoFoundingCollaborationInterest(
      coFoundingCollaborationInterestRequest: {
        userId: $userId
        businessOpportunityDescription: $businessOpportunityDescription
        industries: $industries
        businessStage: $businessStage
        location: $location
        commitment: $commitment
        skills: $skills
        isAvailable: $isAvailable
        preferredCompanyRole: $preferredCompanyRole
        sweatCommitmentInHours: $sweatCommitmentInHours
      }
    ) {
      errorCode
      message
      result {
        commitment
        industries
        isAvailable
        location
        preferredCompanyRole
        skills
        sweatCommitmentInHours
        businessOpportunityDescription
        businessStage
      }
    }
  }
`;

export const SAVE_INVESTMENT_COLLAB = gql`
  mutation saveInvestmentCollaborationInterest(
    $userId: String
    $commitment: [String]
    $industries: [String]
    $interestedIn: String
    $location: String
    $companyStage: [String]
    $investmentCollective: String
    $investmentType: [String]
    $investorType: [String]
    $preferredCompanyRole: String
  ) {
    saveInvestmentCollaborationInterest(
      investmentCollaboration: {
        userId: $userId
        commitment: $commitment
        industries: $industries
        interestedIn: $interestedIn
        location: $location
        companyStage: $companyStage
        investmentCollective: $investmentCollective
        investmentType: $investmentType
        investorType: $investorType
        preferredCompanyRole: $preferredCompanyRole
      }
    ) {
      errorCode
      message
      result {
        commitment
        industries
        interestedIn
        investmentCollective
        investmentType
        investorType
        location
        preferredCompanyRole
      }
    }
  }
`;

export const SAVE_MENTORING_COLLAB = gql`
  mutation saveMentoringCollaborationInterest(
    $userId: String
    $industries: [String]
    $interestedIn: String
    $isAvailable: String
    $skills: [String]
    $myPartOfThePartnership: String
  ) {
    saveMentoringCollaborationInterest(
      mentoringCollaborationInterest: {
        userId: $userId
        industries: $industries
        interestedIn: $interestedIn
        isAvailable: $isAvailable
        skills: $skills
        myPartOfThePartnership: $myPartOfThePartnership
      }
    ) {
      errorCode
      message
      result {
        industries
        interestedIn
        isAvailable
        myPartOfThePartnership
        skills
      }
    }
  }
`;

export const SAVE_PROJECT_PARTNER_COLLAB = gql`
  mutation saveProjectPartnerCollaborationInterest(
    $userId: String!
    $averageDurationInMonths: Int
    $industries: [String]
    $interestedIn: String!
    $location: String!
    $myPartOfThePartnership: String!
  ) {
    saveProjectPartnerCollaborationInterest(
      projectPartnerCollaborationInterestRequest: {
        userId: $userId
        averageDurationInMonths: $averageDurationInMonths
        industries: $industries
        interestedIn: $interestedIn
        location: $location
        myPartOfThePartnership: $myPartOfThePartnership
      }
    ) {
      errorCode
      message
      result {
        industries
        averageDurationInMonths
        interestedIn
        location
        myPartOfThePartnership
      }
    }
  }
`;

export const SAVE_STRATEGIC_PARTNER_COLLAB = gql`
  mutation saveStrategicPartnerCollaborationInterest(
    $userId: String!
    $myPartOfThePartnership: String!
    $industries: [String]
    $interestedIn: String!
    $location: String!
    $preferredBenefits: String
    $skills: [String]
  ) {
    saveStrategicPartnerCollaborationInterest(
      strategicPartnerCollaborationInterestRequest: {
        userId: $userId
        myPartOfThePartnership: $myPartOfThePartnership
        industries: $industries
        interestedIn: $interestedIn
        location: $location
        preferredBenefits: $preferredBenefits
        skills: $skills
      }
    ) {
      errorCode
      message
      result {
        industries
        interestedIn
        location
        myPartOfThePartnership
        preferredBenefits
        skills
      }
    }
  }
`;

export const UPDATE_PROFILE_IMAGE = gql`
  mutation saveProfileImage($userID: String!, $profileImg: String!) {
    saveProfileImage(request: { userID: $userID, profileImg: $profileImg }) {
      userId
      profilePictureUrl
    }
  }
`;

export const CREATE_INTRODUCTION = gql`
  mutation createIndroductionActivity(
    $recipientUserProfileIds: [String]
    $messages: String!
    $requesterUserProfileId: String
    $activitySource: String
    $activitySourceId: String
    $recipientEmails:[String]
    $marker: MarkersInput
  ) {
    createIndroductionActivity(
      request: {
        recipientUserProfileIds: $recipientUserProfileIds
        messages: $messages
        requesterUserProfileId: $requesterUserProfileId
        activitySource: $activitySource
        activitySourceId: $activitySourceId
        recipientEmails:$recipientEmails
        marker: $marker
      }
    ) {
      errorCode
      message
      result {
        recipientUserProfileIds
        requesterUserProfileId
      }
    }
  }
`;

export const CREATE_MATCHREQUEST = gql`
  mutation sendMatchRequest(
    $requesterUserProfileID: String
    $recipientUserProfileID: String
    $requestType: String
    $message: String
    $isKnownPerson: Boolean
  ) {
    sendMatchRequest(
      request: {
        requesterUserProfileID: $requesterUserProfileID
        recipientUserProfileID: $recipientUserProfileID
        requestType: $requestType
        message: $message
        isKnownPerson: $isKnownPerson
      }
    ) {
      errorCode
      message
    }
  }
`;

export const SAVE_BUSINESS_PARTNER_REQUEST = gql`
  mutation saveBusinessPartnerCollaborationRequest(
    $userId: String!
    $titleRequest: String!
    $commitment: [String]
    $industries: [String]
    $location: String!
    $partnershipDescription: String!
    $skills: [String]
    $companyRole: String!
    $benefitType: [String]
    $selectedToWhomIds: [String]
    $id: String
    $locationPlaceID: String
  ) {
    saveBusinessPartnerCollaborationRequest(
      businessPartnerCollaborationRequest: {
        userId: $userId
        titleRequest: $titleRequest
        commitment: $commitment
        industries: $industries
        partnershipDescription: $partnershipDescription
        location: $location
        benefitType: $benefitType
        skills: $skills
        companyRole: $companyRole
        selectedToWhomIds: $selectedToWhomIds
        id: $id
        locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        commitment
        id
        industries
        location
        partnershipDescription
        preferredBenefitType
        preferredCompanyRole
        skills
        title
        userId
      }
    }
  }
`;

export const SAVE_COFOUNDING_REQUEST = gql`
  mutation saveCoFoundingCollaborationRequest(
    $userId: String
    $titleRequest: String
    $commitment: [String]
    $industries: [String]
    $location: String
    $businessStage: [String]
    $skills: [String]
    $businessOpportunityDescription: String
    $sweatCommitmentInHours: Int
    $preferredCompanyRole: String
    $selectedToWhomIds: [String]
    $id: String
    $locationPlaceID: String
  ) {
    saveCoFoundingCollaborationRequest(
      coFoundingCollaborationRequest: {
        userId: $userId
        commitment: $commitment
        industries: $industries
        businessStage: $businessStage
        location: $location
        businessOpportunityDescription: $businessOpportunityDescription
        skills: $skills
        preferredCompanyRole: $preferredCompanyRole
        sweatCommitmentInHours: $sweatCommitmentInHours
        selectedToWhomIds: $selectedToWhomIds
        titleRequest: $titleRequest
        id: $id
         locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        businessStage
        commitment
        id
        industries
        location
        partnershipDescription
        preferredCompanyRole
        skills
        sweatCommitmentInHours
        title
        userId
      }
    }
  }
`;

export const SAVE_INVESTMENT_REQUEST = gql`
  mutation saveInvestmentCollaborationRequest(
    $userId: String!
    $titleRequest: String!
    $industries: [String]
    $location: String!
    $businessDescription: String!
    $selectedToWhomIds: [String]
    $id: String
    $locationPlaceID: String
  ) {
    saveInvestmentCollaborationRequest(
      investmentCollaborationRequest: {
        userId: $userId
        industries: $industries
        businessDescription: $businessDescription
        location: $location
        selectedToWhomIds: $selectedToWhomIds
        titleRequest: $titleRequest
        id: $id
        locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        userId
      }
    }
  }
`;

export const SAVE_PROJECT_PARTNERS_REQUEST = gql`
  mutation saveProjectPartnerCollaborationRequest(
    $userId: String!
    $titleRequest: String!
    $averageDurationInMonths: Int
    $industries: [String]
    $location: String!
    $hoursPerWeek: Int
    $projectDescription: String!
    $skills: [String]
    $selectedToWhomIds: [String]
    $startDate: DateTime
    $id: String
    $locationPlaceID: String
  ) {
    saveProjectPartnerCollaborationRequest(
      projectPartnerCollaborationRequest: {
        userId: $userId
        averageDurationInMonths: $averageDurationInMonths
        industries: $industries
        hoursPerWeek: $hoursPerWeek
        location: $location
        projectDescription: $projectDescription
        skills: $skills
        selectedToWhomIds: $selectedToWhomIds
        titleRequest: $titleRequest
        startDate: $startDate
        id: $id
        locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        averageDurationInMonths
        hoursPerWeek
        industries
        location
        projectDescription
        skills
        startDate
        title
        userId
      }
    }
  }
`;

export const SAVE_STRATEGIC_PARTNERS_REQUEST = gql`
  mutation saveStrategicPartnerCollaborationRequest(
    $userId: String
    $titleRequest: String
    $myPartOfThePartnership: String
    $industries: [String]
    $location: String
    $partnershipDescription: String
    $skills: [String]
    $selectedToWhomIds: [String]
    $id: String
    $locationPlaceID: String
  ) {
    saveStrategicPartnerCollaborationRequest(
      strategicPartnerCollaborationRequest: {
        userId: $userId
        myPartOfThePartnership: $myPartOfThePartnership
        industries: $industries
        partnershipDescription: $partnershipDescription
        location: $location
        skills: $skills
        selectedToWhomIds: $selectedToWhomIds
        titleRequest: $titleRequest
        id: $id
        locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        userId
      }
    }
  }
`;

export const SAVE_MENTORING_REQUEST = gql`
  mutation saveMentoringCollaborationRequest(
    $userId: String!
    $titleRequest: String!
    $myChallenges: String!
    $industries: [String]
    $preferredFieldOfKnowledge: [String]
    $wishFromMentor: String!
    $selectedToWhomIds: [String]
    $id: String
  ) {
    saveMentoringCollaborationRequest(
      mentoringCollaborationRequest: {
        userId: $userId
        titleRequest: $titleRequest
        industries: $industries
        myChallenges: $myChallenges
        preferredFieldOfKnowledge: $preferredFieldOfKnowledge
        wishFromMentor: $wishFromMentor
        selectedToWhomIds: $selectedToWhomIds
        id: $id
      }
    ) {
      errorCode
      message
      result {
        id
        industries
        myChallenges
        preferredFieldOfKnowledge
        title
        userId
        wishFromMentor
      }
    }
  }
`;

export const SAVE_BRAINSTORMING_PARTNERS_REQUEST = gql`
  mutation saveBrainstormCollaborationRequest(
    $userId: String
    $titleRequest: String
    $partnershipDescription: String
    $skills: [String]
    $location: String
    $entrepreneurshipLevel: [String]
    $expertiseLevel: [String]
    $frequencyInMonths: Float
    $selectedToWhomIds: [String]
    $id: String
    $locationPlaceID: String
  ) {
    saveBrainstormCollaborationRequest(
      brainstormCollaborationRequest: {
        userId: $userId
        titleRequest: $titleRequest
        partnershipDescription: $partnershipDescription
        skills: $skills
        location: $location
        entrepreneurshipLevel: $entrepreneurshipLevel
        expertiseLevel: $expertiseLevel
        frequencyInMonths: $frequencyInMonths
        selectedToWhomIds: $selectedToWhomIds
        id: $id
         locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        userId
      }
    }
  }
`;

export const SAVE_COWORKING_REQUEST = gql`
  mutation saveCoworkingDateCollaborationRequest(
    $userId: String
    $titleRequest: String
    $skills: [String]
    $location: String
    $period: [String]
    $days: [String]
    $dateDescription: String
    $selectedToWhomIds: [String]
    $id: String
     $locationPlaceID: String
  ) {
    saveCoworkingDateCollaborationRequest(
      coworkingDateCollaborationRequest: {
        userId: $userId
        titleRequest: $titleRequest
        skills: $skills
        location: $location
        period: $period
        days: $days
        dateDescription: $dateDescription
        selectedToWhomIds: $selectedToWhomIds
        id: $id
        locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        userId
      }
    }
  }
`;

export const SAVE_EXPERT_REQUEST = gql`
  mutation saveExpertCollaborationRequest(
    $userId: String!
    $titleRequest: String!
    $skills: [String]
    $location: String!
    $industries: [String]
    $jobDescription: String!
    $durationJobInMonths: Float
    $hoursPerWeek: Float
    $selectedToWhomIds: [String]
    $startDate: DateTime
    $id: String
    $locationPlaceID: String
  ) {
    saveExpertCollaborationRequest(
      expertCollaborationRequest: {
        userId: $userId
        titleRequest: $titleRequest
        skills: $skills
        location: $location
        industries: $industries
        jobDescription: $jobDescription
        durationJobInMonths: $durationJobInMonths
        hoursPerWeek: $hoursPerWeek
        selectedToWhomIds: $selectedToWhomIds
        startDate: $startDate
        id: $id
        locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        userId
      }
    }
  }
`;

export const SAVE_MASTERMIND_REQUEST = gql`
  mutation saveMastermindCollaborationRequest(
    $userId: String!
    $titleRequest: String!
    $mastermindDescription: String
    $request: CollaborationDetailInput
    $offer: CollaborationDetailInput
    $selectedToWhomIds: [String!]!
    $id: String
    $collaborationCategoryId: String
    $marker: MarkersInput
  ) {
    saveMastermindCollaborationRequest(
      mastermindPartnerCollaborationRequest: {
        userId: $userId
        collaborationCategoryId: $collaborationCategoryId
        titleRequest: $titleRequest
        mastermindDescription: $mastermindDescription
        selectedToWhomIds: $selectedToWhomIds
        id: $id
        request: $request
        offer: $offer
        marker: $marker
      }
    ) {
      errorCode
      message
      result {
        userId
      }
    }
  }
`;

export const SAVE_MASTERSOFMASTERS_REQUEST = gql`
  mutation saveMastersForMastersCollaborationRequest(
    $userId: String!
    $titleRequest: String!
    $location: String!
    $preferredSkills: [String]
    $offeredSkills: [String]
    $collaborationDescription: String
    $industries: [String]
    $durationInMonths: Float
    $estimatedHours: Float
    $selectedToWhomIds: [String]
    $startDate: DateTime
    $id: String
    $locationPlaceID: String
  ) {
    saveMastersForMastersCollaborationRequest(
      mastersForMastersPartnerCollaborationRequest: {
        userId: $userId
        titleRequest: $titleRequest
        preferredSkills: $preferredSkills
        offeredSkills: $offeredSkills
        collaborationDescription: $collaborationDescription
        location: $location
        industries: $industries
        durationInMonths: $durationInMonths
        estimatedHours: $estimatedHours
        selectedToWhomIds: $selectedToWhomIds
        startDate: $startDate
        id: $id
        locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        userId
      }
    }
  }
`;

export const ACCEPT_MATCH_REQUEST = gql`
  mutation acceptMatchRequestActivity($activityId: String) {
    acceptMatchRequestActivity(request: { activityId: $activityId }) {
      errorCode
      message
    }
  }
`;

export const DECLINE_MATCH_REQUEST = gql`
  mutation declineMatchRequestActivity($activityId: String) {
    declineMatchRequestActivity(request: { activityId: $activityId }) {
      errorCode
      message
    }
  }
`;

export const MARK_AS_DONE_MATCH_REQUEST = gql`
  mutation doneActivity($activityType: String, $activityId: String) {
    doneActivity(
      request: { activityType: $activityType, activityId: $activityId }
    ) {
      errorCode
      message
    }
  }
`;

export const MARK_AS_DONE_WITH_USERID = gql`
  mutation doneActivity($activityType: String, $activityId: String,$loggedInUserId: String ) {
    doneActivity(
      request: { activityType: $activityType, activityId: $activityId ,loggedInUserId:$loggedInUserId}
    ) {
      errorCode
      message
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessageActivity(
    $recipientUserProfileIds: [String]
    $requesterUserProfileId: String!
    $messages: String
    $activitySource: String
    $activitySourceId: String
    $marker: MarkersInput
  ) {
    sendMessageActivity(
      request: {
        requesterUserProfileId: $requesterUserProfileId
        recipientUserProfileIds: $recipientUserProfileIds
        messages: $messages
        activitySource: $activitySource
        activitySourceId: $activitySourceId
        marker: $marker
      }
    ) {
      errorCode
      message
    }
  }
`;

export const SEND_THANKYOU = gql`
  mutation sendThankYouActivity(
    $recipientUserProfileIds: [String]
    $requesterUserProfileId: String!
    $messages: String
    $activitySource: String
    $activitySourceId: String,
    $rating:Int

  ) {
    sendThankYouActivity(
      request: {
        requesterUserProfileId: $requesterUserProfileId
        recipientUserProfileIds: $recipientUserProfileIds
        messages: $messages
        activitySource: $activitySource
        activitySourceId: $activitySourceId,
        rating:$rating
      }
    ) {
      errorCode
      message
    }
  }
`;

export const RESPOND_TO_REQUEST = gql`
  mutation sendResponseToActivityActivity(
    $recipientUserProfileIds: [String]
    $requesterUserProfileId: String!
    $messages: String
    $activitySource: String
    $activitySourceId: String
    $collaborationType: String
    $marker: MarkersInput
  ) {
    sendResponseToActivityActivity(
      request: {
        requesterUserProfileId: $requesterUserProfileId
        recipientUserProfileIds: $recipientUserProfileIds
        messages: $messages
        activitySource: $activitySource
        activitySourceId: $activitySourceId
        collaborationType: $collaborationType
        marker: $marker
      }
    ) {
      errorCode
      message
    }
  }
`;

export const SUGGEST_ONE_ON_ONE = gql`
  mutation createSuggestionActivity(
    $recipientUserProfileIds: [String]
    $requesterUserProfileId: String!
    $messages: String
    $activitySource: String
    $activitySourceId: String
    $meetings: [SuggestMeetingRequestInput]
    $marker: MarkersInput
  ) {
    createSuggestionActivity(
      request: {
        requesterUserProfileId: $requesterUserProfileId
        recipientUserProfileIds: $recipientUserProfileIds
        messages: $messages
        activitySource: $activitySource
        activitySourceId: $activitySourceId
        meetings: $meetings
        marker: $marker
      }
    ) {
      errorCode
      message
    }
  }
`;

export const CANCEL_AND_SUGGEST_ONE_ON_ONE = gql`
  mutation suggestionDeclinedandCreatedNewSuggestion(
    $activityId: String
    $meetings: [SuggestMeetingRequestInput]
  ) {
    suggestionDeclinedandCreatedNewSuggestion(
      request: { activityId: $activityId, meetings: $meetings }
    ) {
      errorCode
      message
    }
  }
`;

export const SUGGESTION_ACCEPT = gql`
  mutation suggestionAccepted($activityId: String, $meetingID: String) {
    suggestionAccepted(
      request: { activityId: $activityId, meetingID: $meetingID }
    ) {
      errorCode
      message
    }
  }
`;

export const SUGGESTION_DECLINE = gql`
  mutation suggestionDeclined($activityId: String) {
    suggestionDeclined(request: { activityId: $activityId }) {
      errorCode
      message
    }
  }
`;

export const UNMATCH_MASTER = gql`
  mutation sendUnMatch(
    $loginUserProfileId: String
    $unmatchUserProfileId: String
  ) {
    sendUnMatch(
      request: {
        loginUserProfileId: $loginUserProfileId
        unmatchUserProfileId: $unmatchUserProfileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const DELETE_COLLAB_REQUEST = gql`
  mutation deleteCollabRequest(
    $collaborationType: String
    $loginProfileId: String
    $id: String
    $userId: String
    $circleId: String
  ) {
    deleteCollaborationRequest(
      request: {
        collaborationType: $collaborationType
        loginProfileId: $loginProfileId
        id: $id
        userId: $userId
        circleId: $circleId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const DONE_COLLAB_REQUEST = gql`
  mutation doneCollaborationRequest(
    $collaborationType: String
    $loginProfileId: String
    $id: String
    $userId: String
  ) {
    doneCollaborationRequest(
      request: {
        collaborationType: $collaborationType
        loginProfileId: $loginProfileId
        id: $id
        userId: $userId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const SAVE_NEW_CIRCLE = gql`
  mutation saveCircle(
    $name: String
    $circleTypeId: String
    $members: [CircleMemberRequestInput!]
    $loggedinUser: String
  ) {
    saveCircle(
      saveCircle: {
        circleTypeId: $circleTypeId
        name: $name
        members: $members
        loggedinUser: $loggedinUser
      }
    ) {
      errorCode
      message
      result {
        members {
          role
        }
        brandColor
        name
        shortDescription
        aboutus
        brandSecondColor
        canHaveEvents
        canHaveSubCircles
        circleTags
        circleTypeId
        externalUrl
        hasCustomMessages
        hasKnowledgePosts
        hasNewsUpdates
        hasOneOnOneMeetingReporting
        hasRevenueReporting
        id
        isHideForPublic
        isVisibleInPublicFeed
        label
        location
        logo
        membersArePubliclyVisible
        operatesIn
        shortDescription
        loggedInUserRole
        isPublished
        isCancelled
        isSubCircle
        locationPlaceId
      }
    }
  }
`;

export const UPDATE_CIRCLE = gql`
  mutation saveCircle(
    $name: String
    $circleTypeId: String
    $id: String
    $logo: String
    $location: String
    $operatesIn: String
    $circleTags: [String!]
    $shortDescription: String
    $aboutus: String
    $brandColor: String
    $brandSecondColor: String
    $externalUrl: String
    $membersArePubliclyVisible: Boolean
    $canHaveEvents: Boolean
    $hasNewsUpdates: Boolean
    $hasKnowledgePosts: Boolean
    $canHaveSubCircles: Boolean
    $isCompany: Boolean!
    $isHideForPublic: Int
    $label: String
    $isPublished: Int
    $locationPlaceId: String
  ) {
    saveCircle(
      saveCircle: {
        isCompany: $isCompany
        circleTypeId: $circleTypeId
        name: $name
        logo: $logo
        location: $location
        operatesIn: $operatesIn
        circleTags: $circleTags
        shortDescription: $shortDescription
        aboutus: $aboutus
        brandColor: $brandColor
        brandSecondColor: $brandSecondColor
        externalUrl: $externalUrl
        membersArePubliclyVisible: $membersArePubliclyVisible
        canHaveEvents: $canHaveEvents
        hasNewsUpdates: $hasNewsUpdates
        hasKnowledgePosts: $hasKnowledgePosts
        canHaveSubCircles: $canHaveSubCircles
        isHideForPublic: $isHideForPublic
        id: $id
        label: $label
        isPublished: $isPublished
        locationPlaceId: $locationPlaceId
      }
    ) {
      errorCode
      message
      result {
        members {
          role
        }
        brandColor
        name
        shortDescription
        aboutus
        brandSecondColor
        canHaveEvents
        canHaveSubCircles
        circleTags
        circleTypeId
        externalUrl
        hasCustomMessages
        hasKnowledgePosts
        hasNewsUpdates
        hasOneOnOneMeetingReporting
        hasRevenueReporting
        id
        isHideForPublic
        isVisibleInPublicFeed
        label
        location
        logo
        membersArePubliclyVisible
        operatesIn
        shortDescription
        loggedInUserRole
        isPublished
        isCancelled
        isSubCircle
        locationPlaceId
      }
    }
  }
`;

export const LEAVE_FROM_CIRCLE = gql`
  mutation leaveFromCircle(
    $circleId: String
    $loginProfileId: String
    $profileId: String
  ) {
    leaveFromCircle(
      request: {
        circleId: $circleId
        loginProfileId: $loginProfileId
        profileId: $profileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const REMOVE_CIRCLE_MEMBERS = gql`
  mutation removeMemberFromCircle(
    $circleId: String!
    $loginProfileId: String!
    $profileId: String!
  ) {
    removeMemberFromCircle(
      request: {
        circleId: $circleId
        loginProfileId: $loginProfileId
        profileId: $profileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const MakeAs_Circle_Moderator = gql`
  mutation makeAsCircleModerator(
    $circleId: String!
    $loginProfileId: String!
    $profileId: String!
  ) {
    makeAsCircleModerator(
      request: {
        circleId: $circleId
        loginProfileId: $loginProfileId
        profileId: $profileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const Save_Circle_Invite = gql`
  mutation saveCircleInvite(
    $circleId: String!
    $requestedUserId: String!
    $invitedMembers: [String!]
    $message: String
  ) {
    saveCircleInvite(
      saveInvite: {
        circleId: $circleId
        requestedUserId: $requestedUserId
        invitedMembers: $invitedMembers
        message: $message
      }
    ) {
      errorCode
      message
    }
  }
`;

export const SELF_SAVE_CIRCLE_INVITE = gql`
  mutation selfSaveCircleInvite(
    $email: String
    $uuid: String
  ) {
    selfSaveCircleInvite(
      uuid: $uuid
      email: $email
    ) {
      errorCode
      message
    }
  }
`;

export const CIRCLE_INVITE_ACCEPT = gql`
  mutation acceptCircleInvitation($activityId: String, $loginUserId: String) {
    acceptCircleInvitation(
      request: { activityId: $activityId, loginUserId: $loginUserId }
    ) {
      errorCode
      message
    }
  }
`;

export const CIRCLE_INVITE_DECLINE = gql`
  mutation declineCircleInvitation($activityId: String, $loginUserId: String) {
    declineCircleInvitation(
      request: { activityId: $activityId, loginUserId: $loginUserId }
    ) {
      errorCode
      message
    }
  }
`;

export const CANCEL_CIRCLE = gql`
  mutation circleCloseInitiate($circleId: String, $loginUserId: String) {
    circleCloseInitiate(
      _request: { circleId: $circleId, loginUserId: $loginUserId }
    ) {
      errorCode
      message
    }
  }
`;

export const CANCEL_CIRCLE_ADMIN = gql`
  mutation circleClosed($circleId: String, $loginUserId: String) {
    circleClosed(_request: { circleId: $circleId, loginUserId: $loginUserId }) {
      errorCode
      message
    }
  }
`;

export const SAVE_NEW_SUBCIRCLE = gql`
  mutation saveSubCircle(
    $name: String
    $circleTypeId: String
    $members: [CircleMemberRequestInput!]
    $loggedinUser: String
    $parentCircleId: String
  ) {
    saveSubCircle(
      _request: {
        circleTypeId: $circleTypeId
        name: $name
        members: $members
        loggedinUser: $loggedinUser
        parentCircleId: $parentCircleId
      }
    ) {
      errorCode
      message
      result {
        members {
          role
        }
        brandColor
        name
        shortDescription
        aboutus
        brandSecondColor
        canHaveEvents
        canHaveSubCircles
        circleTags
        circleTypeId
        externalUrl
        hasCustomMessages
        hasKnowledgePosts
        hasNewsUpdates
        hasOneOnOneMeetingReporting
        hasRevenueReporting
        id
        isHideForPublic
        isVisibleInPublicFeed
        label
        location
        logo
        membersArePubliclyVisible
        operatesIn
        shortDescription
        loggedInUserRole
        isPublished
        isCancelled
        isSubCircle
        parentCircleId
      }
    }
  }
`;

export const UPDATE_SUB_CIRCLE = gql`
  mutation saveSubCircle(
    $name: String
    $circleTypeId: String
    $id: String
    $logo: String
    $location: String
    $operatesIn: String
    $circleTags: [String!]
    $shortDescription: String
    $aboutus: String
    $brandColor: String
    $brandSecondColor: String
    $externalUrl: String
    $membersArePubliclyVisible: Boolean
    $canHaveEvents: Boolean
    $hasNewsUpdates: Boolean
    $hasKnowledgePosts: Boolean
    $canHaveSubCircles: Boolean
    $isHideForPublic: Int
    $label: String
    $isPublished: Int
    $parentCircleId: String
    $locationPlaceId: String
  ) {
    saveSubCircle(
      _request: {
        circleTypeId: $circleTypeId
        name: $name
        logo: $logo
        location: $location
        operatesIn: $operatesIn
        circleTags: $circleTags
        shortDescription: $shortDescription
        aboutus: $aboutus
        brandColor: $brandColor
        brandSecondColor: $brandSecondColor
        externalUrl: $externalUrl
        membersArePubliclyVisible: $membersArePubliclyVisible
        canHaveEvents: $canHaveEvents
        hasNewsUpdates: $hasNewsUpdates
        hasKnowledgePosts: $hasKnowledgePosts
        canHaveSubCircles: $canHaveSubCircles
        isHideForPublic: $isHideForPublic
        id: $id
        label: $label
        isPublished: $isPublished
        parentCircleId: $parentCircleId
        locationPlaceId: $locationPlaceId
      }
    ) {
      errorCode
      message
      result {
        members {
          role
        }
        brandColor
        name
        shortDescription
        aboutus
        brandSecondColor
        canHaveEvents
        canHaveSubCircles
        circleTags
        circleTypeId
        externalUrl
        hasCustomMessages
        hasKnowledgePosts
        hasNewsUpdates
        hasOneOnOneMeetingReporting
        hasRevenueReporting
        id
        isHideForPublic
        isVisibleInPublicFeed
        label
        location
        logo
        membersArePubliclyVisible
        operatesIn
        shortDescription
        loggedInUserRole
        isPublished
        isCancelled
        isSubCircle
        parentCircleId
      }
    }
  }
`;

export const Create_Circle_News = gql`
  mutation createCircleNews(
    $newsInfo: String!
    $circleId: String!
    $loginProfileId: String!
  ) {
    createCircleNews(
      request: {
        newsInfo: $newsInfo
        circleId: $circleId
        loginProfileId: $loginProfileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const DELETE_CIRCLE_NEWS = gql`
  mutation deleteCircleNews(
    $circleId: String
    $loginProfileId: String
    $newsId: String
    $newsInfo: String
  ) {
    deleteCircleNews(
      request: {
        circleId: $circleId
        loginProfileId: $loginProfileId
        newsId: $newsId
        newsInfo: $newsInfo
        sendNotification: false
      }
    ) {
      errorCode
      message
    }
  }
`;

export const EDIT_CIRCLE_NEWS = gql`
  mutation editCircleNews(
    $circleId: String
    $loginProfileId: String
    $newsId: String
    $newsInfo: String
    $sendNotification: Boolean!
  ) {
    editCircleNews(
      request: {
        circleId: $circleId
        loginProfileId: $loginProfileId
        newsId: $newsId
        newsInfo: $newsInfo
        sendNotification: $sendNotification
      }
    ) {
      errorCode
      message
    }
  }
`;

export const CONTACT_CIRCLE_MANAGER = gql`
  mutation createContactCircle(
    $circleId: String
    $message: String
    $requestedUserId: String
  ) {
    createContactCircle(
      request: {
        circleId: $circleId
        message: $message
        requestedUserId: $requestedUserId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const RESPOND_TO_NEWS = gql`
  mutation sendResponseToCircleNewsActivity(
    $activitySource: String
    $activitySourceId: String
    $messages: String
    $recipientUserProfileIds: [String]
    $requesterUserProfileId: String
  ) {
    sendResponseToCircleNewsActivity(
      request: {
        activitySource: $activitySource
        activitySourceId: $activitySourceId
        messages: $messages
        recipientUserProfileIds: $recipientUserProfileIds
        requesterUserProfileId: $requesterUserProfileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const SAVE_CIRCLE_POST = gql`
  mutation saveCirclePost(
    $circleId: String
    $message: String
    $loginProfileId: String
  ) {
    saveCirclePost(
      request: {
        circleId: $circleId
        message: $message
        loginProfileId: $loginProfileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const EDIT_CIRCLE_POST = gql`
  mutation editCirclePost(
    $postId: String
    $postInfo: String
    $circleId: String
    $loginProfileId: String
  ) {
    editCirclePost(
      request: {
        postId: $postId
        postInfo: $postInfo
        circleId: $circleId
        loginProfileId: $loginProfileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const DELETE_CIRCLE_POST = gql`
  mutation deleteCirclePost(
    $postId: String
    $postInfo: String
    $circleId: String
    $loginProfileId: String
  ) {
    deleteCirclePost(
      request: {
        postId: $postId
        postInfo: $postInfo
        circleId: $circleId
        loginProfileId: $loginProfileId
      }
    ) {
      errorCode
      message
    }
  }
`;
export const SAVEPROFILE_INVITE = gql`
  mutation saveProfileInvite(
    $requestedUserId: String!
    $invitedMembers: [String!]
    $message: String
  ) {
    saveProfileInvite(
      saveInvite: {
        invitedMembers: $invitedMembers
        requestedUserId: $requestedUserId
        message: $message
      }
    ) {
      errorCode
      message
    }
  }
`;
export const DELETE_PROFILE_INVITE = gql`
  mutation deleteInvitation(
    $id: String
    $requestedUserId: String
    $userID: String
  ) {
    deleteInvitation(
      deleteInvite: {
        id: $id
        userID: $userID
        requestedUserId: $requestedUserId
      }
    ) {
      errorCode
      message
      result {
        invitedMembers {
          userId
          inviteStatus
        }
      }
    }
  }
`;

export const RESPOND_TO_POST = gql`
  mutation sendResponseToCirclePostsActivity(
    $activitySource: String
    $activitySourceId: String
    $messages: String
    $recipientUserProfileIds: [String]
    $requesterUserProfileId: String
  ) {
    sendResponseToCirclePostsActivity(
      request: {
        activitySource: $activitySource
        activitySourceId: $activitySourceId
        messages: $messages
        recipientUserProfileIds: $recipientUserProfileIds
        requesterUserProfileId: $requesterUserProfileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const UPDATE_ONBOARDING = gql`
  mutation updateOnboarding(
    $userId: String
    $profilePictureUrl: String
    $companyRole: String
    $role: String
    $company: String
    $name: String
    $location: String
    $locationPlaceID: String
    $skills: [String]
    $professionalLife: [String]
    $personalLife: [String]
    $expertiseLevel: String
    $companyLevel: String
    $industry: [String]
  ) {
    updateOnboarding(
      onBoarding: {
        userId: $userId
        profilePictureUrl: $profilePictureUrl
        role: $role
        companyRole: $companyRole
        company: $company
        name: $name
        location: $location
        locationPlaceID: $locationPlaceID
        skills: $skills
        professionalLife: $professionalLife
        personalLife: $personalLife
        expertiseLevel: $expertiseLevel
        companyLevel: $companyLevel
        industry: $industry
      }
    ) {
      errorCode
      message
      result {
        companyName
        givenName
        id
        userId
        companyName
        role
      }
    }
  }
`;

export const UPDATE_PREFERENCE = gql`
  mutation updatePreferance(
    $userID: String
    $preferances: [String]
    $myMatchPreferance: Int
  ) {
    updatePreferance(
      preferance: {
        userID: $userID
        preferances: $preferances
        myMatchPreferance: $myMatchPreferance
      }
    ) {
      errorCode
      message
      result {
        companyName
        familyName
        givenName
        preferances
        id
        userId
        myMatchPreferance
      }
    }
  }
`;

export const DONE_CIRCLE_POST = gql`
  mutation doneCirclePost($id: String, $loginProfileId: String) {
    doneCirclePost(_request: { id: $id, loginProfileId: $loginProfileId }) {
      errorCode
      message
    }
  }
`;

export const SAVE_NEW_EVENT = gql`
  mutation saveEvents(
    $title: String
    $loginProfileId: String
    $isOrganizer: Boolean
    $circleId: String
  ) {
    saveEvents(
      request: {
        title: $title
        loginProfileId: $loginProfileId
        isOrganizer: $isOrganizer
        circleId: $circleId
      }
    ) {
      errorCode
      message
      result {
        id
        title
        description
        logo
        createdDate
        operatesIn
        ownerUserId
        createdProfileId
        updatedDate
        isOrganizer
        isDeleted
        quote
        shortDescription
        startDate
        endDate
        location
        locationPlaceID
        videoLink
        description
        website
        hasTicketing
        ticketingUrl
        hasExternalRegistration
        registrationUrl
        maximumAmountOfVisitors
        isRecurring
        hideVisitorsToPublic
        hasNewsUpdate
        isInvitationEnabled
        tagsDesc
        operatesInDesc
        isPublished
        visitors {
          userId
          status
        }
        numberOfVisitors
        participantScopeDesc
        createdProfileId
        loginUserStatus
        numberOfVisitors
        circleId
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvents(
    $title: String
    $id: String
    $quote: String
    $startDate: DateTime!
    $endDate: DateTime!
    $isOrganizer: Boolean
    $canEveryoneInvite: Boolean
    $logo: String
    $operatesInDesc: String
    $location: String
    $videoLink: String
    $tagsDesc: [String]
    $description: String
    $website: String
    $ticketingUrl: String
    $isInvitationEnabled: Boolean
    $hasNewsUpdate: Boolean
    $hideVisitorsToPublic: Boolean
    $participantScopeDesc: String
    $loginProfileId: String
    $profileId: String
    $shortDescription: String
    $isPublished: Boolean
    $organizersEmail:[String]
    $isOrganizerMailSent: Boolean
    $locationPlaceID: String
  ) {
    updateEvents(
      request: {
        title: $title
        id: $id
        quote: $quote
        startDate: $startDate
        endDate: $endDate
        isOrganizer: $isOrganizer
        canEveryoneInvite: $canEveryoneInvite
        logo: $logo
        operatesInDesc: $operatesInDesc
        location: $location
        videoLink: $videoLink
        tagsDesc: $tagsDesc
        description: $description
        website: $website
        ticketingUrl: $ticketingUrl
        isInvitationEnabled: $isInvitationEnabled
        hasNewsUpdate: $hasNewsUpdate
        hideVisitorsToPublic: $hideVisitorsToPublic
        participantScopeDesc: $participantScopeDesc
        loginProfileId: $loginProfileId
        profileId: $profileId
        shortDescription: $shortDescription
        isPublished: $isPublished
        organizersEmail:$organizersEmail
        isOrganizerMailSent:$isOrganizerMailSent
        locationPlaceID: $locationPlaceID
      }
    ) {
      errorCode
      message
      result {
        id
        title
        description
        logo
        createdDate
        operatesIn
        ownerUserId
        createdProfileId
        updatedDate
        isOrganizer
        canEveryoneInvite
        isDeleted
        quote
        shortDescription
        startDate
        endDate
        location
        locationPlaceID
        videoLink
        description
        website
        hasTicketing
        ticketingUrl
        hasExternalRegistration
        registrationUrl
        maximumAmountOfVisitors
        isRecurring
        hideVisitorsToPublic
        hasNewsUpdate
        isInvitationEnabled
        tagsDesc
        operatesInDesc
        isPublished
        visitors {
          userId
          status
        }
        numberOfVisitors
        participantScopeDesc
        createdProfileId
        loginUserStatus
        numberOfVisitors
        circleId
      }
    }
  }
`;

export const JOIN_EVENT = gql`
  mutation joinEvent(
    $id: String
    $loginProfileId: String
    $participantScopeDesc: String
    $messages: String
  ) {
    joinEvent(
      request: {
        id: $id
        loginProfileId: $loginProfileId
        participantScopeDesc: $participantScopeDesc
        messages: $messages
      }
    ) {
      errorCode
      message
    }
  }
`;

export const ACCEPT_EVENT_JOIN = gql`
  mutation acceptJoinAsGuestActivity(
    $activityId: String
    $loginUserId: String
  ) {
    acceptJoinAsGuestActivity(
      request: { activityId: $activityId, loginUserId: $loginUserId }
    ) {
      errorCode
      message
    }
  }
`;
export const DECLINE_EVENT_JOIN = gql`
  mutation declineJoinAsGuestActivity(
    $activityId: String
    $loginUserId: String
  ) {
    declineJoinAsGuestActivity(
      request: { activityId: $activityId, loginUserId: $loginUserId }
    ) {
      errorCode
      message
    }
  }
`;

export const SAVE_EVENTS_INVITE = gql`
  mutation saveEventInvite(
    $eventId: String
    $requestedUserId: String
    $invitedMembers: [String]
    $message: String
  ) {
    saveEventInvite(
      saveInvite: {
        eventId: $eventId
        requestedUserId: $requestedUserId
        invitedMembers: $invitedMembers
        message: $message
      }
    ) {
      errorCode
      message
      result {
        expiryDate
        invitedMembers {
          inviteStatus
          userId
          userType
        }
        id
        publishDate
        relatedTaskLink
        requestedUserId
        sourceId
      }
    }
  }
`;

export const SELF_SAVE_EVENTS_INVITE = gql`
  mutation selfSaveEventInvite(
    $uuid: String
    $email: String
  ) {
    selfSaveEventInvite(
      uuid: $uuid
      email: $email
    ) {
      errorCode
      message
      result {
        expiryDate
        invitedMembers {
          inviteStatus
          userId
          userType
        }
        id
        publishDate
        relatedTaskLink
        requestedUserId
        sourceId
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String) {
    forgotPassword(email: $email) {
      errorCode
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $userId: String
    $newPassword: String
    $token: String
  ) {
    resetPassword(
      request: { userId: $userId, newPassword: $newPassword, token: $token }
    ) {
      errorCode
      message
    }
  }
`;

export const CREATE_EVENT_NEWS = gql`
  mutation createEventNews(
    $newsInfo: String
    $loginProfileId: String
    $eventId: String
    # $profileId: String
    # $id: String
  ) {
    createEventNews(
      request: {
        newsInfo: $newsInfo
        loginProfileId: $loginProfileId
        eventId: $eventId
        # profileId: $profileId
        # id: $id
      }
    ) {
      errorCode
      message
      result {
        id
        postedUser
        newsInfo
        postedDate
        eventId
      }
    }
  }
`;

export const EDIT_EVENT_NEWS = gql`
  mutation editEventNews(
    $newsInfo: String
    $loginProfileId: String
    $eventId: String
    # $profileId: String
    # $id: String
    $newsId: String
    $sendNotification: Boolean!
  ) {
    editEventNews(
      request: {
        newsInfo: $newsInfo
        loginProfileId: $loginProfileId
        eventId: $eventId
        # profileId: $profileId
        # id: $id
        newsId: $newsId
        sendNotification: $sendNotification
      }
    ) {
      errorCode
      message
      result {
        id
        postedUser
        newsInfo
        postedDate
        eventId
      }
    }
  }
`;
export const ACCEPT_EVENT_INVITE = gql`
  mutation acceptEventInvitation($activityId: String, $loginUserId: String) {
    acceptEventInvitation(
      request: { activityId: $activityId, loginUserId: $loginUserId }
    ) {
      errorCode
      message
    }
  }
`;

export const DECLINE_EVENT_INVITE = gql`
  mutation declineEventInvitation($activityId: String, $loginUserId: String) {
    declineEventInvitation(
      request: { activityId: $activityId, loginUserId: $loginUserId }
    ) {
      errorCode
      message
    }
  }
`;

export const MAKEAS_EVENT_MANAGER = gql`
  mutation makeAsEventManager(
    $id: String!
    $loginProfileId: String!
    $profileId: String!
  ) {
    makeAsEventManager(
      request: {
        id: $id
        loginProfileId: $loginProfileId
        profileId: $profileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const CANCEL_EVENT_MANAGER = gql`
  mutation eventClosed($eventId: String, $loginUserId: String) {
    eventClosed(_request: { eventId: $eventId, loginUserId: $loginUserId }) {
      errorCode
      message
    }
  }
`;

export const REMOVE_EVENT_MANAGER = gql`
  mutation removeFromEvent(
    $id: String!
    $loginProfileId: String!
    $profileId: String!
  ) {
    removeFromEvent(
      request: {
        id: $id
        loginProfileId: $loginProfileId
        profileId: $profileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const LEAVE_FROM_EVENT = gql`
  mutation leaveFromEvent(
    $id: String!
    $loginProfileId: String!
    $profileId: String!
  ) {
    leaveFromEvent(
      request: {
        id: $id
        loginProfileId: $loginProfileId
        profileId: $profileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const DELETE_EVENT_NEWS = gql`
  mutation deleteEventNews(
    $newsInfo: String
    $loginProfileId: String
    $eventId: String
    # $profileId: String
    # $id: String
    $newsId: String
    $sendNotification: Boolean!
  ) {
    deleteEventNews(
      request: {
        newsInfo: $newsInfo
        loginProfileId: $loginProfileId
        eventId: $eventId
        # profileId: $profileId
        # id: $id
        newsId: $newsId
        sendNotification: $sendNotification
      }
    ) {
      errorCode
      message
      result {
        id
        postedUser
        newsInfo
        postedDate
        eventId
      }
    }
  }
`;

export const RESPOND_TO_EVENT_NEWS = gql`
  mutation sendResponseToEventNewsActivity(
    $activitySource: String
    $activitySourceId: String
    $messages: String
    $recipientUserProfileIds: [String]
    $requesterUserProfileId: String
  ) {
    sendResponseToEventNewsActivity(
      request: {
        activitySource: $activitySource
        activitySourceId: $activitySourceId
        messages: $messages
        recipientUserProfileIds: $recipientUserProfileIds
        requesterUserProfileId: $requesterUserProfileId
      }
    ) {
      errorCode
      message
    }
  }
`;

export const MOLLIE_INITIAL_PAYMENT = gql`
  mutation mollieInitialPayment(
    $planId:String,
    $userId:String
  ) {
    mollieInitialPayment(
      request: {
        planId: $planId
        userId: $userId
      }
    ) {
      
    errorCode,
    message,
    result{
      _links{
      checkout{
        href
        }
      }
    }

    }
  }
`;


export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelCurrentPlanForUser(
    $userid:String
  ) {
    cancelCurrentPlanForUser(userid: $userid) {
    errorCode
    message
    result{
       userId
       role
     }
    }
  }
`;

export const UPDATE_NOTIFICATIONSEENBY_STATUS = gql`
  mutation updateNotificationSeenByStatus(
    $userId:String
  ) {
    updateNotificationSeenByStatus(userId: $userId) {
    errorCode
    message
    }
  }
`;

export const UPDATE_USER_SUBSCRIPTION = gql`
  mutation updateUserSubscription(
    $userId:String,
    $id:String,
    $remarks:String,
    $subscriptionEndDate:DateTime
  ) {
    updateUserSubscription(
      request: {
      id:$id
      remarks:$remarks
      subscriptionEndDate:$subscriptionEndDate
      userId:$userId
      }
      ) {
       errorCode
       message
    }
  }
`;
export const UPDATE_USER_SUBSCRIPTION_NOTIFICATION_STATUS = gql`
  mutation updateAdminImpersonationActivitySeenStatusByUser(
    $userid:String,
  ) {
    updateAdminImpersonationActivitySeenStatusByUser(userid:$userid) {
       errorCode
       message
    }
  }
`;

export const SELF_SAVE_PROFILE_INVITE = gql`
  mutation SelfProfileInvite(
    $email: String
    $uuid: String
  ) {
    selfProfileInvite(
      uuid: $uuid
      email: $email
    ) {
      errorCode
      message
    }
  }
`;