import { Autocomplete, Button, Checkbox, Chip, Collapse, FormControl, Grid, Hidden, InputLabel, ListItemText, MenuItem, Select, Skeleton, TextField, ThemeProvider } from "@mui/material";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import Image from "../Media/Image";
import { ClearCache, onlyUnique } from "../../apiServices/CommonMethods";
import { SAVE_MASTERMIND_REQUEST } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { masterDataSkills, userRoles } from "../../apiServices/Constants";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChipList from "../Forms/ChipList";
import DescriptionWithSkills from "./Reusable/DescriptionWithSkills";

const MinimumTags = 1;
export default function NewRequestCard(props) {
    //STATES AND VARS
    const circle     = props.circleId; //the circle this request is to be posted in
    const history    = useHistory();
    const globals    = useGlobalContext();
    const freemium   = globals.state.loggedInUser.role?.role == userRoles.freemium && !circle;
    const pfp        = globals.state.loggedInUser.profile?.profilePictureUrl;
    const circles    = globals.state.loggedInUser.circles;
    const labelProps = {bold: true, color: "background", xs: 12}
    const fieldStyle = {
        div: {color: Theme_WIP.palette.primary.main}, 
        "input, svg, label, path, .MuiInputLabel-root, span": {pointerEvents: "none !important"},
        "fieldset":{borderColor: Theme_WIP.palette.lighterPetrol.main}};
    const fieldProps = { 
        size: "small", 
        fullWidth: true, 
        sx: fieldStyle, 
        InputLabelProps: {sx: {pointerEvents: "none !important"}},
    }
    const [collapsed, setCollapsed] = useState(true);
    const [success  , setSuccess  ] = useState();
    const [topCat   , setTopCat   ] = useState();
    const [subCat   , setSubCat   ] = useState();
    const [receivers, setReceivers] = useState([
        { type: "Masters zonder match", id: "2" },
        { type: "Mijn inner circle", id: "1" },
    ]);

    const baseInput = {
        request                : {description: "", skills: []},
        offer                  : {description: "", skills: []},
        skills                 : [],
        title                  : "",
        selectedToWhomIds      : [],
    };
    let [input, setInput] = useState(baseInput);

    const mutationOptions = {
        variables: {
            userId                  : globals.state.loggedInUser.userId,
            titleRequest            : input.title,
            request                 : input.request,
            offer                   : input.offer,
            selectedToWhomIds       : input.selectedToWhomIds,
            id                      : null,
            collaborationCategoryId : subCat,
            marker                  : globals.state.marker
        },
        onCompleted: (data) => {
            ClearCache("circle_feed", globals);
            ClearCache("allCollabRequests", globals);
            setSuccess(true);
            if(props.onCompleted)
                props.onCompleted();
            setTimeout(() => {  //reset the form
                setSuccess  (false);
                setInput    (baseInput);
                setTopCat   (null);
                setSubCat   (null);
                setCollapsed(true);
            }, 3000);
        }};
    const [SaveRequest, { data, loading, error }] = useMutation(SAVE_MASTERMIND_REQUEST, mutationOptions);

    // EFFECTS

    useEffect(() => {
        if(circles && circles.length) {
            if(circle)
                updateInput("selectedToWhomIds", [circle]);

            setReceivers(receivers
                .concat(circles
                    .filter(c => circle == null || c.id == circle)
                    .map   (c => ({type: c.name, id: c.id})))
                .filter(onlyUnique)
            );
        }
    }, [circles]);

    // CALLBACK & UTILITIES

    function updateInput(field, value) {
        var newValues = {};
        newValues[field] = value;
        setInput((prevState) => ({
            ...prevState,
            ...newValues,
        }));
    }

    function getReceiverTypeId(type) {
        const filteredItem = receivers.filter((item) => {
            return item.type === type;
        });
        return filteredItem[0]?.id;
    }

    const checkValidity = (reqOrOffer) => {
        if(reqOrOffer == null)
            return({filled: false, complete: false, valid: true});
        else
            return({
                filled   :  (reqOrOffer.description?.length ?? 0) >    0 || (reqOrOffer.skills?.length ?? 0) >  0,
                complete :  (reqOrOffer.description?.length ?? 0) >    0 && (reqOrOffer.skills?.length ?? 0) >  0,
                valid    : ((reqOrOffer.description?.length ?? 0) >= 1 && (reqOrOffer.skills?.length ?? 0) >= MinimumTags)
                        || ((reqOrOffer.description?.length ?? 0) ==   0 && (reqOrOffer.skills?.length ?? 0) == 0)
            });
    };

    const validRequest = () => {
        var req   = checkValidity(input.request);
        var offer = checkValidity(input.offer);
        return(
          ((input.offer?.skills?.length ?? 0 <= 10) || (input.request?.skills?.length ?? 0 <= 10))        //max skills

      && (req.valid && offer.valid)

      && ((input.request?.description && input.request?.skills?.length != 0) || (input.offer?.description && input.offer?.skills?.length != 0)) 
      && input.title 
      && input.selectedToWhomIds.length)};

    //COMPONENTS

    const TopCategorySelection = () => {return(
        <Grid item xs>
            <FormControl fullWidth>
            <InputLabel id="top-label"><Text color="background" mt={subCat ? 0 : -1}  sx={{pointerEvents: "none !important"}} id={subCat ? "RequestTopLabel2" : "RequestTopLabel1"} /></InputLabel>
            <Select
                onClick={globals.state.loggedInUser.role.usedAllResponses && !props.noPremiumNeeded ? () => history.push("/getPremium") : undefined}
                {...fieldProps}
                labelId="top-label"
                label="xxxxxxxx"
                color="background"
                value={topCat}
                onChange={(event) => {setCollapsed(false); setTopCat(event.target.value)}}
            >
                {globals.functions.meta.getTopCategories().map((value) => (
                <MenuItem key={value.id} value={value.id}>
                    <ListItemText primary={value.title} />
                </MenuItem>
                ))}
            </Select>
            {globals.state.loggedInUser.role.usedAllResponses && !props.noPremiumNeeded && <Text bold id="Upgrade" color="primary" onClick={() => history.push("/getPremium")} gridProps={{sx:{
                background: ThemeConstants.palette.primary.main, 
                position: "absolute", top: "8px", right: "40px", borderRadius: "5px",
                padding: "5px 10px", 
                width: "fit-content"}}}/>}
            </FormControl>
        </Grid>
        );};

    const SubCategorySelection = () => {
        var cats        = globals.functions.meta.getSubCategories(topCat);
        var description = cats.find(c => c.id == subCat)?.description;
        return(
        <Grid item xs={12} mt={2}>
            <Text {...labelProps} mb={3} id="RequestSubLabel"/>
            <ChipList selected={subCat} entries={cats} onClick={setSubCat} value={subCat}/>
            {!collapsed && description && <Text {...labelProps} color={Theme_WIP.palette.lightPetrol.main} pt={4} pb={2}>{description}</Text>}
        </Grid>
        );};

    const SubCategorySelectionXs = () => {
        var cats        = globals.functions.meta.getSubCategories(topCat);
        var description = cats.find(c => c.id == subCat)?.description;
        return(
        <Grid item xs={12} mt={2}>
        <FormControl fullWidth>
            <InputLabel id="sub-label"><Text color="background" mt={-1} sx={{pointerEvents: "none !important"}} id="RequestSubLabel"/></InputLabel>
            <Select
                {...fieldProps}
                labelId="sub-label"
                label="xxxxxxxxxxxx"
                value={subCat}
                onChange={(event) => {setCollapsed(false); setSubCat(event.target.value)}}
            >
                {cats.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                    <ListItemText primary={value.title} />
                </MenuItem>
                ))}
            </Select>
            {!collapsed && description && <Text {...labelProps} pt={4} pb={2}>{description}</Text>}
        </FormControl>
        </Grid>
        );};
    
    // OUTPUT

    return(
        <ThemeProvider theme={Theme_WIP}>

            {globals.state.meta?.collaborationCategories?.length > 0 &&
            <Grid container item xs={12} height="fit-content">
            <Background {...props} px={7} py={4} xs={12} item container border="topRight" color="white" gap={3} sx={{transition: "all 1s"}}>
                <Text {...labelProps} item xs id="ShareRequest" onClick={() => setCollapsed(!collapsed)}/>
                <Grid container       item xs={1} justifyContent="end" onClick={() => setCollapsed(!collapsed)}>{collapsed? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}</Grid>
                <Text {...labelProps} id="" pb={2} color={Theme_WIP.palette.lighterPetrol.main} sx={{color: Theme_WIP.palette.white.main, borderBottom: "solid 1px"}}/>
                <Image 
                            src     = {pfp}
                            alt     = "pfp"
                            sx      = {{borderRadius: Theme_WIP.custom.borderRadius.bottomLeft, aspectRatio: "1 / 1", height: "40px", marginRight: 1}}
                            width   = "40px"/>
                {!success && !error && !loading && <TopCategorySelection/>}
                {!success && !error && !loading && topCat && <Hidden smUp  ><SubCategorySelectionXs/></Hidden>}
                {!success && !error && !loading && topCat && <Hidden smDown><SubCategorySelection/></Hidden>}
                <Collapse in={subCat && !collapsed} sx={{width: "100%"}}>
                    {success && <Text {...labelProps} id="RequestPosted"/>}
                    {error   && <Text {...labelProps} id="RequestFailed"/>}
                    {loading && <Skeleton height={50}/>}
                    {!loading && !success && !error && <Grid container gap={3}>
                        {/* <Image 
                            src     = {pfp}
                            alt     = "pfp"
                            sx      = {{borderRadius: Theme_WIP.custom.borderRadius.bottomLeft, aspectRatio: "1 / 1" }}
                            width   = "40px"/> */}
                        
                        <Grid item xs>
                            <TextField 
                            inputProps={{ maxLength: 100 }}
                            {...fieldProps} 
                            size="medium"
                            sx={{...fieldStyle, "fieldset":{borderColor: "transparent"}, borderBottom: "solid 1px "+ Theme_WIP.palette.lighterPetrol.main}}
                            label={
                                <Text {...labelProps} variant="h1" id="Title" bold
                                sx={{pointerEvents: "none !important"}}/>
                            } 
                            onChange={(event) => updateInput("title", event.target.value)}/>
                        </Grid>

                        <DescriptionWithSkills label="CollaborationDescription"  onChange={(des, skills) => updateInput("request", {description: des, skills: skills})} labelProps={labelProps} fieldProps={fieldProps} fieldStyle={fieldStyle}/>
                        <DescriptionWithSkills label="CollaborationDescription2" onChange={(des, skills) => updateInput("offer"  , {description: des, skills: skills})} labelProps={labelProps} fieldProps={fieldProps} fieldStyle={fieldStyle}/>

                        <Grid item xs={7} md={10}>
                            <ToWho 
                            onChange = {(selected) => updateInput("selectedToWhomIds", selected.map(getReceiverTypeId))}
                            receivers  = {receivers} 
                            disabled   = {circle} 
                            selected   = {circle}
                            fieldProps = {fieldProps}/>
                        </Grid>
                        <Grid item xs><Button 
                            onClick  ={() => (!globals.state.loggedInUser.role.usedAllResponses || props.circleId || props.noPremiumNeeded) ? 
                                SaveRequest() : GoTo(history, "/GetPremium")}
                            disabled ={!validRequest()} 
                            fullWidth 
                            variant="contained" 
                            color="primary"><Text color="primary" id="RequestTabs_Button_Text_Send" py={0.5}/></Button></Grid>
                        
                        {/* {freemium && 
                        <Text {...labelProps} variant="body2" bold={false} id="NewRequestFreemiumDisclaimer"/>} */}
                        {!(input.offer.description && input.offer.skills.length) && !(!input.offer.description && !input.offer.skills.length) && <Text item xs={12} color="background" align="right" id="RequestIncomplete" px={2} pb={1} variant="body2"/>}
                        {!(input.request.description && input.request.skills.length) && !(!input.request.description && !input.request.skills.length) && <Text item xs={12} color="background" align="right" id="RequestIncomplete2" px={2} pb={1} variant="body2"/>}

                    </Grid>}
                </Collapse>
            </Background>
            </Grid>}
        </ThemeProvider>
    );
}


function ToWho(props) {
    const receivers = props.receivers
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if(props.selected && !selected.length)
            setSelected(receivers.filter(r => r.id == props.selected).map(r => r.type));
        if(props.onChange)
            props.onChange(selected);
    }, [selected]);

    return(
    <Grid item xs={12}>
    <FormControl fullWidth>
        <InputLabel id="towhom-label"><Text color="background" mt={-1}  sx={{pointerEvents: "none !important"}} id="RequestToWho"/></InputLabel>
        <Select
            labelId="towhom-label"
            label="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            disabled={props.disabled}
            {...props.fieldProps}
            multiple
            value={selected}
            onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setSelected(value);
                console.log(value);
            }}
            renderValue={(selected) => selected.join(", ")}
        >
            {receivers.map((option, index) => (
            <MenuItem key={index} value={option.type}>
                <Checkbox
                checked={selected.find(id => id == option.type) != null}
                />
                <ListItemText primary={option.type} />
            </MenuItem>
            ))}
        </Select>
    </FormControl>
    </Grid>
    );
}