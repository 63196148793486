import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Styles from "../../../styles/component/ProfileTabs/InfoTabs/CollaborationOfInterestStyle";
import PropTypes from "prop-types";
import { CollaborationTypes } from "../../../apiServices/Constants";
import { Chip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

const useStyles = makeStyles(Styles);

export default function CollaborationOfInterest(props) {
  const classes = useStyles();
  const [collabData, setCollabData] = React.useState(props.data);

  React.useEffect(() => {
    if (typeof props.data !== "undefined") {
      setCollabData(props.data);
    }
  }, [props.data]);
  // console.log("collabData", collabData);
  const collabTypeView = () => {
    if (props.data.itemType === CollaborationTypes.projectPartners) {
      return (
        <Grid item container rowGap={2} xs={12}>
          <Text variant="body1" color="white" xs={12}>
            {/* {"Industries of interest: "} */}
            <b><FormattedMessage id={"IndustriesOfInterest"} />:{" "}</b>
            {(collabData.industries ?? []).map((industry) => (
              <Chip
                key={industry}
                label={
                  <Typography
                    variant="h3"
                    color={"textSecondary"}
                    className={classes.p15}
                    display="inline"
                  >
                    {industry}
                  </Typography>
                }
                variant="outlined"
                className={classes.chipItem}
                size="small"
              />
            ))}
          </Text>
          
          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"Location"} />:</b>
            {" " + collabData.location ?? ""}
          </Text>
          
          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"ProjectsIlikeToWorkOn"} />:</b>
            {(" " + collabData.interestedIn ?? "") + " "}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"WhatIBringToTheProject"} />:</b>
            {" " + collabData.myPartOfThePartnership ?? ""}
          </Text>

          {collabData.averageDurationInMonths ? (
            <Text variant="body1" color="white" xs={12}>
              <b><FormattedMessage id={"AverageDurationInMonths"} />:</b>
              {" " + collabData.averageDurationInMonths ?? ""}
            </Text>
          ) : null}
        </Grid>
      );
    } else if (props.data.itemType === CollaborationTypes.strategicPartners) {
      return (
        <Grid item container rowGap={2} xs={12}>
          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"Industries"} />:{" "}</b>
              {(collabData.industries ?? []).map((industry) => (
                <Chip
                  key={industry}
                  label={industry}
                  variant="outlined"
                  className={classes.chipItem}
                  size="small"
                />
              ))}
          </Text>

          {collabData.skills ? (
            collabData.skills.length ? (
              <Text variant="body1" color="white" xs={12}>
                <b><FormattedMessage id={"PartnerSkillsOfInterest"} />:{" "}</b>
                  {(collabData.skills ?? []).map((skill) => (
                    <Chip
                      key={skill}
                      label={skill}
                      variant="outlined"
                      className={classes.chipItem}
                      size="small"
                    />
                  ))}
              </Text>
            ) : null
          ) : null}

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"Location"} />:</b>
            {" " + collabData.location ?? ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"PartnershipsOfInterest"} />:</b>
            {" " + collabData.interestedIn ?? ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"WhatIBringToThePartnership"} />:</b>
            {" " + collabData.myPartOfThePartnership ?? ""}
          </Text>
          {collabData.preferredBenefits ? (
            <Text variant="body1" color="white" xs={12}>
              <b><FormattedMessage id={"StrategicPartner_PreferedBenefits"} />:</b>
              {" " + collabData.preferredBenefits ?? ""}
            </Text>
          ) : null}
        </Grid>
      );
    } else if (props.data.itemType === CollaborationTypes.businessPartners) {
      return (
        <Grid item container rowGap={2} xs={12}>
          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"BusinessesOfInterest"} />:</b>
              {" " + collabData.interestedIn ?? ""}
          </Text>
        </Grid>
      );
    } else if (props.data.itemType === CollaborationTypes.coFounding) {
      return (
        <Grid item container rowGap={2} xs={12}>
          <Text variant="body1" color="white" xs={12}>
            {/* {"Industries of interest: "} */}
            <b><FormattedMessage id={"IndustriesOfInterest"} />:{" "}</b>
            {(collabData.industries ?? []).map((industry) => (
              <Chip
                key={industry}
                label={
                  <Typography
                    variant="h3"
                    color={"textSecondary"}
                    className={classes.p15}
                    display="inline"
                  >
                    {industry}
                  </Typography>
                }
                variant="outlined"
                className={classes.chipItem}
                size="small"
              />
            ))}
          </Text>

          {collabData.skills ? (
            collabData.skills.length ? (
              <Text variant="body1" color="white" xs={12}>
                <b><FormattedMessage id={"Co-founderSkillsOfInterest"} />:{" "}</b>
                {(collabData.skills ?? []).map((skill) => (
                  <Chip
                    key={skill}
                    label={skill}
                    variant="outlined"
                    className={classes.chipItem}
                    size="small"
                  />
                ))}
              </Text>
            ) : null
          ) : null}

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"Location"} />:</b>
            {" " + collabData.location ?? ""}
          </Text>

          {collabData.businessOpportunityDescription ? (
            <Text variant="body1" color="white" xs={12}>
              <b><FormattedMessage
                id={"BusinessOpportunityDescription_coFounding"}
              />:</b>
              {" " + collabData.businessOpportunityDescription ?? ""}
            </Text>
          ) : null}

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"BusinessStageOfInterest"} />:</b>
            {" " + collabData.businessStage ? collabData.businessStage.join(', ') : ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"MyCommitment"} />:</b>
            {" " + collabData.commitment ? collabData.commitment.join(', ') : ""}
          </Text>

          {collabData.sweatCommitmentInHours ? (
            <Text variant="body1" color="white" xs={12}>
              <b><FormattedMessage id={"SweatCommitmentInHours"} />:</b>
              {" " + collabData.sweatCommitmentInHours ?? ""}
            </Text>
          ) : null}

          {collabData.preferredCompanyRole ? (
            <Text variant="body1" color="white" xs={12}>
              <b><FormattedMessage id={"PreferredCompanyRole"} />:</b>
              {" " + collabData.preferredCompanyRole ?? ""}
            </Text>
          ) : null}

          {collabData.isAvailable ? (
            <Text variant="body1" color="white" xs={12}>
              <b><FormattedMessage id={"Available"} />:</b>
              {" " + collabData.isAvailable ?? ""}
            </Text>
          ) : null}
        </Grid>
      );
    } else if (props.data.itemType === CollaborationTypes.investment) {
      return (
        <Grid item container rowGap={2} xs={12}>
          <Text variant="body1" color="white" xs={12}>
            {/* {"Industries of interest: "} */}
            <b><FormattedMessage id={"IndustriesOfInterest"} />:{" "}</b>
              {(collabData.industries ?? []).map((industry) => (
                <Chip
                  key={industry}
                  label={industry}
                  variant="outlined"
                  className={classes.chipItem}
                  size="small"
                />
              ))}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"Location"} />:</b>
            {" " + collabData.location ?? ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage
              id={"BusinessOpportunityDescription_investment"}
            />:</b>
            {" " + collabData.interestedIn ?? ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"CompanyStageOfInterest"} />:</b>
            {" " + collabData.companyStage ? collabData.companyStage.join(', ') : ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"MyCommitment"} />:</b>
            {" " + collabData.commitment ? collabData.commitment.join(', ') : ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"TypeOfInvestor"} />:</b>
            {" " + collabData.investorType ? collabData.investorType.join(', ') : ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"TypeOfInvestment"} />:</b>
            {" " + collabData.investmentType ? collabData.investmentType.join(', ') : ""}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"PreferredCompanyRole"} />:</b>
            {" " + collabData.preferredCompanyRole ?? ""}
          </Text>

          {collabData.investmentCollective ? (
            <Text variant="body1" color="white" xs={12}>
              <b><FormattedMessage id={"InvestmentCollective"} />:</b>
              {" " + collabData.investmentCollective ?? ""}
            </Text>
          ) : null}
        </Grid>
      );
    } else {
      return (
        <Grid item container rowGap={2} xs={12}>
          <Text variant="body1" color="white" xs={12}>
            {/* {"Industries of knowledge: "} */}
            <b><FormattedMessage id={"IndustriesOfKnowledge"} />:{" "}</b>
            {(collabData.industries ?? []).map((industry) => (
              <Chip
                key={industry}
                label={industry}
                variant="outlined"
                className={classes.chipItem}
                size="small"
              />
            ))}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"SkillsOfknowledge"} />:</b>
              {(collabData.skills ?? []).map((skill) => (
                <Chip
                  key={skill}
                  label={skill}
                  variant="outlined"
                  className={classes.chipItem}
                  size="small"
                />
              ))}
          </Text>

          <Text variant="body1" color="white" xs={12}>
            <b><FormattedMessage id={"WhatIBringAsAMentor"} />:</b>
            {" " + collabData.myPartOfThePartnership ?? ""}
          </Text>

          {collabData.interestedIn ? (
            <Text variant="body1" color="white" xs={12}>
              <b><FormattedMessage id={"InMyPupilsIdeallyILikeToSee"} />:</b>
              {" " + collabData.interestedIn ?? ""}
            </Text>
          ) : null}

          <Text variant="body1" color="white" xs={12}> 
            <b><FormattedMessage id={"Available"} />:</b>
            {" " + typeof collabData.isAvailable !== "undefined"
                ? collabData.isAvailable
                : ""}
          </Text>
        </Grid>
      );
    }
  };

  return <ThemeProvider theme={Theme_WIP}><Grid container>{collabTypeView()}</Grid></ThemeProvider>;
}
CollaborationOfInterest.prototype = {
  data: PropTypes.object,
};
