import { AppBar, Toolbar } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from "../styles/component/HeaderStyle";
import { makeStyles } from '@material-ui/core/styles';
import { IsAuthenticated } from '../apiServices/CommonMethods';
import { updateBrandColor } from '../apiServices/Redux/Reducer';
import theme from '../styles/container/Theme';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(styles);

const Header = ({ history }) => {
    const isAuthenticated = IsAuthenticated();
    const classes = useStyles();
    const dispatch = useDispatch()

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    <div style={{ flex: 1, justifyContent: "center", alignSelf: "center", marginTop: 5 }}>
                        <img src={"/assets/img/mastermatch_logo_petrol_blue_large.svg"} alt={"mastermatch_logo"} className={classes.logo} style={{height: "30px"}}
                            onClick={() => {
                                if (isAuthenticated) {
                                    dispatch(updateBrandColor(theme.palette.primary.main))
                                    history.push("/dashboard/masters")
                                }
                            }} />
                    </div>
                </Toolbar>
            </AppBar >
        </div>
    );
}

export default withRouter(Header);