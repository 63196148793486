

const HeaderStyle = (theme) => ({
    toolbar: {
        justifyContent: "flex-end",
        minHeight: 50,
        backgroundColor: "white",
    },
    appBar: {
        height: 50,
    },
    menuButton: {
        marginRight: 2,
        color: theme.palette.color5.main,
        textTransform: 'none',
        fontFamily: "Helvetica",
        fontSize: 12,
    },
    logo: {
        left: 0,
        height: 40,
    },
    root: {
        flexGrow: 1,
    },
});
export default HeaderStyle;
