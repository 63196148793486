

const CircleSettingsStyle = (theme) => ({
    root: {
        width: "100%",
        marginBottom: 100,
        marginTop: -20
    },
    // txtarea: {
    //     color: "#828282",
    //     margin: "0px !important",
    //     width: "50% !important",
    //     "@media (max-width: 500px)": {
    //         width: "100% !important",
    //     },
    // },
    button: {
        width: "100%",
    },
    mt7: {
        marginTop: "7px",
    },
    mt10: {
        marginTop: "10px",
    },
    mt20: {
        marginTop: "20px",
    },
    Select: {
        width: "100%",
    },
    txtarea: {
        width: "100%",
        border: "none",
        backgroundColor: "#e8e8e8",
        borderRadius: 6,
        padding: "2%",
    },
    btxt: {
        color: "#72B6CD",
    },
    redLine: {
        color: "#ff3366",
        marginTop: 10,
    },
    mainColor: {
        backgroundColor: theme.palette.color3.main,
        padding: "13px 15px",
        borderRadius: 6,
        position: "relative",
        top: 10,
    },
    mainColorbox: {
        padding: "13px 20px",
        borderRadius: 6,
        backgroundColor: theme.palette.secondary.main,
        position: "relative",
        top: 10,
        marginLeft: 6,
    },
    secondColorbox: {
        padding: "13px 20px",
        borderRadius: 6,
        backgroundColor: theme.palette.color2.main,
        position: "relative",
        top: 10,
        marginLeft: 6,
    },
});
export default CircleSettingsStyle;
