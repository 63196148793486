export default function GoTo(history, url, state, refresh = false) {
    if(refresh)
        window.location.href = url;
    else
        history.push(url, {...state, referrer: window.location.pathname});
}

//can only be used with refresh if navigated to current page with GoTo
export function GoBack(history, refresh = false) {
    if(refresh && history.location.state?.referrer)
        GoTo(history, history.location.state.referrer, history.location.state, true);
    else
        history.goBack();
}