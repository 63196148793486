import { makeStyles } from "@material-ui/core";
import { Typography, useTheme } from "@mui/material";

import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";
import Theme_WIP from "../styles/themes/main/Theme_WIP";

const component_style = (color, weight) => ({ text: { 
    color: color + " !important",
    fontWeight: weight
} });

export default function Text(props) {
    const {children} = props;
    const theme    = Theme_WIP; //useTheme();
    const color    = props.color ? 
        (theme.palette[props.color] ?
            theme.palette[props.color].contrastText : props.color
        ) : 
        theme.palette.white.contrastText;
    const weight   = props.bold ? theme.typography.fontWeightBold + " !important" : theme.typography.fontWeightRegular;
    const useStyle = makeStyles(component_style(color, weight));
    const style    = useStyle();

    return(
        <Grid {...props} id={undefined} {...props.gridProps}>
        <Typography className={style.text} {...props} {...props.textProps}>
        {props.itallic && props.id && (<i><FormattedMessage id={props.id} values={props.values}/></i>)}
          {!props.itallic && props.id ? (<FormattedMessage id={props.id} values={props.values}/>) : null}
          {props.itallic ? <i>{children}</i> :children}
        </Typography>
        </Grid>);
}