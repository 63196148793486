import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircleCard from "../Cards/CircleCard";
import Styles from "../../styles/component/ProfileTabs/CircleTabStyle";
import { GetUserId } from "../../apiServices/CommonMethods";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Alert, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Waypoint } from "react-waypoint";
import { useHistory } from "react-router-dom";
import { GET_ALL_CIRCLES_AND_SUBCIRCLES_BY_USERID } from "../../apiServices/Queries";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";

const useStyles = makeStyles(Styles);

export default function CircleTab(props) {
  const classes = useStyles();
  const userId = GetUserId();
  const history = useHistory();
  const [circleData, setCircleData] = React.useState([]);
  let [input, setInput] = React.useState({
    pageNumber: 1,
  });

  const getVariable = () => {
    const variable = {
      loginProfileId: userId,
      pageSize: 20,
      profileId: props.userId !== userId ? props.userId : null,
      pageNumber: input["pageNumber"],
    };
    // console.log("variable", variable);
    return variable;
  };

  const {data: queryData, loading, error} = useCachedQuery(
    "circlesAndSubs",
    GET_ALL_CIRCLES_AND_SUBCIRCLES_BY_USERID, 
    getVariable(),
    (data) => data?.allCirclesAndSubCirclesByUserId?.result?.circles,
    getVariable(),
    5,
    true
  );
  useEffect(() => {
    if(!queryData) return;
    if (input["pageNumber"] === 1)
      setCircleData(queryData);
    else if (queryData.length > 0)
      setCircleData((prevState) => [...prevState, ...queryData]);
  },[queryData]);

  const updateCircleDetails = (circleListData) => {
    // history.push("/dashboard/circleDetails", {
    //   circleDetails: circleListData,
    //   selectedTab: 0,
    // });
    // console.log("updateCircleDetails 124")
    history.push("/dashboard/circleDetails/?id=" + circleListData.id, {
      circleDetails: circleListData,
      selectedTab: 0,
    });
  };

  return (
    <Grid container gap={4} mt={-4}>
      <Grid container spacing={4} className={classes.mb15}>
        {error ? (
          <Grid item sm={12} xs={12}>
            <Alert severity="error">
              {error.length ? (
                error
              ) : (
                <FormattedMessage id={"somethingWentWrong"} />
              )}
            </Alert>
          </Grid>
        ) : null}
      </Grid>

      {loading && !circleData.length ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : circleData.length > 0 ? (
        circleData.map((circleListData, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={12}
              key={index}
              onClick={() => {
                updateCircleDetails(circleListData);
              }}
            >
              <CircleCard circleData={circleListData} />
            </Grid>
            {index === circleData.length - 2 && (
              <Waypoint
                scrollableAncestor={window}
                onEnter={() => {
                  const newPage = input["pageNumber"] + 1;
                  // console.log("circleData.length", circleData.length);
                  // console.log("index", index);
                  setInput((prevState) => ({
                    ...prevState,
                    pageNumber: newPage,
                  }));
                }}
              />
            )}
          </React.Fragment>
        ))
      ) : (
        <Paper elevation={3} className={classes.tabcontent} sx={{width: "100%"}}>
          <Grid container spacing={4}>
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 200,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Typography component="span" variant="body1" color={"textPrimary"}>
                  <FormattedMessage id={"noDataAvailable"} />
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
}
