import { tabStyle } from "./CommonStyle";

const EventsPageStyle = (theme) => ({
  ...tabStyle,

  logo: {
    width: "100%",
  },
  mt5: {
    marginTop: "5px",
  },
  p7: {
    marginTop: 7,
  },
  p15: {
    marginTop: 15,
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  Select: {
    width: "100%",
    marginTop: 7,
  },
  iconSearch: {
    position: "relative",
    right: 50,
    top: 15,
    color: theme.palette.color5.main,
  },
  iconFilter: {
    position: "relative",
    top: 7,
    color: theme.palette.color5.main,
  },
  EventBg: {
    backgroundColor: "#590a8f",
    paddingBottom: 20,
    color: "#fff",
    paddingTop: 20,
    marginTop: -5
  },
  tabcontent: {
    margin: "0px 0 15px 0",
  },
  mLeft3: {
    marginLeft: 3,
  },
  closeicon: {
    float: "right",
    fontSize: 32,
    color: theme.palette.color5.main,
  },
  iconReset: {
    marginTop: "13px",
    padding: "0 !important",
    color: theme.palette.color5.main,
    
  },
});
export default EventsPageStyle;
