
const AccountSettingStyle = (theme) => ({
    root: {
        width: "100%",
    },
    // txtarea: {
    //     color:"#828282",
    //     margin: "0px !important",        
    //     width: "50% !important",
    //     '@media (max-width: 500px)': {
    //         width: "100% !important",
    //     },
    // },
    button: {
        width: "100%",
    },
    mt5: {
        marginTop: "5px",
    },
    mt7: {
        marginTop: "7px",
    },
    mt10: {
        marginTop: "10px",
    },
    mb10: {
        marginBottom: "10px",
    },
    mt20: {
        marginTop: "20px",
    },
    mt10Center: {
        marginTop: "10px",
        textAlign: 'center',
    },
    mt30mb15: {
        marginTop: "30px",
        marginBottom: "15px",
    },
    Select: {
        width: "100%",
    },
    txtarea: {
        width: "100%",
        border: 'none',
        backgroundColor: "#e8e8e8",
        borderRadius: 6,
        padding: "2%",
    },
    btxt: {
        color: "#72B6CD"
    },
    invoiceStyle: {
        color: "#1976d2"
    },
    tabcontent: {
        margin: "0px 0 15px 0",
        padding: "10px !important",
    },
    logo: {
        width: "100%",
    },
    imgSettings: {
        display: 'flex',
    },
    mtb7: {
        marginTop: "7px",
        marginBottom: "7px",
    },
    mtb10: {
        marginTop: "10px",
        marginBottom: "10px",
    },
});
export default AccountSettingStyle;