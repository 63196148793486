import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CollaborationOfInterest from "./InfoTabs/CollaborationOfInterest";
import EditCollaborationOfInterest from "./InfoTabs/EditCollaborationOfInterest";
import CompanyInfo from "./InfoTabs/CompanyInfo";
import EditCompanyInfo from "./InfoTabs/EditCompanyInfo";
import BioInfo from "./InfoTabs/BioInfo";
import CloseIcon from "@material-ui/icons/Close";
import EditBioInfo from "./InfoTabs/EditBioInfo";
import Styles from "../../styles/component/ProfileTabs/InfoTabStyle";
import TabPanel from "./TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { Alert, Skeleton, ThemeProvider } from "@mui/material";
import { ClearCache, GetUserId, removeItemOnce } from "../../apiServices/CommonMethods";
import { CollaborationTypes } from "../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import Text from "../../visualComponents/Text";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import { useGetProfileCollabs, useGetProfileCompanies } from "../../apiServices/Queries/Profiles/GetProfile";

const useStyles = makeStyles(Styles);
//todo: this works poorly. make it clean and test it.
//todo: clear profile cash once anything is edited
//todo: commented out code is mainly for collabs. Delete it if we decide to drop it
export default function InfoTab(props) {
  const classes = useStyles();
  const userID = GetUserId();
  const intl = useIntl();
  const [iseditCollaborationOfInterest, setIsEditCollaborationOfInterest] =
    React.useState(false);
  const [iseditCollaborationOnly, setIseditCollaborationOnly] =
    React.useState(false);
  const [iseditCompanyOnly, setIseditCompanyOnly] = React.useState(false);
  const [iseditCompanyInfo, setIseditCompanyInfo] = React.useState(false);
  const [iseditBioInfo, setIseditBioInfo] = React.useState(false);
  // const [valueCollab, setValueCollab] = React.useState({ itemType: "" });
  const [valueCompanyTab, setValueCompanyTab] = React.useState("");
  // const [collabArray, setCollabArray] = React.useState([]);
  const [companyArray, setCompanyArray] = React.useState([]);
  const [profileData, setProfileData] = React.useState(props.profileData);
  const { data: dataCompany, updateVariables: refetch       } = useGetProfileCompanies(props.userId);
  const { data: dataCollab , updateVariables: refetchCollab } = useGetProfileCollabs  (props.userId);

  useEffect(() => setProfileData(props.profileData), [props.profileData]);

  React.useEffect(() => {
    if (dataCompany) {
      setCompanyArray(dataCompany);
      if (dataCompany.length > 0) {
        setValueCompanyTab(dataCompany[0]);
      }
    }

    // if (dataCollab) {
    //   let arrayCollaborations = generateCollabArray(
    //     dataCollab
    //   );
    //   setCollabArray(arrayCollaborations);
    //   if (arrayCollaborations.length) {
    //     setValueCollab(arrayCollaborations[0]);
    //   }
    // }
  }, [dataCompany, dataCollab]);

  // const generateCollabArray = (dataColllabFromServer) => {
  //   var arrayCollaborations = [];
  //   if (dataColllabFromServer.businessPartnerCollaborationInterest) {
  //     let dataDict = {
  //       ...dataColllabFromServer.businessPartnerCollaborationInterest,
  //       itemType: CollaborationTypes.businessPartners,
  //     };
  //     arrayCollaborations.push(dataDict);
  //   }
  //   if (dataColllabFromServer.investmentCollaborationInterest) {
  //     let dataDict = {
  //       ...dataColllabFromServer.investmentCollaborationInterest,
  //       itemType: CollaborationTypes.investment,
  //     };
  //     arrayCollaborations.push(dataDict);
  //   }
  //   if (dataColllabFromServer.coFoundingPartnerCollaborationInterest) {
  //     let dataDict = {
  //       ...dataColllabFromServer.coFoundingPartnerCollaborationInterest,
  //       itemType: CollaborationTypes.coFounding,
  //     };
  //     arrayCollaborations.push(dataDict);
  //   }
  //   if (dataColllabFromServer.projectPartnerCollaborationInterest) {
  //     let dataDict = {
  //       ...dataColllabFromServer.projectPartnerCollaborationInterest,
  //       itemType: CollaborationTypes.projectPartners,
  //     };
  //     arrayCollaborations.push(dataDict);
  //   }
  //   if (dataColllabFromServer.strategicPartnerCollaborationInterest) {
  //     let dataDict = {
  //       ...dataColllabFromServer.strategicPartnerCollaborationInterest,
  //       itemType: CollaborationTypes.strategicPartners,
  //     };
  //     arrayCollaborations.push(dataDict);
  //   }
  //   if (dataColllabFromServer.mentoringCollaborationInterestPayload) {
  //     let dataDict = {
  //       ...dataColllabFromServer.mentoringCollaborationInterestPayload,
  //       itemType: CollaborationTypes.mentoring,
  //     };
  //     arrayCollaborations.push(dataDict);
  //   }
  //   return arrayCollaborations;
  // };

  // const handleChange = (event, newValue) => {
  //   let filteredCollabs = collabArray.filter((collab) => {
  //     return collab.itemType === newValue;
  //   });
  //   // console.log("filtered array", filteredCollabs);
  //   setValueCollab(filteredCollabs[0]);
  // };

  const handleChangeCompanyTab = (event, newValue) => {
    let filteredCompany = companyArray.filter((company) => {
      return company.name === newValue;
    });
    // console.log("filtered array", filteredCompany);
    setValueCompanyTab(filteredCompany[0]);
  };

  // const editCollaborationOfInterest = () => {
  //   setIsEditCollaborationOfInterest(true);
  //   setIseditCompanyInfo(false);
  //   setIseditCollaborationOnly(!collabArray.length);
  //   setIseditBioInfo(false);
  //   setIseditCompanyOnly(false);
  // };
  // const editCollaborationOfInterestOnly = () => {
  //   let filteredCollab = collabArray.filter((collab) => {
  //     return collab.isLocal;
  //   });
  //   if (!filteredCollab.length) {
  //     setIsEditCollaborationOfInterest(true);
  //     setIseditCompanyInfo(false);
  //     setIseditCollaborationOnly(true);
  //     setIseditBioInfo(false);
  //     setIseditCompanyOnly(false);
  //   }
  // };
  // const saveEditCollaborationOfInterest = (selectedValue, isSave) => {
  //   ClearCache("selfProfile_collabs");
  //   if (isSave) {
  //     let newDict = { ...selectedValue, isLocal: true };
  //     setCollabArray([...collabArray, newDict]);
  //     setValueCollab(newDict);
  //     setIsEditCollaborationOfInterest(true);
  //     setIseditCollaborationOnly(false);
  //   } else {
  //     setIsEditCollaborationOfInterest(false);
  //     setIseditCollaborationOnly(false);
  //     refetchCollab({userId: props.userId});
  //   }
  //   // console.log("isSave", isSave);
  //   // console.log("selectedValue", selectedValue);
  // };

  const editCompanyInfo = () => {
    setIseditCompanyInfo(true);
    setIseditCompanyOnly(!companyArray.length);
    setIseditCollaborationOnly(false);
    setIsEditCollaborationOfInterest(false);
    setIseditBioInfo(false);
  };
  const editCompanyNameOnly = () => {
    // console.log("companyArray", companyArray);
    let filteredCompany = companyArray.filter((company) => {
      return typeof company.companyId === "undefined";
    });
    if (!filteredCompany.length) {
      setIseditCompanyOnly(true);
      setIseditCompanyInfo(true);
      setIsEditCollaborationOfInterest(false);
      setIseditCollaborationOnly(false);
      setIseditBioInfo(false);
      let newCompany = {
        name: intl.formatMessage({ id: "New" }),
        companyRole: "",
        companyLevel: "",
        includedInCompanySince: "",
        industry: [],
        workField: "",
        keyValues: [],
        mission: "",
        website: "",
        numberOfEmployees: "",
        annualRevenue: "",
        certifications: [],
        incubatorOrAccelerator: "",
      };
      setCompanyArray([...companyArray, newCompany]);
      setValueCompanyTab(newCompany);
    }
  };
  const saveEditCompanyInfo = (selectedValue, isSave) => {
    ClearCache("selfProfile_company");
    // console.log("saveEditCompanyInfo", isSave);
    setIseditCompanyOnly(false);
    if (isSave) {
      let filteredCompany = companyArray.filter((company) => {
        return company.companyRole === "";
      });
      if (filteredCompany.length) {
        removeItemOnce(companyArray, filteredCompany[0]);
      }
      setCompanyArray([...companyArray, selectedValue]);
      setValueCompanyTab(selectedValue);
      setIseditCompanyInfo(true);
    } else {
      // console.log("refetch");
      refetch({userId: props.userId});
      setIseditCompanyInfo(false);
    }
  };

  const closeCompanyEditView = () => {
    // console.log("closeCompanyEditView");

    setIseditCompanyInfo(false);
    let filteredCompany = companyArray.filter((company) => {
      return company.companyRole === "";
    });
    if (filteredCompany.length) {
      removeItemOnce(companyArray, filteredCompany[0]);
    }
    if (companyArray.length) {
      setValueCompanyTab(companyArray[0]);
    }
  };

  const editBioInfo = () => {
    setIseditBioInfo(true);
    setIseditCompanyInfo(false);
    setIseditCompanyOnly(false);
    setIseditCollaborationOnly(false);
    setIsEditCollaborationOfInterest(false);
  };
  const saveBioInfo = (profileObject) => {
    setIseditBioInfo(false);
    setProfileData(profileObject);
  };

  // const closeEditView = () => {
  //   setIsEditCollaborationOfInterest(false);
  //   setIseditCollaborationOnly(false);
  //   // console.log("Collab Array", collabArray);
  //   let filteredCollab = collabArray.filter((collab) => {
  //     return collab.isLocal;
  //   });
  //   if (filteredCollab.length) {
  //     filteredCollab.forEach((collab) => {
  //       removeItemOnce(collabArray, collab);
  //     });
  //   }
  //   if (collabArray.length) {
  //     setValueCollab(collabArray[0]);
  //   }
  // };

  return (
    <ThemeProvider theme={Theme_WIP}>
    {profileData && <div className={classes.root}>
      {iseditBioInfo ? (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Typography
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage
                id={"InfoTab_TellYourFutureCollaborationsAboutYou"}
              />
            </Typography>
            <Typography
              variant="h3"
              color={"textSecondary"}
              className={classes.p7}
            >
              <FormattedMessage id={"InfoTab_Description1"} />
            </Typography>
          </Grid>
        </Grid>
      ) : null}

      <Paper elevation={3} className={classes.tabcontent}>
        <Text variant="h1" color="white" bold>
          <FormattedMessage id={"bio"} />{" "}
          {props.userId === userID ? (
            iseditBioInfo ? (
              <CloseIcon
                color="default"
                className={classes.closeicon}
                onClick={saveBioInfo}
              />
            ) : (
              <MoreHorizIcon
                className={classes.doticonbottom}
                onClick={editBioInfo}
              />
            )
          ) : null}
        </Text>
        {iseditBioInfo ? (
          <EditBioInfo
            saveAction={saveBioInfo}
            profileData={profileData}
            userId={props.userId}
          />
        ) : (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Grid container spacing={4}>
              {profileData.expertiseLevel !== null ? (
                <BioInfo userId={props.userId} profileData={profileData} />
              ) : (
                <Grid item xs={10} sm={10}>
                  {!profileData ? (
                    <Skeleton variant="rectangular" height={60} />
                  ) : (
                    <Typography
                      component="span"
                      variant="h3"
                      color={"textPrimary"}
                      gutterBottom
                    >
                      <FormattedMessage id={"noDataAvailable"} />
                    </Typography>
                  )}
                </Grid>
              )}{" "}
            </Grid>
          </Box>
        )}
      </Paper>

      {/* COLLABORATIONS */}
      {/* <Paper elevation={3} className={classes.tabcontent}>
        <Text variant="h1" color="white" bold>
          <FormattedMessage id={"InfoTab_COLLABORATIONSOFINTEREST"} />{" "}
          {props.userId === userID ? (
            iseditCollaborationOfInterest ? (
              <CloseIcon
                color="default"
                className={classes.closeicon}
                onClick={closeEditView}
              />
            ) : (
              <MoreHorizIcon
                className={classes.doticonbottom}
                onClick={editCollaborationOfInterest}
              />
            )
          ) : null}
        </Text>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container spacing={4}>
            {collabArray.length ? (
              <Grid
                item
                xs={iseditCollaborationOfInterest ? 10 : 12}
                sm={iseditCollaborationOfInterest ? 10 : 12}
              >
                <Tabs
                  value={valueCollab.itemType}
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                  variant="scrollable"
                  scrollButtons={false}
                >
                  {collabArray.map((collabDict, index) => (
                    <Tab
                      key={index}
                      value={collabDict.itemType}
                      label={collabDict.itemType}
                      wrapped
                      className={classes.tabProfile}
                    />
                  ))}
                </Tabs>
              </Grid>
            ) : (
              <Grid item xs={10} sm={10}>
                {!profileData ? (
                  <Skeleton variant="rectangular" height={60} />
                ) : (
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                )}
              </Grid>
            )}
            {iseditCollaborationOfInterest ? (
              <Grid item xs={2} sm={2}>
                <Box>
                  <Button
                    variant="text"
                    onClick={editCollaborationOfInterestOnly}
                    size={"small"}
                  >
                    <AddIcon className={classes.doticonbottom} />
                  </Button>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        <TabPanel
          value={valueCollab.itemType ?? ""}
          index={valueCollab.itemType ?? 0}
        >
          {iseditCollaborationOfInterest ? (
            <EditCollaborationOfInterest
              saveAction={saveEditCollaborationOfInterest}
              collaborationOnly={iseditCollaborationOnly}
              data={valueCollab}
              collabArray={collabArray}
            />
          ) : collabArray.length ? (
            <CollaborationOfInterest data={valueCollab} />
          ) : null}
        </TabPanel>
      </Paper> */}

      <Paper elevation={3} className={classes.tabcontent}>
        <Text variant="h1" color="white" bold>
          <FormattedMessage id={"InfoTab_COMPANY"} />{" "}
          {props.userId === userID ? (
            iseditCompanyInfo ? (
              <CloseIcon
                className={classes.closeicon}
                onClick={closeCompanyEditView}
              />
            ) : (
              <MoreHorizIcon
                className={classes.doticonbottom}
                onClick={editCompanyInfo}
              />
            )
          ) : null}
        </Text>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container spacing={4}>
            {companyArray.length ? (
              <Grid
                item
                xs={iseditCompanyInfo ? 10 : 12}
                sm={iseditCompanyInfo ? 10 : 12}
              >
                <Tabs
                  value={valueCompanyTab.name}
                  onChange={handleChangeCompanyTab}
                  aria-label="wrapped label tabs example"
                  variant="scrollable"
                  scrollButtons={false}
                >
                  {companyArray.map((companyDict, index) => (
                    <Tab
                      key={index}
                      value={companyDict.name}
                      label={companyDict.name}
                      wrapped
                      className={classes.tabProfile}
                    />
                  ))}
                </Tabs>
              </Grid>
            ) : (
              <Grid item xs={10} sm={10}>
                {!profileData ? (
                  <Skeleton variant="rectangular" height={60} />
                ) : (
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                )}
              </Grid>
            )}
            {iseditCompanyInfo ? (
              <Grid item xs={2} sm={2}>
                <Button variant="text" onClick={editCompanyNameOnly}>
                  <AddIcon className={classes.doticonbottom} />
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        <TabPanel
          value={valueCompanyTab.name ?? ""}
          index={valueCompanyTab.name ?? 0}
        >
          {iseditCompanyInfo ? (
            <EditCompanyInfo
              saveAction={saveEditCompanyInfo}
              companyOnly={iseditCompanyOnly}
              data={valueCompanyTab}
              companyArray={companyArray}
            />
          ) : companyArray.length ? (
            <CompanyInfo companyData={valueCompanyTab} />
          ) : null}
        </TabPanel>
      </Paper>
    </div>}
    </ThemeProvider>
  );
}
InfoTab.prototype = {
  userId: PropTypes.string,
  updateProfileData: PropTypes.func,
};
