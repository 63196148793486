const ProjectPartnerStyle = (theme) => ({
    p7: {
        marginTop: 7,
    },
    p7c: {
        marginTop: 7,
        color: "#828282",
    },
    p15: {
        marginTop: 15,
    },
    p25: {
        marginTop: 25,
    },
    Select: {
        width: "100%",
        marginTop: 7,
    },
    btxt: {
        marginTop: 15,
        color: "#72B6CD"
    },
    submit: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        width: 150
    },
    txtarea: {
        width: "100%",
        border: 'none',
        backgroundColor: theme.palette.color4.main,
        borderRadius: 6,
        padding: "2%",
    },
    chip: {
        margin: theme.spacing(0.5),
        color: theme.palette.color5.main,
        borderRadius: 5
    },
});
export default ProjectPartnerStyle;