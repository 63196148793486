import React, { useEffect } from 'react'
import { signoutRedirectCallback } from './userService'
import { useHistory } from 'react-router-dom'
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { ClearItems } from '../../apiServices/CommonMethods';
import { config } from '../../Config';

function SignoutOidc() {
  const history = useHistory()
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback()
      ClearItems();
      window.location.href = config.publicPage;
    }
    signoutAsync()
  }, [history])

  return (
    <div>
      <Typography component="h2" variant="h2">
        <FormattedMessage id={"redirecting"} />
      </Typography>
    </div>
  )
}

export default SignoutOidc
