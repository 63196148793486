import React from "react";
import Text from "../../../visualComponents/Text";
import { Link, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ActivityEventCancel({activity, source, color}) {
  const history      = useHistory();
  const placeholders = {event_name: <Link
    color="inherit"
    component = "button"
    underline = "always"
    onClick={() => 
      history.push("/dashboard/eventDetails/?id=" + activity.activity.eventId, {
        selectedEventId: activity.activity.eventId,
      })}
  >
    {activity.activity.eventName}
  </Link>};

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity.activity &&
        <Text item xs={12} color={color??"background"} id="CanceledByTheEventManagerYouCanNoLongerAccessTheEvent" values={placeholders} />
      }
    </ThemeProvider>
  );
}
