import { drawerWidth } from '../container/CommonStyle'

const NavigationbarStyle = (theme) => ({

    appBar: {
        marginLeft: drawerWidth,
        width: '100%',
        height: 51,
        backgroundColor: "white",
        boxShadow: "0px 4px 16px 0px rgba(0, 57, 77, 0.10)"
    },

    toolbar: {
        minHeight: 50,
        backgroundColor: "white",
    },
    menuButton: {
        height: 30
    },
    iconButton: {
        color: theme.palette.color5.main
    }


});
export default NavigationbarStyle;