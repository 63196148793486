//TODO: This file is absolutely in-navigable... Please split this up in a manner which makes sense
import { gql } from "@apollo/client";

export const GET_AUTH_PROFILE = gql`
  query get {
    validateSession {
      email
      userId
      authenticated
      role
      admin
    }
  }
`;

export const GET_NOTIFICATION_COUNT = gql`
  query get {
    feedNotificationCount
  }
`;

export const GET_ADMIN_USER = gql`
  query get {
    adminUser {
      result {
        userID
      }
    }
  }
`;
export const GET_USER_ROLE_BY_ID = gql`
  query get($id: String) {
    currentRoleByUserId(userId: $id) {
      role
      responses
      usedAllResponses
      subType
      maxResponses
      subscriptionEndDate
      scheduledForCancellation
    }
  }
`;

export const GET_PROFILE = gql`
  query profile($userId: String!, $loggedInUserId: String) {
    profile(userId: $userId, loggedInUserId: $loggedInUserId) {
      userId
      createdDate
      skills
      givenName
      email
      familyName
      professionalLife
      personalLife
      languages
      serivcesForClients
      servicesForNetwork
      pastClients
      networks
      portfolio
      role
      description
      country
      visionIn5Years
      companyName
      profilePictureUrl
      locationPlaceID
      matchStatus
      thankyouCount
      myMatchesCount
      expertiseLevel
      circles
      companies {
        name
        companyRole
        companyLevel
        certifications
        includedInCompanySince
        incubatorOrAccelerator
        industry
        keyValues
        mission
        annualRevenue
      }
    }
  }
`;

export const GET_PROFILE_DOMAIN_PAYLOAD = gql`
  query profileDomainPayload {
    profileDomainPayload {
      companyLevel {
        key
        value
      }
      companyRole {
        key
        value
      }
      experienceLevel {
        key
        value
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query company($userId: String!) {
    company(userId: $userId) {
      errorCode
      message
      result {
        companies {
          name
          companyRole
          includedInCompanySince
          companyLevel
          industry
          workfield
          keyValues
          mission
          website
          numberOfEmployees
          annualRevenue
          certifications
          incubatorOrAccelerator
          industry
          companyId
        }
      }
    }
  }
`;

export const GET_SKILLS = gql`
  query allSkill {
    allSkill {
      id
      name
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query allIndustry {
    allIndustry {
      id
      industry
    }
  }
`;
export const GET_COLLAB_PAYLOAD = gql`
  query collaborationDomainPayload {
    collaborationDomainPayload {
      errorCode
      message
      result {
        businessPartnerBenefitType {
          key
          value
        }
        collaborationType {
          key
          value
        }
        commitment {
          key
          value
        }
        investmentType {
          key
          value
        }
        investorType {
          key
          value
        }
        companyStage {
          key
          value
        }
        businessStage {
          key
          value
        }
        period {
          key
          value
        }
        days {
          key
          value
        }
      }
    }
  }
`;

export const GET_PERSONAL_LIFE_PAYLOAD = gql`
  query personalLifePayload {
     personalLife{
      personalLife
     }
  }
`;

export const GET_PROFESSIONAL_LIFE_PAYLOAD = gql`
  query professionalLife {
     professionalLife{
      professionalLife
     }
  }
`;
export const GET_COLLAB = gql`
  query allCollaborationInterest($userId: String!) {
    allCollaborationInterest(userId: $userId) {
      errorCode
      message
      result {
        businessPartnerCollaborationInterest {
          interestedIn
          userId
        }
        investmentCollaborationInterest {
          companyStage
          commitment
          industries
          interestedIn
          investmentCollective
          investmentType
          investorType
          location
          preferredCompanyRole
          userId
        }
        coFoundingPartnerCollaborationInterest {
          businessOpportunityDescription
          businessStage
          commitment
          industries
          isAvailable
          location
          preferredCompanyRole
          skills
          sweatCommitmentInHours
          userId
        }
        projectPartnerCollaborationInterest {
          averageDurationInMonths
          industries
          interestedIn
          location
          myPartOfThePartnership
          userId
        }
        strategicPartnerCollaborationInterest {
          industries
          interestedIn
          location
          myPartOfThePartnership
          preferredBenefits
          skills
          userId
        }
        mentoringCollaborationInterestPayload {
          industries
          interestedIn
          isAvailable
          location
          myPartOfThePartnership
          skills
          userId
        }
      }
    }
  }
`;

// export const GET_MASTERS = gql`
//   query allMasters(
//     $userId: String
//     $pageNumber: Int
//     $pageSize: Int
//     $location: String
//     $industries: [String]
//     $skills: [String]
//     $searchInput: String
//     $maxDistance: Int
//     $collaborationType: [String]
//     $businessType: [String]
//     $isMyMatches: Boolean!
//     $circleId: String
//     $showLoggedInUser: Boolean
//   ) {
//     allMasters(
//       mastersRequest: {
//         userId: $userId
//         pageNumber: $pageNumber
//         pageSize: $pageSize
//         location: $location
//         industries: $industries
//         skills: $skills
//         searchInput: $searchInput
//         maxDistance: $maxDistance
//         collaborationType: $collaborationType
//         businessType: $businessType
//         isMyMatches: $isMyMatches
//         circleId: $circleId
//         showLoggedInUser: $showLoggedInUser
//       }
//     ) {
//       errorCode
//       message
//       result {
//         profiles {
//           givenName
//           familyName
//           skills
//           userId
//           location
//           companyName
//           profilePictureUrl
//           role
//           totalCount
//           matchStatus
//           sharedMatchesCount
//           sharedCircles
//         }
//       }
//     }
//   }
// `;
export const GET_MASTERS = gql`
  query allMastersExtn(
    $userId: String
    $pageNumber: Int
    $pageSize: Int
    $location: String
    $industries: [String]
    $skills: [String]
    $searchInput: String
    $maxDistance: Int
    $collaborationType: [String]
    $businessType: [String]
    $isMyMatches: Boolean!
    $circleId: String
    $showLoggedInUser: Boolean
  ) {
    allMastersExtn(
      mastersRequest: {
        userId: $userId
        pageNumber: $pageNumber
        pageSize: $pageSize
        location: $location
        industries: $industries
        skills: $skills
        searchInput: $searchInput
        maxDistance: $maxDistance
        collaborationType: $collaborationType
        businessType: $businessType
        isMyMatches: $isMyMatches
        circleId: $circleId
        showLoggedInUser: $showLoggedInUser
      }
    ) {
      errorCode
      message
      result {
        profiles {
          givenName
          familyName
          skills
          userId
          location
          companyName
          profilePictureUrl
          role
          totalCount
          matchStatus
          sharedMatchesCount
          sharedCircles
        }
      }
    }
  }
`;

export const GET_MASTERS_MATCHES = gql`
  query allProfilesByUserIds(
    $userId: String
    $pageNumber: Int
    $pageSize: Int
    $location: String
    $industries: [String]
    $skills: [String]
    $searchInput: String
    $maxDistance: Int
    $collaborationType: [String]
    $businessType: [String]
    $isMyMatches: Boolean!
  ) {
    allProfilesByUserIds(
      mastersRequest: {
        userId: $userId
        pageNumber: $pageNumber
        pageSize: $pageSize
        location: $location
        industries: $industries
        skills: $skills
        searchInput: $searchInput
        maxDistance: $maxDistance
        collaborationType: $collaborationType
        businessType: $businessType
        isMyMatches: $isMyMatches
      }
    ) {
      errorCode
      message
      result {
        profiles {
          givenName
          familyName
          skills
          userId
          location
          companyName
          profilePictureUrl
          role
          totalCount
          sharedMatchesCount
          sharedCircles
        }
      }
    }
  }
`;

export const GET_NEW_ACTIVITIES = gql`
  query latestActivities($userId: String, $activitySourceId: String,$pageNumber: Int,$pageSize: Int) {
    latestActivities(
      activityRequest: { userID: $userId, activitySourceId: $activitySourceId ,
        pageNumber: $pageNumber,pageSize: $pageSize
      }
    ) {
      errorCode
      message
      result {
        recipientUserProfiles {
          familyName
          givenName
        }
        activity {
          metaData {
            key
            value
          }
          userRole
          activityType
          messages
          status
          createdDate
          id
          activitySourceDesc
          activitySource
          activitySourceId
          recipientUserProfileIds
          requesterUserProfileId
          activityTypeDesc
          matchRequestPurpose
          updatedDate
          eventName
          eventId
          meetings {
            meetingDateTime
            meetingType
            locationURL
            id
            status
          }
          circleName
          circleId
          isActionNeeded
          statusBeforeClosing
          isKnownPerson
        }
        profilePictureUrl
        email
        givenName
        familyName
        userId
        showProfileBasedOnPreference
      }
    }
  }
`;

export const GET_PENDING_ACTIVITIES = gql`
  query pendingActivities($userId: String, $activitySourceId: String
   $pageNumber: Int,$pageSize: Int) {
    pendingActivities(
      activityRequest: {
        userID: $userId
        activitySourceId: $activitySourceId
        activitySource: MATCH_REQUEST,
         pageNumber: $pageNumber,
        pageSize: $pageSize
      }
    ) {
      errorCode
      message
      result {
        recipientUserProfiles {
          familyName
          givenName
        }
        activity {
          metaData {
            key
            value
          }
          activityType
          messages
          status
          createdDate
          id
          updatedDate
          activitySourceDesc
          activitySource
          activitySourceId
          recipientUserProfileIds
          requesterUserProfileId
          activityTypeDesc
          matchRequestPurpose
          eventName
          meetings {
            meetingDateTime
            meetingType
            locationURL
            id
            status
          }
          circleName
          circleId
          isActionNeeded
          isKnownPerson
        }
        profilePictureUrl
        email
        givenName
        familyName
        userId
        showProfileBasedOnPreference
      }
    }
  }
`;

export const GET_ALL_ACTIVITIES = gql`
  query toAllActivities($userId: String, $activitySourceId: String, $activityUserID: String, $pageNumber: Int,$pageSize: Int) {
    toAllActivities(
      activityRequest: { userID: $userId,
        activitySourceId: $activitySourceId, activityUserID: $activityUserID, pageNumber: $pageNumber
        pageSize: $pageSize }
    ) {
      errorCode
      message
      result {
        recipientUserProfiles {
          familyName
          givenName
        }
        activity {
          metaData {
            key
            value
          }
          userRole
          activityType
          messages
          status
          createdDate
          id
          activitySourceDesc
          activitySource
          activitySourceId
          recipientUserProfileIds
          requesterUserProfileId
          activityTypeDesc
          matchRequestPurpose
          updatedDate
          eventName
          eventId
          meetings {
            meetingDateTime
            meetingType
            locationURL
            id
            status
          }
          circleName
          circleId
          isActionNeeded
          statusBeforeClosing
          isKnownPerson
        }
        profilePictureUrl
        email
        givenName
        familyName
        userId
        showProfileBasedOnPreference
      }
    }
  }
`;

export const GET_COLLAB_REQUESTS = gql`
  query allCollaborationRequest(
    $userId: String
    $pageNumber: Int
    $pageSize: Int
    $location: String
    $industries: [String]
    $skills: [String]
    $searchInput: String
    $maxDistance: Float
    $collaborationCategoryId: String
    $businessTypes: [String]
    $circleId: String
    $requestId: String
    $eventId: String,
    $locationPlaceID:String,
    $matchesOnly:Boolean,
    $profileReqOnly:Boolean,
    $loginProfileId:String,
    $filterType:ReqFilterType
  ) {
    allCollaborationRequest(
      _input: {
        userId: $userId
        pageNumber: $pageNumber
        pageSize: $pageSize
        location: $location
        industries: $industries
        skills: $skills
        searchInput: $searchInput
        maxDistance: $maxDistance
        collaborationCategoryId: $collaborationCategoryId
        businessTypes: $businessTypes
        circleId: $circleId
        requestId:$requestId
        eventId: $eventId,
        locationPlaceID: $locationPlaceID,
        matchesOnly:$matchesOnly,
        profileReqOnly:$profileReqOnly,
        loginProfileId:$loginProfileId
        filterType: $filterType
      }
    ) {
      errorCode
      message
      result {
        givenName
        profilePictureUrl
        familyName
        companyName
        companyRole
        location
        noOfSharedMatches
        requestDate
        updatedDate
        isMyRequests
        sharedCircles
        baseLocation
        locationPlaceId
        circles {
          id
          name
        }
        businessPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          commitment
          preferredBenefitType
          preferredCompanyRole
          id
          requestScopes
        }
        expertCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          jobDescription
          durationJobInMonths
          hoursPerWeek
          startDate
          id
          requestScopes
        }
        projectPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          projectDescription
          averageDurationInMonths
          hoursPerWeek
          startDate
          id
          requestScopes
        }
        mentoringCollaborationRequestPayload {
          userId
          titleRequest
          requestDate
          industries
          preferredFieldOfKnowledge
          wishFromMentor
          myChallenges
          id
          requestScopes
        }
        investmentCollaborationRequest {
          userId
          titleRequest
          requestDate
          businessDescription
          location
          industries
          id
          requestScopes
        }
        strategicPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          myPartOfThePartnership
          myPartOfThePartnership
          selectedToWhomIds
          id
          requestScopes
        }
        coFoundingPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          preferredCompanyRole
          businessStage
          commitment
          sweatCommitmentInHours
          id
          requestScopes
        }
        brainstormPartnerRequest {
          userId
          titleRequest
          requestDate
          partnershipDescription
          frequencyInMonths
          location
          skills
          entrepreneurshipLevel
          expertiseLevel
          id
          requestScopes
        }
        coworkingDateCollaborationRequest {
          userId
          titleRequest
          requestDate
          dateDescription
          days
          period
          skills
          location
          id
          requestScopes
        }
        mastermindCollaborationRequest {
          userId
          titleRequest
          requestDate
          mastermindDescription
          offer {
            description
            skills
          }
          request {
            description
            skills
          }
          id
          requestScopes
          categoryId
          categoryTitle
        }
        mastersForMastersCollaborationRequest {
          userId
          titleRequest
          requestDate
          preferredSkills
          offeredSkills
          collaborationDescription
          industries
          durationInMonths
          estimatedHours
          startDate
          location
          id
          requestScopes
        }
      }
    }
  }
`;

export const GET_COLLAB_REQUESTS_MYMATCHES = gql`
  query collaborationRequestByMatchs(
    $userId: String
    $pageNumber: Int
    $pageSize: Int
    $location: String
    $industries: [String]
    $skills: [String]
    $searchInput: String
    $maxDistance: Float
    $collaborationType: [String]
    $businessTypes: [String]
  ) {
    collaborationRequestByMatchs(
      _input: {
        userId: $userId
        pageNumber: $pageNumber
        pageSize: $pageSize
        location: $location
        industries: $industries
        skills: $skills
        searchInput: $searchInput
        maxDistance: $maxDistance
        collaborationType: $collaborationType
        businessTypes: $businessTypes
      }
    ) {
      errorCode
      message
      result {
        givenName
        profilePictureUrl
        familyName
        companyName
        companyRole
        location
        noOfSharedMatches
        sharedCircles
        requestDate
        isMyRequests
        baseLocation
        businessPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          commitment
          preferredBenefitType
          preferredCompanyRole
          id
        }
        expertCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          jobDescription
          durationJobInMonths
          hoursPerWeek
          startDate
          id
        }
        projectPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          projectDescription
          averageDurationInMonths
          hoursPerWeek
          startDate
          id
        }
        mentoringCollaborationRequestPayload {
          userId
          titleRequest
          requestDate
          industries
          preferredFieldOfKnowledge
          wishFromMentor
          myChallenges
          id
        }
        investmentCollaborationRequest {
          userId
          titleRequest
          requestDate
          businessDescription
          location
          industries
          id
        }
        strategicPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          myPartOfThePartnership
          myPartOfThePartnership
          selectedToWhomIds
          id
        }
        coFoundingPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          preferredCompanyRole
          businessStage
          commitment
          sweatCommitmentInHours
          id
        }
        brainstormPartnerRequest {
          userId
          titleRequest
          requestDate
          partnershipDescription
          frequencyInMonths
          location
          skills
          entrepreneurshipLevel
          expertiseLevel
          id
        }
        coworkingDateCollaborationRequest {
          userId
          titleRequest
          requestDate
          dateDescription
          days
          period
          skills
          location
          id
        }
        mastermindCollaborationRequest {
          userId
          titleRequest
          requestDate
          mastermindDescription
          offer {
            description
            skills
          }
          request {
            description
            skills
          }
          id
          categoryId
          categoryTitle
        }
        mastersForMastersCollaborationRequest {
          userId
          titleRequest
          requestDate
          preferredSkills
          offeredSkills
          collaborationDescription
          industries
          durationInMonths
          estimatedHours
          startDate
          location
          id
        }
      }
    }
  }
`;

export const GET_ACTIVITY_USERS = gql`
  query toWhoProfileListing($userId: String, $searchString: String) {
    toWhoProfileListing(
      request: { userId: $userId, searchString: $searchString }
    ) {
      errorCode
      message
      result {
        givenName
        familyName
        userId
      }
    }
  }
`;

export const GET_ALL_SHARED_MATCHES = gql`
  query sharedMatches($loginUserProfileId: String, $viewUserProfileId: String) {
    sharedMatches(
      request: {
        loginUserProfileId: $loginUserProfileId
        viewUserProfileId: $viewUserProfileId
      }
    ) {
      errorCode
      message
      result {
        sharedCircles
        profiles {
          givenName
          familyName
          companyName
          matchStatus
          skills
          profilePictureUrl
          role
          country
          userId
        }
        sharedMatchCount
      }
    }
  }
`;

export const GET_REQUESTS_BY_USERID = gql`
  query allCollaborationRequestsByUserIds(
    $userId: String
    $pageNumber: Int
    $pageSize: Int
    $loginProfileId: String
  ) {
    allCollaborationRequestsByUserIds(
      _input: {
        userId: $userId
        pageNumber: $pageNumber
        pageSize: $pageSize
        location: null
        industries: null
        skills: null
        searchInput: null
        maxDistance: null
        collaborationType: null
        businessTypes: null
        loginProfileId: $loginProfileId
      }
    ) {
      errorCode
      message
      result {
        givenName
        profilePictureUrl
        familyName
        companyName
        companyRole
        location
        noOfSharedMatches
        sharedCircles
        baseLocation
        requestDate
        isMyRequests
        requestScopes
        locationPlaceId
        circles {
          id
          name
        }
        businessPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          commitment
          preferredBenefitType
          preferredCompanyRole
          id
          locationPlaceID
        }
        expertCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          jobDescription
          durationJobInMonths
          hoursPerWeek
          startDate
          id
          locationPlaceID
        }
        projectPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          projectDescription
          averageDurationInMonths
          hoursPerWeek
          startDate
          id
          locationPlaceID
        }
        mentoringCollaborationRequestPayload {
          userId
          titleRequest
          requestDate
          industries
          preferredFieldOfKnowledge
          wishFromMentor
          myChallenges
          id
          locationPlaceID
        }
        investmentCollaborationRequest {
          userId
          titleRequest
          requestDate
          businessDescription
          location
          industries
          id
          locationPlaceID
        }
        strategicPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          myPartOfThePartnership
          myPartOfThePartnership
          selectedToWhomIds
          id
          locationPlaceID
        }
        coFoundingPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          preferredCompanyRole
          businessStage
          commitment
          sweatCommitmentInHours
          id
          locationPlaceID
        }
        brainstormPartnerRequest {
          userId
          titleRequest
          requestDate
          partnershipDescription
          frequencyInMonths
          location
          skills
          entrepreneurshipLevel
          expertiseLevel
          id
          locationPlaceID
        }
        coworkingDateCollaborationRequest {
          userId
          titleRequest
          requestDate
          dateDescription
          days
          period
          skills
          location
          id
          locationPlaceID
        }
        mastermindCollaborationRequest {
          userId
          titleRequest
          requestDate
          mastermindDescription
          offer {
            description
            skills
          }
          request {
            description
            skills
          }
          id
          locationPlaceID
          categoryId
          categoryTitle
        }
        mastersForMastersCollaborationRequest {
          userId
          titleRequest
          requestDate
          preferredSkills
          offeredSkills
          collaborationDescription
          industries
          durationInMonths
          estimatedHours
          startDate
          location
          id
          locationPlaceID
        }
      }
    }
  }
`;

export const GET_MY_CIRCLES = gql`
  query allCirclesByUserId($userId: String) {
    allCirclesByUserId(userId: $userId) {
      errorCode
      message
      result {
        circles {
          brandColor
          name
          shortDescription
          aboutus
          brandSecondColor
          canHaveEvents
          canHaveSubCircles
          circleTags
          circleTypeId
          externalUrl
          hasCustomMessages
          hasKnowledgePosts
          hasNewsUpdates
          hasOneOnOneMeetingReporting
          hasRevenueReporting
          id
          isHideForPublic
          isVisibleInPublicFeed
          label
          location
          logo
          membersArePubliclyVisible
          operatesIn
          shortDescription
        }
      }
    }
  }
`;

export const GET_CIRCLE_INFO = gql`
  query circleInfo($circleId: String, $loggedInUser: String) {
    circleInfo(circleId: $circleId, loggedInUser: $loggedInUser) {
      errorCode
      message
      result {
        members {
          role
        }
        isCompany
        brandColor
        name
        shortDescription
        aboutus
        brandSecondColor
        canHaveEvents
        canHaveSubCircles
        circleTags
        circleTypeId
        externalUrl
        hasCustomMessages
        hasKnowledgePosts
        hasNewsUpdates
        hasOneOnOneMeetingReporting
        hasRevenueReporting
        id      
        isHideForPublic
        isVisibleInPublicFeed
        label
        location
        logo
        membersArePubliclyVisible
        operatesIn
        shortDescription
        loggedInUserRole
        isPublished
        isCancelled
        isSubCircle
        parentCircleId
        parentCircleName
      }
    }
  }
`;

export const GET_CIRCLE_DOMAIN_PAYLOAD = gql`
  query circleDomain {
    circleDomain {
      result {
        getAllCircleTags {
          key
          value
        }
        getAllOperatesIn {
          key
          value
        }
      }
    }
  }
`;

export const GET_ALL_CIRCLES_WITH_FILTER = gql`
  query allCircles(
    $isCompany: Boolean
    $searchInput: String
    $selectedCircleTags: [String]
    $maxDistance: Int
    $selectedOperatesIn: String
    $selectedPlace: String
    $isPublished: Int
    $loggedInUser: String
    $memberId: String
    $pageSize: Int
    $pageNumber: Int
  ) {
    allCircles(
      circleRequest: {
        isCompany: $isCompany
        searchInput: $searchInput
        selectedCircleTags: $selectedCircleTags
        maxDistance: $maxDistance
        selectedOperatesIn: $selectedOperatesIn
        selectedPlace: $selectedPlace
        isPublished: $isPublished
        loggedInUser: $loggedInUser
        memberId: $memberId
        pageSize: $pageSize
        pageNumber: $pageNumber
      }
    ) {
      errorCode
      message
      result {
        circles {
          members {
            role
          }
          isCompany
          brandColor
          name
          shortDescription
          aboutus
          brandSecondColor
          canHaveEvents
          canHaveSubCircles
          circleTags
          circleTypeId
          externalUrl
          hasCustomMessages
          hasKnowledgePosts
          hasNewsUpdates
          hasOneOnOneMeetingReporting
          hasRevenueReporting
          id
          isHideForPublic
          isVisibleInPublicFeed
          label
          location
          logo
          membersArePubliclyVisible
          operatesIn
          shortDescription
          loggedInUserRole
          isPublished
          isCancelled
          isSubCircle
          parentCircleId
          parentCircleName
        }
      }
    }
  }
`;

export const GET_CIRCLE_BY_UUID = gql`
  query circleByUUID($uuid: String!) {
    circleByUUID(
      uuid: $uuid
    ) {
      errorCode
      message
      result {
        members {
          role
        }
        isCompany
        brandColor
        name
        shortDescription
        aboutus
        brandSecondColor
        canHaveEvents
        canHaveSubCircles
        circleTags
        circleTypeId
        externalUrl
        hasCustomMessages
        hasKnowledgePosts
        hasNewsUpdates
        hasOneOnOneMeetingReporting
        hasRevenueReporting
        id
        isHideForPublic
        isVisibleInPublicFeed
        label
        location
        logo
        membersArePubliclyVisible
        operatesIn
        shortDescription
        loggedInUserRole
        isPublished
        isCancelled
        isSubCircle
        parentCircleId
      }
    }
  }
`;


export const GET_CIRCLE_MEMBERS = gql`
  query circleMembers(
    $circleId: String
    $userId: String
    $pageNumber: Int
    $pageSize: Int
    $searchInput: String
    $skills: [String]
    $industries: [String]
    $collaborationType: [String]
    $businessTypes: [String]
    $location: String
    $maxDistance: Int
  ) {
    circleMembers(
      request: {
        circleId: $circleId
        userId: $userId
        pageNumber: $pageNumber
        pageSize: $pageSize
        searchInput: $searchInput
        skills: $skills
        industries: $industries
        collaborationType: $collaborationType
        businessTypes: $businessTypes
        location: $location
        maxDistance: $maxDistance
      }
    ) {
      errorCode
      message
      result {
        userId
        profilePictureUrl
        givenName
        familyName
        email
        phoneNumber
        location
        companyName
        companyRole
        activeMemberCount
        totalCount
        locationPlaceId
        skills
        businessTypes
        industries
        sharedCircles
        isMember
        isMatch
        circleRole
      }
    }
  }
`;

export const GET_ALL_SUB_CIRCLES = gql`
  query allSubCircles(
    $circleId: String
    $loggedInUser: String
    $pageSize: Int
    $pageNumber: Int
  ) {
    allSubCircles(
      _request: {
        loggedInUser: $loggedInUser
        circleId: $circleId
        pageSize: $pageSize
        pageNumber: $pageNumber
      }
    ) {
      errorCode
      message
      result {
        circles {
          members {
            role
          }
          brandColor
          name
          id
          logo
          shortDescription
          aboutus
          brandSecondColor
          canHaveEvents
          canHaveSubCircles
          circleTags
          circleTypeId
          externalUrl
          hasCustomMessages
          hasKnowledgePosts
          hasNewsUpdates
          hasOneOnOneMeetingReporting
          hasRevenueReporting

          isHideForPublic
          isVisibleInPublicFeed
          label
          location
          parentCircleId
          membersArePubliclyVisible
          operatesIn
          shortDescription
          loggedInUserRole
          isPublished
          isSubCircle
          isCancelled
          parentCircleName
        }
      }
    }
  }
`;

export const GET_CIRCLE_NEWS = gql`
  query circleNewsByUser(
    $circleId: String
    $userId: String
    $loginProfileId: String
    $profileId: String
    $pageNumber: Int
    $pageSize: Int
    $searchText: String
    $newsID: String
  ) {
    circleNewsByUser(
      circleRequest: {
        circleId: $circleId
        userId: $userId
        loginProfileId: $loginProfileId
        profileId: $profileId
        pageNumber: $pageNumber
        pageSize: $pageSize
        searchText: $searchText
        newsID: $newsID
      }
    ) {
      errorCode
      message
      result {
        id
        circleId
        givenName
        familyName
        companyName
        companyRole
        profilePictureUrl
        email
        phoneNumber
        location
        isMember
        isMatch
        newsInfo
        postedDate
        postedUser
        circleId
        createdDate
        createdProfileId
        isDeleted
        sharedCircles
        sharedMatchCount
      }
    }
  }
`;

export const GET_ALL_FEEDS = gql`
  query allCircleFeeds(
    $circleId: String
    $loginProfileId: String
    $pageNumber: Int!
    $pageSize: Int!
    $searchInput: String
    $hint: String
    $location: String
    $locationPlaceID:String
    $industries: [String]
    $skills: [String]
    $maxDistance: Float
    $collaborationType: [String]
    $businessTypes: [String]
    $sourceID:String
  ) {
    allCircleFeeds(
      request: {
        circleId: $circleId
        loginProfileId: $loginProfileId
        pageNumber: $pageNumber
        pageSize: $pageSize
        searchInput: $searchInput
        hint: $hint
        sourceID: $sourceID
        location: $location
        locationPlaceID:$locationPlaceID
        industries: $industries
        skills: $skills
        maxDistance: $maxDistance
        collaborationType: $collaborationType
        businessTypes: $businessTypes
      }
    ) {
      errorCode
      message
      result {
        userId
        profilePictureUrl
        givenName
        familyName
        email
        phoneNumber
        location
        baseLocation
        companyName
        companyRole
        sharedCircles
        sharedMatchesCount
        requestDate
        circles {
          id
          name
        }
        requestFeeds {
          businessPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          commitment
          preferredBenefitType
          preferredCompanyRole
          id
          locationPlaceID
          requestScopes
        }
        expertCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          jobDescription
          durationJobInMonths
          hoursPerWeek
          startDate
          id
          locationPlaceID
          requestScopes
        }
        projectPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          projectDescription
          averageDurationInMonths
          hoursPerWeek
          startDate
          id
          locationPlaceID
          requestScopes
        }
        mentoringCollaborationRequestPayload {
          userId
          titleRequest
          requestDate
          industries
          preferredFieldOfKnowledge
          wishFromMentor
          myChallenges
          id
          locationPlaceID
          requestScopes
        }
        investmentCollaborationRequest {
          userId
          titleRequest
          requestDate
          businessDescription
          location
          industries
          id
          locationPlaceID
          requestScopes
        }
        strategicPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          myPartOfThePartnership
          myPartOfThePartnership
          selectedToWhomIds
          id
          locationPlaceID
          requestScopes
        }
        coFoundingPartnerCollaborationRequest {
          userId
          titleRequest
          requestDate
          industries
          skills
          location
          partnershipDescription
          preferredCompanyRole
          businessStage
          commitment
          sweatCommitmentInHours
          id
          locationPlaceID
          requestScopes
        }
        brainstormPartnerRequest {
          userId
          titleRequest
          requestDate
          partnershipDescription
          frequencyInMonths
          location
          skills
          entrepreneurshipLevel
          expertiseLevel
          id
          locationPlaceID
          requestScopes
        }
        coworkingDateCollaborationRequest {
          userId
          titleRequest
          requestDate
          dateDescription
          days
          period
          skills
          location
          id
          locationPlaceID
          requestScopes
        }
        mastermindCollaborationRequest {
          userId
          titleRequest
          requestDate
          mastermindDescription
          offer {
            description
            skills
          }
          request {
            description
            skills
          }
          id
          locationPlaceID
          requestScopes
          categoryId
          categoryTitle
        }
        mastersForMastersCollaborationRequest {
          userId
          titleRequest
          requestDate
          preferredSkills
          offeredSkills
          collaborationDescription
          industries
          durationInMonths
          estimatedHours
          startDate
          location
          id
          locationPlaceID
          requestScopes
        }
        }
        circlePosts {
          circleId
          circleName
          createdProfileId
          id
          isDeleted
          message
          postedUser
          updatedDate
          createdDate
          createdProfileId
          updatedProfileId
        }
      }
    }
  }
`;
export const GET_PROFILE_INVITATION_LISTS = gql`
  query profileInvitationList($requestedUserId: String) {
    profileInvitationList(request: { requestedUserId: $requestedUserId }) {
      errorCode
      message
      result {
        id
        expiryDate
        invitedMembers {
          inviteStatus
          userId
          userType
        }
        hideInvitation
        requestedUserId
      }
    }
  }
`;

export const GET_ALL_CIRCLES_AND_SUBCIRCLES_BY_USERID = gql`
  query allCirclesAndSubCirclesByUserId(
    $loginProfileId: String
    $profileId: String
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    allCirclesAndSubCirclesByUserId(
      _request: {
        loginProfileId: $loginProfileId
        profileId: $profileId
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      errorCode
      message
      result {
        totalCount
        circles {
          id
          name
          circleTypeId
          brandColor
          externalUrl
          label
          isVisibleInPublicFeed
          membersArePubliclyVisible
          hasNewsUpdates
          hasKnowledgePosts
          hasCustomMessages
          hasRevenueReporting
          hasOneOnOneMeetingReporting
          canHaveEvents
          canHaveSubCircles
          subCircles
          members {
            userId
            role
          }
          circleTags
          logo
          location
          shortDescription
          aboutus
          operatesIn
          isHideForPublic
          brandSecondColor
          loggedInUserRole
          isPublished
          isCancelled
          isSubCircle
          parentCircleId
          isDeleted
          parentCircleMembers {
            userId
            role
          }
        }
        circle {
          name
          members {
            userId
            role
          }
          circleTypeId
          statistics {
            id
          }
          brandColor
          externalUrl
          label
          isVisibleInPublicFeed
          membersArePubliclyVisible
          hasNewsUpdates
          hasKnowledgePosts
          hasCustomMessages
          hasRevenueReporting
          hasOneOnOneMeetingReporting
          canHaveEvents
          canHaveSubCircles
          subCircles
          circleTags
          logo
          location
          shortDescription
          aboutus
          operatesIn
          isHideForPublic
          brandSecondColor
          isPublished
          isCancelled
          loggedinUser
          isSubCircle
          parentCircleId
          id
          createdDate
          updatedDate
          createdProfileId
          updatedProfileId
          isDeleted
        }
      }
    }
  }
`;

export const GET_PREFERENCE = gql`
  query preferancebyUserId($userId: String) {
    preferancebyUserId(userId: $userId) {
       result{
    preferances,
    myMatchPreferance
    }
    }
  }
`;

export const GET_EVENT_INFO = gql`
  query eventById($id: String, $loginProfileId: String) {
    eventById(request: { id: $id, loginProfileId: $loginProfileId }) {
      errorCode
      message
      result {
        id
        title
        description
        logo
        createdDate
        operatesIn
        ownerUserId
        createdProfileId
        updatedDate
        isOrganizer
        canEveryoneInvite
        isDeleted
        quote
        shortDescription
        startDate
        endDate
        location
        locationPlaceID
        videoLink
        description
        website
        hasTicketing
        ticketingUrl
        hasExternalRegistration
        registrationUrl
        maximumAmountOfVisitors
        isRecurring
        hideVisitorsToPublic
        hasNewsUpdate
        isInvitationEnabled
        tagsDesc
        operatesInDesc
        isPublished
        visitors {
          userId
          status
        }
        numberOfVisitors
        participantScopeDesc
        createdProfileId
        loginUserStatus
        numberOfVisitors
        circleId
      }
    }
  }
`;

export const GET_EVENT_BY_UUID = gql`
  query eventByUUID($uuid: String) {
    eventByUUID(uuid: $uuid ) {
      errorCode
      message
      result {
        id
        title
        description
        logo
        createdDate
        operatesIn
        ownerUserId
        canEveryoneInvite
        createdProfileId
        updatedDate
        isOrganizer
        isDeleted
        quote
        shortDescription
        startDate
        endDate
        location
        locationPlaceID
        videoLink
        description
        website
        hasTicketing
        ticketingUrl
        hasExternalRegistration
        registrationUrl
        maximumAmountOfVisitors
        isRecurring
        hideVisitorsToPublic
        hasNewsUpdate
        isInvitationEnabled
        tagsDesc
        operatesInDesc
        isPublished
        visitors {
          userId
          status
        }
        numberOfVisitors
        participantScopeDesc
        createdProfileId
        loginUserStatus
        numberOfVisitors
        circleId
      }
    }
  }
`;

export const GET_ALL_EVENTS = gql`
  query allEvents(
    $hint: String
    $loginProfileId: String
    $pageNumber: Int!
    $pageSize: Int!
    $id: String
    $title: String
    $startDate: DateTime
    $location: String
    $maxDistance: Int
    $operatesIn: [String]
    $eventTags: [String]
    $profileId: String
  ) {
    allEvents(
      request: {
        hint: $hint
        loginProfileId: $loginProfileId
        pageNumber: $pageNumber
        pageSize: $pageSize
        id: $id
        title: $title
        startDate: $startDate
        location: $location
        maxDistance: $maxDistance
        operatesIn: $operatesIn
        eventTags: $eventTags
        profileId: $profileId
      }
    ) {
      errorCode
      message
      result {
        id
        title
        description
        logo
        createdDate
        operatesIn
        ownerUserId
        createdProfileId
        updatedDate
        isOrganizer
        isDeleted
        quote
        shortDescription
        startDate
        endDate
        location
        locationPlaceID
        videoLink
        description
        website
        hasTicketing
        ticketingUrl
        hasExternalRegistration
        registrationUrl
        maximumAmountOfVisitors
        isRecurring
        hideVisitorsToPublic
        hasNewsUpdate
        isInvitationEnabled
        tagsDesc
        operatesInDesc
        isPublished
        visitors {
          userId
          status
        }
        numberOfVisitors
        participantScopeDesc
        createdProfileId
        loginUserStatus
        numberOfVisitors
        circleId
      }
    }
  }
`;

export const GET_EVENT_PAYLOAD = gql`
  query eventDomainPayload {
    eventDomainPayload {
      eventTags {
        key
        value
      }
      participantScope {
        key
        value
      }
      worldLocation {
        key
        value
      }
    }
  }
`;

export const GET_EVENT_VISITORS = gql`
  query eventVisitors(
    $loginProfileId: String
    $pageNumber: Int!
    $pageSize: Int!
    $id: String
    $searchInput: String
    $skills: [String]
    $industries: [String]
    $collaborationType: [String]
    $businessTypes: [String]
    $location: String
    $maxDistance: Int
    $participantScopeDesc: String
  ) {
    eventVisitors(
      request: {
        id: $id
        pageNumber: $pageNumber
        pageSize: $pageSize
        loginProfileId: $loginProfileId
        searchInput: $searchInput
        skills: $skills
        industries: $industries
        collaborationType: $collaborationType
        businessTypes: $businessTypes
        location: $location
        maxDistance: $maxDistance
        participantScopeDesc: $participantScopeDesc
      }
    ) {
      errorCode
      message
      result {
        userId
        profilePictureUrl
        givenName
        familyName
        email
        phoneNumber
        location
        companyName
        companyRole
        noOfAttendingVisitors
        totalCount
        locationPlaceID
        skills
        businessTypes
        industries
        sharedCircles
        isMember
        isMatch
        visitorStatus
      }
    }
  }
`;

export const EVENT_NEWS_BY_USER = gql`
  query eventNewsByUser(
    $userId: String
    $loginProfileId: String
    $searchText: String
    $pageNumber: Int!
    $pageSize: Int!
    $id: String
    $newsID: String
  ) {
    eventNewsByUser(
      circleRequest: {
        userId: $userId
        loginProfileId: $loginProfileId
        searchText: $searchText
        pageNumber: $pageNumber
        pageSize: $pageSize
        id: $id
        newsID:$newsID
      }
    ) {
      errorCode
      message
      result {
        userId
        profilePictureUrl
        givenName
        familyName
        email
        phoneNumber
        location
        companyName
        companyRole
        locationPlaceId
        businessTypes
        industries
        isMember
        isMatch
        sharedCircles
        sharedMatchCount
        circleRole
        postedUser
        newsInfo
        postedDate
        eventId
        isMatch
        isDeleted
        id
        createdDate
        updatedDate
        createdProfileId
        updatedProfileId
        showProfileBasedOnPreference
      }
    }
  }
`;

export const GET_ALL_EVENTS_BYCIRCLEID = gql`
  query allEventsByCircleId(
    $circleId: String
    $loginProfileId: String
    # $hint: String
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    allEventsByCircleId(
      request: {
        circleId: $circleId
        loginProfileId: $loginProfileId
        # hint: $hint
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      errorCode
      message
      result {
        shortDescription
        id
        createdDate
        createdProfileId
        description
        endDate
        hasExternalRegistration
        hasNewsUpdate
        hasTicketing
        hideVisitorsToPublic
        id
        isDeleted
        isInvitationEnabled
        isOrganizer
        isPublished
        isRecurring
        location
        locationPlaceID
        loginUserStatus
        logo
        maximumAmountOfVisitors
        messages
        operatesIn
        operatesInDesc
        ownerUserId
        participantScopeDesc
        participantScope
        quote
        registrationUrl
        shortDescription
        startDate
        tags
        tagsDesc
        ticketingUrl
        title
        updatedDate
        videoLink
        website
        circleId
        numberOfVisitors
      }
    }
  }
`;
export const INVITE_TO_ALL_CIRCLE_MEMBERS = gql`
  query inviteAllCircleMembersForEvent(
    $loginUserId: String
    $circleId: String
    $eventId: String
    $requestedUserId: String
    
  ) {
    inviteAllCircleMembersForEvent(
      _request: {
        loginUserId: $loginUserId
        circleId: $circleId
        eventId: $eventId
        requestedUserId: $requestedUserId
      
      }
    ) {
      errorCode
      message
      result {
        invitedMembers{
          inviteStatus
          userId
          userType
        }
      }
    }
  }
`;

export const GET_ONBOARDING_STATUS = gql`
  query onboardingStatus(
    $userId: String
  ) {
    onboardingStatus(
      request: {
        userId: $userId
      }
    ) {
      errorCode
      message
      result {
       isOnboardingProcessCompleted
      }
    }
  }
`;


export const GET_ALL_MASTERS_BASIC = gql`
  query allMastersBasicDetails(
    $userId: String
    $searchInput:String,
    $showLoggedInUser:Boolean,
  ) {
    allMastersBasicDetails(
      request: {
        userId: $userId,
        searchInput:$searchInput,
        showLoggedInUser:$showLoggedInUser
      }
    ) {
       errorCode,
       message,
       result{
         userId,
         givenName,
         familyName
         companyName 
      } 
    }
  }
`;

export const GET_FEATURES_LISTS = gql`
  query featureListForUserType(
    $getAllPlans: Boolean!
    ) {
  featureListForUserType(
    request:{
      getAllPlans:$getAllPlans
      }
      ){
       errorCode,
       message,
       result{
         planName,
         planAmount,
         discount,
         paymentSchedule 
         planId
         features{
          featureId
          featureName
         }
      }
    }
}
`;

export const GET_INVOICE_LIST = gql`
  query invoicesListForUser( $userid: String ) {
  invoicesListForUser(userid:$userid){
      errorCode
      message
      result{
       invoice{
        id
        createdDate
        taxAmount
        billingStartDate
        billingEndDate
        billedAmount
        status
      }
      mMAddress
      mMWebsite
      mMEmail
      firstName
      lastName     
      email
      showAlertMessage
      }
    }
  }
`;

export const GET_CURRENT_SUBSCRIPTION_EXPIRY = gql`
  query expiryOfCurrentSubscription( $userid: String ) {
  expiryOfCurrentSubscription(userId:$userid)
  {
    errorCode,
    message,
    result{
      userId,
      billingStartDate,
      billingEndDate,
      billedAmount,
      status,
      isNotificationSeenByUser
    }
  }
  }
`;

export const GET_USER_SUBSCRIPTIONS = gql`
  query allUserSubscriptions( 
    $userId: String,
    $pageNumber: Int,
    $pageSize: Int ) {
     allUserSubscriptions(request:{
    userId:$userId,
    pageNumber:$pageNumber,
    pageSize:$pageSize
    })
  {
    errorCode,
    message,
    totalRecords,
    result{
      id,
      firstName,
      lastName,
      email,
      userId,
      company,
      companyRole
      subscriptionTypeDesc,
      remarks,
      subscriptionStartDate,
      subscriptionEndDate,
      subscriptionType,
      role,
      roleDesc
    }
  }
  }
`;

export const GET_USER_SUBSCRIPTIONS_NOTIFICATION = gql`
  query userSubscriptionByUserId( 
    $userId: String, ) {
     userSubscriptionByUserId(userId:$userId)
  {
    errorCode,
    message,
    result{
      id,
      subscriptionTypeDesc,
      remarks,
      role,
      subscriptionTypeDesc,
      subscriptionType,
      subscriptionStartDate,
      subscriptionEndDate,
      firstName,
      lastName,
      email,
      showAlertMessage,
      alertMessageSeenByStatus
    }
  }
  }
`;

export const ACCEPTED_INVITES = gql`
  query acceptedInvites($userId: String) {
    acceptedInvites(userId: $userId)
  }
`;


export const GET_EVENT_UUID = gql`
  query eventUUID($eventId: String) {
    eventUUID(eventId: $eventId)
  }
`;

export const GET_CIRCLE_UUID = gql`
  query circleUUID($circleId: String) {
    circleUUID(circleId: $circleId)
  }
`;