import theme from "../container/Theme";


const MenuStyle = (_theme) => ({
    icons: {
        fontFamily: theme.typography.fonts[1],
        color: "black",
        fontWeight: "bold"
    },
    nested: {
        paddingLeft: "0px",
        marginLeft: "-15px"
    },
    item: {
        position: "relative",
        display: "block",
        textDecoration: "none",
        "&:hover,&:focus,&:visited,&": {
            color: "white"
        },
    },

});
export default MenuStyle;
