import React from "react";
import { FormattedMessage } from "react-intl";
import Text from "../../../visualComponents/Text";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";


export default function ActivityCirclePostRespond({activity, isLess, source, color}) {
  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  if(source == "menu")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text item xs={12} color={color??"background"}>
        <FormattedMessage id="Message" />:{" "}
        {isLess && (activity?.activity?.messages?.length ?? 0)> 150 ? 
                activity.activity.messages.substring(0, 150) + " "
              :  activity?.activity?.messages
        }
      </Text>
    </ThemeProvider>
  );
}
