//TODO: move to Providers folder
import { createContext, useEffect, useContext, useState } from "react";
import { getUser } from "../components/IdentityServer/userService";
import { config } from "../Config";
import QueryString from "query-string";
import { useAuthenticationProfile } from "./CommonDataObjects";

export const AuthContext = createContext({});

function UserProvider(props) {
    const publicPaths = props.publicPaths.map(path => path.toLowerCase());
    const publicRegx  = props.publicRegx .map(path => path.toLowerCase());
    const [user  , setUser  ]  = useState(null);
    const [loaded, setLoaded]  = useState(false);
    const [succes, setSucces]  = useState(false);
    const [error , setError ]  = useState(false);
    const params = QueryString.parse(window.location.search);
    const [authProfile, authLoading, authError] = useAuthenticationProfile();

    useEffect(() => {
        if(!authLoading && (authProfile == null || authError || (authProfile != null && !authProfile.authenticated)))
            setLoaded(false);

        if(!loaded)
            getUser().then((_user) => {
                if(_user == null 
                    && !publicPaths.includes(window.location.pathname.toLowerCase())
                    && !publicRegx .some((path) => (window.location.pathname.toLowerCase() + window.location.search).match(path))) {
                    if(authProfile?.redirect)
                        window.location.href = authProfile.redirect;
                    if(params["attempts"] == null) {    //we use attempts since upon first login we don't have _user until the second request
                        window.location.href = "?attempts=1"
                    } else if (Number(params.attempts) >= 1) {
                        setError(true);
                        window.location.href = config.publicPage;
                    } else {
                        window.location.href = "?attempts=" + (Number(params.attempts) + 1);
                    }
                } else {
                    setSucces(true);
                }
                setUser(_user);
                setLoaded(true);
            });
    }, [loaded]);

    return(
        <AuthContext.Provider value={{user: user, loadedUser: loaded}}>
            {loaded? (error? 
                <p>Redirecting to login page</p>
                : (succes? props.children : null)) : null}
        </AuthContext.Provider>
    );
} 

export default UserProvider;

export const useAuthContext = () => useContext(AuthContext);