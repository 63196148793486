import React, { useEffect } from "react";
import {
  MenuItem,
} from "@material-ui/core";
import {
  ACCEPT_MATCH_REQUEST,
  DECLINE_MATCH_REQUEST,
} from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";


export default function MatchRequestCard({activity, source, isLess, onRefresh, AllActivity, color}) {
  const history = useHistory();
  const intl    = useIntl();

  const [AcceptMatchRequest , { data:  dataAccept, loading: loadingAcceptMatchRequest  },] = useMutation(ACCEPT_MATCH_REQUEST );
  const [DeclineMatchRequest, { data: dataDecline, loading: loadingDeclineMatchRequest },] = useMutation(DECLINE_MATCH_REQUEST);

  const navigateToSuggestOneOnOne = () => {
    history.push("/suggestOneToOne", {
      activity: activity,
      isCancel: false,
    });
  };

  useEffect(() => {
    if (dataAccept ||  dataDecline) {
      onRefresh();
    }
  }, [dataAccept, dataDecline]);

  const acceptMatchRequestAction = async () => {
    const variables = {
      activityId: activity.activity.id,
    };
    AcceptMatchRequest({
      variables: variables,
    });
  };
  const declineMatchRequestAction = async () => {
    const variables = {
      activityId: activity.activity.id,
    };
    DeclineMatchRequest({
      variables: variables,
    });
  };
  
  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };

  const getPurpose = () => {
    var purpseMessage = ""
    if (activity.activity && activity.activity.matchRequestPurpose && activity.activity.matchRequestPurpose.length) {
      purpseMessage = activity.activity.matchRequestPurpose
    } else if (activity.activity && activity.activity.isKnownPerson) {
      purpseMessage = intl.formatMessage({ id: "We_already_know_each_other" })
    }
    return purpseMessage
  };

  if(source == "menu")
    return <>
      {activity?.activity?.status !== ActivityStatus.matchRequestAccepted &&
       activity?.activity?.status !== ActivityStatus.matchRequestDeclined &&
        <MenuItem onClick={acceptMatchRequestAction}>
          <FormattedMessage id="Accept" />
        </MenuItem>
      }
      {activity?.activity?.status !== ActivityStatus.matchRequestAccepted &&
       activity?.activity?.status !== ActivityStatus.matchRequestDeclined &&
        <MenuItem onClick={declineMatchRequestAction}>
          <FormattedMessage id="Decline" />
        </MenuItem>
      }
      <MenuItem onClick={navigateToMessage}>
        <FormattedMessage id="Message" />
      </MenuItem>
      <MenuItem onClick={navigateToSuggestOneOnOne}>
        <FormattedMessage id="MatchRequestCard_Description_Suggest_1_on_1" />
      </MenuItem>
      <MenuItem onClick={AllActivity}>
        <FormattedMessage id="ToAllActivities" />
      </MenuItem>
    </>;
  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text item xs={12} color={color??"background"} id="MatchRequestCard_Description" bold/>
      {/* <Text item xs={12} color={color??"background"}>
        <b><FormattedMessage id="Purpose" />:</b>{" "}{getPurpose()}
      </Text> */}
      <Text item xs={12} color={color??"background"} >
        {isLess && (activity?.activity?.messages?.length ?? 0) > 150 ?
            activity.activity.messages.substring(0, 150) + "..." : activity?.activity?.messages
        }
      </Text>  
    </ThemeProvider>
  );
}
