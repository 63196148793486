import React, { useEffect } from "react";
import Link from "@mui/material/Link";
import {
  CIRCLE_INVITE_ACCEPT,
  CIRCLE_INVITE_DECLINE,
} from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";
import { ClearCache } from "../../../apiServices/CommonMethods";
import { Button, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";


export default function ActivityCircleInvite({activity, source, onRefresh, color, sent}) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const history = useHistory();

  const [acceptInvitation, { data: dataAccept }] =
    useMutation(CIRCLE_INVITE_ACCEPT);
  const [declineInvitation, { data: dataDecline }] =
    useMutation(CIRCLE_INVITE_DECLINE);

  useEffect(() => {
    if (dataAccept || dataDecline )
      onRefresh();
  }, [dataAccept, dataDecline]);

  const acceptInvitationAction = (meeting) => {
    ClearCache("circle");
    ClearCache("allCircles");
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    acceptInvitation({
      variables: variables,
    });
  };
  const declineInvitationAction = () => {
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    declineInvitation({
      variables: variables,
    });
  };

  const navigateToCircleDetail = () => {
    // history.push("/dashboard/circleDetails", {
    //   selectedCircleId: activity.activity.circleId,
    // });
    history.push("/dashboard/circleDetails/?id=" + activity.activity.circleId, {
      selectedCircleId: activity.activity.circleId,
    });
  };

  const placeholders = {circle_name: <Link
    color="inherit"
    component="button"
    underline="always"
    onClick={navigateToCircleDetail}
  >
    {activity.activity.circleName}
  </Link>};

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity.activity &&
        <Text item xs={12} color={color??"background"}
        id={activity.activity.activitySource === "MADE_YOU_CIRCLE_MANAGER" ? "MadeYouCircleManagerOfTheCircle" : "InvitationToTheCircle1" }
        values={placeholders} />
      }
      { activity.activity.status !== ActivityStatus.Closed &&
        activity.activity.status !== ActivityStatus.activityInvitationAccepted &&
        activity.activity.status !== ActivityStatus.activityInvitationDeclined &&
        activity.activity.isActionNeeded === true &&
        !sent &&
          <>
            <Button variant="contained" onClick={acceptInvitationAction}>
              <FormattedMessage id="EventVisitors_MenuItem_Accept" />
            </Button>
            <Button variant="contained" onClick={declineInvitationAction}>
              <FormattedMessage id="EventVisitors_MenuItem_Decline" />
            </Button>
          </>
      }
    </ThemeProvider>
  );
}
