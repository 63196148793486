
const EditBioInfoStyle = (theme) => ({
    mt10: {
        marginTop: "10px",
    },
    p7: {
        marginTop: 7,
    },

    p15: {
        marginTop: 15,
    },
    Select: {
        width: "100%",
        marginTop: 7,
    },
    btxt: {
        marginTop: 15,
        color: "#72B6CD"
    },
    submit: {
        color: "#fff",
    },
    chip: {
        margin: theme.spacing(0.5),
        color: theme.palette.color5.main,
        borderRadius: 5
    },
    chipItem: {
        borderRadius: 5
    },
    chipSolo: {
        color: theme.palette.color5.main
    },
    txtarea: {
        width: "100%",
        border: 'none',
        backgroundColor: "#e8e8e8",
        borderRadius: 6,
        padding: "2%",
    },
});
export default EditBioInfoStyle;
