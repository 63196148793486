import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export function useMatchSuggestionOfCurrentUser() {
    const {loading, error, data, refetch} = useQuery( gql`query get($status: Status!) {
        matchSuggestions(status: $status){
            id
            createdDate
            swipeStatus
            matchMetaData {
                aiGeneratedIntroduction
                aiGeneratedRequest
                userId
                introduction
                request
                pfp
                name
            }
        }
    }`, {
        variables: {
            status: "PENDING"
        }
    } );

    return [data?.matchSuggestions, loading, (error != undefined), refetch];
  }
  
  export function useRandomSuggestionForCurrentUser() {
    const {loading, error, data, refetch} = useQuery( gql`query get {
        randomMatchSuggestion{
            id
            createdDate
            swipeStatus
            requestId
            match
            matchMetaData {
                aiGeneratedIntroduction
                aiGeneratedRequest
                userId
                introduction
                request
                pfp
                name
            }
        }
    }`);

    return [data?.randomMatchSuggestion, loading, (error != undefined), refetch];
  }
  