import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { CssBaseline, Hidden } from "@material-ui/core";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Box } from "@mui/system";
import { useHistory } from "react-router";
import { FormControl, Skeleton, TextareaAutosize } from "@mui/material";
import { FormHelperText } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { ClearCache, GetUserId } from "../../apiServices/CommonMethods";
import { useLocation } from "react-router";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";
import { Alert } from "@mui/material";
import {
  CREATE_EVENT_NEWS,
  EDIT_EVENT_NEWS,
} from "../../apiServices/mutations";
import { Button } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "../../styles/container/Event/AddEventNewsStyle";
import FormContainer from "../../components/Forms/FormContainer";
import InputSection from "../../components/Forms/InputSection";

const useStyles = makeStyles(styles);

export default function AddEventNews(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const userId = GetUserId();
  const location = useLocation();
  const intl = useIntl();
  const [notify, setNotify] = React.useState(false);
  const [
    createEventNews,
    { data: dataAddNews, loading: loadingAddNews },
  ] = useMutation(CREATE_EVENT_NEWS);

  const [editEventNews, { data: dataEditNews, loading: loadingEditNews }] =
    useMutation(EDIT_EVENT_NEWS);

  const [eventData, setEventData] = React.useState("");
  const [eventNewsData, setEventNewsData] = React.useState("");
  const [input, setInput] = React.useState({
    inputText: "",
  });
  const [inputError, setInputError] = React.useState({
    inputText: "",
  });

  React.useEffect(() => {
    // console.log(eventData, "EventData in Add News");
    // console.log(eventNewsData, "EventNewsData in Add News");
    if (location.state && typeof location.state.eventDetails !== "undefined") {
      setEventData(location.state.eventDetails);
    }
    if (location.state && location.state.isEdit === true) {
      setInput({ inputText: location.state.newsData.newsInfo });
      setEventNewsData(location.state.newsData);
    }
  }, [location, dataAddNews, dataEditNews]);

  const handleOnChange = (event) => {
    const { id, value } = event.target;
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const checkForErrors = () => {
    setInputError((prevState) => ({
      ...prevState,
      inputText: !input["inputText"].trim() ? "input error" : "",
    }));

    return input["inputText"].trim().length !== 0;
  };

  const handleOnAddNews = () => {
    ClearCache("event_news");
    const isError = checkForErrors();

    if (isError) {
      const variable = {
        newsInfo: input["inputText"],
        eventId: eventData.id,
        loginProfileId: userId,
      };
      // console.log(variable, "AddNewsVariable");
      createEventNews({
        variables: variable,
      });
    }
  };

  const handleOnEditNews = (flag) => {
    ClearCache("event_news");
    const isError = checkForErrors();
    if (isError) {
      if (location.state.isEdit === true) {
        const editVariable = {
          newsId: eventNewsData.id,
          eventId: eventNewsData.eventId,
          newsInfo: input["inputText"],
          loginProfileId: userId,
          sendNotification: flag,
        };
        // console.log("Edit varibale :", editVariable);
        editEventNews({
          variables: editVariable,
        });
      }
    }
  };

  const handleSucessAlert = () => {
    // history.push("/dashboard/eventDetails", {
    //   eventDetails: eventData,
    //   selectedTab: 1,
    // });
    history.push("/dashboard/eventDetails/?id=" + eventData.id, {
      eventDetails: eventData,
      selectedTab: 1,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        modalMessage = {(dataEditNews?.editEventNews?.errorCode ?? dataAddNews?.createEventNews?.errorCode) ==  0 ? <FormattedMessage id={"newsAddedSuccessfully"} /> : undefined}
        onModalClose = {handleSucessAlert}
        error        = {(dataEditNews?.editEventNews?.errorCode ?? dataAddNews?.createEventNews?.errorCode) == -1 ? (dataEditNews?.editEventNews?.message ?? dataAddNews?.createEventNews?.message) : undefined}
        title        = {<FormattedMessage id={location.state.isEdit ? "editNewsUpdate" : "writeANewsUpdateToAllMembers/Visitors"}/>}
        description  = {location.state.isEdit ? undefined : <FormattedMessage id="AddNews_Page_Description"/>}
        loading      = {loadingAddNews || loadingEditNews}
        onSave       = {() => location.state.isEdit ? handleOnEditNews(notify) : handleOnAddNews()}
        disabled     = {loadingAddNews || loadingEditNews}
        onCancel     = {() => history.goBack()}
        saveId       = "send"
      >
        <InputSection
        type        = "textarea"
        disabled    = {loadingAddNews || loadingEditNews}
        id          = "inputText"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<FormattedMessage id={location.state.isEdit ? "adjustYourMessage" : "writeMessage"}/>}
        value       = {input["inputText"]}
        onChange    = {handleOnChange}
        hint        = {inputError["inputText"] ? <FormattedMessage id="pleaseEnterMessage" /> : undefined}
        max         = {1000}
        />

        {location.state.isEdit && <>
        <InputSection
        type        = "toggle"
        disabled    = {loadingAddNews || loadingEditNews}
        id          = "toggle"
        title       = {<FormattedMessage id="doYouwantToSendANewNotification"/>}
        value       = {notify}
        onChange    = {(event, newValue) => setNotify(newValue)}
        options     = {[
          {key: intl.formatMessage({ id: "sendNotification" }), value: true},
          {key: intl.formatMessage({ id: "onlySave"         }), value: false}
        ]}
        />
        </>}
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
