import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_CIRCLE_NEWS } from "../../apiServices/Queries";
import NewsCard from "../../components/Cards/NewsCard";
import { Alert, CircularProgress, Stack } from "@mui/material";
import { Waypoint } from "react-waypoint";
import Styles from "../../styles/component/Circles/CircleNewsStyle";
import { FormattedMessage, useIntl } from "react-intl";
import { userRoles } from "../../apiServices/Constants";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Filters from "../Filters";

const useStyles = makeStyles(Styles);

export default function CircleNews(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [circledata, setCircleData] = React.useState(props?.circleDetails);
  const [news, setNews] = React.useState([]);
  const [selectedNewsId, setSelectedNewsId] = React.useState("");
  let [input, setInput] = React.useState({
    searchInput: "",
    pageNumber: 1,
  });
  const getVariable = () => {
    const variable = {
      circleId: circledata.id,
      userId: userId,
      loginProfileId: userId,
      profileId: userId,
      pageNumber: input["pageNumber"],
      pageSize: parseInt(5),
      searchText: input["searchInput"] ?? "",
      newsID: selectedNewsId ? selectedNewsId : null
    };
    // console.log("news variable", variable);
    return variable;
  };
  const { loading, refetch, error } = useQuery(
    GET_CIRCLE_NEWS,
    {
      variables: getVariable(),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (
          typeof data !== "undefined" &&
          data.circleNewsByUser.errorCode === 0
        ) {
          const response = data.circleNewsByUser.result;
          if (input["pageNumber"] === 1) {
            setNews(response);
          } else {
            if (response.length > 0) {
              // console.log("response: ", response);
              setNews((prevState) => [...prevState, ...response]);
            }
          }
        }
      },
    }
  );
  useEffect(() => {
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails);
    }
    if (props.selectedNewsId !== null) {
      setSelectedNewsId(props.selectedNewsId);
      // console.log("selectedNewsId cieclenews", props.selectedNewsId);
    }
  }, [input, props]);

  const onSearch = (input) => {
    setInput((prevState) => ({
      ...input,
      pageNumber: parseInt(1),
    }));
    setSelectedNewsId(null)
  };
  const refresh = () => {
    setNews([])
    setInput((prevState) => ({
      ...prevState,
      pageNumber: parseInt(1),
    }));
    refetch()
  };
  const onFocus = () => {
    if (userRole === userRoles.freemium && (circledata.loggedInUserRole !== "Moderator" &&
      circledata.loggedInUserRole !== "Member" &&
      circledata.loggedInUserRole !== "SuperUser")) {
      navigateToGetPremium();
    }
  }
  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };
  return (
    <Grid container gap={ThemeConstants.custom.spacings.md}>
      {error ? (
        <Grid item sm={12} xs={12}>
          <Alert severity="error">
            {error.length ? (
              error
            ) : (
              <FormattedMessage id={"somethingWentWrong"} />
            )}
          </Alert>
        </Grid>
      ) : null}

      <Filters useRefresh search onSearch={onSearch} addDisplayText="Button_Add_new" onAdd={circledata.loggedInUserRole === "Moderator" || circledata.loggedInUserRole === "SuperUser" ? () => {
        history.push("/addNews", { circleDetails: circledata });}
        : undefined
      }/>

      {loading && (!news.length || input["pageNumber"] === 1) ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : news.length > 0 ? (
        news.map((newsdata, index) => (
          <Grid item container xs={12} key={index}>
            <NewsCard
              data={newsdata}
              onRefresh={refresh}
              circleData={circledata}
              key={index}
            />
            {index === news.length - 2 && (
              <Waypoint
                scrollableAncestor={window}
                onEnter={() => {
                  // console.log("index", index);
                  // console.log("news.length", news.length - 2);
                  const newPage = input["pageNumber"] + 1;
                  // console.log("newPage", newPage);
                  setInput((prevState) => ({
                    ...prevState,
                    pageNumber: newPage,
                  }));
                }}
              />
            )}
          </Grid>
        ))
      ) : (
        <Paper elevation={3} className={classes.tabcontent}>
          <Grid container spacing={4}>
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 200,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Typography component="span" variant="h3" color={"textPrimary"}>
                  <FormattedMessage id={"noDataAvailable"} />
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
}
