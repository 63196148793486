import theme from "../../container/Theme";

export default function ChipListStyle() {return({
    list: {
        backgroundColor: "transparent"
    },
    default: {
        color: theme.palette.secondary.main+" !important",
        backgroundColor: "white !important"
    },
    selected: {
        color: theme.palette.background.overlay+" !important",
        backgroundColor: theme.palette.secondary.main+"9F !important"
    },
    highlighted: {
        color: theme.palette.background.overlay+" !important",
        backgroundColor: theme.palette.secondary.main+" !important"
    }
})}