import { Button, Grid, TextField, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Background from "../../../visualComponents/Background";
import useMatchTimeHook from "../../../apiServices/Hooks/MatchTimeHooks";
import { useEffect, useState } from "react";
import Text from "../../../visualComponents/Text";
import { useMatchTimeMetaDataAdmin } from "../../../apiServices/Queries/IAm/MatchTimeMetaData";
import { useAdminMetaData } from "../../../apiServices/Queries/GetAdminMetaData";
import { useSetSystemInfo } from "../../../apiServices/Mutations/SetSystemInfo";

export default function VideoTab(props) {
    const {data: systemInfo, loading: loadingInfo } = useAdminMetaData();
    const [preRegVid , setPreRegVid]      = useState();
    const {data, loading, error, mutate}  = useSetSystemInfo(preRegVid);
    useEffect(() => {
        console.log(systemInfo)
        setPreRegVid(systemInfo?.preregisterVideoId);
    }, [systemInfo]);

    return(
        <ThemeProvider theme={Theme_WIP}>
            <Grid container p={10} justifyContent="center">
                {!loadingInfo && !loading && !error && <Background container item gap={5} justifyContent="center" p={10} py={20} border="bottomLeft">
                    <Text variant="h1" bold item xs={12}>System Data</Text>
                    <Text item xs={12} mb={4}>Note that all this system data is meant for exposed values only. Don't enter sensitive information.</Text>

                    <Text variant="h2" bold item xs={12}>Vimeo IDs</Text>
                    <Text item xs={12}>Fill in only the id of the video; not the full url. the id is a string of numbers at the end of a video url</Text>
                    <TextField fullWidth label="Pre-register video" onChange={(x) => setPreRegVid(x.target.value)} value={preRegVid}/>
                    <Button 
                    disabled={!(preRegVid)}
                    variant="contained" 
                    onClick={mutate}
                    >Save System Data</Button>
                </Background>}
            </Grid>
        </ThemeProvider>
    )
}