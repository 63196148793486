import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP, { ThemeConstants } from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";


export default function ActivityMessageCard({source, activity, AllActivity, isLess, color}) {
  const globals   = useGlobalContext();
  const history   = useHistory();
  const textColor = ThemeConstants.palette[color]?.contrastText ?? color;
  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };
  const message          = activity?.activity?.messages ?? "";
  const regex            = /(<a href="[A-Za-z0-9\/?=&:\.\-_]{1,255}">.{0,100}<\/a>)/g;
  const urls             = message.match(regex);
  //only do this unsafe thing if we trust the source of the message (admin)
  const messageWithLinks = urls?.length && activity?.userId == globals.state.admin.userId ? <>
    {message.split(regex).map(text => 
      !urls.includes(text) ? text : <span dangerouslySetInnerHTML={{__html: text}}></span>)}
  </> : "";

  if(source == "menu")
    return <>
      <MenuItem onClick={navigateToMessage}>
        <FormattedMessage id={"Message"} />
      </MenuItem>
      <MenuItem onClick={AllActivity}>
        <FormattedMessage id={"ToAllActivities"} />
      </MenuItem>
    </>;
  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  return ( 
    <ThemeProvider theme={Theme_WIP}>
      <Text color={color??"background"} sx={{"a": {color: textColor + " !important"}}}>
        {messageWithLinks != "" ? messageWithLinks :
        (isLess && (activity?.activity?.messages?.length ?? 0) > 150 ? 
          activity.activity.messages.substring(0, 150) + "..."
        : activity?.activity?.messages)}
      </Text>
    </ThemeProvider>
  );
}
