import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_CIRCLE_MEMBERS } from '../../apiServices/Queries';
import { CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Styles from '../../styles/component/ProfileTabs/InfoTabs/EditBioInfoStyle';
import { Chip, makeStyles } from '@material-ui/core';
import { useGlobalContext } from '../../apiServices/Providers/AppGlobalStateProvider';

const useStyles = makeStyles(Styles);

export default function MembersMultipleUserSearch(props) {
    const globals = useGlobalContext();
    const userId  = globals.state.loggedInUser.userId;
    const [searchText, setSearchText] = React.useState("");
    const [selectedUser, setSelectedUser] = React.useState([]);
    const [circledata, setCircleData] = React.useState({});

    const classes = useStyles();
    const getVariable = () => {
        const variable = {
            circleId: circledata.id,
            userId: userId,
            pageNumber: 1,
            pageSize: parseInt(100),
            searchInput: searchText,
            skills: null,
            industries: null,
            collaborationType: null,
            businessTypes: null,
            location: null,
            maxDistance: null
        }
        return variable
    }

    const { loading, data, fetchMore } = useQuery(GET_CIRCLE_MEMBERS, {
        variables: getVariable(),
        notifyOnNetworkStatusChange: true,
    });


    useEffect(() => {
        // console.log("data membres", data)
        if (typeof props.circleDetails !== "undefined") {
            setCircleData(props.circleDetails)
        }
    }, [props]);

    const updateTextFileds = (event) => {
        // console.log("updateTextFileds", event.target.value)
        setSearchText(event.target.value)
        fetchMore({
            variables: {
                searchInput: event.target.value,
            },
        })
        if (typeof props.onSearchTextChanges === "function") {
            props.onSearchTextChanges(event.target.value)
        }

    }
    const onChange = (event, newvalue) => {

        const arrayFiltered = selectedUser.filter(member => {
            return data.circleMembers.result.indexOf(member) === -1;
        });
        // console.log("arrayFiltered", arrayFiltered)
        const arrayNew = arrayFiltered.length ? arrayFiltered.concat(data.circleMembers.result) : data.circleMembers.result
        // console.log("arrayNew", arrayNew)
        const arrayMaster = arrayNew.filter(master => {
            return newvalue.includes(master.givenName + " " + master.familyName);
        });
        // console.log("arryayMaster", arrayMaster)
        setSelectedUser(arrayMaster)
        props.onSearch(arrayMaster)
    };

    return (
        <Autocomplete
            {...props}
            multiple
            // freeSolo
            onChange={onChange}
            filterOptions={(x) => x}
            disableClearable
            noOptionsText={""}
            options={
                typeof data !== "undefined" && typeof data.circleMembers !== "undefined" && typeof data.circleMembers.result !== "undefined"
                    && searchText.length ? data.circleMembers.result.filter((user) => 
                        user.userId !== userId && user.givenName != null //filter out emails and ourselves
                    ).map((option, index) => (
                        option.givenName + " " + option.familyName
                    )) : []
            }
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} size="small" className={classes.chip} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={updateTextFileds}
                    value={searchText}
                    InputProps={{
                        ...params.InputProps,
                        // type: 'search',
                    }}
                />
            )}
        />
    );
}
MembersMultipleUserSearch.prototype = {
    onSearch: PropTypes.func,
};
