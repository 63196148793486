import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import { GoogleAPIKEY } from "../../Config";
import { useIntl } from "react-intl";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function LocationAutoComplete(props) {
  const [value, setValue] = React.useState(null);
  const intl = useIntl();
  const [locationValue, setLocationValue] = React.useState(null);
  const [isLabelChange, setIsLabelChange] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const googleUrl =
    "https://maps.googleapis.com/maps/api/js?key=" +
    GoogleAPIKEY +
    "&libraries=geometry,places";

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(googleUrl, document.querySelector("head"), "google-maps");
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );
  const onEndEditing = () => {
    // console.log("end editing");
  };
  React.useEffect(() => {
    let active;
    if(props.onChange)
      props.onChange(value ?? "");
    if (props.value && !isLabelChange) {
      setLocationValue(props.value);
      setIsLabelChange(true);
    } else if (!props.value) {
      setLocationValue(null);
    }
    if (inputValue !== "") {
      // setLocationValue("")
    }
    active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, props.value]);

  return (
    <Autocomplete
      {...props}
      noOptionsText={intl.formatMessage({ id: "NoOptions" })}
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onEnded={onEndEditing}
      onChange={(event, newValue) => {
        setValue(newValue);
        // console.log("location selected", newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={locationValue ?? props.placeHolder}
          fullWidth
          InputLabelProps={{
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat', //h2
            }
          }}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            // type: 'search',
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat', //h2
            },
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
LocationAutoComplete.prototype = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
