import { Route, Switch } from "react-router";

export default function switchRoutes(root, routes){ return(
    <Switch>
        {routes.map((prop, key) => {
            return (
                <Route
                    path={root + prop.path}
                    component={prop.component}
                    key={key}
                />
            );
        })}
    </Switch> );}