import { GET_CIRCLE_DOMAIN_PAYLOAD, GET_COLLAB_PAYLOAD, GET_EVENT_PAYLOAD, GET_INDUSTRIES, GET_PERSONAL_LIFE_PAYLOAD, GET_PROFESSIONAL_LIFE_PAYLOAD, GET_PROFILE_DOMAIN_PAYLOAD, GET_SKILLS } from "../Queries";
import { useCachedQuery } from "./CachedQuery";

export function useCollaborationDomain() {
    return useCachedQuery(
        "collabDomain",
        GET_COLLAB_PAYLOAD,
        {},
        (data) => data?.collaborationDomainPayload?.result,
        180,
        true
    );
  }
export function useSkills() {
    return useCachedQuery(
        "skills",
        GET_SKILLS,
        {},
        (data) => data?.allSkill,
        180,
        true
    );
}
export function useIndustries() {
    return useCachedQuery(
        "industries",
        GET_INDUSTRIES,
        {},
        (data) => data?.allIndustry,
        180,
        true
    );
}
export function useProfileDomain() {
    return useCachedQuery(
        "profileDomain",
        GET_PROFILE_DOMAIN_PAYLOAD,
        {},
        (data) => data?.profileDomainPayload,
        180,
        true
    );
}
export function useCircleDomain() {
    return useCachedQuery(
        "circleDomain",
        GET_CIRCLE_DOMAIN_PAYLOAD,
        {},
        (data) => data?.circleDomain?.result,
        180,
        true
    );
}
export function useEventDomain() {
    return useCachedQuery(
        "eventDomain",
        GET_EVENT_PAYLOAD,
        {},
        (data) => data?.eventDomainPayload,
        180,
        true
    );
}
export function usePersonalLife() {
    return useCachedQuery(
        "personal",
        GET_PERSONAL_LIFE_PAYLOAD,
        {},
        (data) => data?.personalLife,
        180,
        true
    );
}
export function useProfessionalLife() {
    return useCachedQuery(
        "professional",
        GET_PROFESSIONAL_LIFE_PAYLOAD,
        {},
        (data) => data?.professionalLife,
        180,
        true
    );
}