import theme from '../../styles/container/Theme';
import { createSlice } from '@reduxjs/toolkit'

export const brandColorSlice = createSlice({
    name: 'brandColor',
    initialState: {
        currentColor: theme.palette.primary.main
    },
    reducers: {
        updateBrandColor: (state, action) => {
            state.currentColor = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateBrandColor } = brandColorSlice.actions
export const selectedBrandColor = state => state.rootReducer.currentColor;
export default brandColorSlice.reducer