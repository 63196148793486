import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export function useMatchTimeMetaData() {
    const {loading, error, data, refetch} = useQuery( gql`query get {
        matchTimeMetaData {
            reviewedProfiles
            pendingSwipes
            swipedToday
            serverAlive
            finishedRandomSwipes
        }
    }`);

    return [data?.matchTimeMetaData, loading, (error != undefined), refetch];
  }
  
export function useMatchTimeMetaDataAdmin() {
    const {loading, error, data, refetch} = useQuery( gql`query get {
        matchTimeMetaDataAdmin {
            type
            count
            generated
            filled
            empty
        }
    }`);

    return [data?.matchTimeMetaDataAdmin, loading, (error != undefined), refetch];
  }
  