import React, { useEffect, useState } from "react";
import Text from "../../../visualComponents/Text";
import { Fade, Zoom } from "@material-ui/core";
import MatchCard from "../../../components/Matchtime/MatchCard";
import { Button, Grid } from "@mui/material";
import { useMatchSuggestionOfCurrentUser, useRandomSuggestionForCurrentUser } from "../../../apiServices/Queries/IAm/MatchSuggestions";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom";
import Image from "../../../components/Media/Image";
import { useCurrentUserProfile } from "../../../apiServices/CommonDataObjects";
import IntroductionForm from "../../../components/Forms/IntroductionForm";
import Background from "../../../visualComponents/Background";

export default function MTRandomSwipes(props) {
    const [page, setPage] = useState(<Page1 setPage={updatePage}/>);

    function updatePage(page) {setPage(page)}

    return(
    <React.Fragment>
        {page}
    </React.Fragment>);
}

function Page1(props) {
    const [first,       setFirst  ]        = useState(false);
    const [mountedData, setMounted  ]      = useState(false);
    const [match, loading, error, refetch] = useRandomSuggestionForCurrentUser();
    const [loadedCard,  setLoaded]         = useState(true);
    const profile                          = useCurrentUserProfile();

    function onClick(status, updateStatus) {
        setLoaded(false);
        if(status == "INTRODUCED")
            props.setPage(<IntroductionForm 
                afterIntroduction = {() => {
                    updateStatus();
                }}
                goBack = {() => props.setPage(<Page1 {...props}/>)}
                userId = {match?.matchMetaData?.userId} 
                pfp    = {match?.matchMetaData?.pfp   } 
                name   = {match?.matchMetaData?.name  }/>);
        setTimeout(() => { //minimum transition duration
            setLoaded(true);
        }, 100);
    }

    const done = () => mountedData && match == null;

    useEffect(() => {
        if(!first) 
            refetch();
        setFirst(true);

        if(match != null)
            setMounted(true);
    }, [match, loading]);

    return(
    <React.Fragment>
        <Background page color="primary"/>
        <Page done={done()} name={profile?.givenName} empty={!loading && match == null && !mountedData}/>    
        {match != null && <Zoom in={loadedCard && !loading && match != null && !error} exit={false}>
        <Grid item container xs={12} p={{xs: 0, sm: 5}} mt={{xs: 10, sm: 5}} justifyContent="center">
            <MatchCard 
                aiGeneratedIntroduction  = {match?.matchMetaData?.aiGeneratedIntroduction}
                aiGeneratedRequest       = {match?.matchMetaData?.aiGeneratedRequest}
                afterUpdate  = {refetch}
                onClick      = {onClick} 
                suggestionId = {match?.id}
                introduction = {match?.matchMetaData?.introduction} 
                request      = {match?.matchMetaData?.request} 
                imgSrc       = {match?.matchMetaData?.pfp}
                requestId    = {match?.requestId}
                matchId      = {match?.match}
                userId       = {match?.matchMetaData?.userId}/>
        </Grid>
        </Zoom>}
        <Fade in={true} timeout={1000}>
            {done() || (!loading && match == null && !mountedData)?
                <Button variant="contained" color="darkPetrol" onClick={() => window.location.href = "/"}>
                    <Text item xs={12} align="center" color="primary" variant="body1" id="/matchtime/results.button2" p={1}/>
                </Button>
                :
                <Button variant="text" color="white" onClick={() => window.location.href = "/"}>
                    <Text item xs={12} align="center" color="primary" variant="body1" id="/matchtime/results.button"  p={0} sx={{textDecoration: 'underline'}}/>
                </Button>
            }
        </Fade>
    </React.Fragment>);
}

function Page(props) {return(
    <React.Fragment>
        {(props.done || props.empty) && <Zoom in={true}><Image 
            src   = "/assets/img/iam.png"
            width = "80px"/></Zoom>}
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="h1" color="primary" bold id={
                (props.done || props.empty)? "/matchtime/random.done" : "/matchtime/results.title"} values={{name: props.name}}/>
        </Fade>
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="body1" color="primary" bold id={
                (props.done || props.empty)? "" : "/matchtime/results.description.random"}/>
        </Fade>
    </React.Fragment>);}