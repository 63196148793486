import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Styles from '../../styles/component/ProfileTabs/InfoTabs/EditBioInfoStyle';
import { Chip, makeStyles } from '@material-ui/core';
import { useGlobalContext } from '../../apiServices/Providers/AppGlobalStateProvider';
import { useListMastersBasics } from '../../apiServices/Queries/Masters/ListMasters';

const useStyles = makeStyles(Styles);

export default function ActivityMultipleUserSearch(props) {
    const globals = useGlobalContext();
    const userId  = globals.state.loggedInUser.userId;
    const [searchText, setSearchText] = React.useState("");
    const [selectedUser, setSelectedUser] = React.useState([]);
    const classes = useStyles();

    const vars = {
        userId: userId,
        searchInput: searchText
      };
    const {data: masters, loading: loading, updateVariables: updateVariables, hash: hash} = useListMastersBasics(vars);

    const updateTextFileds = (event) => {
        // console.log("updateTextFileds", event.target.value)
        setSearchText(event.target.value)
        updateVariables({
            ...vars,
            searchInput: event.target.value
        });
        if (typeof props.onSearchTextChanges === "function") {
            props.onSearchTextChanges(event.target.value)
        }

    }
    const onChange = (event, newvalue) => {

        const arrayFiltered = selectedUser.filter(master => {
            return masters.indexOf(master) === -1;
        });
        // console.log("arrayFiltered", arrayFiltered)
        const arrayNew = arrayFiltered.length ? arrayFiltered.concat(masters) :
            masters
        // console.log("arrayNew", arrayNew)
        const arrayMaster = arrayNew.filter(master => {
            return newvalue.includes(master.givenName + " " + master.familyName);
        });
        // console.log("arryayMaster", arrayMaster)
        setSelectedUser(arrayMaster)
        // console.log("selectedUser", arrayMaster)
        props.onSearch(arrayMaster)
    };

    return (
        <Autocomplete
            multiple
            freeSolo
            size={"small"}
            id="free-solo-2-demo"
            onChange={onChange}
            filterOptions={(x) => x}
            disableClearable
            options={
                masters && masters.length
                    && searchText.length ? masters.map((option, index) => (
                        option.givenName + " " + option.familyName
                    )) : []
            }
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} size="small" className={classes.chip} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={updateTextFileds}
                    value={searchText}
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : !searchText.length ? <SearchIcon /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
ActivityMultipleUserSearch.prototype = {
    onSearch: PropTypes.func,
};
