import React, { useEffect, useState } from "react";
import Text from "../../../visualComponents/Text";
import { Button, Grid, Grow, TextField } from "@mui/material";
import { Fade } from "@material-ui/core";
import Image from "../../../components/Media/Image";
import ChatBubble from "../../../visualComponents/specificImplementations/ChatBubble";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Background from "../../../visualComponents/Background";
import { useIntl } from "react-intl";
import { renderToString } from 'react-dom/server';
import { useCurrentUserProfile } from "../../../apiServices/CommonDataObjects";
import { useMatchProfilesOfCurrentUser } from "../../../apiServices/Queries/IAm/MatchProfiles";
import getMatchProfile from "../../../apiServices/Methods/MatchProfileMethods";
import { useUpdateProfileFields } from "../../../apiServices/Mutations/IAm/UpdateProfileFields";

export default function MTRequest(props) {
    const [page, setPage] = useState(<Page1 setPage={updatePage}/>);

    function updatePage(page) {setPage(page)}
    return(page);
}

function Page1(props) {return(PageTemplate(
    "/assets/img/matchtime/intro.png",
    "/matchtime.page1.title",
    "/matchtime.page1.description",
    "/matchtime.page1.button",
    () => props.setPage(<Page1Eplain setPage={props.setPage}/>)
));}

function Page1Eplain(props) {
    const [e1, setE1] = useState(false);
    const [e2, setE2] = useState(false);
    const [e3, setE3] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setE1(true);
        }, 1250);
        setTimeout(() => {
            setE2(true);
        }, 2500);
        setTimeout(() => {
            setE3(true);
        }, 4000);
    }, []);

    return (<React.Fragment>
        <Grid maxWidth="384px" container item gap={8} justifyContent="center">
        <Fade in={true} timeout={500}>
            <Image src     = "/assets/img/iam.png"
                   alt     = "iam"
                   width   = "90px"
                   height  = "90px" mb={3}/>
        </Fade>
        <Fade in={true} timeout={700}>
            <Text item xs={12} align="center" variant="body1" bold id={"/matchtime.explain1"}/>
        </Fade>
        <Fade in={e1} timeout={500}>
            <Text item xs={12} align="center" variant="body1" bold id={"/matchtime.explain2"}/>
        </Fade>
        <Fade in={e2} timeout={500}>
            <Text item xs={12} align="center" variant="body1" bold id={"/matchtime.explain3"}/>
        </Fade>
        <Fade in={e3} timeout={500}>
            <Text item xs={12} align="center" variant="body1" bold id={"/matchtime.explain4"}/>
        </Fade>
        <Grid container item xs={6} mt={3} justifyContent="center">
            <Fade in={true} timeout={1000}>
                <Button fullWidth disabled={!e3} variant="contained" color="darkPetrol" onClick={() => props.setPage(<Page2 setPage={props.setPage}/>)} sx={{width: "fit-content"}}>
                    <Text p={0.5} item xs={12} align="center" variant="body2" color="primary" bold id={"/matchtime.explain.button"}/>
                </Button>
            </Fade>
        </Grid>
        </Grid>
    </React.Fragment>);
}

function Page2(props) {
    const [profiles, loadingProfiles, profilesError] = useMatchProfilesOfCurrentUser();
    const [phase  , setPhase  ] = useState(1);
    const [who    , setWho    ] = useState(""  );
    const [what   , setWhat   ] = useState("" );
    const [extra  , setExtra  ] = useState("");
    const [why    , setWhy    ] = useState(""  );
    const [who2   , setWho2   ] = useState(""  );
    const [forWhom, setForWhom] = useState(""  );
    const [what2  , setWhat2  ] = useState("" );
    const [extra2 , setExtra2 ] = useState("");
    const [why2   , setWhy2   ] = useState(""  );
    const [update , setUpdate ] = useState({invoke: setWho});
    const profile = useCurrentUserProfile();
    const intl    = useIntl();
    const [llmIntro , setLlmIntro ] = useState("");
    const [llmReq   , setLlmReq   ] = useState("");
    
    const inputField = (placeholder, update) => 
        <span className="multiline-input" onClick={(event) => {
            Array.prototype.forEach.call(document.getElementsByClassName("multiline-input-selected"), element => {
                element.className = "multiline-input"
            });
            event.target.className = "multiline-input-selected";
            selectInputField(placeholder, update)
        }} style={{
            padding: placeholder ? "2px 5px" : "2px 15px"
        }}>{placeholder}</span>;

    const selectInputField = (value, update) => {
        var input = document.querySelector('input');
        input.select();
        input.value = value;
        setUpdate({invoke: update});
    }

    const firstSentence = intl.formatMessage({id: "/matchtime.page2.user1"}, {
        "who"  : inputField(who  , setWho  ),
        "what" : inputField(what , setWhat ),
        "why"  : inputField(why  , setWhy  )
    });
    const secondSentence = intl.formatMessage({id: "/matchtime.page2.user2"}, {
        "br"     : <br/>,
        "name"   : profile?.givenName ?? "",
        "who"    : inputField(who2    , setWho2   ),
        "forWhom": inputField(forWhom , setForWhom),
        "what"   : inputField(what2   , setWhat2  ),
        "extra"  : inputField(extra2  , setExtra2 ),
        "why"    : inputField(why2    , setWhy2   )
    });
    //const sentenceLength = (input) => renderToString(input).replace(/<\/?[^>]+(>|$)/g, "").length;

    const introPofile = profiles?.find((p) => p.myProfileType == "INTRODUCTION");
    const updateIntroFields = () => {
        if(introPofile != null) {
            setWho2    (introPofile?.fields?.who     ?? "");
            setWhat2   (introPofile?.fields?.what    ?? "");
            setWhy2    (introPofile?.fields?.why     ?? "");
            setForWhom (introPofile?.fields?.forWhom ?? "");
            setExtra2  (introPofile?.fields?.extra   ?? "");
        }
    };
    const requestProfile = profiles?.find((p) => p.myProfileType != "INTRODUCTION");
    const updateRequestFields = () => {
        if(requestProfile != null) {
            setWho    (requestProfile?.fields?.who   ?? "");
            setWhat   (requestProfile?.fields?.what  ?? "");
            setWhy    (requestProfile?.fields?.why   ?? "");
            setExtra  (requestProfile?.fields?.extra ?? "");
        }
    };

    const nextPage = () => {
        mutate();
        props.setPage(<Page3 setPage={props.setPage}/>);
    };

    const mutationVars = () => {
        if(!profile) return([]);
        return([
            { profileId: requestProfile?.id, profileType: requestProfile?.myProfileType ?? "REQUEST", fields: (llmReq ? null : {
                who    : who   ,
                what   : what  ,
                why    : why   ,
                extra  : extra
            })},
            { profileId: introPofile?.id, profileType: introPofile?.myProfileType ?? "INTRODUCTION", fields: (llmIntro ? null : {
                who    : who2   ,
                what   : what2  ,
                why    : why2   ,
                extra  : extra2 ,
                forWhom: forWhom
            })}
        ]);
    };
    const {data, loading, error, mutate}  = useUpdateProfileFields(mutationVars(), intl.locale ?? "nl");
    const fullRequest      = getMatchProfile(intl, false, who, what, null, null, why);
    const fullIntroduction = getMatchProfile(intl, true, who2, what2, forWhom, null, why2);

    useEffect(() => {
        if(!loadingProfiles && profiles != null && profiles.length) {
            updateRequestFields();
            updateIntroFields();
        }
        setLlmIntro(introPofile?.llmResponse);
        setLlmReq  (requestProfile?.llmResponse);
    },[profiles]);

    return(
    <React.Fragment>
        {iamBubble ("/matchtime.page2.iam1", {name: profile?.givenName ?? ""})}
        {userBubble(profile?.profilePictureUrl, 
            <React.Fragment>
                <Text variant="body3" color="background" mb={4} id="/matchtime.page2.label2"/>
                <Text variant="body" bold color="background" mb={4}>{llmReq}</Text>
                {!llmReq && <Text variant="body" bold color="background" mb={4}>{firstSentence}</Text>}
                {requestProfile?.llmResponse && <Button fullWidth variant="text" onClick={() => setLlmReq(llmReq ? "" : requestProfile?.llmResponse)}>
                    <Text p={0.5} item xs={12} align="right" variant="body3" color="background" id={llmReq ? "/matchtime.page2.button4" : "/matchtime.page2.button5"}/>
                </Button>}
            </React.Fragment>,
            fullRequest)}

        {phase >= 2 && iamBubble ("/matchtime.page2.iam2")}
        {phase >= 2 && userBubble(profile?.profilePictureUrl, 
            <React.Fragment>
                <Text variant="body3" color="background" mb={4} id={introPofile?.llmResponse ? "/matchtime.page2.label1" : "/matchtime.page2.label3"}/>
                <Text variant="body" bold color="background" mb={4}>{llmIntro}</Text>
                {!llmIntro && <Text variant="body" bold color="background" mb={4}>{secondSentence}</Text>}
                {introPofile?.llmResponse && <Button fullWidth variant="text" onClick={() => setLlmIntro(llmIntro ? "" : introPofile?.llmResponse)}>
                    <Text p={0.5} item xs={12} align="right" variant="body3" color="background" id={llmIntro ? "/matchtime.page2.button4" : "/matchtime.page2.button5"}/>
                </Button>}
            </React.Fragment>,
            fullIntroduction)}

        <Grid item xs={12} mt={4} container justifyContent="end" sx={{
                position: "-webkit-sticky",
                position: "sticky",
                bottom: "15px"
        }}>
            {(!llmIntro || !llmReq) && <Grid item xs sm={6} pr={3} mb={5}>
                <TextField 
                sx={{background: "rgba(0,0,0,0.1)", "fieldset": {borderColor: "white !important"}, "input": {
                    color: "white !important", 
                    fontWeight: 800,
                    textShadow: "0px 0px 25px black"}}}
                size="small" 
                fullWidth 
                variant="outlined" 
                placeholder="" 
                onChange={(x) => update.invoke(x.target.value)}/>
            </Grid>}
            <Grid item pr={{xs: 0, sm: 17}}>
            <Fade in={true} timeout={1000}>
                <Button variant="contained" color="darkPetrol" 
                disabled={
                    (!llmReq   && (!who  || !what  || !why)              ) ||
                    (!llmIntro && (!who2 || !what2        ) && phase >= 2) ||
                    fullRequest.length > 250 || fullIntroduction.length > 250 || extra2?.length > 250}
                onClick={() => {
                    if(phase < 2) {
                        setPhase(phase+1);
                        updateIntroFields();
                    } else
                        nextPage();
                }}>
                    <Text p={0.5} item xs={12} align="center" variant="body2" color="primary" bold id={"/matchtime.page2.button" + phase}/>
                </Button>
            </Fade>
            </Grid>
        </Grid>
    </React.Fragment>);}

function Page3(props) {return(PageTemplate(
    "/assets/img/matchtime/doggy.gif",
    "/matchtime.page3.title",
    "/matchtime.page3.description",
    "/matchtime.page3.button",
    () => window.location.href = "/" //use window.location.href cause for some reason history.push and <Redirect> break styling
));}

function PageTemplate(imgSrc, title, description, buttonText, onNextPage) {return(
    <React.Fragment>
        <Fade in={true} timeout={500}>
            <Image src     = {imgSrc}
                   alt     = "intro"
                   sx      = {{
                    minWidth: "150px", 
                   }}
                   width   = "50%"/>
        </Fade>
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="h1"    bold id={title}/>
        </Fade>
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="body1" bold id={description}/>
        </Fade>
        <Grid container item xs={6} mt={4} justifyContent="center">
            <Fade in={true} timeout={1000}>
                <Button fullWidth variant="contained" color="darkPetrol" onClick={onNextPage} sx={{width: "fit-content"}}>
                    <Text p={0.5} item xs={12} align="center" variant="body2" color="primary" bold id={buttonText}/>
                </Button>
            </Fade>
        </Grid>
    </React.Fragment>);}

function iamBubble(text, values = null) {
    return(
        <ChatBubble iam matchtime delay={2}>
          <Text item xs={12} variant="body" bold color="white" id={text} values={values}/>
        </ChatBubble>
    )
}

function userBubble(img, content, aiSentence) {
    return(
    <React.Fragment>
        <ChatBubble image={img} delay={6} matchtime name="">
            <Background color="white" mt={-2} p={4} border="topRight" container>
                {content}
            </Background>
        </ChatBubble>
        {aiSentence.length > 250 &&
        <Text item color="red" variant="body1" xs={12} align="right" pr={10} mt={-1} id="/matchtime.page2.warning"/>}
        {/* <Text item xs={9} mt={-3} variant="body3" align="right">{aiSentence}</Text>
        <Text item color={aiSentence.length > 250 ? "red" : "white"} variant="body" xs={2} align="right" pr={10} mt={-3}>{aiSentence.length}/250</Text> */}
    </React.Fragment>
    );
}
