import { GET_COLLAB_REQUESTS } from "../../Queries";
import { useCachedQuery } from "../CachedQuery";

export function useListRequests(variables) {
    return useCachedQuery(
        "allCollabRequests",
        GET_COLLAB_REQUESTS,
        variables,
        (data) => data?.allCollaborationRequest?.result,
        5,
        false
    );
}