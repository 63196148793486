import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Alert, AlertTitle, Skeleton, Grid } from "@mui/material";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { FormattedMessage } from "react-intl";
import styles from "../../styles/container/AccountSettingStyle";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../apiServices/mutations";
import { isValidPassword } from "../General";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";

const useStyles = makeStyles(styles);

export default function ChangePassword(props) {
  const [ChangePasswordMutation, { data, loading, error }] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted: (datas) => {
        if (
          typeof datas !== "undefined" &&
          typeof props.onRefresh === "function" &&
          datas.changePassword.message === "Password Changed Successfully"
        ) {
          props.onRefresh();
        } else {
          setChangePasswordError(datas.changePassword.message)
        }
      },
    }
  );
  const classes = useStyles();
  const theme = useTheme();
  const [oldPasswordError, setOldPasswordError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordWrong, setPasswordWrong] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [passwordMismatch, setpasswordMismatch] = React.useState("");
  const [changePasswordError, setChangePasswordError] = React.useState("");
  const [samePasswordError, setSamePasswordError] = React.useState(false);

  const [input, setInput] = React.useState({});

  React.useEffect(() => {
    // console.log("data", data);
    if (
      typeof data !== "undefined" &&
      typeof data.changePassword !== "undefined"
    ) {
      setInput({});
    }
  }, [data]);

  const saveProfileInfo = async () => {
    // console.log("userid", props.userId);
    // console.log("input", input);
    let isError = await checkForErrors();
    if (isError) {
      ChangePasswordMutation({
        variables: {
          userId: props.userId,
          currentPassword: input["oldPassword"],
          newPassword: input["newPassword"],
        },
      });
    }
  };
  const checkForErrors = async () => {
    setOldPasswordError(!input["oldPassword"] ? "passwordError" : "");
    setPasswordError(!input["newPassword"] ? "passwordError" : "");
    setPasswordWrong(
      typeof input["newPassword"] !== "undefined" &&
        !isValidPassword(input["newPassword"])
        ? "passwordWrong"
        : ""
    );
    setConfirmPasswordError(
      !input["confirmPassword"] ? "confirmPasswordError" : ""
    );
    if (
      typeof input["newPassword"] !== "undefined" &&
      typeof input["confirmPassword"] !== "undefined"
    ) {
      setpasswordMismatch(
        input["newPassword"] !== input["confirmPassword"]
          ? "mismatchPasswordError"
          : ""
      );
    }
    if (input["newPassword"] === input["oldPassword"]) {
      setSamePasswordError(true);
    }

    return (
      input["oldPassword"] &&
      input["newPassword"] &&
      isValidPassword(input["newPassword"]) &&
      input["confirmPassword"] &&
      input["newPassword"] === input["confirmPassword"] &&
      input["newPassword"] !== input["oldPassword"]
    );
  };
  const handleOnChange = (event) => {
    let inputs = input;
    inputs[event.target.id] = event.target.value;
    setInput(inputs);
    if (event.target.id === "oldPassword") {
      setOldPasswordError("");
    }
    if (event.target.id === "newPassword") {
      setPasswordError("");
      setPasswordWrong("");
    }
    if (event.target.id === "confirmPassword") {
      setConfirmPasswordError("");
      setpasswordMismatch("");
    }
    setChangePasswordError("")
    setSamePasswordError(false)
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>

        {error ? (
          <Alert severity="error" >
            <AlertTitle>
              <FormattedMessage id={"error"} />
            </AlertTitle>
            {<FormattedMessage id={"somethingWentWrong"} />}
          </Alert>
        ) : null}
        {changePasswordError.length ? (
          <Alert severity="error" onClose={() => { setChangePasswordError("") }}>
            <AlertTitle>
              <FormattedMessage id={"error"} />
            </AlertTitle>
            {changePasswordError}
          </Alert>
        ) : null}
        {samePasswordError ? (
          <Alert severity="error" onClose={() => { setSamePasswordError(false) }}>
            <AlertTitle>
              <FormattedMessage id={"error"} />
            </AlertTitle>
            {<FormattedMessage id={"samePasswordError"} />}
          </Alert>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" variant="h2" color={"textPrimary"} className={classes.mtb7}>
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <FormattedMessage id={"oldPassword"} />
          )}
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              id="oldPassword"
              type={"password"}
              size="small"
              autoComplete="current-password"
              variant="outlined"
              onChange={handleOnChange}
              inputProps={{ style: theme.palette.textFieldInputLabelProps }}
              InputLabelProps={{ style: theme.palette.textFieldInputProps }}
              helperText={
                oldPasswordError ? (
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={oldPasswordError} />
                  </Typography>
                ) : null
              }
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mtb7}
        >
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <FormattedMessage id={"newPassword"} />
          )}
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              id="newPassword"
              size="small"
              type={"password"}
              autoComplete="current-password"
              variant="outlined"
              onChange={handleOnChange}
              inputProps={{ style: theme.palette.textFieldInputLabelProps }}
              InputLabelProps={{ style: theme.palette.textFieldInputProps }}
              helperText={
                passwordError ? (
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={passwordError} />
                  </Typography>
                ) : passwordWrong ? (
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={passwordWrong} />
                  </Typography>
                ) : null
              }
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mtb7}
        >
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <FormattedMessage id={"confirmPassword"} />
          )}
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              id="confirmPassword"
              size="small"
              type={"password"}
              autoComplete="current-password"
              variant="outlined"
              onChange={handleOnChange}
              inputProps={{ style: theme.palette.textFieldInputLabelProps }}
              InputLabelProps={{ style: theme.palette.textFieldInputProps }}
              helperText={
                confirmPasswordError ? (
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={confirmPasswordError} />
                  </Typography>
                ) : passwordMismatch ? (
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={passwordMismatch} />
                  </Typography>
                ) : null
              }
            />
          )}
        </FormControl>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Grid item sm={2} xs={12}>
          <Typography component="h3" variant="h3">
            <ButtonDarkBlue onClick={saveProfileInfo}>
              <FormattedMessage id={"save"} />
            </ButtonDarkBlue>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
ChangePassword.prototype = {
  userId: PropTypes.string,
};
