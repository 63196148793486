import React from "react";
import { useHistory } from "react-router";
import { ActivitySource } from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { Link, ThemeProvider } from "@mui/material";
import Text from "../../../visualComponents/Text";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";


export default function ActivityCollabRequest({activity, source, color}) {
    const history = useHistory();

    const navigateToRequest = () => {
        history.push("/dashboard/requests", {
            selectedRequestId: activity.activity.activitySourceId,
        });
    };

    if(source == "menu")
      return undefined;
    if(source == "canCollapse")
      return undefined;
    return (
        <ThemeProvider theme={Theme_WIP}>
            {activity.activity && <Text color={color??"background"} item xs={12}>
                {activity.activity.messages}
                {(activity.activity && activity.activity.activitySourceDesc === ActivitySource.collabRequest) && (
                    <Link color="inherit" underline="always" component="button" onClick={navigateToRequest}>
                        <FormattedMessage id="GoToRequest" />
                    </Link>
                )}
            </Text>
            }
        </ThemeProvider>
    );
}
