import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router";
import styles from "../styles/container/OnboardingPageStyle";
import { CircularProgress, CssBaseline } from "@material-ui/core";
import { TaxonomyList } from "../apiServices/Constants";
import { useCircleDomain, useCollaborationDomain, useIndustries, usePersonalLife, useProfessionalLife, useProfileDomain, useSkills } from "../apiServices/Queries/GeneralQueries";

const useStyles = makeStyles(styles);
export default function OnboardingMain() {
  const classes = useStyles();
  const history = useHistory();
  const { data: dataCollabPayLoad    } = useCollaborationDomain ();
  const { data: dataSkills           } = useSkills              ();
  const { data: dataIndustries       } = useIndustries          ();
  const { data: dataProfilePayload   } = useProfileDomain       ();
  const { data: dataCirclePayload    } = useCircleDomain        ();
  const { data: dataPersonalLife     } = usePersonalLife        ();
  const { data: dataProfessionalLife } = useProfessionalLife    ();
  React.useEffect(() => {
    if (dataCollabPayLoad) {
      localStorage.setItem(TaxonomyList.commitment                 , JSON.stringify(dataCollabPayLoad.commitment                 ));
      localStorage.setItem(TaxonomyList.investmentType             , JSON.stringify(dataCollabPayLoad.investmentType             ));
      localStorage.setItem(TaxonomyList.investorType               , JSON.stringify(dataCollabPayLoad.investorType               ));
      localStorage.setItem(TaxonomyList.companyStage               , JSON.stringify(dataCollabPayLoad.companyStage               ));
      localStorage.setItem(TaxonomyList.businessStage              , JSON.stringify(dataCollabPayLoad.businessStage              ));
      localStorage.setItem(TaxonomyList.businessPartnerBenefitType , JSON.stringify(dataCollabPayLoad.businessPartnerBenefitType ));
      localStorage.setItem(TaxonomyList.period                     , JSON.stringify(dataCollabPayLoad.period                     ));
      localStorage.setItem(TaxonomyList.days                       , JSON.stringify(dataCollabPayLoad.days                       ));
    }
    if (dataIndustries) {
      let industryValues = dataIndustries.map(
        ({ industry }) => industry
      );
      localStorage.setItem(TaxonomyList.industries, JSON.stringify(industryValues));
    }
    if (dataSkills) {
      let skillValues = dataSkills.map(({ name }) => name);
      localStorage.setItem(TaxonomyList.skills, JSON.stringify(skillValues));
    }
    if (dataProfilePayload) {
      localStorage.setItem(TaxonomyList.expertLevel  , JSON.stringify(dataProfilePayload.experienceLevel ));
      localStorage.setItem(TaxonomyList.companyLevel , JSON.stringify(dataProfilePayload.companyLevel    ));
      localStorage.setItem(TaxonomyList.companyRole  , JSON.stringify(dataProfilePayload.companyRole     ));
    }
    if (dataCirclePayload) {
      localStorage.setItem(TaxonomyList.circleTags      , JSON.stringify(dataCirclePayload.getAllCircleTags));
      localStorage.setItem(TaxonomyList.circleOperatesIn, JSON.stringify(dataCirclePayload.getAllOperatesIn));
    }

    if (dataPersonalLife) {
      let personalLifeValues = dataPersonalLife.map(({ personalLife }) => personalLife);
      localStorage.setItem(TaxonomyList.personalLife, JSON.stringify(personalLifeValues));
    }
    if (dataProfessionalLife) {
      let ProfessionalValues = dataProfessionalLife.map(({ professionalLife }) => professionalLife);
      localStorage.setItem(TaxonomyList.professionalLife, JSON.stringify(ProfessionalValues));
    }

    if(dataCollabPayLoad    != null
    && dataIndustries       != null
    && dataSkills           != null
    && dataProfilePayload   != null
    && dataCirclePayload    != null
    && dataPersonalLife     != null
    && dataProfessionalLife != null)
      handleClick();
  }, [
    dataProfilePayload,
    dataCollabPayLoad,
    dataSkills,
    dataIndustries,
    dataCirclePayload,
    dataPersonalLife,
    dataProfessionalLife
  ]);

  const handleClick = () => {
    localStorage.setItem("onBoardProgress", 0);
    history.push("/OnboardingStepper");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="sm" className={classes.container}>
        <CircularProgress color="inherit" size={40} />
      </Container>
    </React.Fragment>
  );
}
