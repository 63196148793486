import React from "react";
import {
  MenuItem,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { ActivitySource } from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

export default function ActivityRespondToRequestCard({activity, source, isLess, AllActivity, color}) {
  const history = useHistory();

  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };
  const navigateToThankyou = () => {
    history.push("/sendThankyou", {
      activity: activity,
    });
  };
  const navigateToRequest = () => {
    history.push("/dashboard/requests", {
      selectedRequestId: activity.activity.activitySourceId,
    });
  };

  if(source == "menu")
    return
      <>
        <MenuItem onClick={navigateToMessage}>
          <FormattedMessage id={"Message"} />
        </MenuItem>
        {/* <MenuItem onClick={navigateToThankyou}>
          <FormattedMessage id={"Send_A_Thank_You"} />
        </MenuItem> */}
        <MenuItem onClick={AllActivity}>
          <FormattedMessage id={"ToAllActivities"} />
        </MenuItem>
      </>;
  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text color={color??"background"} item xs={12}>
        {isLess && (activity?.activity?.messages?.length ?? 0) > 150 ? 
            activity.activity.messages.substring(0, 150) + "..." : activity?.activity?.messages}
      </Text>
      {(!isLess || (activity?.activity?.messages?.length ?? 151) <= 150)
      && activity?.activity?.activitySourceDesc ===  ActivitySource.collabRequest &&
        <Text color={color??"background"}>
          <Link
            color="inherit"
            component="button"
            underline="always"
            onClick={navigateToRequest}
          >
            <FormattedMessage id="GoToRequest" />
          </Link>
        </Text>
      }
    </ThemeProvider>
  );
}
