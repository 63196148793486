import React from "react";
import {
  MenuItem,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";


export default function ActivityEventNewsUpdateCard({source, activity, isLess, color}) {
  const history = useHistory();

  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };
  const navigateToEventDetail = () => {
    history.push("/dashboard/eventDetails/?id=" + activity.activity.eventId, {
      selectedEventId: activity.activity.eventId,
      selectedTab: 1,
      selectedNewsID: activity.activity.activitySourceId,
    });
  };
  
  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  if(source == "menu")
    return <MenuItem onClick={navigateToMessage}> <FormattedMessage id={"Message"} /> </MenuItem>;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text item xs={12} color={color??"background"}>
        {isLess && (activity?.activity?.messages?.length ?? 0) > 150 ?
          activity.activity.messages.substring(0, 150) + " " : activity.activity.messages}
      </Text>

      {activity?.activity?.status !== ActivityStatus.Closed && (!isLess ||  (activity?.activity?.messages?.length ?? 0) <= 150) &&
        <Text item xs={12} color={color??"background"}>
          <Link
            color="inherit"
            component="button"
            underline="always"
            onClick={navigateToEventDetail}
          >
            <FormattedMessage id="SeeAllNewsUpdates" />
          </Link>
        </Text>
      }
    </ThemeProvider>
  );
}
