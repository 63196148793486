import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Styles from "../../styles/component/Cards/CircleCardStyle";
import { masterDataCircleOperatesIn } from "../../apiServices/Constants";
import { CardMedia, Chip } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";
const useStyles = makeStyles(Styles);

export default function CircleCard(props) {
  const classes = useStyles();
  const getOperatesInKey = (operatesIn) => {
    const selectedOperatesIn = operatesIn;
    const array = masterDataCircleOperatesIn().filter((item) => {
      return item.value === parseInt(selectedOperatesIn);
    });
    return array.length ? array[0].key : "";
  };
  
  return (
    <ThemeProvider theme={Theme_WIP}>
    <Background color="white" border="bottomLeft">
      <Grid container xs={12}>
        <Grid container item xs={7} sm={9} p={5} gap={2} height="fit-content">
          {props.circleData?.parentCircleName && <Text variant="body2" bold color="background" id="CircleHead"  values={{circle: props.circleData.parentCircleName}}/>}
          <Text item xs={12} variant="h1" bold>
            {props.circleData ? props.circleData.name : ""}
          </Text>

          {props?.circleData?.shortDescription && <Text item xs={12} variant="body1" color={"background"}>
            {props.circleData.shortDescription}
          </Text>}

          {props?.circleData?.location  && <Text item xs={12} variant="body1" color={"background"}>
            <b><FormattedMessage id="Location"/>:</b> {props.circleData.location}
          </Text>}

          {/* <Text item xs={12} sm={12} variant="body1"
              color={"background"}
              sx={{
                wordWrap: "break-word", marginTop: 7,
              }}
          >
            {(props.circleData && props.circleData.location
              ? intl.formatMessage({ id: "Location" }) +
              ":" +
              " " +
              props.circleData.location +
              " / "
              : "") +
              (props.circleData &&
                props.circleData.operatesIn &&
                props.circleData.operatesIn.length
                ? getOperatesInKey(props.circleData.operatesIn) + " circle"
                : "")}
          </Text> */}
          {props.circleData?.circleTags && props.circleData?.circleTags.length && 
          <Grid container item xs={12} gap={2}>
              {(props.circleData?.circleTags
                ? props.circleData?.circleTags
                : []
              ).map((tags) => (
                <Chip
                  key={tags}
                  label={tags}
                  color="background"
                />
              ))}
          </Grid>}
          
        </Grid>
        <Grid item xs={5} sm={3}>
          <CardMedia
            component="img"
            image={props.circleData?.logo ?? "/assets/img/mastermatch_circle_default.png"}
            alt="profile"
            className={classes.logo}
          />
        </Grid>
      </Grid>
    </Background>
    </ThemeProvider>
  );
}
