
const BioInfoStyle = (theme) => ({
    p7: {
        marginTop: 7,
        marginRight: 5
    },
    p15: {
        marginTop: 15,
    },
    chip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5
    },
    iconGlobe: {
        color: "#72B6CD",
        fontSize: 14,
    },
    iconGlobelink: {
        position: 'relative',
        bottom: 3,
        left: 5,
        color: "#72B6CD",
    },
});
export default BioInfoStyle;