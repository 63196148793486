import React from "react";
import _IncomingMessage from "./_IncomingMessage";
import _IamMessage from "./_IamMessage";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";

export default function Message(props) {
    const {children} = props;
    
    if(props.iam)
        return(<ThemeProvider theme={Theme_WIP}><_IamMessage {...props}>{children}</_IamMessage></ThemeProvider>);
    else
        return(<_IncomingMessage {...props}>{children}</_IncomingMessage>);
}