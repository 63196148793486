
import { tabStyle } from "../../container/CommonStyle"

const InfoTabStyle = (theme) => ({
    ...tabStyle,
    root: {
        width: "100%",
    },
    mt10: {
        marginTop: "10px",
    },
    doticon: {
        float: 'right',
        fontSize: 32,
        marginTop: 10,
        border: "2px solid #bdbdbd",
        borderRadius: 4,
        color: "#bdbdbd",
    },

    doticonbottom: {
        float: 'right',
        fontSize: 32,
        color: "#bdbdbd",
        paddingBottom: 5
    },
    closeicon: {
        float: 'right',
        fontSize: 32,
        color: theme.palette.color5.main
    },
    tabcontent: {
        margin: "8px 0px 15px 0",
        padding: "20px !important",
    },
    p7: {
        marginTop: 7,
    },
    boldFont: {
        fontWeight: "bold"
    }
});

export default InfoTabStyle;