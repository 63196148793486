import * as React from "react";
import { CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { Create_Circle_News } from "../../apiServices/mutations";
import { GetUserId } from "../../apiServices/CommonMethods";
import { useLocation } from "react-router";
import { EDIT_CIRCLE_NEWS } from "../../apiServices/mutations";
import { selectedBrandColor } from "../../apiServices/Redux/Reducer";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import FormContainer from "../../components/Forms/FormContainer";
import InputSection from "../../components/Forms/InputSection";

export default function AddNews(props) {
  const brandColor = useSelector(selectedBrandColor);
  const [notify, setNotify] = React.useState(false);
  const history = useHistory();
  const userId = GetUserId();
  const location = useLocation();
  const intl = useIntl();
  const [
    createNews,
    { data: dataAddNews, loading: loadingAddNews },
  ] = useMutation(Create_Circle_News);

  const [editNewsData, { data: dataEditNews, loading: loadingEditNews }] =
    useMutation(EDIT_CIRCLE_NEWS);

  const [circleData, setCircleData] = React.useState("");
  const [circleNewsData, setCircleNewsData] = React.useState("");
  const [input, setInput] = React.useState({
    inputText: "",
  });
  const [inputError, setInputError] = React.useState({
    inputText: "",
  });

  React.useEffect(() => {
    if (location.state && typeof location.state.circleDetails !== "undefined") {
      setCircleData(location.state.circleDetails);
    }
    if (location.state && location.state.isEdit === true) {
      setInput({ inputText: location.state.newsData.newsInfo });
      setCircleNewsData(location.state.newsData);
    }
  }, [location, dataAddNews, dataEditNews]);

  const handleOnChange = (event) => {
    const { id, value } = event.target;
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const checkForErrors = () => {
    setInputError((prevState) => ({
      ...prevState,
      inputText: !input["inputText"].trim() ? "input error" : "",
    }));

    return input["inputText"].trim().length !== 0;
  };

  const handleOnAddNews = () => {
    const isError = checkForErrors();

    if (isError) {
      const variable = {
        newsInfo: input["inputText"],
        circleId: circleData.id,
        loginProfileId: userId,
      };

      createNews({
        variables: variable,
      });
    }
  };

  const handleOnEditNews = (flag) => {
    const isError = checkForErrors();

    if (isError) {
      if (location.state.isEdit === true) {
        const editVariable = {
          newsId: circleNewsData.id,
          newsInfo: input["inputText"],
          loginProfileId: userId,
          circleId: circleNewsData.circleId,
          sendNotification: flag,
        };
        // console.log("Edit varibale :", editVariable);
        editNewsData({
          variables: editVariable,
        });
      }
    }
  };

  const handleSucessAlert = () => {
    // history.push("/dashboard/circleDetails", {
    //   circleDetails: circleData,
    //   selectedTab: 1,
    // });
    history.push("/dashboard/circleDetails/?id=" + circleData.id, {
      circleDetails: circleData,
      selectedTab: 1,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        modalMessage = {(dataEditNews?.editCircleNews?.errorCode ?? dataAddNews?.createCircleNews?.errorCode) ==  0 ? <FormattedMessage id={"newsAddedSuccessfully"} /> : undefined}
        onModalClose = {handleSucessAlert}
        error        = {(dataEditNews?.editCircleNews?.errorCode ?? dataAddNews?.createCircleNews?.errorCode) == -1 ? (dataEditNews?.editCircleNews?.message ?? dataAddNews?.createCircleNews?.message) : undefined}
        title        = {<FormattedMessage id={location.state.isEdit ? "editNewsUpdate" : "writeANewsUpdateToAllMembers/Visitors"}/>}
        description  = {location.state.isEdit ? undefined : <FormattedMessage id="AddNews_Page_Description"/>}
        loading      = {loadingAddNews || loadingEditNews}
        onSave       = {() => location.state.isEdit ? handleOnEditNews(notify) : handleOnAddNews()}
        disabled     = {loadingAddNews || loadingEditNews}
        onCancel     = {() => history.goBack()}
        saveId       = "send"
      >
        <InputSection
        type        = "textarea"
        disabled    = {loadingAddNews || loadingEditNews}
        id          = "inputText"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<FormattedMessage id={location.state.isEdit ? "adjustYourMessage" : "writeMessage"}/>}
        value       = {input["inputText"]}
        onChange    = {handleOnChange}
        hint        = {inputError["inputText"] ? <FormattedMessage id="pleaseEnterMessage" /> : undefined}
        max         = {1000}
        />

        {location.state.isEdit && <>
        <InputSection
        type        = "toggle"
        disabled    = {loadingAddNews || loadingEditNews}
        id          = "toggle"
        title       = {<FormattedMessage id="doYouwantToSendANewNotification"/>}
        value       = {notify}
        onChange    = {(event, newValue) => setNotify(newValue)}
        options     = {[
          {key: intl.formatMessage({ id: "sendNotification" }), value: true},
          {key: intl.formatMessage({ id: "onlySave"         }), value: false}
        ]}
        />
        </>}
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
