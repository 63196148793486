import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  CssBaseline,
  Hidden,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import {
  selectedBrandColor,
  updateBrandColor,
} from "../../apiServices/Redux/Reducer";
import { useDispatch, useSelector } from "react-redux";
import { GetUserId } from "../../apiServices/CommonMethods";
import { useMutation } from "@apollo/client";
import { CANCEL_CIRCLE_ADMIN } from "../../apiServices/mutations";
import { Alert, Skeleton } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Styles from "../../styles/container/Circle/CancelConfirmationScreenStyle";

const useStyles = makeStyles(Styles);

export default function CancelConfirmationScreen() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const userId = GetUserId();
  const location = useLocation();
  const dispatch = useDispatch();
  const brandColor = useSelector(selectedBrandColor);
  const [selectedCircleId, setSelectedCircleId] = React.useState(null);

  const [
    cancleCircleByAdmin,
    { data: dataCancelCircleByAdmin, loading: loadingCancelCircleByAdmin },
  ] = useMutation(CANCEL_CIRCLE_ADMIN);
  React.useEffect(() => {
    if (
      location.state &&
      typeof location.state.selectedCircleId !== "undefined"
    ) {
      setSelectedCircleId(location.state.selectedCircleId);
    }
    if (
      typeof dataCancelCircleByAdmin !== "undefined" &&
      dataCancelCircleByAdmin.circleClosed.errorCode === 0
    ) {
      dispatch(updateBrandColor(theme.palette.primary.main));
      history.push("/dashboard/circles");
    }
  }, [location, dataCancelCircleByAdmin]);
  const cancelCircleByAdminAction = async () => {
    let variable = {
      circleId: selectedCircleId,
      loginUserId: userId,
    };
    // console.log(" cancelCircleByAdminAction variable", variable);
    cancleCircleByAdmin({
      variables: variable,
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(4), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            <Grid item sm={11} xs={10}>
              {typeof dataCancelCircleByAdmin !== "undefined" &&
                dataCancelCircleByAdmin.circleClosed.errorCode === -1 ? (
                <Alert severity={"error"}>
                  {dataCancelCircleByAdmin.circleClosed.message}
                </Alert>
              ) : null}
            </Grid>
            <Grid item sm={11} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"Delete_Circle"} />
              </Typography>
            </Grid>
            <Grid item sm={1} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={() => {
                  history.goBack();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component="h3" variant="h2" color={"textPrimary"}>
                <FormattedMessage id={"CancelConfirmationScreen_Description"} />
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3}>
              {loadingCancelCircleByAdmin ? (
                <Skeleton variant="rectangular" height={40} />
              ) : brandColor ? (
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: brandColor, color: "white" }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <FormattedMessage id={"Button_Text_GoBack"} />
                </Button>
              ) : (
                <ButtonDarkBlue
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <FormattedMessage id={"Button_Text_GoBack"} />
                </ButtonDarkBlue>
              )}
            </Grid>
            <Grid item xs={3} sm={3}>
              {loadingCancelCircleByAdmin ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Button
                  disableElevation
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #bdbdbd",
                  }}
                  onClick={cancelCircleByAdminAction}
                >
                  <FormattedMessage id={"DeleteCircle"} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
