import React from "react";
import Grid from "@mui/material/Grid";
import MatchRequestCard from "../../../components/Cards/ActivityCards/MatchRequestCard";
import { onlyUnique } from "../../../apiServices/CommonMethods";
import ActivityMessageCard from "../../../components/Cards/ActivityCards/ActivityMessageCard";
import ActivityThankyouCard from "../../../components/Cards/ActivityCards/ActivityThankyouCard";
import ActivityRespondToRequest from "../../../components/Cards/ActivityCards/ActivityRespondToRequestCard";
import ActivityIntroductionCard from "../../../components/Cards/ActivityCards/ActivityIntroductionCard";
import ActivitySuggestionCard from "../../../components/Cards/ActivityCards/ActivitySuggestionCard";
import ActivityLeftCircleCard from "../../../components/Cards/ActivityCards/ActivityLeftCircleCard";
import ActivityCircleInvite from "../../../components/Cards/ActivityCards/ActivityCircleInvite";
import ActivityNewsUpdate from "../../../components/Cards/ActivityCards/ActivityNewsUpdate";
import ActivityCircleContactManager from "../../../components/Cards/ActivityCards/ActivityCircleContactManager";
import ActivityNewsRespond from "../../../components/Cards/ActivityCards/ActivityNewsRespond";
import ActivityCirclePostRespond from "../../../components/Cards/ActivityCards/ActivityCirclePostRespond";
import ActivityCirclePost from "../../../components/Cards/ActivityCards/ActivityCirclePost";
import ActivityMatchRequestAcceptCard from "../../../components/Cards/ActivityCards/ActivityMatchRequestAcceptCard";
import ActivitySuggestionResponseCard from "../../../components/Cards/ActivityCards/ActivitySuggestionResponseCard";
import ActivityEventJoinAsGuest from "../../../components/Cards/ActivityCards/ActivityEventJoinAsGuest";
import ActivityEventJoinAsGuestRespond from "../../../components/Cards/ActivityCards/ActivityEventJoinAsGuestRespond";

import ActivityEventInvite from "../../../components/Cards/ActivityCards/ActivityEventInvite";
import ActivityMakeEventManager from "../../../components/Cards/ActivityCards/ActivityMakeEventManager";
import ActivityEventCancel from "../../../components/Cards/ActivityCards/ActivityEventCancel";
import ActivityEventNewsUpdateCard from "../../../components/Cards/ActivityCards/ActivityEventNewsUpdateCard";
import ActivityEventNewsRespond from "../../../components/Cards/ActivityCards/ActivityEventNewsRespond";
import { Waypoint } from "react-waypoint";
import ActivityCollabRequest from "../../../components/Cards/ActivityCards/ActivityCollabRequest";
import YesCard from "../../../components/Cards/ActivityCards/YesCard";
import MatchSuggestionFoundCard from "../../../components/Cards/ActivityCards/MatchSuggestionFoundCard";
import { ActivityCard } from "../../ActivityPage";
import { useIntl } from "react-intl";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";
import { ThemeConstants } from "../../../styles/themes/main/Theme_WIP";

export default function ActivityList(activities, onRefresh, onRefreshAllActivity) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const intl = useIntl();
  const globals = useGlobalContext();

  const loadCard = (activity, source, isLess, color) => {    
    let props = {color: color == "white" ? "background" : color, sent: activity.activity.requesterUserProfileId == globals.state.loggedInUser, activity: activity, source: source, isLess: isLess, onRefresh: onRefresh, AllActivity: () => onRefreshAllActivity(activity)}
    
    if (activity.activity.activityType === "MATCH_REQUEST")
      return (MatchRequestCard({...props}));
    
    else if (activity.activity.activityType === "RESPONSE_TO_REQUEST" &&  activity.activity.activitySource !== "CIRCLE_NEWS")
      return (ActivityRespondToRequest({...props}));
    
    else if (activity.activity.activityType === "MESSAGE")
      return (ActivityMessageCard({...props}));
    
    else if (activity.activity.activityType === "THANK_YOU")
      return (ActivityThankyouCard({...props}));
    
    else if (activity.activity.activityType === "INTRODUCTION")
      return (ActivityIntroductionCard({...props}));

    else if (activity.activity.activityType === "SUGGESTION")
      return (ActivitySuggestionCard({...props}));

    else if (activity.activity.activityType === "CIRCLE_UPDATE" || activity.activity.activityType === "PROFILE_INVITATION" || activity.activity.activityType === "CIRCLE_CLOSED")
      return (ActivityLeftCircleCard({...props}));
    
    else if (activity.activity.activityType === "CIRCLE_INVITATION")
      return (ActivityCircleInvite({...props}));

    else if (activity.activity.activityType === "CIRCLE_NEWS_UPDATE")
      return (ActivityNewsUpdate({...props}));

    else if (activity.activity.activityType === "CIRCLE_CONTACT_FORM")
      return (ActivityCircleContactManager({...props}));
    
    else if (activity.activity.activityType === "CIRCLE_NEWS_RESPOND")
      return (ActivityNewsRespond({...props}));

    else if (activity.activity.activityType === "CIRCLE_POST")
      return (ActivityCirclePost({...props}));

    else if (activity.activity.activityType === "CIRCLE_POST_RESPOND")
      return (ActivityCirclePostRespond({...props}));

    else if (activity.activity.activityType === "MATCH_REQUEST_ACCEPT")
      return (ActivityMatchRequestAcceptCard({...props}));

    else if (activity.activity.activityType === "SUGGESTION_RESPOND")
      return (ActivitySuggestionResponseCard({...props}));

    else if (activity.activity.activityType === "JOIN_AS_GUEST")
      return (ActivityEventJoinAsGuest({...props}));

    else if (activity.activity.activityType === "JOIN_AS_GUEST_RESPOND")
      return (ActivityEventJoinAsGuestRespond({...props}));

    else if (activity.activity.activityType === "EVENT_INVITATION")
      return (ActivityEventInvite({...props}));

    else if (activity.activity.activityType === "EVENT_UPDATE")
      return (ActivityMakeEventManager({...props}));

    else if (activity.activity.activityType === "EVENT_CLOSED")
      return (ActivityEventCancel({...props}));

    else if (activity.activity.activityType === "EVENT_NEWS_UPDATE")
      return (ActivityEventNewsUpdateCard({...props}));

    else if (activity.activity.activityType === "EVENT_NEWS_RESPOND")
      return (ActivityEventNewsRespond({...props}));

    else if (activity.activity.activityType === "UPDATE")
      return (ActivityCollabRequest({...props}));

    else if (activity.activity.activityType === "YES")
      return (YesCard({...props}));

    else if (activity.activity.activityType === "MATCH_SUGGESTION_FOUND")
      return (MatchSuggestionFoundCard({...props}));

    return ActivityCircleContactManager({...props});
  };

  const getCurrentActivityList = () => activities?.activities ? activities.activities.filter(onlyUnique) : activities?.filter(onlyUnique);

  return (
      <Grid container maxWidth="md" gap={ThemeConstants.custom.spacings.md} mt={ThemeConstants.custom.spacings.md}>
        {getCurrentActivityList().map((activity, index) => (
            <React.Fragment key={index}>
            <Grid item xs={12} sm={12} key={index} sx={{pointerEvents: "none !important"}}>
              <ActivityCard
              //todo      = {activityUserID == undefined} 
              activity  = {activity} 
              onRefresh = {onRefresh} 
              loadCard  = {(source, isLess, color) => loadCard(activity, source, isLess, color)}
              intl      = {intl} 
              userId    = {globals.state.loggedInUser.userId}>
                {loadCard(activity)}
              </ActivityCard>
            </Grid>
            {index ===
                getCurrentActivityList().length -
                2 && (
                <Waypoint
                    scrollableAncestor={window}
                    onEnter={() => {
                    // console.log("index", index);
                    const newPage = pageNumber + 1;
                    // console.log("newPage", newPage);
                    setPageNumber(newPage)
                    }}
                />
                )}
            </React.Fragment>
        ))
        }
      </Grid>
  );
}
