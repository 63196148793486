import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ButtonDarkBlue } from "../components/Buttons/ButtonDarkBlue";
import styles from "../styles/container/ForgotPasswordStyle";
import {
  Alert,
  Box,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { isEmail } from "../components/General";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "../apiServices/mutations";
import { FormattedMessage } from "react-intl";
import SuccessAlertWithAction from "../components/Alert/SuccessAlertWithAction";
import { signinRedirect } from "../components/IdentityServer/userService";
import Header from "../components/Header";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(styles);
export default function ForgotPassword() {
  const theme = useTheme();
  const classes = useStyles();
  const [email, setEmail] = React.useState([]);
  const [isEmailEmpty, setIsEmailEmpty] = React.useState(false);
  const [isEmailError, setIsEmailError] = React.useState(false);
  const [inputError, setInputError] = React.useState(false);

  const [forgotPassword, { data, loading }] = useMutation(
    FORGOT_PASSWORD,
    {
      onCompleted: (data) => {
        // console.log(data);
        if (
          typeof data !== "undefined" &&
          data.forgotPassword.errorCode === 0
        ) {
          // console.log("sucess");
          setIsEmailError(false);
        }
        if (
          typeof data !== "undefined" &&
          data.forgotPassword.errorCode === -1
        ) {
          // console.log("error");
          setEmail([]);
          setIsEmailError(true);
        }
      },
    }
  );
  const handleSave = () => {
    const isEmailEmpty = email.length === 0 ? true : false;
    setIsEmailEmpty(isEmailEmpty);
    if (!isEmailEmpty) {
      const emailFilter = !isEmail(email) ? "notvalidemail" : "";
      let result = emailFilter.includes("notvalidemail");
      const isValidemail = result ? true : false;
      setInputError(isValidemail);
      if (isValidemail === false) {
        setIsEmailError(false);
        const variables = {
          email: email,
        };
        // console.log("forgotPasswordVariables", variables);
        forgotPassword({
          variables: variables,
        });
      }
    } else {
      setIsEmailError(false);
    }
  };
  const onChange = (event) => {
    setIsEmailEmpty(false);
    setInputError(false);
    setIsEmailError(false);
    setEmail(event.target.value);
  };

  const handleSucessAlert = () => {
    signinRedirect();
  };
  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(2), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              {typeof data !== "undefined" &&
                data.forgotPassword.errorCode === 0 ? (
                <SuccessAlertWithAction
                  message={
                    <FormattedMessage id={"ForgotPassword_Description"} />
                  }
                  open={true}
                  handleClose={handleSucessAlert}
                />
              ) : null}
              {isEmailError ? (
                <Alert severity={"error"}>{data.forgotPassword.message}</Alert>
              ) : null}
            </Grid>
            <Grid item sm={11} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"Forgot_Password"} />
              </Typography>
            </Grid>
            <Grid item sm={1} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={handleSucessAlert}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"email"} />
                *:
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      id="filled-basic"
                      label={
                        <Typography variant="h5" component="span">
                          <FormattedMessage id={"RegisteredEmailId"} />
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 200 }}
                      onChange={onChange}
                    />
                  )}
                  {isEmailEmpty ? (
                    <FormHelperText>
                      {" "}
                      <Typography variant="h5" component="span" color="error">
                        <FormattedMessage id={"emailError"} />
                      </Typography>{" "}
                    </FormHelperText>
                  ) : null}
                  {inputError ? (
                    <FormHelperText>
                      {" "}
                      <Typography variant="h5" component="span" color="error">
                        <FormattedMessage id={"invite_email_valid_error"} />
                      </Typography>{" "}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12} className={classes.mt10}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <ButtonDarkBlue onClick={handleSave}>
                  <FormattedMessage id={"Button_text_Send"} />
                </ButtonDarkBlue>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
