import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../Header";
import { Alert, Hidden, Skeleton } from "@mui/material";
import Navigationbar from "../Navigationbar";
import Footer from "../Footer";
import { CssBaseline, useTheme } from "@material-ui/core";
import { SEND_THANKYOU } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import ActivityUserSearch from "../ActivityUserSearch";
import SuccessAlertDialog from "../Alert/SuccessAlertDialog";
import { ActivityTypes } from "../../apiServices/Constants";
import SendMessageStyle from "../../styles/component/Activities/SendMessageStyle";
import { FormattedMessage, useIntl } from "react-intl";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";

const useStyles = makeStyles(SendMessageStyle);

export default function SendThankyou() {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const intl = useIntl();

  const [activity, setActivity] = React.useState({});
  const [selectedUser, setSelectedUser] = React.useState(null);

  const [SendMessage, { data, loading }] = useMutation(SEND_THANKYOU);
  let [input, setInput] = React.useState({
    message: "",
    recipient: [],
    rating: null
  });
  let [inputError, setInputError] = React.useState({
    message: "",
    recipient: "",
    rating: ""
  });
  React.useEffect(() => {
    if (location.state && location.state.activity) {
      setActivity(location.state.activity);
      setInput((prevState) => ({
        ...prevState,
        recipient:
          ActivityTypes.introduction ===
            location.state.activity.activityTypeDesc
            ? location.state.activity.activity.requesterUserProfileId
            : location.state.activity.activity.recipientUserProfileIds,
      }));
    }
    if (location.state && location.state.selectedUser) {
      // console.log("location.state.selectedUser", location.state.selectedUser)
      setSelectedUser(location.state.selectedUser);
      setInput((prevState) => ({
        ...prevState,
        recipient: location.state.selectedUser.userId
      }));
    }
    if (typeof data !== "undefined") {
      setInput((prevState) => ({
        ...prevState,
        message: "",
      }));
    }
  }, [location, data]);

  const updateTextFileds = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const sendThankyouAction = async () => {
    let isValid = await checkForErrors();
    const variable = activity.activity
      ? {
        activitySource: activity.activity.activitySource,
        messages: input["message"],
        recipientUserProfileIds: activity.activity.requesterUserProfileId,
        requesterUserProfileId: userId,
        activitySourceId: activity.activity.activitySourceId,
        rating: input["rating"]
      }
      : {
        messages: input["message"],
        recipientUserProfileIds: input["recipient"],
        requesterUserProfileId: userId,
        rating: input["rating"]
      };
    // console.log("activity.activity ", activity.activity);
    // console.log("variable", variable);
    if (isValid) {
      SendMessage({
        variables: variable,
      });
    }
  };

  const checkForErrors = async () => {
    // console.log("input", input);
    setInputError((prevState) => ({
      ...prevState,
      recipient: !input["recipient"].length ? "recipientError" : "",
      message: !input["message"].trim() ? "messageError" : "",
      rating: !input["rating"] ? "ratingError" : "",
    }));
    return input["message"].trim() && input["recipient"].length && input["rating"];
  };
  const searchAction = (searchUser) => {
    // console.log("searchUser", searchUser);
    setInput((prevState) => ({
      ...prevState,
      recipient: [searchUser.userId],
    }));
    setInputError((prevState) => ({
      ...prevState,
      recipient: "",
    }));
  };
  const onRatingAction = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      rating: parseInt(newvalue),
    }));
    setInputError((prevState) => ({
      ...prevState,
      rating: "",
    }));
  };
  const getUserName = () => {
    if (typeof activity.givenName !== "undefined") {
      return activity.givenName + " " + activity.familyName
    } else if (selectedUser !== null) {
      return selectedUser.givenName + " " + selectedUser.familyName
    } else {
      return ""
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ mt: theme.spacing(2), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              {typeof data !== "undefined" &&
                data.sendThankYouActivity.errorCode === 0 ? (
                <SuccessAlertDialog
                  message={<FormattedMessage id={"ThankYouSentSuccessfully"} />}
                  open={true}
                />
              ) : null}
              {typeof data !== "undefined" &&
                data.sendThankYouActivity.errorCode === -1 ? (
                <Alert severity={"error"}>
                  {data.sendThankYouActivity.message}
                </Alert>
              ) : null}
            </Grid>
            <Grid item sm={11} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"ThankYou"} values={{full_name_sender: getUserName()}}/>
              </Typography>
            </Grid>
            <Grid item sm={1} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={() => history.goBack()}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"SendThankYou_Description"} />
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"I_Like_To_Thank"} />
                *:
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : typeof activity.givenName !== "undefined" || selectedUser !== null ? (
                    <TextField
                      id="recipient"
                      variant="filled"
                      size="small"
                      value={getUserName()}
                      disabled={typeof activity.givenName !== "undefined" || selectedUser !== null}
                    />
                  ) : (
                    <ActivityUserSearch
                      onSearch={searchAction}
                      placeHolder={intl.formatMessage({
                        id: "TypeName",
                      })}
                    />
                  )}
                  {inputError["recipient"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"pleaseEnterTheUserName"} />
                    </Typography>
                  ) : null}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"Rating_Field_Label"} />
                *:
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt7}
              >
                <FormattedMessage id={"Rating_Field_Description"} />
              </Typography>
              <Typography component="h3" variant="h3" color={"textPrimary"} className={classes.mt7}>
                {loading ? (
                  <Skeleton variant="rectangular" height={80} />
                ) : (<Stack spacing={1}>
                  <Rating name="half-rating" precision={1.0} size={"large"} onChange={onRatingAction} />
                </Stack>)}
                {inputError["rating"] ? (
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"pleaseEnterRating"} />
                  </Typography>
                ) : null}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"writeAMessage"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={80} />
                  ) : (
                    <TextareaAutosize
                      variant="filled"
                      id="message"
                      aria-label="minimum height"
                      minRows={5}
                      onChange={updateTextFileds}
                      placeholder={intl.formatMessage({
                        id: "typeYourMessage",
                      })}
                      className={classes.txtarea}
                    />
                  )}
                  {inputError["message"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"pleaseEnterMessage"} />
                    </Typography>
                  ) : null}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              {!loading ? (
                <ButtonDarkBlue onClick={sendThankyouAction}>
                  <FormattedMessage id={"Button_text_Send"} />
                </ButtonDarkBlue>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
