import React from "react";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

export default function ActivityLeftCircleCard({activity, source, color}) {
  const history = useHistory();
  const intl = useIntl();

  const getMessage = () => {
    if (activity.activity.messages === "Circle update activity") {
      return "MadeYouCircleManagerOfTheCircle";
    } else if (activity.activity.messages === "Circle Closed") {
      return "ActivityLeftCircleCard_Description";
    } else if (activity.activity.messages === "Accepted Circle Invitation") {
      return "AcceptedTheInvitationOfTheCircle";
    } else if (activity.activity.messages === "Circle Invitation Declined") {
      return "DeclinedTheInvitationOfTheCircle";
    } else if (activity.activity.messages === "Left the Circle") {
      return "LeftTheCircle";
    } else if (activity.activity.messages === "Removed from Circle") {
      return "Removed_From_Circle1";
    }
  };
  const navigateToCircleDetail = () => {
    history.push("/dashboard/circleDetails/?id=" + activity.activity.circleId, {
      selectedCircleId: activity.activity.circleId,
      selectedTab: 3,
      selectedPostID: activity.activity.activitySourceId,
    });
  };

  const placeholders = {circle_name: <Link
    color="inherit"
    component="button"
    underline="always"
    onClick={navigateToCircleDetail}
  >
    {activity.activity.circleName}
  </Link>};

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text color={color??"background"} id={getMessage()} values={placeholders}/>
    </ThemeProvider>
  );
}
