import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Link from "@material-ui/core/Link";
import { Chip } from "@material-ui/core";
import Styles from "../../../styles/component/ProfileTabs/InfoTabs/BioInfoStyle";
import { ThemeProvider } from "@mui/material";
import PropTypes from "prop-types";
import LanguageIcon from "@mui/icons-material/Language";
import { FormattedMessage } from "react-intl";
import { toTitleCase } from "../../../apiServices/CommonMethods";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

const useStyles = makeStyles(Styles);

export default function BioInfo(props) {
  const profile = props.profileData;
  const classes = useStyles();

  return (
    <Grid container item xs={12} rowGap={2}>
      <ThemeProvider theme={Theme_WIP}>
      {profile?.skills && <Text xs={12} variant="body1" color="white">
        <b><FormattedMessage id={"skills"} />:{" "}</b>
        {profile?.skills.map((skill) => (
            <Chip
              key={skill}
              label={
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textSecondary"}
                  className={classes.p15}
                  display="inline"
                >
                  {skill}
                </Typography>
              }
              variant="outlined"
              className={classes.chipItem}
              size="small"
            />
          ))
        }
      </Text>}
      {profile?.professionalLife &&
        <Text xs={12} variant="body1" color="white">
          <b><FormattedMessage id={"BioInfo_MeAsAProfessional"} />:{" "}</b>
          {profile.professionalLife.map((professionalLife) => (
                <Chip
                  key={professionalLife}
                  label={
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textSecondary"}
                      className={classes.p15}
                      display="inline"
                    >
                      {professionalLife}
                    </Typography>
                  }
                  variant="outlined"
                  className={classes.chipItem}
                  size="small"
                />
              ))
          }
        </Text>
      }
      {profile?.personalLife &&
        <Text xs={12} variant="body1" color="white">
          <b><FormattedMessage id={"meAsAPerson"} />:{" "}</b>
          {profile.personalLife.map((personalLife) => (
                <Chip
                  key={personalLife}
                  label={
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textSecondary"}
                      className={classes.p15}
                      display="inline"
                    >
                      {personalLife}
                    </Typography>
                  }
                  variant="outlined"
                  className={classes.chipItem}
                  size="small"
                />
              ))
          }
        </Text>
      }
      {profile.expertiseLevel && <Text xs={12} variant="body1" color="white">
        <b><FormattedMessage id={"Myexpertise"} />:{" "}</b>
        {toTitleCase(profile.expertiseLevel)}
      </Text>}
      {profile.languages != null && profile.languages.length ? (
        <Text xs={12} variant="body1" color="white">
          <b><FormattedMessage id={"languages"} />:{" "}</b>
          {profile.languages ? profile.languages.join(', ') : ""}
        </Text>
      ) : null}
      <Text xs={12} variant="body1" color="white">
        <b><FormattedMessage id={"whatIDoForClients"} />:{" "}</b>
        {profile.serivcesForClients}
      </Text>
      <Text xs={12} variant="body1" color="white">
        <b><FormattedMessage id={"whatIBringToMyNetwork"} />:{" "}</b>
        {profile.servicesForNetwork}
      </Text>
      {profile.visionIn5Years && profile.visionIn5Years != null ?
        <Text xs={12} variant="body1" color="white">
          <b><FormattedMessage id={"myVisionIn5Years"} />:{" "}</b>
          {" " + profile.visionIn5Years}
        </Text> : null}
      {profile.pastClients != null && profile.pastClients.length ? (
        <Text xs={12} variant="body1" color="white">
          <b><FormattedMessage id={"BioInfo_ClientsIWorkedFor"} />:{" "}</b>
          {profile.pastClients ? profile.pastClients.join(', ') : ""}
        </Text>
      ) : null}
      {profile.networks != null && profile.networks.length ? (
        <Text xs={12} variant="body1" color="white">
          <b><FormattedMessage id={"BioInfo_BusinessNetworksIAmMemberOf"} />:{" "}</b>
          {profile.networks ? profile.networks.join(', ') : ""}
        </Text>
      ) : null}

      {/* URL */}
      <Typography
        component="h3"
        variant="h3"
        color={"textPrimary"}
        className={classes.p15}
      >
        {profile.portfolio ? (
          <Typography component="h3" variant="h3" className={classes.iconGlobe}>
            <LanguageIcon style={{ fontSize: "16px" }} />{" "}
            <Typography component="h3" variant="h3" display="inline">
              <Link
                href={
                  profile.portfolio.startsWith("http")
                    ? profile.portfolio
                    : "https://" + profile.portfolio}
                variant="h3"
                underline="always"
                target={"_blank"}
                rel="noopener"
                className={classes.iconGlobelink}
              >
                <FormattedMessage id={"portfolio"} />
              </Link>
            </Typography>
          </Typography>
        ) : null}
      </Typography>
      </ThemeProvider>
    </Grid >
  );
}
BioInfo.prototype = {
  userId: PropTypes.string,
  profileData: PropTypes.object,
  error: PropTypes.bool,
  loading: PropTypes.bool,
};
