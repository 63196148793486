import { gql } from "@apollo/client";
import { useMutationBase } from "../mutations";

export function useSetSystemInfo(preregVidId) { 
    return useMutationBase(gql`
    mutation SetSystemInfo($preregVidId: String!) {
        setSystemInfo(preregVidId: $preregVidId)
    }`, 
    {noCallOnStart: true,
     variables: { 
        preregVidId: preregVidId},
     errorPolicy: "ignore"});
}