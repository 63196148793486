import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GetUserId } from "../apiServices/CommonMethods";
import { useListMastersBasics } from "../apiServices/Queries/Masters/ListMasters";

export default function AdminAutoSearch(props) {
    const [searchText, setSearchText] = React.useState("");
    const [value, setValue] = React.useState("");
    const [showLoggedInUser, setShowLoggedInUser] = React.useState(true);

    const userId = GetUserId();
    const vars = {
        userId: userId,
        searchInput: searchText,
        showLoggedInUser: showLoggedInUser
      };
    const {data: masters, loading: loading, updateVariables: updateVariables, hash: hash} = useListMastersBasics(vars);

    useEffect(() => {
        if (typeof props.serchTextCheck !== "undefined" && props.serchTextCheck === true) {
            setSearchText(null);
            setValue(null);
        }
        if (typeof props.showLoggedInUser !== "undefined") {
            setShowLoggedInUser(props.showLoggedInUser);
        }
    }, [props, masters]);

    const updateTextFileds = (event) => {
        // console.log("event", event.target.value)
        setSearchText(event.target.value.split(" ").join(''));
        if (event.target.value.length) {
            updateVariables({
                ...vars,
                searchInput: event.target.value,
            });
        }
        if (!event.target.value.length) {
            // console.log("event 2", event)
            setValue("");
            props.onSearch("");
            // if (typeof props.onUserSelection === "function") {
            //     console.log("event 3", event)
            //     props.onUserSelection("");
            //     setValue("");
            // }
        }
    };
    const onChange = (event, newvalue) => {
        setValue(newvalue);
        if (searchText.length) {
            const arryayMaster = masters.filter((master) => {
                return (master.givenName + " " + master.familyName + ", " + master.companyName) === newvalue;
            });
            props.onSearch(arryayMaster);
            if (typeof props.onUserSelection === "function") {
                props.onUserSelection(newvalue);
            }
        }
    };
    const onFocus = () => {
        props.onFocus();
    }
    return (
        <Autocomplete
            freeSolo
            value={value}
            size={"small"}
            id="free-solo-2-demo"
            onChange={onChange}
            filterOptions={(x) => x}
            disableClearable
            onFocus={onFocus}
            options={
                masters && masters.length &&
                    searchText !== null &&
                    searchText.length
                    ? masters.map(
                        (option, index) => option.givenName + " " + option.familyName + ", " + option.companyName
                    )
                    : []
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={updateTextFileds}
                    value={searchText}
                    placeholder={
                        typeof props.placeHolder !== "undefined"
                            ? props.placeHolder
                            : "Search"
                    }
                    InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : searchText !== null && !searchText.length ? (
                                    <SearchIcon />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
AdminAutoSearch.prototype = {
    onSearch: PropTypes.func,
};
