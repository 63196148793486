import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { gql } from "@apollo/client";

export function useHasExtendedTrial() {
    const {loading, error, data} = useQuery( gql`query get { hasExtendedTrial }` );
    const [result, setResult] = useState(undefined);
    useEffect(() => {
      if(!loading && data != undefined && data.hasExtendedTrial != undefined)
        setResult(data.hasExtendedTrial);
    }, [loading]);
    return [result, loading, (error != undefined)];
  }
  