import React from "react";
import { useHistory } from "react-router";
import { Button, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";
import MatchProfileCard from "../../Matchtime/MatchProfileCard";
import { getValueFromJsonKeyValue } from "../../../apiServices/CommonMethods";

// TODO matchtime styling restore
export default function YesCard({activity, source}) {
  const history = useHistory();

  if(source == "canCollapse")
    return undefined;
  if(source == "menu")
    return undefined;
  return (
      <ThemeProvider theme={Theme_WIP}>
          <Text variant="body1" color="primary" id="Activity.Yes.Description" values={{first_name: activity?.givenName}}/>
          <MatchProfileCard
            imgSrc                  = {activity?.profilePictureUrl ?? "/logo512.png"}
            userId                  = {activity?.userId}
            introduction            = {getValueFromJsonKeyValue(activity?.activity?.metaData, "introduction")  ?? ""}
            request                 = {getValueFromJsonKeyValue(activity?.activity?.metaData, "request")       ?? ""}
            aiGeneratedIntroduction = {getValueFromJsonKeyValue(activity?.activity?.metaData, "introductionGenerated")}
            aiGeneratedRequest      = {getValueFromJsonKeyValue(activity?.activity?.metaData, "requestGenerated")}
          />
          <Button variant="contained" onClick={() => history.push("/suggestOneToOne", {matchtime: true, selectedUser: {
              userId: activity?.userId,
              givenName: activity?.givenName,
              familyName: activity?.familyName
          } })}>
              <Text id="Activity.Yes.Meeting" color="primary" bold p={1}/>
          </Button>
          <Button variant="contained" onClick={() => history.push('/dashboard/profile/', { selectedUserId: activity?.userId })}>
              <Text id="Activity.Yes.Profile" color="primary" bold p={1} values={{first_name: activity?.givenName}}/>
          </Button>
      </ThemeProvider>
    );
}
