import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Link from "@material-ui/core/Link";
import { Chip } from "@material-ui/core";
import Styles from "../../../styles/component/ProfileTabs/InfoTabs/CompanyInfoStyle";
import PropTypes from "prop-types";
import LanguageIcon from "@mui/icons-material/Language";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

const useStyles = makeStyles(Styles);

export default function CompanyInfo(props) {
  const classes = useStyles();
  const [currentCompany, setCurrentCompany] = React.useState({
    name: "",
    companyRole: "",
    includedInCompanySince: 0,
    visionIn5Years: "",
    companyLevel: "",
    industry: [],
    workfield: "",
    keyValues: "",
    mission: "",
    website: "",
    numberOfEmployees: "",
    annualRevenue: "",
    certifications: "",
    incubatorOrAccelerator: "",
  });
  React.useEffect(() => {
    if (typeof props.companyData !== "undefined") {
      setCurrentCompany(props.companyData);
      // console.log(props.companyData);
    }
  }, [props.companyData]);

  // console.log("currentCompany", currentCompany);
  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container item xs={12} rowGap={2}>
      <Text variant="body1" color="white" xs={12}>
        <b><FormattedMessage id="Industry"/>:</b>
          {(currentCompany.industry ?? []).map((value, index) => (
            <Chip
              label={value}
              variant="outlined"
              className={classes.chipItem}
              size="small"
              key={index}
            />
          ))}
      </Text>

      <Text variant="body1" color="white" xs={12}>
        <b><FormattedMessage id={"Companyrole"} />:{" "}</b>
        {" " + currentCompany.companyRole}
      </Text>

      <Text variant="body1" color="white" xs={12}>
        <b><FormattedMessage id={"Typeofcompany"} />:{" "}</b>
        {" " + currentCompany.companyLevel}
      </Text>

      {currentCompany.includedInCompanySince !== null ? (
        <Text variant="body1" color="white" xs={12}>
          <b><FormattedMessage id={"WithTheCompanySince"} />:{" "}</b>
          {" " + currentCompany.includedInCompanySince}
        </Text>
      ) : null}

      {currentCompany.workfield !== null ? (
        <Text variant="body1" color="white" xs={12}>
          <b><FormattedMessage id={"Workfield"} />:{" "}</b>
          {" " + currentCompany.workfield}
        </Text>
      ) : null}

      <Text variant="body1" color="white" xs={12}>
        <b><FormattedMessage id={"KeyValues"} />:</b>{" "}
          {(typeof currentCompany !== "undefined" &&
            typeof currentCompany.keyValues !== "undefined" &&
            currentCompany.keyValues
            ? currentCompany.keyValues
            : []
          ).map((value, index) => (
            <Chip
              label={value}
              variant="outlined"
              className={classes.chipItem}
              size="small"
              key={index}
            />
          ))}
      </Text>

      <Text variant="body1" color="white" xs={12}>
        <b><FormattedMessage id={"Mission"} />:{" "}</b>
        {" " + currentCompany.mission}
      </Text>

      {currentCompany.website ? (
        <Typography component="h3" variant="h3" className={classes.iconGlobe}>
          <LanguageIcon style={{ fontSize: "16px" }} />{" "}
          <Typography component="h3" variant="h3" display="inline">
            <Link
              href={
                currentCompany.website.startsWith("http")
                  ? currentCompany.website
                  : "https://" + currentCompany.website}
              variant="h3"
              underline="always"
              target={"_blank"}
              rel="noopener"
              className={classes.iconGlobelink}
            >
              <FormattedMessage id={"Website"} />
            </Link>
          </Typography>
        </Typography>
      ) : null}

      {currentCompany.numberOfEmployees !== null ? (
        <Text variant="body1" color="white" xs={12}>
          <b><FormattedMessage id={"Employees"} />:{" "}</b>
          {" " + currentCompany.numberOfEmployees}
        </Text>
      ) : null}

      {currentCompany.annualRevenue !== null && currentCompany.annualRevenue !== "" ? (
        <Text variant="body1" color="white" xs={12}>
          <b><FormattedMessage id={"AnnualRevenue"} />:{" "}</b>
          {" €" + currentCompany.annualRevenue}
        </Text>
      ) : null}

      {currentCompany.certifications != null &&
        currentCompany.certifications.length ? (
        <Text variant="body1" color="white" xs={12}>
          <b><FormattedMessage id={"Certifications"} />:{" "}</b>
          {" " + currentCompany.certifications.join(', ')}
        </Text>
      ) : null}
      {currentCompany.incubatorOrAccelerator !== null && currentCompany.incubatorOrAccelerator !== "" ? (
        <Text variant="body1" color="white" xs={12}>
          <b><FormattedMessage id={"IncubatorOrAccelerator"} />:{" "}</b>
          {" " + currentCompany.incubatorOrAccelerator}
        </Text>
      ) : null}
    </Grid>
    </ThemeProvider>
  );
}
CompanyInfo.prototype = {
  companyData: PropTypes.object,
};
