import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Chip, FormHelperText, TextareaAutosize } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Styles from "../../../styles/component/ProfileTabs/InfoTabs/EditBioInfoStyle";
import { Alert, Autocomplete, Skeleton } from "@mui/material";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILE_BIO } from "../../../apiServices/mutations";
import PropTypes from "prop-types";
import { toTitleCase } from "../../../apiServices/CommonMethods";
import { ButtonDarkBlue } from "../../Buttons/ButtonDarkBlue";
import {
  Limits,
  masterDataExpertLevel,
  masterDataPersonalLife,
  masterDataprofessionalLife,
  masterDataSkills,
} from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import { clearSelfProfileCache, useGetProfile } from "../../../apiServices/Queries/Profiles/GetProfile";

const useStyles = makeStyles(Styles);

export default function EditBioInfo(props) {
  const classes = useStyles();

  let [input, setInput] = React.useState({
    skills: [],
    expertise: "",
    doForClients: "",
    bringToMyNetWork: "",
    myVision: "",
    languages: [],
    pastClients: [],
    portfolio: "",
    businessNetwork: [],
    professional: [],
    personal: [],
  });
  let [inputError, setInputError] = React.useState({
    skills: "",
    expertise: "",
    doForClients: "",
    bringToMyNetWork: "",
    professional: "",
    personal: "",
  });
  const [ProfileUpdate, { data, loading, error }] =
    useMutation(UPDATE_PROFILE_BIO, {onCompleted: (data) => {
      clearSelfProfileCache();
    }});
  const {data: getInfoData} = useGetProfile(props.userId, null);


  React.useEffect(() => {
    if (typeof data !== "undefined" && data.updateAboutMe.errorCode === 0) {
      setProfileObject(data.updateAboutMe.result);
      props.saveAction(data.updateAboutMe.result);
    }
    // if (
    //   typeof props.profileData !== "undefined" &&
    //   typeof props.profileData.skills !== "undefined"
    // ) {
    //   setProfileObject(props.profileData);
    // }
    if (getInfoData) {
      // console.log("getInfoData", getInfoData);
      // if (typeof props.profileData.skills === "undefined") {
      setProfileObject(getInfoData);
      // }
    }
  }, [data, props.profileData, error, getInfoData]);
  const setProfileObject = (profileObject) => {
    setInput((prevState) => ({
      ...prevState,
      professional: profileObject.professionalLife ?? [],
      personal: profileObject.personalLife ?? [],
      skills: profileObject.skills,
      expertise: profileObject.expertiseLevel
        ? toTitleCase(profileObject.expertiseLevel)
        : "",
      doForClients: profileObject.serivcesForClients ?? "",
      bringToMyNetWork: profileObject.servicesForNetwork ?? "",
      myVision: profileObject.visionIn5Years ?? profileObject.myVision ?? "",
      languages: profileObject.languages ?? [],
      pastClients: profileObject.pastClients ?? [],
      portfolio: profileObject.portfolio ?? "",
      businessNetwork: profileObject.networks ?? [],
    }));
  };

  const handleSave = async () => {
    let isError = await checkForErrors();
    let variable = {
      userId: props.userId,
      skills: input["skills"],
      expertiseLevel: input["expertise"],
      languages: input["languages"],
      serivcesForClients: input["doForClients"],
      servicesForNetwork: input["bringToMyNetWork"],
      visionIn5Years: input["myVision"],
      pastClients: input["pastClients"],
      networks: input["businessNetwork"],
      portfolio: input["portfolio"],
      professionalLife: input["professional"],
      personalLife: input["personal"],
    };
    if (isError) {
      ProfileUpdate({
        variables: variable,
      });
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "personal" || id === "professional") {
      const arayWords = value.split(" ");
      if (arayWords.length < 4) {
        updateTextFileds(id, value);
      }
    } else {
      updateTextFileds(id, value);
    }
  };

  const updateTextFileds = (id, value) => {
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const handleChangeAutoComplete = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      skills: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      skills: "",
    }));
  };
  const handleChangeAutoCompleteLanguages = (event, newvalue) => {
    // console.log("Languages newvalue", newvalue)
    setInput((prevState) => ({
      ...prevState,
      languages: newvalue,
    }));
  };

  const handleChangeAutoCompletePastClients = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      pastClients: newvalue,
    }));
  };
  const handleChangeAutoCompleteNetWorks = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      businessNetwork: newvalue,
    }));
  };
  const handleChangeAutoCompletePersonal = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      personal: newvalue,
    }));
  };
  const handleChangeAutoCompleteProfessional = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      professional: newvalue,
    }));
  };
  const handleChangeSelect = (event) => {
    setInput((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [event.target.name]: "",
    }));
  };

  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      skills: !input["skills"].length ? "skillError" : "",
      professional: !input["professional"].length ? "professionalError" : "",
      expertise: !input["expertise"] ? "expertiseError" : "",
    }));
    return (
      input["skills"].length &&
      input["professional"].length &&
      input["expertise"]);
  };

  // console.log("input", input);

  return (
    <Grid container spacing={4}>
      {error ? (
        <Grid item sm={12}>
          <Alert severity="error">
            {<FormattedMessage id={"somethingWentWrong"} />}
          </Alert>
        </Grid>
      ) : null}
      {typeof data !== "undefined" && data.updateAboutMe.errorCode === -1 ? (
        <Grid item sm={12}>
          <Alert severity="error">{data.updateAboutMe.message}</Alert>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"skills"} />
          *:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Field_Description"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="skills"
              autoSelect={true}
              options={masterDataSkills()}
              freeSolo
              filterSelectedOptions
              value={input["skills"]}
              onChange={handleChangeAutoComplete}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add skills"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, maxLength: 25 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                    e.target.value = e.target.value.split(" ").length > 2 ? e.target.value.trim() : e.target.value
                    e.target.value = e.target.value.substring(0, 25)
                  }}
                />
              )}
            />
          )}
          {inputError["skills"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterskills"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"Myexpertise"} />
          *:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          <InputLabel id="demo-simple-select-label">
            <FormattedMessage id={"InputLabel_Text_Select"} />
          </InputLabel>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Select
              labelId="demo-simple-select-label"
              name="expertise"
              onChange={handleChangeSelect}
              value={input["expertise"]}
            >
              {masterDataExpertLevel().map((option, index) => (
                <MenuItem value={toTitleCase(option.key)}>
                  {toTitleCase(option.key)}
                </MenuItem>
              ))}
            </Select>
          )}
          {inputError["expertise"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterexpertise"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"WhatIDoForThClients"} />
          :
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Description1"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextareaAutosize
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              id="doForClients"
              className={classes.txtarea}
              value={input["doForClients"]}
              onChange={handleChange}
              maxLength={Limits.text}
            />
          )}
          {inputError["doForClients"] ? (
            <Typography variant="h5" component="span" color="error">
              <FormattedMessage id={"PleaseEnterWhatIDoForTheClients"} />
            </Typography>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"whatIBringToMyNetwork"} />
          :
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Description2"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextareaAutosize
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              id="bringToMyNetWork"
              className={classes.txtarea}
              value={input["bringToMyNetWork"]}
              onChange={handleChange}
              maxLength={Limits.text}
            />
          )}
          {
            inputError["bringToMyNetWork"] ? (
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"PleaseEnterWhatIBringToMyNetwork"} />
              </Typography>
            ) : null
          }
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"EditBioInfo_MeAsAProfessional"} />*:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Field_Description"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="professional"
              freeSolo
              autoSelect={true}
              options={masterDataprofessionalLife()}
              value={input["professional"]}
              onChange={handleChangeAutoCompleteProfessional}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add languages"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, maxLength: 25 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                    e.target.value = e.target.value.split(" ").length > 2 ? e.target.value.trim() : e.target.value
                    e.target.value = e.target.value.substring(0, 25)
                  }}
                />
              )}
            />
          )}
          {inputError["professional"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterdetails"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"EditBioInfo_MeAsPerson"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Field_Description"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="personal"
              freeSolo
              autoSelect={true}
              options={masterDataPersonalLife()}
              value={input["personal"]}
              onChange={handleChangeAutoCompletePersonal}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add languages"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, maxLength: 25 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                    e.target.value = e.target.value.split(" ").length > 2 ? e.target.value.trim() : e.target.value
                    e.target.value = e.target.value.substring(0, 25)
                  }}
                />
              )}
            />
          )}
          {inputError["personal"] ? (
            <FormHelperText>
              {" "}
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={"Pleaseenterdetails"} />
              </Typography>{" "}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h2"
          variant="h2"
          color={"textPrimary"}
          className={classes.btxt}
        >
          <FormattedMessage id={"Optional"} />:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"myVisionIn5Years"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Description3"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextareaAutosize
              variant="filled"
              aria-label="minimum height"
              minRows={5}
              id="myVision"
              className={classes.txtarea}
              value={input["myVision"]}
              onChange={handleChange}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"languages"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Description6"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="languages"
              freeSolo
              options={[]}
              autoSelect={true}
              value={input["languages"]}
              onChange={handleChangeAutoCompleteLanguages}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add languages"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, maxLength: 25 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                    e.target.value = e.target.value.substring(0, 25)
                  }}
                />
              )}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"EditBioInfo_ClientsIWorkedFor"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Description6"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="pastClients"
              freeSolo
              options={[]}
              autoSelect={true}
              value={input["pastClients"]}
              onChange={handleChangeAutoCompletePastClients}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add clients"
                  variant="outlined"
                />
              )}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"BioInfo_BusinessNetworksIAmMemberOf"} />:
        </Typography>
        <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          <FormattedMessage id={"EditBioInfo_Description5"} />
        </Typography>
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <Autocomplete
              multiple
              id="businessNetwork"
              freeSolo
              options={[]}
              autoSelect={true}
              value={input["businessNetwork"]}
              onChange={handleChangeAutoCompleteNetWorks}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                    className={classes.chip}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  variant="outlined"
                />
              )}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          component="h3"
          variant="h2"
          color={"textPrimary"}
          className={classes.mt10}
        >
          <FormattedMessage id={"EditBioInfo_Portfolio"} />:
        </Typography>
        {/* <Typography
          component="h3"
          variant="h3"
          color={"textSecondary"}
          className={classes.p7}
        >
          Field description Field description Field description
        </Typography> */}
        <FormControl variant="filled" className={classes.Select}>
          {loading ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <TextField
              onChange={handleChange}
              id="portfolio"
              // label="Write here"
              variant="filled"
              size="small"
              value={input["portfolio"]}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonDarkBlue
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={handleSave}
        >
          <FormattedMessage id={"Button_Text_Save"} />
        </ButtonDarkBlue>
      </Grid>
    </Grid>
  );
}
EditBioInfo.prototype = {
  userId: PropTypes.string,
};
