import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { gql } from "@apollo/client";

export function useGetContactList() {
    const [called, setCalled] = useState(false);
    const [result, setResult] = useState();
    const [fetch, {loading, refetch}] = useLazyQuery( gql`query activities {
        findAllContacts {
          fullName
          pfp
          userId
          messageTime
          messageSummary
          pending
          received
        }
      }`, {
        onCompleted: (data) => setResult(data.findAllContacts)
      } );
    
    return {result: () => {
        if (!called) {
            setCalled(true);
            fetch();
            return []; 
        } else
            return result ?? [];
    }, loading: loading, refetch: refetch};
  }
  