import { gql } from "@apollo/client";
import { useMutationBase } from "../../mutations";

export function useUpdateProfileFields(updated, locale) { 
    return useMutationBase(gql`
    mutation UpdateProfileFields($updated: [UpdateProfileFieldsInput!]!, $locale: String) {
        updateProfileFields(inputs: $updated, locale: $locale)
    }`, 
    {variables: { 
        updated: updated, 
        locale: locale
    },
     errorPolicy: "ignore"});
}