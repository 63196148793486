import { tabStyle } from "../../container/CommonStyle"

const CircleFeedsTabStyle = (theme) => ({
  ...tabStyle,
  root: {
    pointerEvents: "none !important",
    width: "100%",
  },
  logosmall: {
    width: "100%",
    borderRadius: 6,
  },
  doticon: {
    float: "right",
    fontSize: 32,
    marginTop: 10,
    border: "2px solid #bdbdbd",
    borderRadius: 4,
    color: "#bdbdbd",
  },
  // tabcontent: {
  //   borderRadius: 4,
  //   width: "100%",
  //   fontWeight: "400",
  //   marginTop: 8,
  //   border: "1px solid #bdbdbd",
  //   padding: "10px !important",
  // },
  tabcontent: {
    margin: "8px 0 15px 0",
    padding: "10px !important",
  },
  p5: {
    marginTop: 5,
  },
  p10: {
    paddingTop: 10,
  },
  p15: {
    marginTop: 15,
  },
  grey: {
    color: theme.palette.color4.main,
  },
  mt10: {
    marginTop: 10,
  },
  doticonbottom: {
    float: "right",
    fontSize: 32,
    color: "#bdbdbd",
  },
  msgicon: {
    fontSize: 20,
    color: theme.palette.secondary.main,
    textAlign: "right",
    marginLeft: 5,
  },

  right: {
    float: "right",
  },
  chip: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  chipItem: {
    borderRadius: 5,
  },
  posted: {
    position: "relative",
    left: -5,
    top: 2,
  },
  iconSearch: {
    position: "relative",
    right: 50,
    top: 15,
    color: theme.palette.color5.main,
  },
  txtarea: {
    width: "100%",
    border: "none",
    backgroundColor: theme.palette.color3.main,
    borderRadius: 6,
    padding: "2%",
  },
  Select: {
    width: "100%",
  },
  mt7: {
    marginTop: 7,
  },
  closeicon: {
    float: "right",
    fontSize: 32,
    color: theme.palette.color5.main,
  },
  alignLeft: {
    justifyContent: "left",
  },
  iconFilter: {
    position: "relative",
    top: 7,
    left: 7,
    color: theme.palette.color5.main,
  },
  iconReset: {
    marginTop: "13px",
    padding: "0 !important",
    color: theme.palette.color5.main,
  },
});
export default CircleFeedsTabStyle;
