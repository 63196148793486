import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  CssBaseline,
} from "@material-ui/core";
import styles from "../../styles/container/CirclesPageStyle";
import { Stack } from "@mui/material";
import CircleCard from "../../components/Cards/CircleCard";
import {
  CirclesTabItems,
  CompaniesTabItems,
  masterDataCircleOperatesIn,
  masterDataCircleTags,
} from "../../apiServices/Constants";
import { useDispatch } from "react-redux";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import { START_CIRCLE } from "../../Config";
import { FormattedMessage, useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";
import { userRoles } from "../../apiServices/Constants";
import { useHasCompanyAccess } from "../../apiServices/Queries/Profiles/MetaData";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useListAllCircles } from "../../apiServices/Queries/Circles/GetUserCircles";
import { onlyUnique } from "../../apiServices/CommonMethods";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Filters from "../../components/Filters";
import { MarkerEntitySources } from "../../components/General";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles   = makeStyles(styles);
const maxPageSize = 50;

export default function CirclesPage(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const companies = props.companies;
  const tabItems = companies ? CompaniesTabItems : CirclesTabItems;
  const dispatch = useDispatch();
  const companyAccess = useHasCompanyAccess();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const savedFilter = JSON.parse(localStorage.getItem("filters_"+location.key));
  useEffect(() => globals.functions.setMarker({
    lastPageVisitedUponCreation: MarkerEntitySources.CIRCLES_PAGE, lastEntityVisitedUponCreationId: undefined, flags: []
  }), []);

  const theme = useTheme();
  const [allCircles, setAllCircles] = React.useState([]);
  const [circleTabType, setCircleTabType] = React.useState(tabItems.find(i => i.type == savedFilter?.tab) ?? (companies && companyAccess.myCompaniesOnly ? tabItems[1] : tabItems[0]));

  const [input, setInput] = React.useState({
    selectedPlace: savedFilter?.value?.selectedPlace,
    maxDistance: savedFilter?.value?.maxDistance,
    selectedCircleTags: savedFilter?.value?.selectedCircleTags,
    selectedOperatesIn: savedFilter?.value?.selectedOperatesIn,
    pageNumber: 1,
    searchInput: savedFilter?.value?.searchInput,
  });

  const getCircleTags = () => {
    const arraySelectedTags = input["selectedCircleTags"];
    const array = masterDataCircleTags()
      .filter((item) => {
        return arraySelectedTags.includes(item.key);
      })
      .map((item) => {
        return item.value.toString();
      });
    return array.length ? array : null;
  };
  const getOperatesIn = () => {
    const arraySelectedOperates = input["operatesIn"];
    const array = masterDataCircleOperatesIn()
      .filter((item) => {
        return arraySelectedOperates.includes(item.key);
      })
      .map((item) => {
        return item.value.toString();
      });
    return array.length ? array.join(",") : null;
  };
  const getVariable = () => ({
      selectedPlace: input["selectedPlace"],
      maxDistance: input["maxDistance"],
      selectedCircleTags: input["selectedCircleTags"] ? getCircleTags() : null,
      selectedOperatesIn: input["operatesIn"] ? getOperatesIn() : null,
      loggedInUser: userId,
      isPublished: circleTabType.type === "All" ? 1 : null,
      memberId: circleTabType.type === "All" ? null : userId,
      pageSize: maxPageSize,
      pageNumber: input["pageNumber"],
      searchInput: input.searchInput ?? "",
      isCompany: companies
    });

  const {data: data, loading: loading, updateVariables: refetch} = useListAllCircles(getVariable(), 60, true);
  useEffect(() => {
    if(!data || (!data.length && input["pageNumber"] != 1))
      return;
    if (input["pageNumber"] === 1)
      setAllCircles(data);
    else
      setAllCircles((prevState) => [
        ...prevState,
        ...data,
      ]);
  }, [data]);

  useEffect(() => { //deduplication redundancy
    if(loading || !allCircles)
      return;
    let flattened = allCircles.filter(onlyUnique);
    if(flattened.length != allCircles.length)
      setAllCircles(flattened);
  }, [allCircles]);

  useEffect(() => {
    if(!companyAccess.loading && !companyAccess.accessToCompanies && companies) {
      history.push("/");
    }
    refetch(getVariable());
  }, [circleTabType, input]);

  const handleChange = (event, newValue) => {
    let filteredTabItems = tabItems.filter((tabItem) => {
      return tabItem.type === newValue;
    });
    setCircleTabType(filteredTabItems[0]);
  };

  const onSearch = (input) =>
    setInput({
      ...input,
      pageNumber: 1
    });

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container gap={ThemeConstants.custom.spacings.lg} mt={ThemeConstants.custom.spacings.lg}>
      <Filters search includeCircleFilters includeOperatesIn includeLocation onSearch={onSearch} useRefresh
        addDisplayText="circlePage_Button_Text_StartACircle" onAdd={
          userRole === userRoles.admin ?
            (() => history.push("/addNewCircle"))
          :
          !companies && userRole != userRoles.admin && (() => window.open(START_CIRCLE, "_blank"))}
        tab={circleTabType.type} tabs={tabItems} onTab={handleChange}/>

      <Grid item xs={12} container gap={ThemeConstants.custom.spacings.md} className={classes.mt5}>
        {loading && allCircles.length ? (
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              flex: "row",
              justifyContent: "center",
              width: "100%",
              height: 300,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <CircularProgress />
              <Typography
                component="h5"
                variant="h5"
                className={classes.title}
              ></Typography>
            </Stack>
          </Box>
        ) : allCircles.length ? (
          allCircles.map((item, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                xs={12}
                sm={12}
                key={index}
                onClick={() => {
                  // history.push("/dashboard/circleDetails", {
                  //   circleDetails: item,
                  // });
                  history.push("/dashboard/circleDetails/?id=" + item.id);

                  dispatch(
                    updateBrandColor(
                      item.brandColor ?? theme.palette.primary.main
                    )
                  );
                }}
              >
                <CircleCard circleData={item} />
              </Grid>
              {index == allCircles.length - 2 && allCircles.length >= maxPageSize && (
                <Waypoint
                  scrollableAncestor={window}
                  onEnter={() => {
                    // console.log("index", index);
                    const newPage = input["pageNumber"] + 1;
                    setInput((prevState) => ({
                      ...prevState,
                      pageNumber: newPage,
                    }));
                  }}
                />
              )}
            </React.Fragment>
          ))
        ) : (
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              flex: "row",
              justifyContent: "center",
              width: "100%",
              height: 300,
            }}
          >
            <Typography
              component="span"
              variant="h3"
              color={"textPrimary"}
            >
              <FormattedMessage id={"noDataAvailable"} />
            </Typography>
          </Box>
        )}
      </Grid>
      </Grid>
    </React.Fragment>
  );
}
