import React from "react";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";


export default function ActivityCirclePost({isLess, activity, source, color}) {
  const history = useHistory();

  const navigateToCircleDetail = () => {
    history.push("/dashboard/circleDetails/?id=" + activity.activity.circleId, {
      selectedCircleId: activity.activity.circleId,
      selectedTab: 3,
      selectedPostID: activity.activity.activitySourceId,
    });
  };

  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  if(source == "menu")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text item xs={12} color={color??"background"} >
        {isLess && (activity?.activity?.messages?.length ?? 0) > 150 ? (
            activity.activity.messages.substring(0, 150) 
        ) : activity.activity.messages
        }
      </Text>
      {(!isLess || (activity.activity && activity.activity.messages.length <= 150)) && activity.activity.status !== ActivityStatus.Closed &&
        <Text
          color   = "background"
          onClick = {navigateToCircleDetail}
          id      = "GoToTheCircle" />
      }
    </ThemeProvider>
  );
}
