

const CircleCardStyle = (theme) => ({
    logo: {
        width: "100%",
        minHeight: "100%",
        borderRadius: "5px 5px 5px 0px",
        aspectRatio: 1
    },
    tabcontent: {
        width: "100%",
        margin: "8px 0 8px 0",
        cursor: "pointer",
    },
    title: {
        fontSize: "1.3rem"
    },
    p7: {
        marginTop: 7,
        wordBreak: "break-all"
    },
    grey: {
        color: theme.palette.color5.main,
    },
    buttonblue: {
        backgroundColor: "#a3ccfb",
        borderRadius: 4,
        padding: "4px 20px 2px 20px",
        color: "#828282",
    },
    chip: {
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5,
        fontWeight: 700,
        backgroundColor: theme.palette.color4.main,
        borderColor: theme.palette.color4.main,
    },
    p15: {
        marginTop: 15,
        color: "Black"
    },
});
export default CircleCardStyle;
