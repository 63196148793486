import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import { useIntl } from "react-intl";
import { useGetActivityUsers } from "../apiServices/Queries/Activities/ActivityUsers";

export default function ActivityUserSearch(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;

  const [searchText, setSearchText] = React.useState("");
  const [userToRemove, setUserToRemove] = React.useState("");

  const intl = useIntl();
  const { loading, data, updateVariables } = useGetActivityUsers({
    userId: userId,
    searchString: searchText,
  });
  const users = data?.length ? [...data].sort ((a,b)  => a?.givenName && b?.givenName ? a.givenName.localeCompare(b.givenName) : -1) : [];

  useEffect(() => {

    if (typeof props.userToRemove !== "undefined") {
      setUserToRemove(props.userToRemove);
    }
  }, [props, data]);

  const updateTextFileds = (event) => {
    setSearchText(event.target.value);
    updateVariables({
      userId: userId,
      searchString: event.target.value,
    });
    if (typeof props.onSearchTextChanges === "function") {
      props.onSearchTextChanges(event.target.value);
    }
  };
  const onChange = (event, newvalue) => {
    const arryayMaster = users.filter((master) => {
      return master.givenName + " " + master.familyName === newvalue;
    });
    if(props.onSearch) //legacy
      props.onSearch(arryayMaster[0]);
    if(props.onChange) //proper
      props.onChange(arryayMaster[0]);
  };
  const getupdatedList = () => {
    const arrayFiltered = users.filter((item) => {
      return item.userId !== userToRemove;
    });
    return arrayFiltered;
  };
  return (
    <Autocomplete
      {...props.inputProps}
      fullWidth
      sx={props.sx}
      noOptionsText={searchText && intl.formatMessage({ id: props.inputProps?.noOptionsText ?? "notFoundContact2" })}
      onChange={onChange}
      filterOptions={(x) => x}
      disableClearable
      options={users &&
          searchText.length && getupdatedList().length
          ? getupdatedList().map(
            (option, index) => option.givenName + " " + option.familyName
          )
          : (searchText.length ? [searchText + "##" + intl.formatMessage({ id: props.inputProps?.noOptionsText ?? "notFoundContact2" })] : [])
      }
      renderOption={(p, o, s) => 
          o.includes("##") ? <li className={p.className + " MuiAutocomplete-noOptions"} style={{pointerEvents: "none"}}>{o.replace(searchText + "##", "")}</li> 
                           : <li {...p}>{o}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={updateTextFileds}
          placeholder={
            props.placeHolder ?? props.placeholder ?? "Typen"
          }
          // InputLabelProps={{
          //   style: {
          //     fontSize: 14,
          //     fontFamily: 'Montserrat', //h2
          //   }
          // }}
          value={searchText}
          InputProps={{
            ...params.InputProps,
            // type: 'search',
            // style: {
            //   fontSize: 14,
            //   fontFamily: 'Montserrat', //h2
            // },
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
ActivityUserSearch.prototype = {
  onSearch: PropTypes.func,
};
