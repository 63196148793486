import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Styles from "../../../styles/component/Cards/RequestCardStyle";
import Text from "../../../visualComponents/Text";
import { Chip, Collapse, Grid, ThemeProvider } from "@mui/material";
import Theme_WIP, { ThemeConstants } from "../../../styles/themes/main/Theme_WIP";
import DescriptionWithSkills from "../../Cards/Reusable/DescriptionWithSkills";

const useStyles = makeStyles(Styles);

export default function MastermindCardDetails(props) {
  const classes = useStyles();
  const [requestDetails, setRequestDetails] = React.useState({});

  useEffect(() => {
    if (typeof props.request !== "undefined") {
      setRequestDetails(props.request);
    }
  }, [props]);

  function Chips (props) {return (props.values.map((value, index) => (
    <Chip
      sx={{mr: ThemeConstants.custom.spacings.sm}}
      color="background"
      label={<Text variant="body2" color="background">{value}</Text>}
      size="small"
    />
  )))};

  return (
    <ThemeProvider theme={Theme_WIP}>
    {!props.editing ? <>
      {requestDetails.mastermindDescription && <Typography variant="h3" className={classes.p15} display="inline">
        {requestDetails.mastermindDescription}
      </Typography>}

      {requestDetails.request?.description && 
        <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.xs} sx={{overflowWrap: "anywhere"}}>
          <Grid container item xs={12}>
            <Text bold id="LookingFor" color="background" mr={2}/>
            <Chips values={requestDetails.request.skills}/>
          </Grid>
          {props.collapsed && requestDetails.request?.description &&  <Text item xs={12} color="background">{requestDetails.request.description.substring(0, 75) + "..."}</Text>}
          <Collapse in={!props.collapsed}>
            <Text item xs={12} color="background">{requestDetails.request?.description}</Text>
          </Collapse>
        </Grid>}
      {requestDetails.offer?.description &&
        <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.xs} sx={{overflowWrap: "anywhere"}}>
          <Grid container item xs={12}>
            <Text bold id="Brings" color="background" mr={2}/>
            <Chips values={requestDetails.offer.skills}/>
          </Grid>
          {props.collapsed && requestDetails.offer?.description &&  <Text item xs={12} color="background">{requestDetails.offer.description.substring(0, 75) + "..."}</Text>}
          <Collapse in={!props.collapsed}>
            <Text item xs={12} color="background">{requestDetails.offer?.description}</Text>
          </Collapse>
        </Grid>}
    </>
    :
    <Grid container item xs={12} gap={2}>
      <DescriptionWithSkills label="CollaborationDescription"  onChange={(des, skills) => props.onChange("request", {description: des, skills: skills})} description={requestDetails.request?.description} skills={requestDetails.request?.skills}/>
      <DescriptionWithSkills label="CollaborationDescription2" onChange={(des, skills) => props.onChange("offer"  , {description: des, skills: skills})} description={requestDetails.offer  ?.description} skills={requestDetails.offer  ?.skills}/>
    </Grid>
    }
    </ThemeProvider>
  );
}
