//TODO: admin only path
import { Button, Grid, MenuItem, Select, Skeleton, TextField, TextareaAutosize } from "@mui/material";
import Tile from "../../../visualComponents/Tile";
import Text from "../../../visualComponents/Text";
import { useEffect, useState } from "react";
import { useUpdateBlueprints } from "../../../apiServices/Mutations/Tasks";

const taskify = (blueprint) => ({...blueprint, identity: blueprint.id});

function Task(props) {
    const [task,    setTask   ] = useState();
    const [edited,  setEdited ] = useState(false);
    const [deleted, setDeleted] = useState(false);
    if(task == null && props.data != null) {
        setTask(props.data.id ? taskify(props.data) : props.data);
    }

    useEffect(() => {
      if(task != null && JSON.stringify(task) != JSON.stringify({...props.data, identity: task.identity}) && !edited) {
        setEdited(true);
      }
    }, [task])

    const update_ = (updatedTask) => {
        setTask((prevTask) => {
            let updated = { ...prevTask, ...updatedTask};
            props.update(updated);
            return(updated);
        });
    };

    const delete_ = () => {
        setDeleted  (true);
        props.delete(task);
    };

    const color = () => {
        if      (deleted        ) return "rgba(255,0,0,0.1)";
        else if (task.id == null) return "rgba(0,255,0,0.1)";
        else if (edited         ) return "rgba(0,0,0,0.1)";
        else                      return "transparent";
    }

    return(
        task == null ?
        <Grid container item xs={12}>
            <Skeleton width="100%" height="40px"/>
        </Grid>
        :
        <Grid container item xs={12} p={2} gap={2} bgcolor={color()}>
            <Button
            color="error" variant={deleted ? "outlined" : "contained"} disabled={deleted}
            onClick={delete_}>Delete</Button>
            <Select
            disabled     = {deleted}
            onChange     = {(value) => update_({triggerSource: value.target.value})}
            defaultValue = {task.triggerSource}>
                <MenuItem value={"ACCOUNT_REGISTRATION"}>Account registration</MenuItem>
                <MenuItem value={"PARTICIPATE_IN_EVENT"}>Participate in event</MenuItem>
                <MenuItem value={"MEETING_TAKES_PLACE" }>Meeting takes place</MenuItem>
            </Select>
            <TextField
            disabled    = {deleted}
            onChange    = {(value) => update_({daysAfterTrigger: parseInt(value.target.value)})}
            placeholder = {task.daysAfterTrigger}
            type        = "number"/>
            <TextareaAutosize 
            disabled    = {deleted}
            placeholder = {task.payload}
            onChange    = {(value) => update_({payload: value.target.value})}/>
        </Grid>
    );
}

export default function TaskTab() {
    const [mutateCount,       setCount]   = useState(1);
    const [mutateCount_,      setCount_]  = useState(0);
    const [updatedBlueprints, setUpdated] = useState([]);
    const [deletedBlueprints, setDeleted] = useState([]);
    const [tasks,             setTasks]   = useState([]);
    const {data, loading, error, mutate}  = useUpdateBlueprints(updatedBlueprints, deletedBlueprints);

    useEffect(() => {
    }, [tasks])
    

    function addTask() {
        setTasks(tasks.concat(
            [{identity: (Math.random() + 1).toString(36).substring(7), triggerSource: "ACCOUNT_REGISTRATION", id: null}]
        ));
    }

    function submit() {
        setTasks  ([]);
        mutate    (  );
        setUpdated([]);
        setDeleted([]);
        setCount  (mutateCount + 1);
    }

    function deleteTask(task) {
        setUpdated(updatedBlueprints.filter((t) => t.identity != task.identity));
        if(task.id == undefined) {
            setTasks  (tasks            .filter((t) => t.identity != task.identity));
        } else
            setDeleted(deletedBlueprints.concat([task]));
    }
    function updateTask(task) {
        if(updatedBlueprints.find(bp => bp.identity == task.identity))
            setUpdated(updatedBlueprints.map(bp => bp.identity == task.identity ? task : bp ))
        else
            setUpdated(updatedBlueprints.concat([task]));
    }

    function displayTasks() {
        if(error)
            return <Text variant="body" color="red">{error}</Text>
        if(data != null && !loading && data['updateTaskBlueprints'] == null)
            return <Text variant="body" color="red">Something went wrong</Text>

        if(mutateCount != mutateCount_)
            setTimeout(() => setCount_(mutateCount), 10); //updating data is not frame perfect, therefore a tiny delay is needed
        if(!tasks.length && !loading && data != null && mutateCount == mutateCount_)
            setTasks(data.updateTaskBlueprints.map(bp => taskify(bp)));
        return !data? <Task/> : tasks.map(task => 
        <Task 
        data={task} 
        delete={deleteTask}
        update={updateTask}/>);
    }

    return(
        <Grid container p={5}>
        <Tile id="myTile">
        <Grid container item xs={12} gap={6}>
            <Grid item xs={12}>
                <Text item xs={12} variant="h1" bold>Task Blueprints</Text>
                <Text item xs={12} variant="body2">
                    Selecteer per regel in de dropdown welk iam bericht je wilt sturen.
                    Per gebruiker wordt een Scheduled Task aangemaakt als de trigger wordt aangeroepen.
                    De task met de laagste interval wordt als eerste gepland.
                    Als de verstuurdatum in de praktijk een datum in het verleden is wordt dit bericht niet verstuurd.
                    Na het uitvoeren van een task, wordt de volgende task ingepland.
                    De interval wordt gedefinieerd door het aantal dagen dat is ingevuld.
                    <br/><br/>
                    - Het getal staat voor het aantal wachtdagen.<br/>
                    - 0 betekent dat het bericht direct na het vorige bericht wordt verstuurd. Als er geen kleiner getal is dan 0 wordt het bericht meteen na de trigger verstuurd.<br/>
                    - Op dit moment kan er nog niet 2 keer hetzelfde aantal wachtdagen worden gebruikt.<br/>
                    - De intervallen moeten op dit moment minimaal 1 dag meer zijn dan andere intervallen.<br/>
                    - De intervallen worden op chronologische volgorde verstuurd, dus bijv. 1, 2, 3, 4, Niet 1, 3, 2, 4.<br/>
                    - Als de tekst in het bericht wordt aangepast, geldt dat alleen voor nieuwe berichten.<br/>
                    <br/><br/>
                    <b>Triggers:</b><br/>
                    Account registration: first time dashboard is opened.<br/>
                    Meeting takes place: meeting is accepted.<br/>
                    Event: event invite accepted<br/><br/>

                    <b>Schedule tasks are stopped:</b><br/>
                    If event is canceled.<br/>
                    A user leaves or get removed from an event.<br/>
                    If meeting is canceled.<br/><br/>

                    <b>Placeholders:</b><br/>
                    <b>{"{name}"       }</b> - Meeting takes places = full names of recipient and sender.<br/>
                    <b>{"{location}"   }</b> - Meeting takes places = Location of meeting.<br/>
                    <b>{"{location}"   }</b> - Participate in event = Location of event.<br/>
                    <b>{"{date}"       }</b> - Meeting takes places = Date of meeting.<br/>
                    <b>{"{date}"       }</b> - Participate in event = Date of event.<br/>
                    <b>{"{name_as_url}"}</b> - Participate in event = Name of event as url<br/>
                    <b>{"{url}"        }</b> - Currenlty not used.<br/>
                    <b>{"{other}"      }</b> - Currently not used.<br/>
                    </Text>
            </Grid>
            <Grid item container xs={12} gap={2}>
                {displayTasks()}


                {loading || error ? null : <Grid item xs={12}>
                    <Button color="lightPetrol" variant="contained" onClick={addTask}>Add</Button>
                </Grid>}
            </Grid>
            {loading || error || !tasks.length ? null : <Grid item xs={12}>
                <Button color="primary" variant="contained" onClick={submit}>Save</Button>
            </Grid>}
            {!new URLSearchParams(window.location.search).get("debug") ? null : 
            <Grid container item xs={12}>
            <Text overflow="scroll" item xs={6}   variant="body2">{JSON.stringify(deletedBlueprints)}</Text>
            <Text overflow="scroll" item xs={6} variant="body2">{JSON.stringify(updatedBlueprints)}</Text></Grid>}
        </Grid>
        </Tile>
        </Grid>
    );
}