import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Button, CssBaseline } from "@mui/material";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";
import Tile from "../../visualComponents/Tile";
import Message from "../../visualComponents/specificImplementations/Messages/Message";
import ChatBubble from "../../visualComponents/specificImplementations/ChatBubble";
import Banner from "../../visualComponents/specificImplementations/Banners/Banner";

export default function ComponentExpo(props) {

    const chatBubbleData = (
        <div>
            <Text color="primary" variant="h1" bold>Testing <b>ChatBubble.jsx</b></Text>
            <Text variant="h2">Testing <b>ChatBubble.jsx</b></Text>
            <Text color="darkPetrol">Testing <b>ChatBubble.jsx</b></Text>
            <Text color="background" variant="body2">Testing <b>ChatBubble.jsx</b></Text>
        </div>
    );

    const tileData = (
        <div>
            <Text variant="h1" bold>Testing <b>Tile.jsx</b></Text>
            <Text variant="h2">Testing <b>Tile.jsx</b></Text>
            <Text>Testing <b>Tile.jsx</b></Text>
            <Text variant="body2">Testing <b>Tile.jsx</b></Text>
        </div>
    );

    const iamMessageData = (
        <div>
            <Text color="darkPetrol" variant="h1" bold>Testing <b>Message.jsx</b></Text>
            <Text color="primary" variant="body">Testing <b>Message.jsx</b></Text>
        </div>
    );
    const messageData = (
        <div>
            <Text color="background" variant="h1" bold>Testing <b>Message.jsx</b></Text>
            <Text color="background" variant="body">Testing <b>Message.jsx</b></Text>
        </div>
    );

    const expoTiles = () => (
        <Background color="darkPetrol" z="-100 !important" container p={12}>
            <Grid item xs={12} mb={4}>
                <Text color="darkPetrol" variant="h1" bold>Components in container: <b>Tile.jsx</b></Text>
                <Text color="darkPetrol" variant="body2">Container settings: p=12</Text>
                <Text color="darkPetrol" variant="body2">Container settings for components: xs=12 md=6</Text>
            </Grid>

            <Tile item xs={12} md={6}>
                {tileData}
            </Tile>
            <Tile item xs={12} md={6}>
                {tileData}
            </Tile>
            <Tile item xs={12} md={6}>
                {tileData}
            </Tile>
            <Tile item xs={12} md={6}>
                {tileData}
            </Tile>
        </Background>
    );

    const expoMessages = () => (
        <Background color="background" z="-100 !important" container p={12}>
            <Grid item xs={12} mb={4}>
                <Text color="background" variant="h1" bold>Components in container: <b>IamMessage.jsx & Tile.jsx</b></Text>
                <Text color="background" variant="body2">Container settings: p=12</Text>
                <Text color="background" variant="body2">Container settings for components: xs=12</Text>
            </Grid>

            <Message item xs={12} iam>
                {iamMessageData}
            </Message>
            <Message item xs={12} outgoing name="My Long Name Here" message="Match Request" date="2023 xxxx">
                {messageData}
            </Message>
            <Message item xs={12} iam>
                {iamMessageData}
            </Message>
            <Message item xs={12}  name="My Long Name Here" message="Match Request" date="2023 xxxx">
                {messageData}
            </Message>
            <Message item xs={12} name="My Long Name Here" message="Bericht">
                {messageData}
            </Message>
            <Message item xs={12} name="My Long Name Here" date="2023 xxxx" outgoing>
                {messageData}
            </Message>
            <Message item xs={12} name="My Long Name Here" date="2023 xxxx">
                {messageData}
            </Message>
        </Background>
    );

    const expoChatBubble = () => (
        <Background color="darkPetrol" z="-100 !important" container p={12}>
            <Grid item xs={12}>
                <Text color="darkPetrol" variant="h1" bold>Components in container: <b>ChatBubble.jsx</b></Text>
                <Text color="darkPetrol" variant="body2">Container settings: p=12</Text>
                <Text color="darkPetrol" variant="body2">Container settings for components: xs=12</Text>
            </Grid>

            <ChatBubble iam item xs={12}>
                {chatBubbleData}
            </ChatBubble>
            <ChatBubble item xs={12}>
                {chatBubbleData}
            </ChatBubble>
            <ChatBubble iam item xs={12}>
                {chatBubbleData}
            </ChatBubble>
            <ChatBubble item xs={12} name="My Long Name Here">
                {chatBubbleData}
            </ChatBubble>
        </Background>
    );

  return (
    <React.Fragment>
    <CssBaseline>
    <ThemeProvider theme={Theme_WIP}>
        <Background page/>
        <Text item xs={12} p={2} color="white" variant="body2">Every variable content is just text displaying "Testing Testing". However this text can be any content in any desired layout</Text>
        <Banner circle color="white" p={5}
        name          = "Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx"
        subtitle      = "Banner.jsx Banner.jsx Banner.jsx Banner.jsx"
        description   = "Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx"
        otherInfo     = {["Oprichter @ VoorLocatie", "Rotterdam, Nederland"]}
        otherInfo2    = {["74 matches", "325 thank you's"]}
        metaData      = {[{key: "Locatie", value: "Rotterdam"}, {key: "Leden", value: 249}]}
        tags          = {["Netwerken", "Party", "Tech"]}
        action        = {{name: "Contact", onClick: (() => alert("You clicked the button!"))}}
        onMore        = {() => alert("More actions")}
        customButtons = {null}
        tabs          = {[{name: "tab1", onClick: (() => alert("You clicked tab1!"))}, {name: "tab2", onClick: (() => alert("You clicked tab2!"))}]}
        />
        {expoTiles()}
        <Banner event color="background" p={5}
        name          = "Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx"
        subtitle      = "Banner.jsx Banner.jsx Banner.jsx Banner.jsx"
        description   = "Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx"
        otherInfo     = {["Oprichter @ VoorLocatie", "Rotterdam, Nederland"]}
        otherInfo2    = {["74 matches", "325 thank you's"]}
        metaData      = {[{key: "Locatie", value: "Rotterdam"}, {key: "Leden", value: 249}]}
        tags          = {["Netwerken", "Party", "Tech"]}
        action        = {{name: "Contact", onClick: (() => alert("You clicked the button!"))}}
        onMore        = {() => alert("More actions")}
        customButtons = {null}
        tabs          = {[{name: "tab1", onClick: (() => alert("You clicked tab1!"))}, {name: "tab2", onClick: (() => alert("You clicked tab2!"))}]}
        />
        {expoMessages()}
        <Banner color="darkPetrol" p={5}
        name          = "Banner.jsx Banner.jsx Banner.jsx Banner.jsx"
        description   = "Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx Banner.jsx"
        otherInfo     = {["Oprichter @ VoorLocatie", "Rotterdam, Nederland"]}
        otherInfo2    = {["74 matches", "325 thank you's"]}
        action        = {{name: "Match Ontdekken", onClick: (() => alert("You clicked the button!"))}}
        onMore        = {() => alert("More actions")}
        customButtons = {null}
        tabs          = {[{name: "tab1", onClick: (() => alert("You clicked tab1!"))}, {name: "tab2", onClick: (() => alert("You clicked tab2!"))}]}
        />
        {expoChatBubble()}
    </ThemeProvider>
    </CssBaseline>
    </React.Fragment>
  );
}