import { useCachedQuery } from "../CachedQuery";
import { GET_ALL_MASTERS_BASIC, GET_MASTERS } from "../../Queries";

export function useListMasters(variables) {
    return useCachedQuery(
        "allMastersExtn",
        GET_MASTERS,
        variables,
        (data) => data?.allMastersExtn?.result?.profiles,
        60,
        true
    );
  }
  
export function useListMastersBasics(variables) {
    return useCachedQuery(
        "allMastersBasic",
        GET_ALL_MASTERS_BASIC,
        variables,
        (data) => data?.allMastersBasicDetails?.result, 
        60,
        true
    );
  }
  