import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../Navigationbar";
import Header from "../Header";
import styles from "../../styles/container/AccountSettingStyle";
import { Redirect } from "react-router-dom";


const useStyles = makeStyles(styles);

export default function MolliePaymentStatus(props) {
    return (
        <React.Fragment>
            <CssBaseline />
            <Navigationbar />
            <Container maxWidth="sm">
                <Redirect to={{
                    pathname: '/dashboard/settings',
                    state: { paymentStatus: true }
                }}
                />
                {/* <Box sx={{ flexGrow: 1, mt: theme.spacing(6), mb: theme.spacing(4) }}>
                    <Grid container spacing={4}>
                        <Grid item sm={11} xs={10}>
                            <Typography component="h1" variant="h1" color={"textPrimary"}>
                                <FormattedMessage id={"ChooseActivity"} />
                            </Typography>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            <CloseIcon
                                className={classes.closeicon}
                                onClick={() => history.push("/dashboard/masters")}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Typography
                                component="h3"
                                variant="h3"
                                color={"textPrimary"}
                                className={classes.mt10}
                            >
                                <FormattedMessage id={"Payment_status_message"} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Box> */}
            </Container>
        </React.Fragment>
    )
}

