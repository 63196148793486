import { useEffect, useState } from "react";
import { ApolloGraphQlURI } from "../../Config";

export default function useMatchTimeHook(apiKey, resource) {
    const [response, setResponse] = useState(null);
    const [loading , setLoading ] = useState(false);
    const xhr = new XMLHttpRequest();
          xhr.open('POST', ApolloGraphQlURI.replace("graphql", "matchtime") + resource + "?apiKey=" + apiKey);
          xhr.onload    = () => setResponse(xhr.responseText);
          xhr.onabort   = () => setResponse(xhr.responseText);
          xhr.onerror   = () => setResponse(xhr.responseText);

    useEffect(() => setLoading(false), [response]);

    const mutate = () => {
        setLoading(true);
        xhr.send();
    };
    return [response, loading, mutate];
}