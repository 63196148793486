import * as React from 'react';
import {Box} from "@mui/material";
import clsx from 'clsx';

export default function Image(props) {

    //this has been made into a custom component so we have more control over any custom logic in the future
    //props should include alt and src
  return (
    <Box
    component="img"
    {...props}/>
  );
}
