//This is an intermediate page which sets onboarding status from query if initial query failed and
//there is no onboarding progress from last session
//without this page, the app would default to the onboarding page if no local item has been found or
//if the onboarding query fails, this will display an oops page
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import styles from "../../styles/container/Pages/OopsPage";
import { CircularProgress, CssBaseline } from "@material-ui/core";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import { FormattedMessage } from "react-intl";
import { useIsOnboardingComplete } from "../../apiServices/CommonDataObjects";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function OopsPage() {
  const history = useHistory();
  const classes = useStyles();
  const [query, error] = useIsOnboardingComplete();

  useEffect(() => {
    if(query != undefined) { //when query is done loading
        if(query) {
            localStorage.setItem("onBoardProgress", 4);
            window.location.reload();
        } else {
            history.push("/OnboardingPage");
        }
    }
  }, [query, error]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <Grid item alignContent={"center"} sm={12} mt={6} mx={12}>
            {error ? (
            <Typography
            component="h1"
            variant="h1"
            className={classes.text}
            >
                <FormattedMessage id={"OopsPage.Message"} />
                <br/><br/><br/>
                <ButtonDarkBlue onClick={() => window.location.reload()}>
                    <FormattedMessage id={"Button.TryAgain"} />
                </ButtonDarkBlue>
            </Typography>
            ) : 
            (<CircularProgress color="inherit" size={50} />) }
        </Grid>
      </Container>
    </React.Fragment>
  );
}