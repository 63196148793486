import { createTheme, responsiveFontSizes }
    from '@material-ui/core/styles';

const theme = responsiveFontSizes(createTheme({
    spacing: 4,
    typography: {
        fonts: [
            'Helvetica',
            'Montserrat',
        ],
        fontFamily: 'Montserrat',
        h1: {
            fontSize: 26,
            fontFamily: 'Montserrat', // h1 black white
            fontWeight: 800,
        },
        h2: {
            fontSize: 16,
            fontFamily: 'Montserrat', // h2
            fontWeight: 600,
        },
        h3: {
            fontSize: 16,
            fontFamily: 'Montserrat', // h3
        },
        h4: {
            fontSize: 14,
            fontFamily: 'Montserrat',// .class text black bold:
            fontWeight: "bold",
        },
        h5: {
            fontSize: 14,
            fontFamily: 'Montserrat', // class text black regular
        },
        h6: {
            fontSize: 10,
            fontFamily: 'Montserrat',
        },

        button: {
            textTransform: 'none'
        },
        tab: {
            textTransform: 'none'
        },

    },
    palette: {

        error: {
            main: '#c62828' // red
        },
        primary: {
            main: "rgba(0,0,0,0)"
        },
        secondary: {
            main: '#006080',
            dark: '#002836',
            overlay: 'white'
        },
        background: {
            default: '#e4f1f5',
            dark: '#00394D',
            overlay: 'white',
            specialOverlay: '#5EB0CC'
        },
        text: {
            primary: '#00000',//grey
            secondary: '#828282',
        },
        info: {
            main: "#828282"
        },


        // Custom
        color1: {
            main: '#590A8F' // purple
        },
        color2: { // bright blue
            main: '#72B6CD',
            dark: '#3589a6',
            overlay: 'white'
        },
        color3: {
            main: '#6FB8D1' // light grey
        },
        color4: {
            main: '#E0E0E0' // medium grey
        },
        color5: {
            main: '#006080' // dark grey
        },
        color6: {
            main: '#00000' // black
        },
        colorDynamic: {
            main: localStorage.getItem("circlePrimary") ?? "transparent"
        },
        textFieldInputProps: {
            fontSize: 12,
            fontFamily: 'Helvetica',
            color: "#E0E0E0"
        },
        textFieldInputLabelProps: {
            fontSize: 12,
            fontFamily: 'Helvetica',
            color: "#828282"
        }
    },
    custom: {
        borderRadius: {
            none       : "0px",
            topLeft    : "0px 20px 20px 20px",
            topRight   : "20px 0px 20px 20px",
            bottomRight: "20px 20px 0px 20px",
            bottomLeft : "20px 20px 20px 0px",
        },
    }
}));


export default theme;
