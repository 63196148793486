import React from "react";
import {
  MenuItem,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { ActivitySource } from "../../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

export default function ActivityIntroductionCard({activity, source, isLess, AllActivity, color}) {
  const history = useHistory();
  const intl = useIntl();

  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };
  const navigateToRequest = () => {
    history.push("/dashboard/requests", {
      selectedRequestId: activity.activity.activitySourceId,
    });
  };
  const navigateToThankyou = () => {
    history.push("/sendThankyou", {
      activity: activity,
    });
  };

  if(source == "canCollapse")
    return (activity?.activity?.messages?.length ?? 0) > 150;
  if(source == "menu")
    return <>
      <MenuItem onClick={navigateToMessage}>
        <FormattedMessage id={"Message"} />
      </MenuItem>
      {/* <MenuItem onClick={navigateToThankyou}>
        <FormattedMessage id={"Send_A_Thank_You"} />
      </MenuItem> */}
      <MenuItem onClick={AllActivity}>
        <FormattedMessage id={"ToAllActivities"} />
      </MenuItem>
    </>;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity?.recipientUserProfiles &&
        <Text variant="h2" color={color??"background"} id="WantToIntroduce" values={{
          to_who    : activity.recipientUserProfiles[0].givenName + " " + activity.recipientUserProfiles[0].familyName,
          my_contact: activity.recipientUserProfiles.length > 1 ? 
                      activity.recipientUserProfiles[1].givenName + " " + activity.recipientUserProfiles[1].familyName 
                    : intl.formatMessage({ id: "Not_On_MM_Yet" }),
        }}/>
      }

      {activity?.activity?.messages && 
      <Text item xs={12} color={color??"background"}>
        {isLess && (activity?.activity?.messages?.length ?? 0) > 150 ? 
          activity.activity.messages.substring(0, 150) + "..." : activity.activity.messages}
      </Text>}

      <Text color={color??"background"} id="GoToRequestPast" values={{
        link: <Link
          color="inherit"
          component="button"
          underline="always"
          onClick={navigateToRequest}
        >
          <FormattedMessage id="GoToRequestPast2" />
        </Link>}}
      />

    </ThemeProvider>
  );
}
