import React, { useEffect, useState } from "react";
import Text from "../../../visualComponents/Text";
import { Fade, Zoom } from "@material-ui/core";
import MatchCard from "../../../components/Matchtime/MatchCard";
import { Button, Grid } from "@mui/material";
import { useMatchSuggestionOfCurrentUser } from "../../../apiServices/Queries/IAm/MatchSuggestions";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom";
import Image from "../../../components/Media/Image";
import { useCurrentUserProfile } from "../../../apiServices/CommonDataObjects";
import IntroductionForm from "../../../components/Forms/IntroductionForm";
import GoTo from "../../../apiServices/Navigation/NavigateWithReload";
import { canEditMatchProfiles, didIAmFindMatches, doneSwiping, isIAmMakingMatches, isItMatchTime, suggestionsShouldBeGenerated } from "../../../apiServices/Methods/MatchProfileMethods";

export default function MTResults(props) {
    const [page, setPage] = useState(<Page1 setPage={updatePage}/>);

    function updatePage(page) {setPage(page)}

    return(
    <React.Fragment>
        {page}
    </React.Fragment>);
}

function Page1(props) {
    const [first, setFirst  ]      = useState(false);
    const [mountedData, setMounted  ]      = useState(false);
    const [match, loading, error, refetch] = useMatchSuggestionOfCurrentUser();
    const [loadedCard, setLoaded]          = useState(true);
    const profile                          = useCurrentUserProfile();
    const history                          = useHistory();

    function onClick(status, updateStatus) {
        setLoaded(false);
        if(status == "INTRODUCED")
            props.setPage(<IntroductionForm 
                afterIntroduction = {() => {
                    updateStatus();
                }}
                goBack = {() => props.setPage(<Page1 {...props}/>)}
                userId = {match?.matchMetaData?.userId} 
                pfp    = {match?.matchMetaData?.pfp   } 
                name   = {match?.matchMetaData?.name  }/>);
        setTimeout(() => { //minimum transition duration
            setLoaded(true);
        }, 100);
    }
//Niet getreurd, er komen elke dag nieuwe masters bij. Mag ik je aan hen voorstellen?
    const done  = () => doneSwiping(history.location.state?.meta) || (mountedData && match == null);
    const empty = () => !loading && match == null && !mountedData;

    useEffect(() => {
        if(!first) 
            refetch();
        setFirst(true);

        if(match != null)
            setMounted(true);
    }, [match, loading]);

    return(
    <React.Fragment>
        <Page history={history} done={done()} name={profile?.givenName} empty={empty()}/>    
        {match != null && <Zoom in={loadedCard && !loading && match != null && !error} exit={false}>
        <Grid item container xs={12} p={{xs: 0, sm: 5}} mt={{xs: 10, sm: 5}} justifyContent="center">
            <MatchCard 
                aiGeneratedIntroduction  = {match?.matchMetaData?.aiGeneratedIntroduction}
                aiGeneratedRequest       = {match?.matchMetaData?.aiGeneratedRequest}
                afterUpdate  = {refetch}
                onClick      = {onClick} 
                introduction = {match?.matchMetaData?.introduction} 
                request      = {match?.matchMetaData?.request} 
                imgSrc       = {match?.matchMetaData?.pfp}
                name         = {match?.matchMetaData?.name}
                suggestionId = {match?.id}
                userId       = {match?.matchMetaData?.userId}/>
        </Grid>
        </Zoom>}
        {<Fade in={match == null} timeout={1000}>
            {!suggestionsShouldBeGenerated || done()?
                <Button variant="contained" color="darkPetrol" onClick={() => history.push("/matchtime", {meta: {
                    finishedRandomSwipes: true  
                }})}>
                    <Text item xs={12} align="center" color="primary" variant="body1" id={empty() ? "ok" : "/matchtime/results.button4"} p={1}/>
                </Button>
                :
                <Button variant={empty() ? "contained" : "text"} color={empty() ? "darkPetrol" : "white"} onClick={() => GoTo(history, "/", null, true)}>
                    <Text item xs={12} align="center" color="primary" variant="body1" bold={empty()} id={empty() ? "ok" : "/matchtime/results.button"}  p={1} 
                    sx={{textDecoration: empty() ? "none" : "underline" }}/>
                </Button>
            }
        </Fade>}
    </React.Fragment>);
}

function Page(props) {return(
    <React.Fragment>
        {(props.done || props.empty) && <Zoom in={true}><Image 
            src   = "/assets/img/iam.png"
            width = "80px"/></Zoom>}
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="h1"    bold id={
                props.done? "/matchtime/results.done" : ("/matchtime/results.title" + (
                    props.empty? (
                       !canEditMatchProfiles && !isIAmMakingMatches(props.history.location.state?.meta) && !didIAmFindMatches(props.history.location.state?.meta) ? "3" : "2") : ""
                ))} values={{name: props.name}}/>
        </Fade>
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="body1" id={
                props.done || props.empty? "" : "/matchtime/results.description"}/>
        </Fade>
    </React.Fragment>);}