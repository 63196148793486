import React from "react";
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { GET_USER_SUBSCRIPTIONS } from "../apiServices/Queries";
import { useQuery } from "@apollo/client";
import SuccessAlertDialog from './Alert/SuccessAlertDialog';
import Text from '../visualComponents/Text';
import { Hidden, ThemeProvider } from '@mui/material';
import Theme_WIP from '../styles/themes/main/Theme_WIP';
import Background from "../visualComponents/Background";
import { useGlobalContext } from '../apiServices/Providers/AppGlobalStateProvider';
import { useGetFlag } from "../apiServices/Queries/Profiles/Flags";
import { useSetFlag } from "../apiServices/Mutations/Profiles/Flags";
import { ClearCache } from "../apiServices/CommonMethods";
import { useSubInfo } from "../objects/Subscriptions";

export default function BottomBar(props) {
    const globals = useGlobalContext();
    const userId  = globals.state.loggedInUser.userId;
    let [subscription, setSubscription] = React.useState();
    const [modal, setModal] = React.useState(!localStorage.getItem("acceptedInvites"));
    let acceptedInvites  = globals.state.loggedInUser.acceptedInvites;
    const history = useHistory();
    const flagQuery = useGetFlag("SeenAcceptedInvite");
    const flagMutat = useSetFlag("SeenAcceptedInvite");
    const sub       = useSubInfo();

    useQuery(GET_USER_SUBSCRIPTIONS, {
        variables: {
            userId: userId,
            pageNumber: 1,
            pageSize: 1
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (typeof data !== "undefined" && data.allUserSubscriptions.errorCode === 0) {
                const response = data.allUserSubscriptions.result;
                const userSubscriptions = response.map((userSubscription) => {
                    return createData(
                        userSubscription.subscriptionEndDate,
                        userSubscription.subscriptionType,
                    );
                })
                if(userSubscriptions.length > 0) {
                    setSubscription(userSubscriptions[0]);
                }
            }
        }
    })

    function createData(endDate, subscriptionType) {
        return { endDate, subscriptionType };
    }

    function closeNotification() {
        localStorage.setItem("acceptedInvites", true);
        setModal(false);
        flagMutat.mutate();
        ClearCache("flag");
    }
    
    function notification() {
        return(modal && acceptedInvites && !flagQuery.loading && !flagQuery.data ? (
            <SuccessAlertDialog 
            onClose = {closeNotification}
            message = {<FormattedMessage id={"Premium_Activated_After_Invite"} />} 
            open    = {acceptedInvites} />
        ) : (<span></span>));
    }
    
    return (
        <ThemeProvider theme={Theme_WIP}>
            {(sub?.current?.LowerCaseSubType == sub.all.initialTrial.LowerCaseSubType 
          || (sub?.current?.LowerCaseSubType == sub.all.freemium.LowerCaseSubType && !acceptedInvites)) 
          && !localStorage.getItem("acceptedInvites") &&
            <Background id="bluebar" container color="primary" p={1} sx={{
                zIndex: "9999 !important",
                position: "fixed",
                width: '100vw',
                height: "30px",
                borderStyle: "none",
                top: 0,
                //bottom: 0,
                left: 0,
                right: 0,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
            }}>
                <Hidden mdUp>
                    <Text color="primary" align="center" variant="body2" id="Bottom_Bar_Trial_Countdown_With_Invite" mr={1}/>
                    <Text color="primary" align="center" variant="body2" onClick={() => history.push("/dashboard/profile", {tab: 3})} id="Bottom_Bar_Trial_Countdown_With_Invite_Link" sx={{textDecoration: "underline"}}/>
                </Hidden>
                <Hidden mdDown>
                    <Text color="primary" align="center" variant="body1" id="Bottom_Bar_Trial_Countdown_With_Invite" mr={1}/>
                    <Text color="primary" align="center" variant="body1" onClick={() => history.push("/dashboard/profile", {tab: 3})} id="Bottom_Bar_Trial_Countdown_With_Invite_Link" sx={{textDecoration: "underline"}}/>
                </Hidden>
            </Background>}

            {notification()}
        </ThemeProvider>
    );
}