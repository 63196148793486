const MatchCardStyle = (theme) => ({
    logosmall: {
        width: "100%",
        borderRadius: 6,
    },
    tabcontent: {
        margin: "8px 0 15px 0",
        padding: "10px !important",
    },
    p3: {
        marginTop: 3,
    },
    p15: {
        marginTop: 15,
    },
    violet: {
        color: theme.palette.color1.main,
    },
    grey: {
        color: theme.palette.color5.main,
    },

    margin: {
        marginRight: 4,
        color: theme.palette.color5.main,
    },

    chip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5
    }
});
export default MatchCardStyle;