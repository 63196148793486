import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { useCachedQuery } from "../../CachedQuery";

const Query = gql`query get { collabCategories {
  id
  title
  description
  parentId
} }`

export function useCollabCategories() {
  return useCachedQuery(
      "collabCategories",
      Query,
      {},
      (data) => data?.collabCategories,
      180,
      true
  );
}

export function useCollabCategories_OLD() {
    const {loading, error, data, refetch} = useQuery( Query );
    const [result, setResult] = useState([]);
    useEffect(() => {
      if(!loading && data != undefined && data.collabCategories != undefined)
        setResult(data.collabCategories);
    }, [loading]);
    return [result, loading, refetch];
  }
  