import React from "react";
import { useLazyQuery } from "@apollo/client";
import { useGlobalContext } from "../../Providers/AppGlobalStateProvider";
import { 
    GET_ALL_ACTIVITIES,
    GET_NEW_ACTIVITIES,
    GET_PENDING_ACTIVITIES 
} from "../../Queries";


export default function useGetActivitiesByFilter(state, activitySourceId, activityUserID, pageNumber = 1, pageSize = 10) {
  const globals  = useGlobalContext();
  const userId   = globals.state.loggedInUser.userId;
  const [activities, setActivities] = React.useState([]);


  const getVariables = () => {
    const variable = {
      userId: userId,
      activitySourceId: activitySourceId,
      activityUserID: activityUserID,
      pageNumber: pageNumber,
      pageSize: pageSize
    };
    return variable;
  };

  const getVariablesLatest = () => {
    const variable = {
      userId: userId,
      activitySourceId: activitySourceId,
      activityUserID: activityUserID,
      pageNumber: pageNumber,
      pageSize: pageSize
    };
    return variable;
  };

  const getVariablesToAll = () => {
    const variable = {
      userId: userId,
      activitySourceId: activitySourceId,
      activityUserID: activityUserID,
      pageNumber: pageNumber,
      pageSize: pageSize
    };
    return variable;
  };

  const [fetchNewActivities, { loading }] = useLazyQuery(
    GET_NEW_ACTIVITIES,
    {
      variables: getVariablesLatest(),
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (
          typeof data !== "undefined" &&
          data.latestActivities.errorCode === 0
        ) {
          if (pageNumber === 1) {
            setActivities(data.latestActivities.result);
          } else {
            if (data.latestActivities.result.length) {
              setActivities(prevState => [
                ...prevState,
                ...data.latestActivities.result,
              ]);
            }
          }
        }
      },
    }
  );

  const [fetchPendingActivities, { loading: loadingPendingActivities },] = useLazyQuery(GET_PENDING_ACTIVITIES, {
    variables: getVariables(),
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (dataPendingActivities) => {
      // console.log("dataPendingActivities", dataPendingActivities);

      if (
        typeof dataPendingActivities !== "undefined" &&
        dataPendingActivities.pendingActivities.errorCode === 0
      ) {
        if (pageNumber === 1) {
          setActivities(dataPendingActivities.pendingActivities.result);
        } else {
          if (dataPendingActivities.pendingActivities.result.length) {
            setActivities(prevState => [
              ...prevState,
              ...dataPendingActivities.pendingActivities.result,
            ]);
          }
        }
      }
    },
  });

  const [fetchAllActivities, { loading: loadingAllActivities },] = useLazyQuery(GET_ALL_ACTIVITIES, {
    variables: getVariablesToAll(),
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (dataAllActivities) => {
      // console.log("dataAllActivities", dataAllActivities);

      if (
        typeof dataAllActivities !== "undefined" &&
        dataAllActivities.toAllActivities.errorCode === 0
      ) {
        if (pageNumber === 1) {
          setActivities(dataAllActivities.toAllActivities.result);
        } else {
          if (dataAllActivities.toAllActivities.result.length) {
            setActivities(prevState => [
              ...prevState,
              ...dataAllActivities.toAllActivities.result,
            ]);
          }
        }
      }
    },
  });

    if (state == "new")
        return {fetchActivities: fetchNewActivities, loading: loading, activities: activities};
    else if (state == "pending")
        return {fetchActivities: fetchPendingActivities, loading: loadingPendingActivities, activities: activities};
    else
        return {fetchActivities: fetchAllActivities, loading: loadingAllActivities, activities: activities};

}
