import React, { useEffect } from "react";
import { CssBaseline } from "@material-ui/core";
import Navigationbar from "../../components/Navigationbar";
import { useHistory, useLocation } from "react-router";
import { useMutation } from "@apollo/client";
import {
  SAVE_NEW_SUBCIRCLE,
} from "../../apiServices/mutations";
import { GetUserId } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import FormContainer from "../../components/Forms/FormContainer";
import InputSection from "../../components/Forms/InputSection";
import { Hidden } from "@mui/material";
import Footer from "../../components/Footer";


export default function AddNewSubCircle() {
  const intl = useIntl();
  const userId = GetUserId();
  const history = useHistory();
  const location = useLocation();
  const [parentCircleData, setParentCircleData] = React.useState({});

  useEffect(() => {
    // console.log("data", location.state);

    if (location.state && location.state.parentCircleData) {
      setParentCircleData(location.state.parentCircleData);
    }
  }, [location]);
  const [SubmitSubCircle, { data, loading, error }] =
    useMutation(SAVE_NEW_SUBCIRCLE);

  let [input, setInput] = React.useState({
    users: [],
    name: "",
    type: 1,
  });
  let [inputError, setInputError] = React.useState({
    type: "",
    nameError: "",
  });

  const updateTextFileds = (id, value) => {
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    updateTextFileds(id, value);
  };
  const setMembers = () => {
    const arrayMembers = input["users"].map((user) => ({
      userId: user.userId,
      role: "Moderator",
    }));
    // console.log("arrayMembers", arrayMembers);
    return arrayMembers;
  };

  const SubmitNewSubCircleAction = async () => {
    let isValid = await checkForErrors();

    const variable = {
      name: input["name"],
      circleTypeId: "2",
      members: setMembers(),
      loggedinUser: userId,
      parentCircleId: parentCircleData.id,
    };
    // console.log("Variable :", variable);
    if (isValid) {
      // console.log("isvalid :");
      SubmitSubCircle({
        variables: variable,
      });
    }
  };
  const checkForErrors = async () => {
    // console.log("input", input);
    setInputError((prevState) => ({
      ...prevState,
      name: !input["name"] ? "nameError" : "",
    }));
    return input["name"];
  };
  const searchAction = (searchUser) => {
    // console.log("searchUser", searchUser);
    setInput((prevState) => ({
      ...prevState,
      users: searchUser,
    }));
    setInputError((prevState) => ({
      ...prevState,
      users: "",
    }));
  };
  const handleSucessAlert = () => {
    // console.log("Subcircle save", data.saveSubCircle)
    // history.push("/dashboard/circleDetails", {
    //   circleDetails: { ...data.saveSubCircle.result, "loggedInUserRole": "Moderator" },
    //   selectedTab: 7,
    // });
    history.push("/dashboard/circleDetails/?id=" + data.saveSubCircle.result.id, {
      circleDetails: { ...data.saveSubCircle.result, "loggedInUserRole": "Moderator" },
      selectedTab: 7,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        modalMessage = {data?.saveSubCircle?.errorCode == 0 ? <FormattedMessage id="CircleCreatedSuccessfully"/> : undefined}
        onModalClose = {handleSucessAlert}
        error        = {error || (data?.saveSubCircle?.errorCode == -1) ? data?.saveSubCircle?.message ?? <FormattedMessage id="somethingWentWrong"/> : undefined}
        title        = {<FormattedMessage id="AddNewSubCircle_CreateCircle"/>}
        loading      = {loading}
        onSave       = {SubmitNewSubCircleAction}
        disabled     = {loading || !input.name}
        onCancel     = {() => history.goBack()}
        saveId       = "Button_Text_Create"
      >
        <InputSection
        type        = "text"
        disabled    = {loading}
        id          = "name"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<><FormattedMessage id="AddNewSubCircle_NameCircle"/>*:</>}
        onChange    = {handleChange}
        hint        = {inputError["name"] ? <FormattedMessage id="PleaseEnterTheCircleName" /> : undefined}
        max         = {250}
        />
        <InputSection
        type        = "circlemember"
        disabled    = {loading}
        id          = "member"
        title       = {<FormattedMessage id="AddNewSubCircle_ToWho"/>}
        description = {<FormattedMessage id="AddNewSubCircle_FieldDescription"/>}
        options     = {parentCircleData}
        onChange    = {searchAction}
        placeholder = {intl.formatMessage({ id: "Name" })}
        />
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
