import React from "react";
import { CssBaseline, Grid, ThemeProvider } from "@mui/material";
import Background from "./Background";
import Theme_WIP from "../styles/themes/main/Theme_WIP";

export default function Tile(props) {
    const {children} = props;
  return (
    <CssBaseline>
    <ThemeProvider theme={Theme_WIP}>
        <Grid item container {...props} p={3} zIndex="-50 !important" position="relative">
            <Background color="white" border="bottomRight" p={5} overflow="hidden">
                {children}
            </Background>
        </Grid>
    </ThemeProvider>
    </CssBaseline>
  );
}