import { Grid, IconButton, useTheme } from "@mui/material";
import Background from "../../visualComponents/Background";
import Image from "../Media/Image";
import Text from "../../visualComponents/Text";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Avatar, Grow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSaveRandomSuggestion, useSetSuggestionStatus } from "../../apiServices/Mutations/IAm/SetSuggestionStatus";
import { useCreateYesActivity } from "../../apiServices/Mutations/Activities/YesActivity";
import { GetUserId } from "../../apiServices/CommonMethods";

export default function MatchCard(props) {
    const theme               = useTheme();
    const imgSrc              = props.imgSrc        ?? "/logo512.png";
    const name                = props.name          ?? "";
    const introductionProfile = props.introduction  ?? "";
    const requestProfile      = props.request       ?? "";
    const userId              = props.userId; //of the user displayed on card
    const suggestionId        = props.suggestionId;
    const matchId             = props.matchId; //only used for random swipes
    const requestId           = props.requestId; //only used for random swipes
    const loggedInId          = GetUserId();
    const aiGeneratedIntroduction = props.aiGeneratedIntroduction;
    const aiGeneratedRequest      = props.aiGeneratedRequest;

    const [hint, setHint]     = useState(false);

    const [status, setStatus] = useState("PENDING");

    const saveRandomSuggestion            = useSaveRandomSuggestion(suggestionId, requestId, matchId, status, (data_) => {
        if(props.afterUpdate)
            props.afterUpdate();
    });

    const {data, loading, error, mutate}  = useSetSuggestionStatus(suggestionId, status, (data_) => {
        if(data_?.setSuggestionStatus && props.afterUpdate)
            props.afterUpdate();
    });
    const yesActivity                     = useCreateYesActivity  (loggedInId, userId, suggestionId);


    const onClick = (status) => {
        setStatus(status);
    }

    useEffect(() => {
        if(!loading && !yesActivity.loading && !saveRandomSuggestion.loading) {
            if(status == "ACCEPTED") {
                    yesActivity.mutate();
                    matchId? saveRandomSuggestion.mutate() : mutate()
            } else if(status == "REJECTED")
                    matchId? saveRandomSuggestion.mutate() : mutate();
            if(status == "ACCEPTED" || status == "INTRODUCED" || status == "REJECTED")
                props.onClick(status, () => matchId? saveRandomSuggestion.mutate() : mutate()); //This is to allow for delayed mutation in introductions
            setStatus("PENDING");
        }
    }, [status, loading, data, yesActivity, saveRandomSuggestion]);
    return(
    <Background item xs={12} p={5} color="rgba(255, 255, 255, 0.80)" border="topRight" sx={{maxWidth: "450px", minWidth: "250px"}}>
    <Background item xs={12} p={5} color="white"                     border="topRight" container justifyContent="center" gap={6}>
        <Image
            onClick = {() => window.location.href = "/dashboard/profile/?id=" + userId}
            src     = {imgSrc}
            alt     = "pfp"
            sx      = {{ cursor: "pointer", marginTop: "-70px", marginBottom: "5px", borderRadius: theme.custom.borderRadius.bottomLeft, aspectRatio: "1 / 1" }}
            width   = "90px"/>
        {name && <Text item xs={12} variant="h2" color="white" bold align="center" mt={-2} >{name}</Text>}
        {aiGeneratedIntroduction && 
        <Text item xs={12} variant="body3" color="background" align="center" mt={-4} id="AiGeneratedText"/>}
        <Text item xs={12} variant="body1" color="black"      align="center"     >{introductionProfile}</Text>
        {aiGeneratedRequest && 
        <Text item xs={12} variant="body3" color="background" align="center" mt={-4} id="AiGeneratedText"/>}
        <Text item xs={12} variant="body1" color="black"      align="center" bold>{requestProfile     }</Text>

        <Grid item xs={12} mb={5} container justifyContent="center" gap={{xs: 0, sm: 3, md: 6}}>
            <IconButton 
            onClick={() => onClick("REJECTED")}
            aria-label="reject" 
            color="primary" 
            sx={{transition: "all 1s", scale: "1.5", marginTop: 6, '&:hover': {scale: "1.75"}}}>
                <Avatar src="/assets/img/matchtime/cancel.png"/>
            </IconButton>

            <IconButton 
            onClick={() => onClick("INTRODUCED")}
            onMouseEnter = {() => setHint(true )}
            onMouseLeave = {() => setHint(false)}
            aria-label="match" 
            color="primary" 
            sx={{transition: "all 1s", scale: "1", marginTop: 6, '&:hover': {scale: "1.25"}}}>
                <Avatar src="/assets/img/matchtime/matchicon.png"/>
            </IconButton>

            <IconButton
            onClick={() => onClick("ACCEPTED")}
            aria-label="accept" 
            color="primary" 
            sx={{transition: "all 1s", scale: "1.5", marginTop: 6, '&:hover': {scale: "1.75"}}}>
                <Avatar src="/assets/img/matchtime/check.png"/>
            </IconButton>
        </Grid>
        <Grow in={hint} timeout={500}>
            <Text item xs={12} mt={-4} variant="body3" bold id="MatchCard.hint" color="#FF7C06" align="center"/>
        </Grow>
    </Background>
    </Background>
    );
}