import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { useGlobalContext } from "../../Providers/AppGlobalStateProvider";
import { useCachedQuery } from "../CachedQuery";

export function useUserMetaData() {
  return useCachedQuery(
    "metadata",
    gql`query getmeta {
      userMetaData {
        connector
        hasCompanies
      }
    }`,
    {},
    (data) => data?.userMetaData,
    5,
    true
  );
}
  
export function useHasCompanyAccess() {
    const globals    = useGlobalContext();   
    const userId     = globals.state.loggedInUser.userId;
    const admin      = globals.state.admin.userId;
    const {data: metaData, loading: loading} = useUserMetaData();

    return {
        accessToCompanies: (admin == userId || metaData?.connector || metaData?.hasCompanies),
        myCompaniesOnly: (metaData?.hasCompanies && !metaData?.connector) ?? true,
        loading: metaData == null || loading
    };
}