import React, { useState } from "react";
import QRCode from "qrcode.react";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROFILE_INVITATION_LISTS } from "../../apiServices/Queries";
import { Button, Stack, ThemeProvider } from "@mui/material";
import {
  CircularProgress,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import {
  SAVEPROFILE_INVITE,
  DELETE_PROFILE_INVITE,
} from "../../apiServices/mutations";
import { isEmail } from "../General";
import { SHARE_PROFILE_URL } from "../../Config";
import { userRoles } from "../../apiServices/Constants";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import { useHasExtendedTrial } from "../../apiServices/Queries/HasExtendedTrial";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import InputSection from "../Forms/InputSection";
import Modal from "../modals/Modal";


export default function InviteTab(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const intl = useIntl();
  const [email       , setEmail       ] = React.useState([]);
  const [message     , setMessage     ] = React.useState("");
  const [inviteList  , setInviteList  ] = React.useState([]);
  const [modal       , setModal       ] = React.useState(false);
  const [inputError  , setInputError  ] = React.useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = React.useState(false);
  const [isEmailError, setIsEmailError] = React.useState(false);
  const [page        , setPage        ] = useState(0);
  const pageSize = 10;
  const [extendedTrial , loadingTrial      , trialError] = useHasExtendedTrial();
  const [shareUrl, setShareUrl] = React.useState(SHARE_PROFILE_URL + userId); //this is save for now since the invite page only appears if the user owns this page

  const { loading, refetch } = useQuery(
    GET_PROFILE_INVITATION_LISTS,
    {
      variables: {
        requestedUserId: userId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (
          typeof data !== "undefined" &&
          data.profileInvitationList.errorCode === 0
        ) {
          // console.log("data.profileInvitationList", data.profileInvitationList)
          const response = data.profileInvitationList.result;
          setInviteList(response);
        }
      },
    }
  );

  React.useEffect(() => {
    if (props?.profileInfo?.userId) {
      setShareUrl(SHARE_PROFILE_URL + props.profileInfo.userId);
    }
  }, [props?.profileInfo?.userId]);

  const onChange = (event, newvalue) => {
    setIsEmailEmpty(false);
    setInputError(false);
    setEmail(newvalue);
  };

  const [saveInvite, {data: saveinvitedata, loading: saveinviteloading}] = 
  useMutation(SAVEPROFILE_INVITE, {
    onCompleted: (data) => {
      // console.log("sucess", data);
      if (
        typeof data !== "undefined" &&
        data.saveProfileInvite.errorCode === 0
      ) {
        // console.log("sucess");
        setEmail([]);
        setMessage("");
        setModal(true);
        setIsEmailError(false);
      }
      if (
        typeof data !== "undefined" &&
        data.saveProfileInvite.errorCode === -1
      ) {
        // console.log("error");
        setEmail([]);
        setMessage("");
        setIsEmailError(true);
      }
      refetch();
    },
  });

  const [deleteInvite] = useMutation(DELETE_PROFILE_INVITE, {
    onCompleted: (data) => {
      // console.log(data);
      if (
        typeof data !== "undefined" &&
        data.deleteInvitation.errorCode === 0
      ) {
        // console.log("sucess", data);
        refetch();
      }
    },
  });

  const sendInvite = () => {
    const isEmailEmpty = email.length === 0 ? true : false;
    setIsEmailEmpty(isEmailEmpty);
    if (!isEmailEmpty) {
      const emailFilter = email.map((option) =>
        !isEmail(option) ? "notvalidemail" : ""
      );
      let result = emailFilter.includes("notvalidemail");
      const isValidemail = result ? true : false;
      setInputError(isValidemail);
      if (isValidemail === false) {
        setIsEmailError(false);
        const variables = {
          invitedMembers: email,
          requestedUserId: userId,
          message: message
        };
        // console.log("saveInviteVariables", variables);
        saveInvite({
          variables: variables,
        });
      }
    } else {
      setIsEmailError(false);
    }
  };
  const deleteInvitations = (data) => {
    const variables = {
      id: data.id,
      userID: data.invitedMembers[0].userId,
      requestedUserId: data.requestedUserId,
    };
    // console.log("saveInviteVariables", variables);
    deleteInvite({
      variables: variables,
    });
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Modal open={modal} onClose={() => setModal(false)}><Text color="background" id="Success" variant="h1" bold/></Modal>
    <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.lg} height="fit-content" columnGap={"50px"}>
      {!loadingTrial && <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.sm}>
        <Text item xs={12} variant="h1"    color="background" id={"ProfileInvite_intro_title"       + (extendedTrial ? "2":"")} bold/>
        <Text item xs={12} variant="body1" color="background" id={"ProfileInvite_intro_description" + (extendedTrial ? "2":"")}/>
      </Grid>}

      <Text item xs={12} variant="h1" bold color={ThemeConstants.palette.lightPetrol.main} id="Profile/Invite.Title2" />
      <Grid container item rowGap={ThemeConstants.custom.spacings.sm} height="fit-content" maxWidth="120px">
        <Text item xs={12} bold variant="body1" id="CopyUrl" color="background"/>
        <Button variant="contained" onClick={() => {navigator.clipboard.writeText(shareUrl);}}>
            <FormattedMessage id={"Copy_Link"} />
        </Button>
      </Grid>
      <Grid container item rowGap={ThemeConstants.custom.spacings.sm} maxWidth="120px">
        <Text item xs={12} bold variant="body1" id="CopyQR" color="background"/>
        <QRCode
        id="qrCodeId"
        value={shareUrl}
        size={100}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"H"}
        includeMargin={true}
        />
      </Grid>

      {/* email */}
      <Text item xs={12} variant="h1" color={ThemeConstants.palette.lightPetrol.main} id="Profile/Invite.MailTitle" bold/>
      <InputSection
        inputProps  = {{color: "white"}}
        placeholder = {intl.formatMessage({id: "Type"})}
        type        = "multipleText"
        title       = {userRole === userRoles.admin ? <FormattedMessage id="ProfileInvite_Admin_title" /> : <FormattedMessage id="ProfileInvite_title" />}
        description = {<FormattedMessage id={"Invite_Description"} />}
        onChange    = {onChange}
        id          = "emailIds"
        disabled    = {saveinviteloading}
        hint        = {
          (isEmailError && saveinvitedata.saveProfileInvite.message)
        ||(isEmailEmpty && <FormattedMessage id={"invite_email_error"} />)
        ||(inputError   && <FormattedMessage id={"invite_email_valid_error"} />)
        }
      />
      <InputSection
        inputProps  = {{color: "white"}}
        placeholder = {intl.formatMessage({id: "Type"})}
        type        = "textarea"
        title       = {<FormattedMessage id="OnboardingInvite.message.label" />}
        description = {<FormattedMessage id="Profile/Invite.MailDescription" />}
        onChange    = {(value) => setMessage(value.target.value)}
        value       = {message}
        max         = {300}
        id          = "emailIds"
        disabled    = {saveinviteloading}
        hint        = {
          (isEmailError && saveinvitedata.saveProfileInvite.message)
        ||(isEmailEmpty && <FormattedMessage id={"invite_email_error"} />)
        ||(inputError   && <FormattedMessage id={"invite_email_valid_error"} />)
        }
      />
      <Grid item>
        <Button disabled={saveinviteloading || !email.length} variant="contained" onClick={sendInvite}>
          <FormattedMessage id="Button_text_Send"/>
        </Button>
      </Grid>

      <Grid container item xs={12} gap={ThemeConstants.custom.spacings.xs}>
        {loading && !inviteList.length ? (
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              flex: "row",
              justifyContent: "center",
              width: "100%",
              height: 300,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <CircularProgress />{" "}
            </Stack>
          </Box>
        ) :
            inviteList.length > 0 &&  <>
            <Text item xs={12} variant="body1" color="background" bold id="Profile/Invite.Invites"/> 
            {(inviteList.map((data, index) => (
              index >= page*pageSize && index < (page+1)*pageSize && <Grid container>
                <Grid item sm={9} xs={8}>
                  <Text component="body1" color="background" noWrap sx={{textDecoration: "underline"}}>
                    {data.invitedMembers[0].userId}
                  </Text>
                </Grid>
                <Grid item sm={3} xs={4}>
                  {data.invitedMembers[0].inviteStatus === "PENDING" ? (
                    <Text variant="body1" color="background" id="InviteTab_Pending" />
                  ) : (
                    ""
                  )}
                  {data.invitedMembers[0].inviteStatus === "ACCEPTED" ? (
                    <Text variant="body1" color="background" id="InviteTab_Accepted" />
                  ) : (
                    ""
                  )}
                </Grid>
                {/* {data.invitedMembers[0].inviteStatus === "PENDING" ? (
                  <Grid item sm={2} xs={2}>
                    <Typography color={"textSecondary"}>
                      <CloseIcon
                        className={classes.icoClose}
                        onClick={() => deleteInvitations(data)}
                      />
                    </Typography>
                  </Grid>
                ) : null} */}
              </Grid>
            ))
          )}</>
          }
          {inviteList.length > 0 &&
          <Grid xs={12} my={4} justifyContent={"space-between"} container>
            <Button disabled={page == 0} onClick={() => setPage(page - 1)}>Vorige</Button>
            <Button>Pagina {page + 1}/{Math.floor(inviteList.length/pageSize) + 1}</Button>
            <Button disabled={(page+1)*pageSize >= inviteList.length} onClick={() => setPage(page + 1)}>Volgende</Button>
          </Grid>
          }
      </Grid>
    </Grid>
    </ThemeProvider>
  );
}
