import { ClearCache } from "../../CommonMethods";
import { useGlobalContext } from "../../Providers/AppGlobalStateProvider";
import { GET_COLLAB, GET_COMPANIES, GET_PROFILE } from "../../Queries";
import { useCachedQuery } from "../CachedQuery";

export function clearSelfProfileCache() {ClearCache("selfProfile");}

export function useGetProfile(userId, requesterId) {
    const globals = useGlobalContext();
    return useCachedQuery(
        globals.state.loggedInUser?.userId == userId? "selfProfile" : "profile",
        GET_PROFILE,
        requesterId ? {userId: userId, loggedInUserId: requesterId} : {userId: userId},
        (data) => data?.profile,
        30,
        true
    );
}
export function useGetProfileCompanies(userId) {
    const globals = useGlobalContext();
    return useCachedQuery(
        globals.state.loggedInUser?.userId == userId? "selfProfile_company" : "profile_company",
        GET_COMPANIES,
        {userId: userId},
        (data) => data?.company?.result?.companies,
        30,
        true
    );
}
export function useGetProfileCollabs(userId) {
    const globals = useGlobalContext();
    return useCachedQuery(
        globals.state.loggedInUser?.userId == userId? "selfProfile_collabs" : "profile_collabs",
        GET_COLLAB,
        {userId: userId},
        (data) => data?.allCollaborationInterest?.result,
        30,
        true
    );
}