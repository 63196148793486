import { GET_ACTIVITY_USERS } from "../../Queries";
import { useCachedQuery } from "../CachedQuery";

  
export function useGetActivityUsers(variables) {
    return useCachedQuery(
        "activityUsers",
        GET_ACTIVITY_USERS,
        variables,
        (data) => data?.toWhoProfileListing?.result, 
        15,
        true
    );
  }
  