import React, { useEffect, useState } from "react";
import { getUser, signinRedirectCallback } from "./userService";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { loginType } from "../../apiServices/Constants";
import { GET_ONBOARDING_STATUS, GET_PROFILE_INVITATION_LISTS } from "../../apiServices/Queries";
import { useLazyQuery } from "@apollo/client";
//import { GetUserId } from "../../apiServices/CommonMethods";

function SigninOidc() {
  const history = useHistory();
  const [token, setToken] = useState();

  const [profileDetails, { data }] = useLazyQuery(GET_ONBOARDING_STATUS, {
    variables: {
      userId: token,
      loggedInUserId: null,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // console.log("signinodc data", data);
      if (typeof data.onboardingStatus !== "undefined" && data.onboardingStatus.errorCode === 0
        && data.onboardingStatus.result.isOnboardingProcessCompleted === true) {
        // console.log("Profile entered");
        localStorage.setItem("onBoardProgress", 4);
        inviteDetails()
      } else {
        // console.log("Profile not entered");
        // localStorage.getItem("onBoardProgress") === null? then stay on onboarding page, else get handled by the onboarding stepper
        history.push("/OnboardingPage");
      }
    },
  });

  const [inviteDetails] = useLazyQuery(
    GET_PROFILE_INVITATION_LISTS,
    {
      variables: {
        requestedUserId: token,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (
          typeof data !== "undefined" && data.profileInvitationList.errorCode === 0 &&
          data.profileInvitationList.result.length > 1
        ) {
          localStorage.setItem("onBoardProgress", 4);
          history.push("/dashboard/masters");
        //we can't downgrade this. would be weird flow. therefore use this check
        } else if((localStorage.getItem("onBoardProgress") ?? 0) < 1) {
          localStorage.setItem("onBoardProgress", 1);
          history.push("/OnboardingStepper");
        }
        window.location.reload();
      },
    }
  );
  useEffect(() => {
    async function signinAsync() {
      try {
        forceRedirectIfStalling(); //for some reason signinRedirectCallback() works but throws a blocking exception anyway
        await signinRedirectCallback();
      } finally {
        getUser().then((user) => {
          if(user == null)
            window.location.href = "/";
          if (user && typeof user["profile"] !== "undefined") {
            setToken(user["profile"].sub);
            localStorage.setItem("accessToken", user["access_token"]);
            localStorage.setItem("refreshToken", user["refresh_token"])
            localStorage.setItem("loginType", loginType.idserver);
            //only use the onboarding state from the API if we have no active memory of user's progress
            //currently we deduce onboarding state from related data. It is not an explicit field
            //if we expand our data model to include this field, we revisit this logic
            if(localStorage.getItem("onBoardProgress") == null)
              profileDetails();
          }
        });
      }
    }
    signinAsync();
  }, [history, data]);

  function forceRedirectIfStalling () { //used as a bandaid for some broken oidc implementation
    return new Promise(() => {
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    });
  }

  return (
    <div>
      <Typography component="h2" variant="h2">
        <FormattedMessage id={"redirecting"} />
      </Typography>
    </div>
  );
}

export default SigninOidc;
