const AttendEventStyle = (theme) => ({
  submit: {
    marginBottom: 20,
    color: "#fff",
    fontSize: 13,
    padding: "6px 0 6px 0",
  },
  mt7: {
    marginTop: "7px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  mt30: {
    marginTop: "30px",
  },
  closeicon: {
    float: "right",
    fontSize: 32,
    color: "#bdbdbd",
  },
  Select: {
    width: "100%",
  },
  txtarea: {
    width: "96%",
    border: "none",
    backgroundColor: theme.palette.color3.main,
    borderRadius: 6,
    padding: "2%",
  },
  num: {
    color: "white",
    padding: 5,
    backgroundColor: theme.palette.color2.main,
    borderRadius: 3,
    marginRight: 30
  },
  stepNo: {
    backgroundColor: theme.palette.color2.main,
    color: '#fff',
    marginRight: 10,
    borderRadius: 4,
    padding: "2px 7px 1px 7px",
  },
  P35L35: {
    marginTop: 35,
    paddingLeft: 35,
  },
  P20L35: {
    marginTop: 20,
    paddingLeft: 0,
  },
});

export default AttendEventStyle;
