import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { ButtonDarkBlue } from '../Buttons/ButtonDarkBlue';
import { Box } from '@mui/system';
import { useHistory } from 'react-router-dom';
import Header from '../Header';
import DatePicker from '../DatePicker/DatePicker'
import { Hidden } from '@mui/material';
import Navigationbar from '../Navigationbar';
import Footer from '../Footer';
import { CssBaseline } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mt7: {
        marginTop: "7px",
    },
    mt10: {
        marginTop: "10px",
    },
    closeicon: {
        float: 'right',
        fontSize: 32,
        color: "#bdbdbd",
        '@media (max-width: 599px)': {
            position: 'absolute',
            top: "10%",
        },
    },
    Select: {
        width: "100%",
    },
    txtarea: {
        border: 'none',
        backgroundColor: "#e8e8e8",
        borderRadius: 5,
        padding: "2%",
    },
}));

export default function AddTask() {
    const classes = useStyles();
    const history = useHistory();

    return (
        <React.Fragment>
            <CssBaseline />
            <Navigationbar />
            <Container maxWidth="sm" >
                <Box sx={{ mt: 10, mb: 10, marginX: 2 }}>
                    <Grid container spacing={4} >
                        <Grid item sm={11} xs={10}>
                            <Typography component="h1" variant="h1" color={"textPrimary"}>
                                Plan a task for yourself.
                            </Typography>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            <CloseIcon className={classes.closeicon} onClick={() => history.goBack()} />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Typography component="h3" variant="h3" color={"textPrimary"} className={classes.mt10}>
                                Description field description field description field description field description field description field
                                description field description field description field description field
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography component="h3" variant="h3" color={"textPrimary"} className={classes.mt10}>
                                Related To*:
                            </Typography>
                            <Typography component="h3" variant="h3" color={"textPrimary"} className={classes.mt7}>
                                <FormControl variant="filled" className={classes.Select}>
                                    <TextField id="filled-basic" label="Type name..." variant="filled" size="small" />
                                </FormControl>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography component="h3" variant="h3" color={"textPrimary"} className={classes.mt10}>
                                Write note*:
                            </Typography>
                            <Typography component="h3" variant="h3" color={"textPrimary"} className={classes.mt7}>
                                <FormControl variant="filled" className={classes.Select}>
                                    <TextareaAutosize
                                        variant="filled"
                                        aria-label="minimum height"
                                        minRows={5}
                                        placeholder="Write note..."
                                        className={classes.txtarea} />
                                </FormControl>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography component="h3" variant="h3" color={"textPrimary"} className={classes.mt10}>
                                Remind me*:
                            </Typography>
                            <Typography component="h3" variant="h3" color={"textPrimary"} className={classes.mt7}>
                                <FormControl variant="filled" className={classes.Select}>
                                    <DatePicker />
                                </FormControl>
                            </Typography>
                        </Grid>
                        <Grid item sm={3} xs={9}>
                            <ButtonDarkBlue>Save</ButtonDarkBlue>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Hidden mdUp>
                <Footer />
            </ Hidden >
        </React.Fragment>
    );
}
