
import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Button, CssBaseline } from "@mui/material";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";

export default function StyleExpo(props) {
  return (
    <React.Fragment>
    <CssBaseline>
    <ThemeProvider theme={Theme_WIP}>
        <Background page/>
        
        <Grid container>
            <Text item sm={12} variant="h1" m={3}>Color Palette</Text>
            <Background 
            color="darkPetrol" border="bottomLeft"
            container item alignContent="center" sm={12} p={5} m={6}>
                <Background 
                border="bottomRight" 
                item alignContent="center" sm={6} p={5}>
                    <Text variant="h1" bold>Testing <b>Testing</b></Text>
                    <Text variant="h2">Testing <b>Testing</b></Text>
                    <Text>Testing <b>Testing</b></Text>
                    <Text variant="body2">Testing <b>Testing</b></Text>
                </Background>
                <Background 
                color="lightPetrol" border="bottomLeft" 
                item alignContent="center" sm={6} p={5}>
                    <Text variant="h1" color="lightPetrol">Testing <b>Testing</b></Text>
                    <Text variant="h2" color="lightPetrol">Testing <b>Testing</b></Text>
                    <Text color="lightPetrol">Testing <b>Testing</b></Text>
                    <Text variant="body2"color="lightPetrol">Testing <b>Testing</b></Text>
                </Background>

                <Background 
                color="primary" border="topRight" 
                item alignContent="center" sm={6} p={5}>
                    <Text variant="h1" color="primary">Testing <b>Testing</b></Text>
                    <Text variant="h2" color="primary">Testing <b>Testing</b></Text>
                    <Text color="primary">Testing <b>Testing</b></Text>
                    <Text variant="body2"color="primary">Testing <b>Testing</b></Text>
                </Background>
                <Background 
                color="lighterPetrol" border="topLeft" 
                item alignContent="center" sm={6} p={5}>
                    <Text variant="h1" color="lighterPetrol">Testing <b>Testing</b></Text>
                    <Text variant="h2" color="lighterPetrol">Testing <b>Testing</b></Text>
                    <Text color="lighterPetrol">Testing <b>Testing</b></Text>
                    <Text variant="body2"color="lighterPetrol">Testing <b>Testing</b></Text>
                </Background>

                <Background 
                color="background" border="bottomRight"
                container item alignContent="center" sm={12} p={5} mt={5}>
                    <Grid item sm={6}>
                        <Text variant="h1" color="background">Testing <b>Testing</b></Text>
                        <Text variant="h2" color="background">Testing <b>Testing</b></Text>
                        <Text color="background">Testing <b>Testing</b></Text>
                        <Text variant="body2"color="background">Testing <b>Testing</b></Text>
                    </Grid>
                    <Grid item sm={6}>
                        <Text variant="h1" color="lightPetrol">Testing <b>Testing</b></Text>
                        <Text variant="h2" color="lightPetrol">Testing <b>Testing</b></Text>
                        <Text color="lightPetrol">Testing <b>Testing</b></Text>
                        <Text variant="body2"color="lightPetrol">Testing <b>Testing</b></Text>
                    </Grid>
                </Background>

                <Background 
                color="darkPetrol" border="bottomLeft"
                container item alignContent="center" sm={12} p={5} mt={5}>
                    <Grid item sm={6}>
                        <Text variant="h1" color="darkPetrol">Testing <b>Testing</b></Text>
                        <Text variant="h2" color="darkPetrol">Testing <b>Testing</b></Text>
                        <Text color="darkPetrol">Testing <b>Testing</b></Text>
                        <Text variant="body2"color="darkPetrol">Testing <b>Testing</b></Text>
                    </Grid>
                    <Grid item sm={6}>
                        <Text variant="h1" color="primary">Testing <b>Testing</b></Text>
                        <Text variant="h2" color="primary">Testing <b>Testing</b></Text>
                        <Text color="primary">Testing <b>Testing</b></Text>
                        <Text variant="body2"color="primary">Testing <b>Testing</b></Text>
                    </Grid>
                </Background>
            </Background>

            <Text id="testing.string" variant="h2" item sm={12} m={2} mt={0} values={{five: "5"}}/>

            <Grid item sm={12} mx={5}>
                <Button color="primary" variant="contained">test</Button>
                <Button color="darkPetrol" variant="contained">test</Button>
                <Button color="lightPetrol" variant="contained">test</Button>
                <Button color="lighterPetrol" variant="contained">test</Button>
                <Button color="background" variant="contained">test</Button>
                <Button color="white" variant="contained">test</Button>
            </Grid>
        </Grid>
    </ThemeProvider>
    </CssBaseline>
    </React.Fragment>
  );
}