import React from "react";
import Link from "@mui/material/Link";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

export default function ActivityMakeEventManager({activity, source, color}) {
  const history = useHistory();

  const getMessage = () => {
    if (activity.activity.messages === "Made you event manager of the event") {
      return "activity_event_manager_message";
    } else if (activity.activity.messages === "Leave from Event") {
      return "activity_event_not_attending";
    } else if (activity.activity.messages === "Leave/Removed from Event") {
      return "activity_event_remove_event";
    } else if (activity.activity.messages === "Accepted Event Invitation") {
      return "activity_event_invite_accept1";
    } else if (activity.activity.messages === "Event Invitation Declined") {
      return "DeclinedTheInvitationOfTheEvent";
    } else if (activity.activity.messages === "Upcoming Event Reminder") {
      return "Upcoming_Event_Description1";
    } else if (activity.activity.messages === "Event Closed") {
      return "ActivityMakeEventManager_Description";
    }
  };
  const navigateToEventDetail = () => {
    history.push("/dashboard/eventDetails/?id=" + activity.activity.eventId, {
      selectedEventId: activity.activity.eventId,
    });
  };

  const placeholders = {event_name: <Link
    color="inherit"
    component = "button"
    underline = "always"
    onClick={navigateToEventDetail}
  >
    {activity.activity.eventName}
  </Link>};

  if(source == "menu")
    return undefined;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Text color={color??"background"} id={getMessage()} values={placeholders}/>
    </ThemeProvider>
  );
}
