import { gql } from "@apollo/client";
import { useCachedQuery } from "./CachedQuery";

export function useAdminMetaData() {
    return useCachedQuery(
      "systeminfo",
      gql`query get { systemInfo {
        preregisterVideoId
    } }`,
    {},
    (data) => data?.systemInfo,
    180,
    true
  );
}
  