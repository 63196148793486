import { GET_CIRCLE_INFO } from "../../Queries";
import { useCachedQuery } from "../CachedQuery";

export function useGetCircle(id, userId) {
  return useCachedQuery(
    "circle",
    GET_CIRCLE_INFO,
    { circleId: id, loggedInUser: userId },
    (data) => data?.circleInfo?.result,
    30,
    true
  );
}
  